<template>
  <div class="station-container">
    <StationHeader v-if="selectedNode.type == productCodes.station" />
    <GroupHeader v-else />
    <div class="box-body">
      <div class="box-content">
        <StationBase v-if="showStationMenu == 'base'" />
        <StationConfig v-else-if="showStationMenu == 'config'" />
        <StationInfo v-else-if="showStationMenu == 'info'" />
        <StationControl v-else-if="showStationMenu == 'control'" />
        <StationSearch v-else-if="showStationMenu == 'search'" />
        <StationAnalyse v-else-if="showStationMenu == 'analyse'" />
        <StationAlarm v-else-if="showStationMenu == 'alarm'" />
      </div>
    </div>
    <Spin fix v-if="showSpin">{{spinContent}}</Spin>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import StationHeader from './header/Station'
import GroupHeader from './header/Group'
import StationBase from './base/Index'
import StationConfig from './config/Index'
import StationInfo from './info/Index'
import StationControl from './control/Index'
import StationSearch from './search/Index'
import StationAnalyse from './analyse/Index'
import StationAlarm from './alarm/Index'
export default {
  name: 'StationIndex',
  components: {
    StationHeader,
    GroupHeader,
    StationBase,
    StationConfig,
    StationInfo,
    StationControl,
    StationSearch,
    StationAnalyse,
    StationAlarm,
  },
  props: {
  },
  data() {
    return {
      showArgModal: false,
      showLightArgModal: false,
      showMonitorModal: false,
      cmdItem: {},
      tabName: 'power', //组态标签页：power = 配电柜，light = 单灯
      lightStyle: 'config', //单灯标签页： config = 组态，list = 列表
      showControlTimeModal: false,
      showViewTimeModal: false,
      modal: 'alarm', //base, ctrl, alarm, analysis,
      orders: [],
      remotes: [],
      pageId: 0,
      deviceCode: '',
      pages: [],
      commandItem: {},
      info: { organs: '', road: '', rule: {} },
      interval: null,
    }
  },
  computed: {
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['weeks', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode', 'groupLoaded']),
    ...mapState('auth', ['showStationMenu', 'showSpin', 'spinContent', 'spinTimeout']),
  },
  watch: {
    selectedNode() {
      // debugger;
      if (this.selectedNode.type!="Station" && this.showStationMenu=="info") {
           this.$store.commit('auth/setStationMenu', "base");
      }
    },
    showSpin(){
      if(this.showSpin && this.spinTimeout){
        setTimeout(() => {
          if(this.showSpin){
            this.$store.commit('auth/hideSpin');
            this.$Modal.error({
                title: '超时',
                content: '等待处理结果超时，请重试'
            });
          }
        }, this.spinTimeout*1000);
      }
    },
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    switchTab: function (name) {
      this.tabName = name;
    },
    switchStyle: function (name) {
      this.lightStyle = name;
    },
  }
}
</script>
<style scoped>
.station-container {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  padding-left: 16px;
  position: relative;
  /* border: solid 1px blue; */
}
.box-body {
  height: 400px;
  flex: auto;
  margin: 15px 15px 15px 0;
  /* border: solid 1px blue; */
  background-color: white;
  display: flex;
}
.box-content{
  /* border: solid 1px red; */
  width: 500px;
  flex: auto;
  /* width: 100%; */
  height: 100%;
  margin-right: 30px;
  overflow-x: hidden;
  overflow-y: auto;
  /* scrollbar-width: none;
  -ms-overflow-style: none; */
  position: relative;
}
</style>