<template>
  <div class="control-panel">
    <div class="panel-pan">
      <div class="panel-pan-box">
        <!-- direction	int	操作命令：0-上，1-下，2-左，3-右，4-左上，5-左下，6-右上，7-右下，8-放大，9-缩小，10-近焦距，11-远焦距	Y
              speed	int	云台速度：0-慢，1-适中，2-快，海康设备参数不可为0
         -->
        <li class='slice1 slice  sliceFour'><a href="javascript:void(0);"><img :src="img.pole.icons.direction.left" alt="" class="left panel-pan-img" @mousedown.stop="direction(2,true)" @mouseup.stop="direction(2,false)" /></a></li>
        <li class='slice2 slice sliceFour'><a href="javascript:void(0);"><img :src="img.pole.icons.direction.lower" alt="" class="left panel-pan-img" @mousedown.stop="direction(1,true)" @mouseup.stop="direction(1,false)" /></a></li>
        <li class='slice3 slice sliceFour'><a href="javascript:void(0);"><img :src="img.pole.icons.direction.right" alt="" class="left panel-pan-img" @mousedown.stop="direction(3,true)" @mouseup.stop="direction(3,false)" /></a></li>
        <li class='slice4 slice sliceFour'><a href="javascript:void(0);"><img :src="img.pole.icons.direction.upper" alt="" class="left panel-pan-img" @mousedown.stop="direction(0,true)" @mouseup.stop="direction(0,false)" /></a></li>
        <li class='slice5 slice sliceEight'><a href="javascript:void(0);"><img :src="img.pole.icons.direction.lowerLeft" alt="" class="left panel-pan-img" @mousedown.stop="direction(5,true)" @mouseup.stop="direction(5,false)" /></a></li>
        <li class='slice6 slice sliceEight'><a href="javascript:void(0);"><img :src="img.pole.icons.direction.lowerRight" alt="" class="left panel-pan-img" @mousedown.stop="direction(7,true)" @mouseup.stop="direction(7,false)" /></a></li>
        <li class='slice7 slice sliceEight'><a href="javascript:void(0);"><img :src="img.pole.icons.direction.upperLeft" alt="" class="left panel-pan-img" @mousedown.stop="direction(4,true)" @mouseup.stop="direction(4,false)" /></a></li>
        <li class='slice8 slice sliceEight'><a href="javascript:void(0);"><img :src="img.pole.icons.direction.upperRight" alt="" class="left panel-pan-img" @mousedown.stop="direction(6,true)" @mouseup.stop="direction(6,false)" /></a></li>
        <div class="panel-pan-core"></div>
      </div>
    </div>
    <div class="panel-args">
      <table>
        <tr class="panel-tr">
          <td style="text-align: right;"><img :src="img.pole.icons.direction.shot" class="tool-img" /></td>
          <td>镜头</td>
          <td class="icon-add">
            <div class="icon-add-box" @mousedown="direction(8,true)" @mouseup="direction(8,false)">- </div>
          </td>
          <td class="icon-add">
            <div class="icon-add-box" @mousedown="direction(9,true)" @mouseup="direction(9,false)">+</div>
          </td>
        </tr>
        <tr class="panel-tr">
          <td style="text-align: right;"><img :src="img.pole.icons.direction.aperture" class="tool-img" /></td>
          <td>光圈</td>
          <td class="icon-add">
            <div class="icon-add-box" @mousedown="direction(-1,true)" @mouseup="direction(-1,false)">-</div>
          </td>
          <td class="icon-add">
            <div class="icon-add-box" @mousedown="direction(-1,true)" @mouseup="direction(-1,false)">+</div>
          </td>
        </tr>
        <tr class="panel-tr">
          <td style="text-align: right;"><img :src="img.pole.icons.direction.focal" class="tool-img" /></td>
          <td>焦距</td>
          <td class="icon-add">
            <div class="icon-add-box" @mousedown="direction(10,true)" @mouseup="direction(10,false)">-</div>
          </td>
          <td class="icon-add">
            <div class="icon-add-box" @mousedown="direction(11,true)" @mouseup="direction(11,false)">+</div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import Bus from './bus'
export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  mounted: function () {
  },
  methods: {
    direction(num, is) {
      if (num == -1) {
        this.$Message.warning("设备不支持");
        return;
      }
      Bus.$emit('setPanel', { num, is })
    }
  }
}
</script>
<style scoped>
.control-panel {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  min-height: 165px;
  display: flex;
  padding: 3px;
}
.panel-pan {
  /* border: solid 1px red; */
  width: 165px;
  height: 165px;
  flex: none;
}
.panel-args {
  /* border: solid 1px red; */
  width: 500px;
  flex: auto;
}
.tool-img {
  margin-top: 5px;
  width: 18px;
  height: 18px;
}
table {
  margin-top: 20px;
  height: 120px;
  width: 100%;
}
.icon-add {
  text-align: center;
  cursor: pointer;
}
.panel-tr {
  text-align: center;
}
.icon-add-box {
  width: 18px;
  height: 18px;
  color: #4b8fff;
  background: #ebf4fe;
  text-align: center;
  line-height: 18px;
}
.panel-pan-box {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: #f7fafe;
  box-shadow: 0px 5px 20px 0px rgba(0, 77, 182, 0.1) inset;
  position: relative;
  list-style: none;
  overflow: hidden;
}
.slice {
  position: absolute;
  top: -100px;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
}
.panel-pan-core {
  position: absolute;
  top: 37px;
  z-index: 30;
  left: 37px;
  width: 86px;
  height: 86px;
  background: #ffffff;
  border: 1px solid #cedbeb;
  box-shadow: 0px 0px 10px 0px rgba(0, 77, 182, 0.2) inset;
  border-radius: 50%;
}
.sliceFour {
  z-index: 26;
}
.slice1 img {
  margin-top: 150px;
  margin-left: -77px;
}
.slice2 img {
  margin-top: 185px;
  margin-left: -27px;
}
.slice3 img {
  margin-top: 153px;
  margin-left: 4px;
}
.slice4 img {
  margin-top: 103px;
  margin-left: -27px;
}
.sliceEight {
  z-index: 20;
}
.slice5 img {
  margin-top: 182px;
  margin-left: -70px;
}
.slice6 img {
  margin-top: 183px;
  margin-left: 4px;
}
.slice7 img {
  margin-top: 109px;
  margin-left: -69px;
}
.slice8 img {
  margin-top: 111px;
  margin-left: 4px;
}
</style>
