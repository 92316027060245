<template>
  <div class="police-box">
    <vxe-table :data="alarmsData" style="width: 100%;" align="center" border stripe :height="tabHeight" default-expand-all :empty-text="showLang('com.data.no')">
      <vxe-column type="seq" width="55" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
      <vxe-column field="stationName" title="站点"  align="left">
        <template #default="{ row }">
          <span class="stationName" @click="jumpDetailsDve(row)" :title="row.stationName"> {{row.stationName}}</span>
        </template>
      </vxe-column>
      <vxe-column field="paramName" title="设备" width="150">
        <template #default="{ row }">
          <span @click="showAlarmHisData(row)"> {{row.paramName}}</span>
        </template>
      </vxe-column>
      <vxe-column field="lightCount" title="类型" sortable width="150">
        <template #default="{ row }">
          <span class="red" @click="showAlarmHisData(row)"> {{row.name}}</span>
        </template>
      </vxe-column>
      <vxe-column field="offlineError" title="内容" sortable>
        <template #default="{ row }">
          <span :style="{color: row.isConfirm ? 'blue' : 'red'}" @click="showAlarmHisData(row)">[{{row.isConfirm ? showLang('com.order.confirmed') : showLang('com.state.unconfirmed')}}]</span>
          <span class="red" @click="showAlarmHisData(row)"> {{row.extra}}</span>
        </template>
      </vxe-column>
      <vxe-column field="startTime" title="时间" sortable width="150">
        <template #default="{ row }">
          <span @click="showAlarmHisData(row)"> {{row.startTime}}</span>
        </template>
      </vxe-column>
    </vxe-table>

    <ModalAlarmData v-model="showAlarmDataModal" :item="editItem" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalAlarmData from '@/views/light/road/monitor/station/alarm/report/ModalAlarmData'
export default {
  name: 'ReportSimple',
  components: {
    ModalAlarmData,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      tabHeight: 500,
      showModal: this.value,
      curtab: 'power',
      editItem: {},
      showAlarmDataModal: false,
      isShowPoliceBox: true,
      alarms: [],
      total: 0,
      page: {
        currentPage: 1,
        pageSize: 20,
      },
      pageSizes: [20, 100, 500, 1000, 5000],
      alarmsData: [],
      timeout: null,
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
    },
    selectedNode() {
      this.clockAjax(this.page.currentPage = 1);
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  destroyed: function () {
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('group', ['selectedNode', 'groupLoaded', 'selectedDevices', 'stationAlarmChanged']),
    ...mapState('common', ['alarmTypes', 'productCodes']),
  },
  mounted: function () {
    this.clockAjax();
  },
  methods: {
    showAlarmHisData: function (item) {
      this.editItem = item;
      this.showAlarmDataModal = true;
    },
    getItemNameLength: function (item) {
      let len = 0;
      if (item.stationName) {
        len += item.stationName.length;
      }
      if (item.pName) {
        len += item.pName.length;
      }
      return len;
    },
    clockAjax() {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/alarm/QueryReportSimple`, { groupId, stationId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'alarmsData', res.data);
        }
      });
    },
    jumpDetailsDve(params) {
      this.$store.commit('group/selectNodeById', { nodeId: params.stationId, type: this.productCodes.station });
      this.$store.commit('auth/switchStationTabName', this.curtab);
    },
  }
}
</script>
<style scoped>
.police-box {
  width: 100%;
}
span {
  cursor: pointer;
}
</style>