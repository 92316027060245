<template>
<div class="logs-container">
  <div class="logs-tabs">
    <div :class="['tab-item', curtab == 'log' ? 'tab-active' : '']" @click="switchTab('log')">我的日志</div>
    <div :class="['tab-item', curtab == 'alarm' ? 'tab-active' : '']" @click="switchTab('alarm')">我的故障</div>
  </div>
  <div class="logs-list">
    <LogsList v-if="curtab == 'log'" />
    <AlarmList v-else />
  </div>
</div>
</template>
<script>
import LogsList from './List'
import AlarmList from './Alarm'
export default {
  name: 'LogsContent',
  components: {
    LogsList,
    AlarmList
  },
  props: {
    item: {
      type: Object,
      default(){return {icon: '', title: '', count: 0, unit: '', color: ''}}
    }
  },
  data() {
    return {
      curtab: 'log',
      list: [
        {icon: this.img.pole.asset.level2.box, title: '智慧云盒', count: 3},
        {icon: this.img.pole.asset.level2.light, title: '智慧照明', count: 3},
        {icon: this.img.pole.asset.level2.video, title: '安防监控', count: 3},
        {icon: this.img.pole.asset.level2.led, title: '广告发布', count: 3},
        {icon: this.img.pole.asset.level2.env, title: '环境监测', count: 3},
        {icon: this.img.pole.asset.level2.speak, title: '一键报警', count: 3},
        {icon: this.img.pole.asset.level2.broad, title: '广播音柱', count: 3},
        {icon: this.img.pole.asset.level2.elec, title: '强电管理', count: 3},
        {icon: this.img.pole.asset.level2.charge, title: '充电桩', count: 3},
        {icon: this.img.pole.asset.level2.gryo, title: '倾斜监测', count: 3},
        {icon: this.img.pole.asset.level2.water, title: '水浸监测', count: 3},
        {icon: this.img.pole.asset.level2.lock, title: '智能门锁', count: 3},
      ],
    }
  },
  computed: {
  },
  watch: {
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {
    switchTab: function(tab){
      this.curtab = tab;
    },
  },
}
</script>
<style scoped>
.logs-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  background-color: white;
  border-radius: 8px;
}
.logs-tabs{
  height: 30px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  /* border: solid 1px red; */
}
.tab-item{
  /* border: solid 1px red; */
  margin-right: 15px;
  padding: 3px;
  cursor: pointer;
  border-bottom: solid 3px transparent;
}
.tab-active{
  border-bottom: solid 3px blue;
}
.logs-list{
  height: 300px;
  flex: auto;
  overflow-y: auto;
}
table{
  width: 100%;
}
td{
  border-bottom: solid 1px #E9EDF4;
  height: 38px;
}
</style>