<template>
  <div class="snapFace-control">
    <div class="snapFace-title">实时抓拍
      <div class="snapFace-choice">
        <span>请选择摄像头：</span>
        <Select v-model="tool" class="snapFace-type">
          <template v-for="item in tools">
            <Option :value="item.code" :key="item.code">{{item.name}}</Option>
          </template>
        </Select>
      </div>
    </div>
    <div class="snapFace-con">
      <div class="snapFace-con-box">
        <img :src="testImg" />
        <span>时间：2018-06-27 18:46:52</span>
        <span>地点：测时间外半球</span>
      </div>
      <div class="snapFace-con-box">
        <img :src="testImg" />
        <span>时间：2018-06-27 18:46:52</span>
        <span>地点：测时间外半球</span>
      </div>
      <div class="snapFace-con-box">
        <img :src="testImg" />
        <span>时间：2018-06-27 18:46:52</span>
        <span>地点：测时间外半球</span>
      </div>
      <div class="snapFace-con-box">
        <img :src="testImg" />
        <span>时间：2018-06-27 18:46:52</span>
        <span>地点：测时间外半球</span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  computed: {

  },
  data() {
    return {
      tool: "s1",
      tools: [
        { name: "办公室人脸识别", code: "s1" },
        { name: "财务室人脸识别", code: "s2" },
        { name: "会议室人脸识别", code: "s3" },
        { name: "大厅人脸识别", code: "s4" },
      ],
      testImg: require("@/assets/portrait.png")
    }
  },
  watch: {

  },
  mounted: function () {

  },
  methods: {

  }
}
</script>
<style scoped>
.snapFace-control {
  height: calc(100%);
  padding: 20px 30px;
  background: #fff;
}
.snapFace-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  margin-bottom: 15px;
  font-weight: bold;
}
.snapFace-choice {
  float: right;
}
.snapFace-choice span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
}
.snapFace-con {
  height: calc(100% - 18px);
  width: 100%;
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  overflow: hidden;
}
.snapFace-con-box {
  margin-right: 16px;
}
.snapFace-con-box img {
  width: 178px;
  height: 151px;
}
.snapFace-type {
  width: 150px;
  height: 32px;
  color: #007eff;
}
</style>
