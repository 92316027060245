<template>
  <div class="contrast-control">
    <div class="contrast-title">实时对比</div>
    <div class="contrast-con">
      <div class="contrast-top-img">
        <img :src="testImg" />
        <img :src="testImg" />
        <div class="contrast-top-result">
          <div class="contrast-top-circle">
            <i-circle :percent="95" :size="46">
              <span class="demo-i-circle-inner" style="font-size:14px;color: #007EFF;">99%</span>
            </i-circle>
          </div>
        </div>
      </div>
      <div class="contrast-con-info">
        <p>姓名：<span>嫌疑人</span></p>
        <p>所属名单库：<span>黑名单</span></p>
        <p>证件号码：<span>456421198906210525</span></p>
      </div>
      <div class="contrast-con-imgList">
        <img :src="testImg" />
        <img :src="testImg" />
        <img :src="testImg" />
        <img :src="testImg" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  computed: {

  },
  data() {
    return {
      testImg: require("@/assets/portrait.png")
    }
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
  }
}
</script>
<style scoped>
.contrast-control {
  height: calc(100%);
  padding: 20px 30px;
}
.contrast-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  margin-bottom: 15px;
  font-weight: bold;
}
.contrast-con {
  width: 100%;
  height: calc(100% - 25px);
}
.contrast-top-img {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  position: relative;
}
.contrast-top-img img {
  width: 159px;
  height: 131px;
}
.contrast-top-result {
  position: absolute;
  width: 54px;
  height: 54px;
  background-color: #ffffff;
  border-radius: 50%;
  left: 50%;
  margin-left: -27px;
  top: 50%;
  margin-top: -27px;
}
.contrast-top-circle {
  width: 46px;
  height: 46px;
  margin-top: 4px;
  margin-left: 4px;
}
.contrast-con-info {
  margin-top: 5px;
}
.contrast-con-info p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
  line-height: 20px;
}
.contrast-con-info span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
  line-height: 20px;
}
.contrast-con-imgList {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  overflow: hidden;
}
.contrast-con-imgList img {
  width: 101px;
  height: 87px;
  margin-right: 14px;
}
</style>
