<template>
  <div class="alarm-rule-container">
    <div class="query-data-area">
      <AuthButton opCode='pd3' size="default" type="error" @click="deleteParams" style="margin: 0 5px;">
        {{showLang('com.op.batch.del')}}</AuthButton>
      <Button size="default" type="info" @click="updateOutputTimeTable" style="margin: 0 5px;">{{showLang('timetable.batch.modify')}}</Button>
    </div>
    <div class="alarm-data-area" ref="table1">
      <u-table ref="chkTable" :data="list" style="width: 100%;" use-virtual row-key="id" border stripe
        :height="tabHeight" default-expand-all>
        <u-table-column type="index" header-align="center" fixed="left"></u-table-column>
        <u-table-column type="selection" header-align="center" fixed="left"></u-table-column>
        <u-table-column prop="name" :label="showLang('com.tab.title')" width="150" header-align="center" fixed="left">
          <template slot-scope="params">
            {{params.row.name}}
          </template>
        </u-table-column>
        <u-table-column prop="stationName" :label="showLang('station.belong')" sortable width="250"
          header-align="center">
          <template slot-scope="params">
            {{params.row.stationName}}
          </template>
        </u-table-column>
        <u-table-column prop="mainName" :label="showLang('com.association.main')" sortable width="150"
          header-align="center">
          <template slot-scope="params">
            {{params.row.mainName}}
          </template>
        </u-table-column>
        <u-table-column prop="hc" :label="showLang('com.lighting.schedule.tab')" sortable header-align="center">
          <template slot-scope="params">
            {{params.row.timeName}}
          </template>
        </u-table-column>
        <u-table-column prop="rc" :label="showLang('com.output.ctrl.channel')" sortable header-align="center">
          <template slot-scope="params">
            {{params.row.content.oc > 0 ? `K${params.row.content.oc}` : showLang('com.not.set')}}
          </template>
        </u-table-column>
        <u-table-column width="120" fixed="right">
          <template slot="header">
            <AuthButton opCode='pa3' style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}
            </AuthButton>
            <AuthButton opCode='pv3' style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}
            </AuthButton>
          </template>
          <template slot-scope="scope">
            <AuthButton opCode='pe3' style="margin-right: 5px" @click="handleEdit(scope)">{{showLang('com.op.edit')}}
            </AuthButton>
            <AuthButton opCode='pd3' type="error" @click="handleDelete(scope)">{{showLang('com.op.del')}}</AuthButton>
          </template>
        </u-table-column>
      </u-table>
      <ModalOutputEdit v-model="showEditModal" :item="editItem" :stations="selectedStations" @saved="itemSaved" />
      <!-- <ModalArgEdit v-model="showArgModal" /> -->
    </div>
    <Modal v-model="updateOutputTimeTableShow" :title="showLang('timetable.batch.modify')" @on-ok="updateOutputTimeTableOk">
      <Form :label-width="100">
        <Form-item :label="showLang('cmd.err.plan.type')">
          <Select v-model="timeTableType" :placeholder="showLang('save.select')">
            <Option :value="1">{{showLang('timetable.ptimey')}}</Option>
            <Option :value="2">{{showLang('timetable.Bjtime')}}</Option>
          </Select>
        </Form-item>
        <Form-item :label="showLang('com.export.cn.plan.relate')">
          <Select v-model="timeTableIndex" :placeholder="showLang('save.select')">
            <Option v-for="el,index in timeTableList" :key="index" :value="el.id">{{el.name}}</Option>
          </Select>
        </Form-item>
      </Form>
    </Modal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalOutputEdit from './ModalOutputEdit'
// import ModalArgEdit from './ModalArgEdit'
export default {
  name: 'ConfigSwitchIndex',
  components: {
    ModalOutputEdit,
    // ModalArgEdit,
  },
  props: {
  },
  data() {
    return {
      curTab: 'hour24',
      list: [],
      updateOutputTimeTableShow: false,
      tabHeight: 600,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showArgModal: false,
      timeTableType: 1,
      timeTableIndex: 0,
      timeTableList: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.getList();
    },
    timeTableType() {
      this.QueryCommonYearList();
    }
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    this.getList();
  },
  destroyed: function () {
  },
  methods: {
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 10;
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params.row };
      this.showEditModal = true;
    },
    itemSaved: function () {
      this.getList();
    },
    updateOutputTimeTableOk() {
      if (this.timeTableIndex < 1) {
        this.$Message.warning(this.showLang('save.light.plan.need'));
        return;
      }
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let ajaxData = {
        "list": [],
        "timeId": this.timeTableIndex
      }
      for (let index = 0; index < chks.length; index++) {
        ajaxData.list.push(chks[index].id)
      }
      this.$axios.post(`//${this.domains.trans}/station/config/UpdateOutputTimeTable`,ajaxData).then(res => {
        if (res.code == 0) {
          this.$Message.success(this.showLang('com.ins.success'));
           this.getList();
        }
      });
    },
    QueryCommonYearList() {
      let url = this.timeTableType == 1 ? 'QueryCommonYearList' : 'QueryBjYearList';
      this.$axios.post(`//${this.domains.trans}/station/plan/${url}`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeTableList', res.data);
        }
      });
    },
    updateOutputTimeTable() {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('gis.item.select'));
        return;
      }
      this.updateOutputTimeTableShow = true;
      this.timeTableType = 1;
      this.QueryCommonYearList()
    },
    deleteParams: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.del.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, { list: list }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', params.row.name),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, { list: [params.row.id] }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    getList: function () {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryOutput`, { groupId, stationId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-rule-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area {
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  border: solid 1px rgba(143, 138, 138, 0.452);
  margin-bottom: 5px;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  padding-left: 10px;
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>