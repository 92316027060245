<template>
  <div class="analysis-control">
    <div class="analysis-tabs">
      <template v-for="el in navList">
        <div class="analysis-tab" :key="el.code" :class="[navIndex==el.code?'tab-active':'']" @click="navBut(el)">
          {{el.name}}
        </div>
      </template>
    </div>
    <div class="analysis-box">
      <template v-for="(item, idx) in navList">
        <component v-if="item.code == navIndex" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import People from './people'
import Vehicle from './vehicle'

export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  computed: {

  },
  data() {
    return {
      navIndex: "s1",
      navList: [
        { code: 's1', name: '人流量统计', com: People, args: {} },
        { code: 's2', name: '车辆统计', com: Vehicle, args: {} },
      ]
    }
  },
  watch: {

  },
  mounted: function () {

  },
  methods: {
    navBut(el) {
      this.navIndex = el.code;
    }
  }
}
</script>
<style scoped>
.analysis-control {
  height: calc(100%);
  display: flex;
  flex-direction: column;
  align-content: stretch;
  background-color: white;
  padding: 15px 30px;
}
.analysis-tabs {
  height: 48px;
  flex: none;
  display: flex;
}
.analysis-tab {
  padding: 2px 10px;
  cursor: pointer;
  background-color: #fff;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8793a5;
  line-height: 48px;
}
.tab-active {
  color: #0a1421;
  font-weight: bold;
}
.tab-active::after {
  content: "";
  width: calc(100%);
  height: 2px;
  display: block;
  margin: -5px auto 0;
  border-bottom: 2px solid #3880fc;
}
.analysis-box {
  height: 100%;
}
</style>
