<template>
  <Modal v-model="showModal" :mask-closable="false" width="1350px">
    <div slot="header">{{showLang('com.device.box')}} {{item.stationName}} {{showLang('dev.meter.ammeter')}} {{item.paramName}} {{showLang('energy.nav.contrast.record')}}</div>
    <Form ref="form" :label-width="0">
      <FormItem :label="showLang('com.site.info.region')" :label-width="80">
        <div style="display: flex">
          <Input ref="area" type="text" v-model="form.area" :placeholder="showLang('com.site.info.region')" style="width: 150px; margin-right: 10px;"></Input>
          {{showLang('com.date.start')}}：
          <DatePicker v-model="form.start" type="date" :format="selectedFormat" style="width: 150px; margin-right: 10px;"></DatePicker>
          {{showLang('com.date.end')}}：
          <DatePicker v-model="form.end" type="date" :format="selectedFormat" style="width: 150px; margin-right: 10px;"></DatePicker>
          <Button type="info" :loading="searchLoading" @click="initEnergys" style="margin-right: 10px">{{showLang('com.op.query')}}</Button>
          <!-- <Button type="success" :loading="exportLoading" @click="exportData">{{showLang('com.op.export')}}</Button> -->
          <!-- <span style="margin-left: 20px">总计 {{energys.length}} 条记录, 能耗 {{totalEnergy}} KWh, 电费 {{totalMoney}} 元</span> -->
        </div>
      </FormItem>
      <FormItem>
        <div class="branch-table">
          <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :data="energys" :height="600" :row-config="{isHover: true}" :export-config="{}" show-footer :footer-method="footerMethod" footer-cell-class-name="footer-cell-class">
            <vxe-column type="checkbox" width="60"></vxe-column>
            <vxe-column field="seq" type="seq" width="90"></vxe-column>
            <vxe-column field="stationName" :title="showLang('com.site')" header-align="center">
              <template #default="{ row }">
                {{row.stationName}}
              </template>
            </vxe-column>
            <vxe-column field="date" :title="showLang('com.date.time')"  width="260" header-align="center">
              <template #default="{ row }">
                {{row.date}}
              </template>
            </vxe-column>
            <vxe-colgroup :title="showLang('energy.before.usage')" header-align="center">
              <vxe-column field="beforeLight" :title="showLang('energy.usage.light') + '(KWh)'"  width="150" header-align="center">
                <template #default="{ row }">
                  {{row.beforeLight}}
                </template>
              </vxe-column>
            </vxe-colgroup>
            <vxe-colgroup :title="showLang('energy.after.usage')" header-align="center">
              <vxe-column field="afterTotal" :title="showLang('energy.usage.real') + '(KWh)'"  width="150" header-align="center">
                <template #default="{ row }">
                  <!-- <a href="#" @click="showAfterTotal(row)">{{row.afterTotal}} KWh</a> -->
                  {{row.afterTotal}}
                </template>
              </vxe-column>
              <vxe-column field="afterLight" :title="showLang('energy.usage.light') + '(KWh)'"  width="150" header-align="center">
                <template #default="{ row }">
                  <!-- <a href="#" @click="showAfterLight(row)">{{row.afterLight}} KWh</a> -->
                  {{row.afterLight}}
                </template>
              </vxe-column>
            </vxe-colgroup>
            <vxe-column field="save" :title="showLang('energy.save.amount') + '(KWh)'"  width="150" header-align="center">
              <template #default="{ row }">
                {{row.save}}
              </template>
            </vxe-column>
            <vxe-column field="money" :title="showLang('energy.save.electric')+'('+showLang('com.unit.cash')+')'"  width="150" header-align="center">
              <template #default="{ row }">
                {{row.money}}
              </template>
            </vxe-column>
          </vxe-table>
        </div>
        <ModalLightEnergyRecord v-model="showAfterLightModal" :item="editItem" />
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      <!-- <Button type="primary" :loading="loading" @click="doCommand">发送</Button> -->
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalLightEnergyRecord from './ModalLightEnergyRecord'
export default {
  name: 'ModalAreaCheckRecord',
  components: {
    ModalLightEnergyRecord
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      form: {
        area: 'day',
        start: '',
        end: '',
      },
      energys: [],
      showFormat: '',
      searchLoading: false,
      exportLoading: false,
      editItem: {},
      showAfterLightModal: false,
      footerData: []
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
      // console.log('handle edit', this.item);
        this.form.area = this.item.stationName;
        this.form.start = this.item.start;
        this.form.end = this.item.end;
        this.initEnergys();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['singleCmdResult']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedFormat: function(){
      switch(this.form.type){
        case 'day': return 'yyyy-MM-dd';
        case 'month': return 'yyyy-MM';
        case 'year': return 'yyyy';
        default: return 'yyyy-MM-dd';
      }
    },
    totalEnergy: function(){
      let sum = 0;
      for(let en of this.energys){
        sum += en.val;
      }
      return this.img.val(sum, 1);
    },
    totalMoney: function(){
      let sum = 0;
      for(let en of this.energys){
        sum += en.money;
      }
      return this.img.val(sum, 1);
    },
  },
  methods: {
    showAfterLight: function(params){
      // console.log('show after light', params, this.item);
      this.editItem = {stationId: this.item.id, day: params.day, stationName: this.item.stationName};
      this.showAfterLightModal = true;
    },
    footerMethod: function(){
      return this.footerData;
    },
    getLostRate: function(row){
      return row.afterTotal <= 0 ? 0 : this.img.val((row.afterTotal - row.afterLight - row.supply)*100 / row.afterTotal, 2)
    },
    exportData: function () {
      let format = 'yyyy-MM-dd';
      switch(this.form.type){
        case 'month': format = 'yyyy-MM-01'; break;
        case 'year': format = 'yyyy-01-01'; break;
        default: format = 'yyyy-MM-dd'; break;
      }
      let start = new Date(this.form.start).format(format);
      let end = new Date(this.form.end).format(format);
      this.exportLoading = true;
      var chks = this.$refs.chkTable.getCheckboxRecords();
      let ids = [];
      chks.map(p => ids.push(p.day));
      let fname = this.showLang('com.site')+ this.item.stationName+this.showLang('energy.nav.contrast.detailed')+ '.xlsx';
      this.$store.dispatch('auth/reqFile', {
        title: fname,
        url: `//${this.domains.trans}/station/analyse/ExportStationEnergyCheckListFromArea`,
        args:  { area: this.form.area, start, end},
      }).then(() => {
        this.exportLoading = false;
      });
    },
    initEnergys: async function () {
      let format = 'yyyy-MM-dd';
      let showFormat = 'yyyy-MM-dd';
      let start = new Date(this.form.start).format(format);
      let end = new Date(this.form.end).format(format);
      this.searchLoading = true;
      this.$axios.post(`//${this.domains.trans}/station/analyse/QueryStationEnergyCheckListFromArea`, { area: this.form.area, start, end}).then(res => {
        this.searchLoading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.showFormat = showFormat;
          this.$set(this, 'energys', res.data.list);
          // let total = 0, before = 0, usage = 0, save = 0, money = 0;
          // res.data.map(p => {
          //   total += p.afterTotal;
          //   before += p.beforeLight;
          //   usage += p.afterLight;
          //   save += p.save;
          //   money += p.money;
          //   // supply += p.supply;
          // });
          // avg = this.img.val(save * 100 / before, 2);
          // let minsName = `${parseInt(mins/60)}小时${mins%60}分钟`
          this.$set(this, "footerData", [
            [
              this.showLang('com.export.footer.name'), 
              res.data.list.length+this.showLang('energy.record'), 
              '', 
              '', 
              res.data.beforeLight+'KWh', 
              res.data.afterTotal+'KWh', 
              res.data.afterLight+'KWh', 
              res.data.save+'KWh', 
              res.data.money+this.showLang('com.unit.cash')
            ],
            // [2, 3, 4, 5]
          ]);
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
>>> .footer-cell-class {
  /* color: red; */
  font-weight: bold;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
.branch-table {
  height: 600px;
  overflow: auto;
}
.table {
  border-collapse: collapse;
  width: 100%;
}
.table-header {
  /* width: 1064px; */
  height: 47px;
  text-align: center;
  background: rgba(10, 26, 68, 0.8);
  box-shadow: inset 0px 0px 24px 0px #04559d;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #52fbfe;
  border: solid 1px black;
}
.table-header img {
  text-align: center;
  vertical-align: middle;
  margin-right: 3px;
}
.column-header {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  border: solid 1px black;
  text-align: center;
  height: 30px;
  /* width: 130px; */
  /* border-collapse: collapse; */
  background: #183992;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.column-header img {
  vertical-align: middle;
  margin-right: 10px;
}
.column-content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #050505;
  background: #2c9ef55e;
  border: solid 1px black;
  text-align: center;
  height: 35px;
  /* width: 130px; */
}
</style>