<template>
  <!-- 外接电管理 -->
  <div class="pole-container">
    <template v-if="poleSelectedNode.isLeaf">
      <baiMaIndex class="content-item" v-if="currentPower && currentPower.protocol == 'baima'" :power="currentPower" />
      <baseIndex class="content-item" v-else :devices="devices" />
    </template>
    <groupIndex v-else :devices="devices" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import baseIndex from './base/deviceDetails'
import baiMaIndex from './base/deviceBaiMaDetails'
import groupIndex from './group/Index'
export default {
  name: 'Home',
  components: {
    baseIndex,
    groupIndex,
    baiMaIndex,
  },
  data() {
    return {
      devices: [],
    }
  },
  computed: {
    ...mapState('group', ['poleSelectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
    currentPower: function(){
      if(!this.poleSelectedNode.isLeaf)return null;
      let cs = this.devices.filter(p => p.stationId == this.poleSelectedNode.id);
      if(cs.length == 0)return null;
      return cs[0];
    },
  },
  watch: {
    poleSelectedNode(){
      this.getItems();
    }
  },
  mounted: function () {
    this.getItems();
  },
  destroyed: function () {

  },
  methods: {
    getItems: function () {
      let poleId = 0, groupId = 0;
      if(!this.poleSelectedNode.isLeaf){
        groupId = this.poleSelectedNode.groupId;
      }else{
        poleId = this.poleSelectedNode.poleId;
      }
      this.$axios.post(`//${this.domains.trans}/pole/config/QueryPowerList`, {groupId, poleId, index: 1, size: 15}).then(res => {
        if (res.code != 0) {
          return;
        }
        this.$set(this, 'devices', res.data.list);
      })
    },
  }
}
</script>
<style scoped>
.pole-container {
  background: #e3e9ef;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
}
</style>
