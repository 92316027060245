<template>
  <div class="config-parameter">
    <div class="parameter-box">
      <div class="parameter-list">
        <i class="parameter-spot"></i>
        <span class="parameter-title">传感器类型</span>
        <span class="parameter-value">1/1.8" Progressive Scan CMOS</span>
      </div>
      <div class="parameter-list">
        <i class="parameter-spot"></i>
        <span class="parameter-title">镜头接口</span>
        <span class="parameter-value">M16</span>
      </div>
      <div class="parameter-list">
        <i class="parameter-spot"></i>
        <span class="parameter-title">补光距离</span>
        <span class="parameter-value">最远可达30 m</span>
      </div>
      <div class="parameter-list">
        <i class="parameter-spot"></i>
        <span class="parameter-title">图像尺寸</span>
        <span class="parameter-value">2560 × 1440</span>
      </div>
      <div class="parameter-list">
        <i class="parameter-spot"></i>
        <span class="parameter-title">音频采样率</span>
        <span class="parameter-value">8 kHz/16 kHz</span>
      </div>
      <div class="parameter-list">
        <i class="parameter-spot"></i>
        <span class="parameter-title">用户管理</span>
        <span class="parameter-value">最多32个用户</span>
      </div>
      <div class="parameter-list">
        <i class="parameter-spot"></i>
        <span class="parameter-title">客户端</span>
        <span class="parameter-value">iVMS-4200</span>
      </div>
      <div class="parameter-list">
        <i class="parameter-spot"></i>
        <span class="parameter-title">报警触发</span>
        <span class="parameter-value">移动侦测，遮挡报警，异常</span>
      </div>
      <div class="parameter-list">
        <i class="parameter-spot"></i>
        <span class="parameter-title">联动方式</span>
        <span class="parameter-value">上传FTP，上传中心，邮件，抓图</span>
      </div>
    </div>
    <!-- <div class="parameter-operate">
      <img :src="img.pole.icons.video.hd" alt="">
      <img :src="img.pole.icons.video.voice" alt="">
      <img :src="img.pole.icons.video.rainproof" alt="">
      <img :src="img.pole.icons.video.aperture" alt="">
      <img :src="img.pole.icons.video.denoise" alt="">
    </div> -->
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'PoleBroadIndex',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'poleType']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize() {
    },
  },
  mounted: function () {

  },
  methods: {

  }
}
</script>
<style scoped>
.config-parameter {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.parameter-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.parameter-spot {
  width: 4px;
  height: 4px;
  background: #24334a;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
}
.parameter-title {
  width: 85px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
  display: inline-block;
  margin-left: 5px;
}
.parameter-value {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
}
.parameter-operate {
  display: flex;
  flex-direction: initial;
  justify-content: space-around;
}
</style>