<template>
  <div class="lamp-popup" v-if="lampList.length > 0">
    <div class="lamp-header">
      <img :src="img.powerbox.single.lamp.bright" alt="" />
      <div class="lamp-right">
        <div class="lamp-input">
          <div class="lamp-input-list">
            {{ showLang('com.lighting.rated.v') }}<span class="lamp-input-value">{{ lampList[0].topUr ? lampList[0].topUr : '-' }} V</span>
          </div>
          <div class="lamp-input-list">
            {{ showLang('com.lighting.schedule.tab') }}<span class="lamp-input-value">{{ lampList[0].timeName }}</span>
          </div>
          <div class="lamp-input-list">
            {{ showLang('com.lighting.T') }}<span class="lamp-input-value">{{ lampList[0].tm ? lampList[0].tm : '-' }}℃</span>
          </div>
          <div class="lamp-input-list">
            {{ showLang('com.date.start') }}<span class="lamp-input-value">{{ lampList[0].sm ? lampList[0].sm : '-' }}</span>
          </div>
        </div>
        <div class="lamp-input">
          <div class="lamp-input-list">
            {{ showLang('com.lighting.powerOn') }}<span class="lamp-input-value">{{ lampList[0].time }}</span>
          </div>
          <div class="lamp-input-list">
            {{ showLang('com.ins.time.last') }}<span class="lamp-input-value" style="width: 150px">{{ lampList[0].fireTime }}</span>
          </div>
          <div class="lamp-input-list">
            {{ showLang('com.lighting.V') }}<span class="lamp-input-value">{{ lampList[0].topV ? lampList[0].topV : '-' }}V</span>
          </div>
          <div class="lamp-input-list">
            {{ showLang('com.lighting.incline.state') }}<span class="lamp-input-value">{{ showLang('com.state.normal') }}</span>
          </div>
        </div>
        <div class="lamp-table">
          <div class="lamp-table-title">{{ showLang('com.device.lamp') }}</div>
          <table border="0" cellspacing="0" cellpadding="0" width="100%">
            <tr>
              <td>{{ showLang('com.ins.ch') }}</td>
              <td>{{ showLang('com.lighting.V') }}(V)</td>
              <td>{{ showLang('com.lighting.I') }}(A)</td>
              <td>{{ showLang('com.lighting.P') }}(W)</td>
              <td>{{ showLang('com.lighting.op') }}(%)</td>
              <td>{{ showLang('com.lighting.oc') }}</td>
              <td>{{ showLang('com.lighting.cl') }}</td>
            </tr>
            <tr v-for="(el, index) in lampList" :key="el.id" :class="[index % 2 == 0 ? 'aaa' : 'bbb']">
              <td>K{{ el.ch }}:{{ el.n }}</td>
              <td>{{ img.val(el.u, 2) }}</td>
              <!-- 电压 -->
              <td>{{ img.val(el.c, 2) }}</td>
              <!-- 电流 -->
              <td>{{ img.val(el.p, 2) }}</td>
              <!-- 功率 -->
              <td>{{ img.val(el.op, 2) }}</td>
              <!-- 亮度 -->
              <td>{{ img.val(el.oc, 2) }}</td>
              <!-- 色温 -->
              <td>{{ img.val(el.cl, 2) }}</td>
              <!-- 漏电电流 -->
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="lamp-chart">
      <div class="chart-nav">
        <a :class="chartNav == 0 ? 'active' : ''" @click="chartNav = 0">{{ showLang('com.data') }}</a>
        <a :class="chartNav == 1 ? 'active' : ''" @click="chartNav = 1">{{ showLang('com.data.chart') }}</a>
        <a :class="chartNav == 2 ? 'active' : ''" @click="chartNav = 2">{{ showLang('com.gis.location') }}</a>
        <a :class="chartNav == 3 ? 'active' : ''" @click="chartNav = 3">{{ showLang('com.device.pole.img') }}</a>
      </div>
      <div class="chart-term" v-if="chartNav < 2">
        {{ showLang('com.date.start') }}:
        <DatePicker v-model="form.start" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
        {{ showLang('com.date.end') }}:
        <DatePicker v-model="form.end" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
        <Button type="info" @click="getList">{{ showLang('com.op.query') }}</Button>
        <span v-if="chartNav == 1">
          {{ showLang('com.data.show') }}：
          <Select :placeholder="showLang('save.select')" v-model="viewCode" style="width: 180px; margin:3px 10px; flex: none;">
            <template v-for="(item, idx) in views">
              <Option :value="item.code" :key="idx">{{ showLang(item.lang) }}</Option>
            </template>
          </Select>
        </span>
      </div>
      <div v-if="chartNav == 0" class="chart-table">    
        <vxe-table  border resizable show-overflow  show-header-overflow keep-source ref="table" align="center"  :data="historyData" :height="tabHeight2" :row-config="{isHover: true,height: 96}" :checkbox-config="{checkField: 'checked',highlight: true}">
          <vxe-column type="seq" field="seq" width="60"></vxe-column>
            <vxe-column field="time" :title="showLang('com.date.time')">
              <template #default="{ row }">{{ row.time }}</template>
            </vxe-column>
            <!-- <vxe-column field="name" :title="showLang('com.tab.lamp.cap')">
              <template #default="{ row }">{{ row.name }}</template>
            </vxe-column> -->
            <vxe-column field="u1" :title="showLang('com.lighting.V')">
              <template #default="{ row }"> {{ img.val(row.u1, 2) }}V </template>
            </vxe-column>
            <vxe-column field="c1" :title="showLang('com.lighting.I')">
              <template #default="{ row }"> 
              <div >{{ img.val(row.c1, 2) }}A</div> 
              <div class="row-height">{{ img.val(row.c2, 2) }}A</div> 
              <div class="row-height">{{ img.val(row.c3, 2) }}A</div> 
              <div class="row-height">{{ img.val(row.c4, 2) }}A</div> 
            </template>
            </vxe-column>
            <vxe-column field="p1" :title="showLang('com.lighting.P')">
              <template #default="{ row }"> 
                <div >{{ img.val(row.p1, 2) }}W </div>
                <div class="row-height">{{ img.val(row.p2, 2) }}W </div>
                <div class="row-height">{{ img.val(row.p3, 2) }}W </div>
                <div class="row-height">{{ img.val(row.p4, 2) }}W </div>
              </template>
            </vxe-column>
            <vxe-column field="op1" :title="showLang('com.lighting.op')">
              <template #default="{ row }"> 
                <div>{{ img.val(row.op1, 2) }}% </div>
                <div class="row-height">{{ img.val(row.op2, 2) }}% </div>
                <div class="row-height">{{ img.val(row.op3, 2) }}% </div>
                <div class="row-height">{{ img.val(row.op4, 2) }}% </div>
              </template>
            </vxe-column>
            <vxe-column field="tc1" :title="showLang('com.lighting.T')">
              <template #default="{ row }"> {{ img.val(row.tc1, 2) }}℃ </template>
            </vxe-column>
        </vxe-table>
      </div>
      <div v-if="chartNav == 1" style="height:300px">
        <lineChart :option="chartsLineData" id="chartsLampData" :key="timer"></lineChart>
      </div>
      <div v-if="chartNav == 2" style="height:800px">
        <lampMap :singleId="option.lid" />
      </div>
      <div v-if="chartNav == 3" style="height:500px;overflow-y: auto;">
        <ModalPicture :item="lampList[0]" :stations="selectedStations" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import lineChart from '@/components/charts/line'
import ModalPicture from './ModalPicture'
import lampMap from '@/views/light/road/gis/mapNew/Index'

export default {
  name: 'lampPopup',
  components: {
    lineChart,
    lampMap,
    ModalPicture
  },
  props: {
    option: {
      type: Object,
      default: () => {}
    },
    lampList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      chartNav: 1,
      form: {
        start: '',
        end: '',
        lightId: 0,
        stationId: 0
      },
      tabHeight: 190,
      tabHeight2: 300,
      items: [
        { needArgs: false, needPswd: false, code: 'forceRead', name: '召测', flag: 1 },
        { needArgs: true, needPswd: true, code: 'openSingle', name: '开灯', flag: 0 },
        { needArgs: true, needPswd: true, code: 'closeSingle', name: '关灯', flag: 0 },
        { needArgs: true, needPswd: true, code: 'handSingle', name: '调光', flag: 0 },
        { needArgs: false, needPswd: true, code: 'setclock', name: '校准时钟', flag: 1 },
        { needArgs: false, needPswd: false, code: 'getclock', name: '查询时钟', flag: 1 },
        { needArgs: true, needPswd: true, code: 'setGroup', name: '设置分组', flag: 1 },
        { needArgs: false, needPswd: false, code: 'getGroup', name: '查询分组', flag: 1 },
        { needArgs: false, needPswd: false, code: 'getDayPlan1', name: '查询计时日表', flag: 1 },
        { needArgs: true, needPswd: true, code: 'setDayPlan1', name: '下发计时日表', flag: 0 },
        { needArgs: false, needPswd: false, code: 'getDayPlan2', name: '查询准时日表', flag: 1 },
        { needArgs: true, needPswd: true, code: 'setDayPlan2', name: '下发准时日表', flag: 0 },
        { needArgs: false, needPswd: false, code: 'getPlanType', name: '查询日表模式', flag: 1 },
        { needArgs: true, needPswd: true, code: 'setPlanType', name: '设置日表模式', flag: 0 },
        { needArgs: true, needPswd: true, code: 'update', name: 'OTA升级', flag: 0 },
        { needArgs: false, needPswd: false, code: 'getInfo', name: '设备信息' },
        { needArgs: false, needPswd: false, code: 'getSimInfo', name: '卡信息' }
      ],
      historyData: [],
      spanCols: ['time', 'tc', 'u'],
      timer: '',
      chartsLineData: {
        color: ['#4166F5', '#0DEEBC', '#C96CFF', '#ff0'],
        title: '',
        x: ['Mon'],
        unit: '',
        legend: {
          data: ['Email'],
          left: 'right'
        },
        series: [
          {
            name: '亮灯率',
            data: [150]
          }
        ],
        grid: {
          top: '5%', // 等价于 y: '16%'
          left: '40',
          right: '40',
          bottom: '3%',
          containLabel: true
        },
        setSeries: {
          smooth: true //平滑折线图
        }
      },
      viewCode: 'c',
      views: [
        {
          name: '电流',
          lang: 'com.lighting.I',
          code: 'c',
          unit: 'A'
        },
        {
          name: '电压',
          lang: 'com.lighting.V',
          code: 'u',
          unit: 'V'
        },
        {
          name: '功率',
          lang: 'com.lighting.P',
          code: 'p',
          unit: 'W'
        },
        {
          name: '亮度',
          lang: 'com.lighting.op',
          code: 'op',
          unit: '%'
        },
        {
          name: '温度',
          lang: 'com.lighting.T',
          code: 'tc',
          unit: '℃'
        }
      ],
      deviceArray: [
        { name: '全部', type: 'all' },
        { name: '单灯', type: 'light' }
      ]
    }
  },
  computed: {
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['weeks', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function() {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station)
    }
  },
  watch: {
    lampList() {
      this.getList()
      this.tabHeight = this.tabHeight + (this.lampList.length - 1) * 60
    },
    viewCode() {
      this.chartData()
    }
  },
  mounted: function() {
 
    let now = new Date()
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss')
    now.setDate(now.getDate() - 1)
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss')
    this.chartsLineData.series[0].name = this.showLang('com.stat.light.rate')
    this.deviceArray = [
      { name: this.showLang('com.state.all'), type: 'all' },
      { name: this.showLang('com.device.lamp'), type: 'light' }
    ]
    this.items = [
      { needArgs: false, needPswd: false, code: 'forceRead', name: this.showLang('com.but.testing'), flag: 1 },
      { needArgs: true, needPswd: true, code: 'openSingle', name: this.showLang('com.but.open.lamp'), flag: 0 },
      { needArgs: true, needPswd: true, code: 'closeSingle', name: this.showLang('com.but.close.lamp'), flag: 0 },
      { needArgs: true, needPswd: true, code: 'handSingle', name: this.showLang('com.but.dimming'), flag: 0 },
      { needArgs: false, needPswd: true, code: 'setclock', name: this.showLang('com.but.get.Calibrate'), flag: 1 },
      { needArgs: false, needPswd: false, code: 'getclock', name: this.showLang('com.but.get.QueryClock'), flag: 1 },
      { needArgs: true, needPswd: true, code: 'setGroup', name: this.showLang('com.but.set.packets'), flag: 1 },
      { needArgs: false, needPswd: false, code: 'getGroup', name: this.showLang('com.but.get.packets'), flag: 1 },
      { needArgs: false, needPswd: false, code: 'getDayPlan1', name: this.showLang('com.but.get.timing'), flag: 1 },
      { needArgs: true, needPswd: true, code: 'setDayPlan1', name: this.showLang('com.but.set.timing'), flag: 0 },
      { needArgs: false, needPswd: false, code: 'getDayPlan2', name: this.showLang('com.but.get.ontime'), flag: 1 },
      { needArgs: true, needPswd: true, code: 'setDayPlan2', name: this.showLang('com.but.set.ontime'), flag: 0 },
      { needArgs: false, needPswd: false, code: 'getPlanType', name: this.showLang('com.but.get.tableType'), flag: 1 },
      { needArgs: true, needPswd: true, code: 'setPlanType', name: this.showLang('com.but.set.tableType'), flag: 0 },
      { needArgs: true, needPswd: true, code: 'update', name: this.showLang('com.but.ota'), flag: 0 },
      { needArgs: false, needPswd: false, code: 'getInfo', name: this.showLang('com.but.get.deviceInfo') },
      { needArgs: false, needPswd: false, code: 'getSimInfo', name: this.showLang('com.but.card.info') }
    ]
  },
  destroyed: function() {},
  methods: {
    getList() {
      this.form.start = new Date(this.form.start).format('yyyy-MM-dd HH:mm:ss')
      this.form.end = new Date(this.form.end).format('yyyy-MM-dd HH:mm:ss')
      let params = { sid: this.lampList[0].sid, paramId: this.lampList[0].lid, start: this.form.start, end: this.form.end }
      this.$axios.post(`//${this.domains.trans}/station/param/history`, params).then(res => {
        if (res.code == 0) {
          this.handleData(res.data)
        }
      })
    },
    handleData(data) {
      let newData = []
      let this_ = this
      data.forEach(el => {
        if(this_.lampList.length>0){
          newData.push({
            time: el.createTime,
            id: el.id,
            c1: el.content ? el.content[`c1`] : '-', //电流
            c2: el.content ? el.content[`c2`] : '-', //电流
            c3: el.content ? el.content[`c3`] : '-', //电流
            c4: el.content ? el.content[`c4`] : '-', //电流
            u1: el.content ? el.content.u : '-', //电压
            p1: el.content ? el.content[`p1`] : '-', //功率(W)
            p2: el.content ? el.content[`p2`] : '-', //功率(W)
            p3: el.content ? el.content[`p3`] : '-', //功率(W)
            p4: el.content ? el.content[`p4`] : '-', //功率(W)
            op1: el.content && el.content[`op1`] ? el.content[`op1`] : '-', //亮度(%)
            op2: el.content && el.content[`op2`] ? el.content[`op2`] : '-', //亮度(%)
            op3: el.content && el.content[`op3`] ? el.content[`op3`] : '-', //亮度(%)
            op4: el.content && el.content[`op4`] ? el.content[`op4`] : '-', //亮度(%)
            tc1: el.content ? el.content.tc : '-' //温度(℃)
          })
        }
        // for (let i = 1; i <= this_.lampList.length; i++) {
        //   newData.push({
        //     time: el.createTime,
        //     id: el.id,
        //     ch: i,
        //     name: this_.lampList[i - 1].n,
        //     c: el.content ? el.content[`c${i}`] : '-', //电流
        //     u: el.content ? el.content.u : '-', //电压
        //     p: el.content ? el.content[`p${i}`] : '-', //功率(W)
        //     op: el.content && el.content[`op${i}`] ? el.content[`op${i}`] : '-', //亮度(%)
        //     tc: el.content ? el.content.tc : '-' //温度(℃)
        //   })
        // }
      }) //
      this.$set(this, 'historyData', newData)
      this.chartData()
    },
    arraySpanMethod(params) {
      if (this.spanCols.indexOf(params.column.property) != -1) {
        if (params.rowIndex > 0) {
          if (
            this.historyData[params.rowIndex - 1].name == this.historyData[params.rowIndex].name &&
            this.historyData[params.rowIndex - 1].id == this.historyData[params.rowIndex].id
          ) {
            return [0, 1]
          }
        }
        let rowSpan = 1
        let colVal = this.historyData[params.rowIndex].name
        for (let i = params.rowIndex + 1; i < this.historyData.length; i++) {
          if (this.historyData[i].name == colVal && this.historyData[i].id == this.historyData[params.rowIndex].id) {
            rowSpan++
          }
        }
        return [rowSpan, 1]
      }
    },
    chartData() {
      let this_ = this
      this.chartsLineData.x = []
      this.chartsLineData.series = []
      let unitName=this_.views.filter(p => p.code == this_.viewCode)
      this_.chartsLineData.unit=unitName[0].unit
      for (let i = 1; i <= this_.lampList.length; i++) {
        this.chartsLineData.series.push({
          name: 'K' + i + this.showLang('com.ins.ch')+'('+unitName[0].unit+')',
          data: []
        })
      }
      this.historyData.forEach(el => {
        this_.chartsLineData.x.push(el.time)
        this_.chartsLineData.series.forEach((item, index) => {
          if (el[this_.viewCode] == '未测量' || el[this_.viewCode] == -1 || !Number.isNaN(el[this_.viewCode])) {
            el[item.name] = 0
          }
          this_.chartsLineData.series[index].data.push(el[this_.viewCode+(index+1)])
        })
      })
      this.timer = new Date().getTime()
    }
  }
}
</script>
<style scoped>
.lamp-header {
  width: 100%;
  display: flex;
  justify-content: initial;
  padding: 0 20px;
}
.lamp-right {
  width: calc(100% - 140px);
  padding-left: 60px;
}
.lamp-input {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}
.lamp-input-list {
  vertical-align: middle;
  margin-right: 60px;
  display: flex;
  justify-content: flex-start;
  line-height: 40px;
}
.lamp-input-value {
  width: 164px;
  height: 34px;
  background: #f1f6fb;
  border-radius: 4px;
  display: inline-block;
  line-height: 34px;
  text-indent: 10px;
  margin-left: 10px;
}
.lamp-table {
  width: 1243px;
  height: 265px;
  background: #fff;
  border-radius: 12px;
  border: 10px solid #f0f4fa;
  margin-top: 30px;
}
.lamp-table-title {
  width: 100%;
  height: 50px;
  background: linear-gradient(-90deg, #3682ff, #4c9eff);
  border-radius: 6px 6px 0px 0px;
  text-align: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  line-height: 50px;
}
.lamp-table table td {
  text-align: center;
  box-sizing: border-box;
  border-right: 1px solid #d0d9e9;
  border-bottom: 1px solid #d0d9e9;
  height: 38px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #324252;
  line-height: 38px;
}
.lamp-table table tr td:last-child {
  border-right: none;
}
.aaa {
  background: #f6f9fe;
}
.bbb {
  background: #ffffff;
}
.chart-nav {
  height: 30px;
  margin: 20px 0px 20px 20px;
}
.chart-nav a {
  padding: 0 15px;
  height: 30px;
  background: #eef4f9;
  display: block;
  float: left;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324762;
  text-align: center;
  line-height: 30px;
}
.chart-nav a.active {
  background: #3880fc;
  color: #ffffff;
}
.chart-term {
  padding-left: 20px;
  margin-bottom: 20px;
}
.row-height{
  border-top: 1px solid #e8eaec;
  height: 24px;
}
</style>
