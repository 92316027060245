<template>
  <Modal v-model="showModal" :mask-closable="false" width="1220px">
    <div slot="header">{{showLang('com.edit.box.schedule')}}</div>
    <div class="total-container">
      <div class="float-container">
        <div>
          <Form :model="form" :label-width="40" inline style="margin-left: 20px">
            <FormItem :label="showLang('com.tab.title')">
              <Input ref="name" type="text" v-model="form.name" maxlength="20" style="width: 380px" :placeholder="showLang('save.name')"></Input>
            </FormItem>
            <FormItem :label="showLang('com.state.default')" :label-width="70">
              <i-switch v-model="form.isDefault" size="large" true-color="#13ce66" false-color="#ff4949">
                <span slot="open">{{showLang('com.op.yes')}}</span>
                <span slot="close">{{showLang('com.op.deny')}}</span>
              </i-switch>
            </FormItem>
            <FormItem :label-width="0">
              <Button type="primary" v-if="form.type !== '0'" @click="editDayAction(1)">{{showLang('timetable.batch.modify')}}</Button>
            </FormItem>
          </Form>
        </div>
        <div>
          <Button v-for="(item, idx) in months" :key="idx" :type="midx == idx ? 'primary':'default'" style="margin: 3px 4px; width: 71px"
            @click="switchMonth(idx)">{{showLang(item.lang)}}</Button>
        </div>
        <div class="edit-container">
          <div class="time-container">
            <div class="time-item" v-for="m in 31" :key="m" @click="editDayAction(0, m, midx)">
              <div class="time-title">{{m}}</div>
              <!-- {{midx + 1}}-{{m}} -->
              <p v-for="n in 4" :key="n">{{getAction(midx+1, m, n)}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="week-area">
        <div class="week-header">{{showLang('timetable.week.light.plan')}}</div>
        <div class="week-content">
          <div class="week-item" v-for="(item, idx) in form.extra.weeks" :key="idx">
            {{weeks[item.week - 1]}} {{item.am ? showLang('com.date.am') : showLang('com.date.arvo')}}
            <i-switch v-model="item.enable" size="large" true-color="#13ce66" false-color="#ff4949" style="margin-left: 5px">
              <span slot="open">{{showLang('com.state.allow')}}</span>
              <span slot="close">{{showLang('com.state.prohibit')}}</span>
            </i-switch>
          </div>
        </div>
      </div>
      <ModalDayAction v-model="showDayModal" :item="dayEditItem" @dayActionSaved="dayActionSaved" />
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="saveTimeTable">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalDayAction from './ModalDayAction'
export default {
  name: 'ModalTimeTable',
  components: {
    ModalDayAction,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showDayModal: false,
      gpsEdit: true,
      months: [
        { name: '一月', lang: 'com.date.Jan' },
        { name: '二月', lang: 'com.date.Feb' },
        { name: '三月', lang: 'com.date.Mar' },
        { name: '四月', lang: 'com.date.Apr' },
        { name: '五月', lang: 'com.date.May' },
        { name: '六月', lang: 'com.date.Jun' },
        { name: '七月', lang: 'com.date.Jul' },
        { name: '八月', lang: 'com.date.Aug' },
        { name: '九月', lang: 'com.date.Sep' },
        { name: '十月', lang: 'com.date.Oct' },
        { name: '十一月', lang: 'com.date.Nov' },
        { name: '十二月', lang: 'com.date.Dec' }],
      weeks: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日',],
      midx: 0,
      days: 31,
      week: 0,
      showModal: this.value,
      isAdd: false,
      index: -1,
      loading: false,
      form: {
        id: 0,
        name: '',
        isDefault: false,
        // type: '1',
        // mode: 1,
        extra: {
          weeks: [
            { week: 1, am: true, enable: true },
            { week: 1, am: false, enable: true },
            { week: 2, am: true, enable: true },
            { week: 2, am: false, enable: true },
            { week: 3, am: true, enable: true },
            { week: 3, am: false, enable: true },
            { week: 4, am: true, enable: true },
            { week: 4, am: false, enable: true },
            { week: 5, am: true, enable: true },
            { week: 5, am: false, enable: true },
            { week: 6, am: true, enable: true },
            { week: 6, am: false, enable: true },
            { week: 7, am: true, enable: true },
            { week: 7, am: false, enable: true },
          ]
        },
        content: {
          1: {
            1: { o1: '00:00', c1: '00:00', a1: 2, o2: '00:00', c2: '00:00', a2: 2, o3: '00:00', c3: '00:00', a3: 2, o4: '00:00', c4: '00:00', a4: 2, },
            2: { o1: '00:00', c1: '00:00', a1: 2, o2: '00:00', c2: '00:00', a2: 2, o3: '00:00', c3: '00:00', a3: 2, o4: '00:00', c4: '00:00', a4: 2, },
            //...
          },
          2: {},
          //...
        },
      },
      dayEditItem: {},
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        this.isAdd = this.item.isAdd;
        this.index = this.item.index;
        if (this.isAdd) {
          this.form.id = 0;
          this.form.name = '';
          this.form.type = '1';
          this.form.isDefault = false;
          this.form.extra = {
            weeks: [
              { week: 1, am: true, enable: true },
              { week: 1, am: false, enable: true },
              { week: 2, am: true, enable: true },
              { week: 2, am: false, enable: true },
              { week: 3, am: true, enable: true },
              { week: 3, am: false, enable: true },
              { week: 4, am: true, enable: true },
              { week: 4, am: false, enable: true },
              { week: 5, am: true, enable: true },
              { week: 5, am: false, enable: true },
              { week: 6, am: true, enable: true },
              { week: 6, am: false, enable: true },
              { week: 7, am: true, enable: true },
              { week: 7, am: false, enable: true },
            ]
          };
          this.form.content = {};
          for (let i = 1; i <= 12; i++) {
            this.form.content[i] = {};
            for (let j = 1; j <= 31; j++) {
              this.form.content[i][j] = {
                o1: '00:00',
                c1: '00:00',
                a1: 2,
                o2: '00:00',
                c2: '00:00',
                a2: 2,
                o3: '00:00',
                c3: '00:00',
                a3: 2,
                o4: '00:00',
                c4: '00:00',
                a4: 2,
              }
            }
          }
        } else {
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.form.isDefault = this.item.data.isDefault;
          this.getTableContent();
        }
        let now = new Date();
        let month = now.getMonth();
        this.switchMonth(month);
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
    // this.getTables();
    this.weeks = [this.showLang('com.date.mon'), this.showLang('com.date.tues'), this.showLang('com.date.wed'), this.showLang('com.date.thurs'), this.showLang('com.date.fri'), this.showLang('com.date.sat'), this.showLang('com.date.sunday')]
  },
  computed: {
    ...mapState('common', ['mapCenter']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  methods: {
    saveTimeTable: async function () {
      if (!this.form.name || this.form.name.length > 20) {
        this.$Message.warning(this.showLang('com.save.err.len.name', 20));
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/plan/SaveBjYearDetail`, this.form).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.showModal = false;
          this.$emit('saved');
        }
      });
    },
    dayActionSaved: function (params) {
      // // 0、单日年表，1、批量年表，2、单日周表
      if (params.type == 0) {
        for (let i = 1; i <= 4; i++) {
          this.form.content[params.month + 1][params.day][`o${i}`] = params.action[`o${i}`];
          this.form.content[params.month + 1][params.day][`c${i}`] = params.action[`c${i}`];
          this.form.content[params.month + 1][params.day][`a${i}`] = parseInt(params.action[`a${i}`]);
        }
      }
      else if (params.type == 1) {
        let start = new Date(params.action.start);
        let end = new Date(params.action.end);
        let startMonth = start.getMonth(), startDay = start.getDate();
        let endMonth = end.getMonth(), endDay = end.getDate();
        // let weekEnables = params.action.weekEnables;
        let year = new Date().getFullYear();
        let i = startMonth, j = startDay;
        while (params.type == 1) {
          let action = this.form.content[i + 1][j];
          if (params.gps) {
            let day = new Date(`${year}-${i + 1}-${j}`);
            if (!day) {
              // debugger;
              j++;
              if (i * 31 + j > endMonth * 31 + endDay) break;
              if (j > 31) {
                j = 1;
                i++;
              }
              continue
            }
            let riseAndSet = day.sunRiseAndSet(params.gpsForm.lat, params.gpsForm.lng);
            for (let k = 1; k <= 4; k++) {
              if (!params.action[`e${k}`]) continue;
              let to; //开关灯时间
              if (params.action[`ro${k}`] == '1') {//sun rise
                to = riseAndSet.rise;
              } else {//sun set
                to = riseAndSet.set;
              }
              let bb = params.action[`bo${k}`];
              let d = parseInt(params.action[`do${k}`]);
              if (bb == '2') {//提前
                to = this.delayTime(to, -1 * d);
              } else if (bb == '3') {//延后
                to = this.delayTime(to, d);
              }
              action[`o${k}`] = to;
              action[`a${k}`] = params.action[`a${k}`];

              let tc;
              if (params.action[`rc${k}`] == '1') {//sun rise
                tc = riseAndSet.rise;
              } else {//sun set
                tc = riseAndSet.set;
              }
              let bbc = params.action[`bc${k}`];
              let dc = parseInt(params.action[`dc${k}`]);
              if (bbc == '2') {//提前
                tc = this.delayTime(tc, -1 * dc);
              } else if (bbc == '3') {//延后
                tc = this.delayTime(tc, dc);
              }
              action[`c${k}`] = tc;
            }
          } else {
            for (let k = 1; k <= 4; k++) {
              action[`a${k}`] = parseInt(params.action[`a${k}`]);
              if (params.action[`e${k}`]){
                action[`c${k}`] = params.action[`c${k}`];
              } 
              if (params.action[`eo${k}`]){
                action[`o${k}`] = params.action[`o${k}`];
              } 
            }
          }
          j++;
          if (i * 31 + j > endMonth * 31 + endDay) break;
          if (j > 31) {
            j = 1;
            i++;
          }
        }



        //   let start = new Date(params.action.start);
        //   let end = new Date(params.action.end);
        //   let weekEnables = params.action.weekEnables;
        //   for(let day = start; day <= end; day.setDate(day.getDate() + 1)){
        //     let week = day.getDay();
        //     if(week == 0)week = 7;
        //     let wn = this.weeks[week-1];
        //     if(weekEnables.indexOf(wn) == -1)continue;
        //     let m = day.getMonth() + 1;
        //     let d = day.getDate();
        //     let item = this.form.contentDay[m][d];
        //     let riseAndSet = null;
        //     if(params.gps)riseAndSet = day.sunRiseAndSet(params.gpsForm.lat, params.gpsForm.lng);
        //     for(let i = 1; i <= 4; i++){
        // // console.log('day act saved', startM, startD, item, i, params.action[`c${i}`], params.action[`to${i}`], params.action[`ao${i}`]);
        //       if(!params.action[`e${i}`])continue;
        //       if(params.gps){
        //         let to; //开关灯时间
        //         if(params.action[`ro${i}`] == '1'){//sun rise
        //           to = riseAndSet.rise;
        //         }else{//sun set
        //           to = riseAndSet.set;
        //         }
        //         let bb = params.action[`bo${i}`];
        //         let d = parseInt(params.action[`do${i}`]);
        //         if(bb == '2'){//提前
        //           to = this.delayTime(to, -1 * d);
        //         }else if(bb == '3'){//延后
        //           to = this.delayTime(to, d);
        //         }
        //         item[`o${i}`] = to;
        //         item[`a${i}`] = params.action[`a${i}`];

        //         let tc;
        //         if(params.action[`rc${i}`] == '1'){//sun rise
        //           tc = riseAndSet.rise;
        //         }else{//sun set
        //           tc = riseAndSet.set;
        //         }
        //         let bbc = params.action[`bc${i}`];
        //         let dc = parseInt(params.action[`dc${i}`]);
        //         if(bbc == '2'){//提前
        //           tc = this.delayTime(tc, -1 * dc);
        //         }else if(bbc == '3'){//延后
        //           tc = this.delayTime(tc, dc);
        //         }
        //         item[`c${i}`] = tc;
        //       }else{
        //         item[`o${i}`] = params.action[`o${i}`];
        //         item[`c${i}`] = params.action[`c${i}`];
        //         item[`a${i}`] = parseInt(params.action[`a${i}`]);
        //       }
        //     }
        //   }
        //   let ms = params.action.start.getMonth() + 1;
        //   let me = params.action.end.getMonth() + 1;
        //   let dddd = new Date(start.getFullYear(), 2, 0);
        //   let last = dddd.getDate();
        //   if(ms <= 2 && me >=3 && last == 28){
        //     let week = dddd.getDay();
        //     if(week == 0)week = 7;
        //     week++;
        //     if(week == 8)week = 1;
        //     let wn = this.weeks[week-1];
        //     if(weekEnables.indexOf(wn) == -1)return;
        //     let item = this.form.contentDay[2][29];
        //     let riseAndSet = null;
        //     if(params.gps)riseAndSet = dddd.sunRiseAndSet(params.gpsForm.lat, params.gpsForm.lng);
        //     for(let i = 1; i <= 4; i++){
        //       if(!params.action[`e${i}`])continue;
        //       if(params.gps){
        //         let to; //开关灯时间
        //         if(params.action[`ro${i}`] == '1'){//sun rise
        //           to = riseAndSet.rise;
        //         }else{//sun set
        //           to = riseAndSet.set;
        //         }
        //         let bb = params.action[`bo${i}`];
        //         let d = parseInt(params.action[`do${i}`]);
        //         if(bb == '2'){//提前
        //           to = this.delayTime(to, -1 * d);
        //         }else if(bb == '3'){//延后
        //           to = this.delayTime(to, d);
        //         }
        //         item[`o${i}`] = to;
        //         item[`a${i}`] = params.action[`a${i}`];

        //         let tc;
        //         if(params.action[`rc${i}`] == '1'){//sun rise
        //           tc = riseAndSet.rise;
        //         }else{//sun set
        //           tc = riseAndSet.set;
        //         }
        //         let bbc = params.action[`bc${i}`];
        //         let dc = parseInt(params.action[`dc${i}`]);
        //         if(bbc == '2'){//提前
        //           tc = this.delayTime(tc, -1 * dc);
        //         }else if(bbc == '3'){//延后
        //           tc = this.delayTime(tc, dc);
        //         }
        //         item[`c${i}`] = tc;
        //       }else{
        //         item[`o${i}`] = params.action[`o${i}`];
        //         item[`c${i}`] = params.action[`c${i}`];
        //         item[`a${i}`] = parseInt(params.action[`a${i}`]);
        //       }
        //     }
        //   }
      }
      // else{
      //   for(let i = 1; i <= 4; i++){
      //     this.form.contentWeek[params.day][`o${i}`] = params.action[`o${i}`];
      //     this.form.contentWeek[params.day][`a${i}`] = parseInt(params.action[`a${i}`]);
      //     this.form.contentWeek[params.day][`c${i}`] = params.action[`c${i}`];
      //     // this.form.contentWeek[params.day][`ac${i}`] = params.action[`ac${i}`];
      //   }
      // }
    },
    delayTime: function (time, delay) {//time: 05:34, delay: 5 | -5
      let ts = time.split(':');
      let h = parseInt(ts[0]), m = parseInt(ts[1]);
      let t = h * 60 + m + delay;
      let h1 = parseInt(t / 60), m1 = t - h1 * 60;
      let hs = `${h1}`.padStart(2, '0');
      let ms = `${m1}`.padStart(2, '0');
      return `${hs}:${ms}`;
    },
    editDayAction: function (type, day, month) {
      // type: 0、单日年表，1、批量年表，2、单日周表
      let action;
      if (type == 0) {
        action = this.form.content[this.midx + 1][day];
      } else {
        action = {
          start: '01-01',
          end: '12-31',
          weekEnables: [this.showLang('com.date.mon'), this.showLang('com.date.tues'), this.showLang('com.date.wed'), this.showLang('com.date.thurs'), this.showLang('com.date.fri'), this.showLang('com.date.sat'), this.showLang('com.date.sunday')],
          o1: '18:00',
          c1: '06:00',
          a1: 1,
          ro1: '2',
          bo1: '1',
          do1: '5',
          // ac1: '0',
          rc1: '1',
          bc1: '1',
          dc1: '5',
          e1: true,
          eo1: true,
          o2: '00:00',
          c2: '00:00',
          a2: 2,
          ro2: '2',
          bo2: '1',
          do2: '5',
          ac2: '2',
          rc2: '1',
          bc2: '1',
          dc2: '5',
          e2: false,
          eo2: false,
          o3: '00:00',
          c3: '00:00',
          a3: 2,
          ro3: '2',
          bo3: '1',
          do3: '5',
          ac3: '2',
          rc3: '1',
          bc3: '1',
          dc3: '5',
          e3: false,
          eo3: false,
          a4: 2,
          o4: '00:00',
          c4: '00:00',
          ro4: '2',
          bo4: '1',
          do4: '5',
          ac4: '2',
          rc4: '1',
          bc4: '1',
          dc4: '5',
          e4: false,
          eo4: false,
        };
      }
      this.dayEditItem = { type: type, day: day, month: month, action: action };
      this.showDayModal = true;
    },
    getAction: function (month, day, idx) {
      let m = this.form.content[month];
      if (!m) return '00:00 ' + this.showLang('com.state.invalid');
      let d = m[day];
      if (!d) return '00:00 ' + this.showLang('com.state.invalid');
      let a = `${d['a' + idx]}`;
      return `${d['o' + idx]}-${d['c' + idx]} ${a == '2' ? this.showLang('com.state.disable') : this.showLang('com.state.enable')}`;
    },
    switchMonth: function (idx) {
      this.midx = idx;
      let now = new Date();
      let year = now.getFullYear();
      let firstDate = new Date(`${year}-${idx + 1}-1`);
      this.week = firstDate.getDay();
      if (this.week == 0) {
        this.week = 7;
      }
      firstDate.setMonth(firstDate.getMonth() + 1);
      firstDate.setDate(firstDate.getDate() - 1);
      this.days = firstDate.getDate();
      // this.getTableContent();
    },
    getTableContent: async function () {
      this.loading = true;
      let res = await this.$axios.post(`//${this.domains.trans}/station/plan/QueryBjYearDetail`, { id: this.form.id });
      this.loading = false;
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        // this.hideLoading();
        return;
      }
      // this.form.type = res.data.type;
      this.form.name = res.data.name;
      if (!res.data.content[12]) {
        res.data.content = {};
        for (let i = 1; i <= 12; i++) {
          res.data.content[i] = {};
          for (let j = 1; j <= 31; j++) {
            res.data.content[i][j] = {
              o1: '00:00',
              c1: '00:00',
              a1: 2,
              o2: '00:00',
              c2: '00:00',
              a2: 2,
              o3: '00:00',
              c3: '00:00',
              a3: 2,
              o4: '00:00',
              c4: '00:00',
              a4: 2,
            }
          }
        }
      }
      this.$set(this.form, 'content', res.data.content);
      this.$set(this.form, 'extra', res.data.extra);
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.total-container {
  /* border: solid 1px red; */
  height: 700px;
  display: flex;
}
.edit-container {
  height: calc(100% - 140px);
  /* border: solid 1px red; */
  overflow-y: auto;
  width: 970px;
}
.week-area {
  border: solid 1px #dcdee2;
  border-radius: 6px;
  width: 200px;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.week-header {
  height: 40px;
  flex: none;
  line-height: 40px;
  text-align: center;
  border-bottom: solid 1px #dcdee2;
}
.week-content {
  height: 400px;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.week-item {
  border-bottom: solid 1px #dcdee2;
  height: 35px;
  flex: none;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.float-container {
  width: 980px;
  flex: none;
  border: solid 1px #dcdee2;
  margin-right: 5px;
  border-radius: 6px;
  padding-top: 5px;
}
.time-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.time-item {
  border: solid 1px #4767aa;
  border-radius: 3px;
  width: 125px;
  height: 160px;
  background: linear-gradient(to top, #cccccc, #ffffff);
  font-family: 微软雅黑, 宋体;
  margin: 5px;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
}
.time-item p {
  margin-top: 3px;
  white-space: nowrap;
}
.time-title {
  font-size: 22px;
  color: #4767aa;
  font-style: italic;
  font-weight: bold;
}
</style>