<template>
  <div class="police-box">
    <vxe-table :data="alarmsData" style="width: 100%;" align="center" border stripe :height="tabHeight" default-expand-all :empty-text="showLang('com.data.no')">
      <vxe-column type="seq" width="55" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
      <vxe-column field="stationName" title="站点" align="left">
        <template #default="{ row }">
          <span class="stationName" @click="jumpDetailsDve(row)" :title="row.stationName"> {{row.stationName}}</span>
        </template>
      </vxe-column>

      <vxe-column field="startTime" title="时间" sortable width="200">
        <template #default="{ row }">
          <span @click="showAlarmHisData(row)"> {{row.offlineTime}}</span>
        </template>
      </vxe-column>
    </vxe-table>

  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'DeviceOffline',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      tabHeight: 500,
      showModal: this.value,
      curtab: 'power',
      editItem: {},
      showAlarmDataModal: false,
      isShowPoliceBox: true,
      alarms: [],
      total: 0,
      page: {
        currentPage: 1,
        pageSize: 20,
      },
      pageSizes: [20, 100, 500, 1000, 5000],
      alarmsData: [],
      timeout: null,
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.initData();
        // this.initModalPosition();
      }
    },
    selectedNode() {
      this.clockAjax(this.page.currentPage = 1);
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  destroyed: function () {
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('group', ['selectedNode', 'groupLoaded', 'selectedDevices', 'stationAlarmChanged']),
    ...mapState('common', ['alarmTypes', 'productCodes']),
  },
  mounted: function () {
    this.clockAjax();
  },
  methods: {
    clockAjax() {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/alarm/QueryOffline`, { groupId, stationId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'alarmsData', res.data);
        }
      });
    },
    jumpDetailsDve(params) {
      this.$store.commit('group/selectNodeById', { nodeId: params.stationId, type: this.productCodes.station });
      this.$store.commit('auth/switchStationTabName', this.curtab);
    },
  }
}
</script>
<style scoped>
.police-box {
  width: 100%;
}
span {
  cursor: pointer;
}
</style>