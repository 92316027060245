<template>
  <table class="history-item" v-if="source.content">
    <tr>
      <td colspan="3" class="main-header"><img :src="img.mutiSearchFireTime" class="topImg" />
        {{showLang('com.date.occurrence')}}：{{new Date(source.fireTime).format('yyyy-MM-dd HH:mm:ss')}} - {{index+1}}</td>
    </tr>
    <tr class="sub-header">
      <td></td>
      <td>{{showLang('cmd.project.A')}}</td>
      <td>{{showLang('cmd.project.B')}}</td>
      <td>{{showLang('cmd.project.C')}}</td>
      <td></td>
      <td>{{showLang('cmd.project.A')}}</td>
      <td>{{showLang('cmd.project.B')}}</td>
      <td>{{showLang('cmd.project.C')}}</td>
    </tr>
    <tr>
      <td class="detail-header">{{showLang('com.lighting.V')}}</td>
      <td>{{source.content.ua}} V</td>
      <td>{{source.content.ub}} V</td>
      <td>{{source.content.uc}} V</td>
      <td class="detail-header">{{showLang('com.lighting.I')}}</td>
      <td>{{source.content.ca}} A</td>
      <td>{{source.content.cb}} A</td>
      <td>{{source.content.cc}} A</td>
    </tr>
    <tr>
      <td class="detail-header">{{showLang('com.data.active.power')}}</td>
      <td class="active">{{source.content.apa}} W</td>
      <td class="active">{{source.content.apb}} W</td>
      <td class="active">{{source.content.apc}} W</td>
      <td class="detail-header">{{showLang('com.data.reactive.power')}}</td>
      <td class="active">{{source.content.rpa}} Var</td>
      <td class="active">{{source.content.rpb}} Var</td>
      <td class="active">{{source.content.rpc}} Var</td>
    </tr>
    <tr>
      <td class="detail-header">{{showLang('com.data.power.factor')}}</td>
      <td>{{source.content.fa}}</td>
      <td>{{source.content.fb}}</td>
      <td>{{source.content.fc}}</td>
      <td class="detail-header">{{showLang('com.stat.total.q')}}</td>
      <td>{{showLang('com.lighting.ae')}}：{{source.content.ae}} KWh</td>
      <td>{{showLang('com.lighting.re')}}：{{source.content.re}} Kvarh</td>
      <td></td>
    </tr>
  </table>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PowerHistoryItem',
  components: {
  },
  props: {
    index: { // 每一行的索引
      type: Number
    },
    source: { // 每一行的内容
      type: Object,
      default() {
        return {}
      }
    },
    title: {
      type: String
    },
    lat: {
      type: Number
    },
    lng: {
      type: Number
    },
  },
  data() {
    return {
      contactTexts: {
        0: '停止',
        1: '手动',
        2: '遥控',
        3: '时控',
      },
      types: { 0: '关闭', 1: '开启', 2: '无效' },
      showModal: false,
      loading: false,
      main: {
        va: 0,
        vb: 0,
        vc: 0,
        vaState: '',
        vbState: '',
        vcState: '',
        iav: 0,
        iaf: 0,
        iap: 0,
        ibv: 0,
        ibf: 0,
        ibp: 0,
        icv: 0,
        icf: 0,
        icp: 0,
        energy: 0,
        temp: 0,
        lat: 0,
        lng: 0,
      },
      doors: [],
      branchs: [],
      locks: [],
      switchs: [],
      outputs: [],
      contacts: []
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    uval: function () {
      return function (item, flag) {
        let u = -1;
        if (this.isEnergy) {
          u = item.content[`u${flag}`];
        } else {
          u = item.content[`u${flag}`];
        }
        if (u < 0) return '-';
        let low = this.itemInfo.main.content[`u${flag}r`] * this.itemInfo.main.content[`u${flag}l`] / 100;
        let high = this.itemInfo.main.content[`u${flag}r`] * this.itemInfo.main.content[`u${flag}h`] / 100;
        u = this.img.val(u, 1);
        return `${u}(${low} - ${high})`
      }
    },
    cval: function () {
      //{{img.val(mitem.lastData.ca, 1)}} ({{img.val(mitem.content.car * mitem.content.cal / 100, 1)}} - {{img.val(mitem.content.car * mitem.content.cah / 100, 1)}}) A
      return function (item, flag) {
        let c = -1;
        if (this.isEnergy) {
          c = item.content[`c${flag}`];
        } else {
          c = item.content[`c${flag}`];
        }
        if (c < 0) return '-';
        let low = this.itemInfo.main.content[`c${flag}r`] * this.itemInfo.main.content[`c${flag}l`] / 100;
        let high = this.itemInfo.main.content[`c${flag}r`] * this.itemInfo.main.content[`c${flag}h`] / 100;
        c = this.img.val(c, 1);
        return `${c}(${low} - ${high}) A`
      }
    },
    pval: function () {
      //({{img.val(mitem.lastData.pc * 100 / mitem.content.pcr, 1)}} %)
      return function (item, flag) {
        let p = -1;
        if (this.isEnergy) {
          p = item.content[`ap${flag}`];
        } else {
          p = item.content[`p${flag}`];
        }
        if (p < 0) return '-';
        let high = this.img.val(p * 100 / this.itemInfo.main.content[`p${flag}r`], 1);
        p = this.img.val(p, 1);
        return `${p}W(${high}%)`
      }
    },
    fval: function () {
      return function (item, flag) {
        let f = -1;
        if (this.isEnergy) {
          f = item.content[`f${flag}`];
        } else {
          f = item.content[`f${flag}`];
        }
        if (f < 0) return '-';
        let high = this.itemInfo.main.content[`p${flag}f`];
        f = this.img.val(f, 3);
        return `${f}(${high})`
      }
    },
    rpval: function () {
      return function (item, flag) {
        if (this.isEnergy) {
          return item.content[`rp${flag}`] + 'Var';
        } else {
          let u = item.content[`u${flag}`];
          let c = item.content[`c${flag}`];
          let p = item.content[`p${flag}`];
          if (!u || !c || !p) return '--';
          if (u < 0 || c < 0 || p < 0) return '-';
          return (u * c - p).toFixed(2) + 'Var';
        }
      }
    },
    showItem: function () {
      return function (idx) {
        return { name: '' + idx, value: idx };
      }
    },
  },
  created: function () {
    if (!this.source.main) {
      this.source.main = { content: {} };
    }
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    fixed: function (v, len) {
      if (v) return v.toFixed(len);
      return 0;
    },
    valueData: function (v) {
      if (v == -2) return this.showLang('com.not.set');
      else if (v == -1) return this.showLang('com.state.not.measured');
      else if (v >= 0) return v;
      else return '-';
    },
    openState: function (val) {
      return !val ? this.showLang('com.state.close') : this.showLang('com.state.break')
    },
    alarmState: function (val) {
      return !val ? this.showLang('com.state.normal') : this.showLang('com.state.police')
    },
    leakageStatus: function (params) {
      if (params.content.cl > (params.cfg.lh)) return this.showLang('com.lighting.severe.leakage');
      if (params.content.cl > (params.cfg.ll)) return this.showLang('com.lighting.minor.leakage');
      return this.showLang('com.lighting.cl.no');
    },
    currentStatus: function (params, type) {
      if (params.content.tv != 1) return this.showLang('com.state.normal');
      if (params.content[`c${type}`] < (params.cfg[`c${type}r`] * params.cfg[`c${type}l`] / 100)) return this.showLang('alarm.type.current.under');
      if (params.content[`c${type}`] > (params.cfg[`c${type}r`] * params.cfg[`c${type}h`] / 100)) return this.showLang('alarm.type.current.over');
      return this.showLang('com.state.normal');
    },
    voltageStatus: function (params, type) {
      if (params.content.tv != 1) return this.showLang('com.state.normal');
      if (params.content[`u${type}`] < (params.cfg[`u${type}r`] * params.cfg[`u${type}l`] / 100)) return this.showLang('alarm.type.voltage.under');
      if (params.content[`u${type}`] > (params.cfg[`u${type}r`] * params.cfg[`u${type}h`] / 100)) return this.showLang('alarm.type.voltage.over');
      return this.showLang('com.state.normal');
    },
    switchStatus: function (params) {
      if (params.content.hv == 1) return this.showLang('com.op.hand');
      if (params.content.rv == 1) return this.showLang('com.op.remote');
      if (params.content.tv == 1) return this.showLang('com.op.time.control');
      return this.showLang('com.op.cease');
    },
    waterState: function (params) {
      if (params.content.s3 == params.content.a3) return this.showLang('alarm.type.water.3');
      if (params.content.s2 == params.content.a2) return this.showLang('alarm.type.water.2');
      if (params.content.s1 == params.content.a1) return this.showLang('alarm.type.water.1');
      return this.showLang('com.ins.waterless');
    }
  }
}
</script>
<style scoped>
.alarm-color {
  color: red;
}
td {
  height: 40px;
  white-space: nowrap;
  width: 120px;
}
.main-header {
  background-color: #4781bf;
  border-radius: 6px 6px 0px 0px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  width: 267px;
  height: 32px;
  /* text-align: left;
  padding-left: 15px !important;
  font-weight: bold; */
}
.topImg {
  vertical-align: middle;
  margin-right: 3px;
}
.sub-header {
  background-color: #cfe3f7;
  /* background: #CFE3F7; */
  height: 46px;
  text-align: center;
}
.detail-header {
  /* background-color: #F2F2F2; */
  background-color: #d7e3ef;
  text-align: center;
}
.branch-title {
  padding-top: 5px;
}
.history-item {
  /* border-width: 1px;
  border-collapse: collapse;
  border-color: cyan; */
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
}
/* .table-data tr{
  padding: 8px;
  border-width: 1px;
  border-collapse: collapse;
  border-color: cyan;
} */
.table-data td {
  /* background-color: darkcyan; */
  padding: 5px 8px;
  height: 40px;
  white-space: nowrap;
  width: 120px;
  border-collapse: collapse;
  /* border: solid 1px #91CBD9; */
}
.active {
  background-color: #f2f6fa;
}
</style>