<template>
  <div class="history-item">
    <div class="history-item-time">
      <DatePicker v-model="filter.start" type="datetime" format="yyyy-MM-dd HH:mm:ss" placeholder="请选择开始时间" style="width: 175px; margin-right: 10px;"></DatePicker>
      <Tooltip content="查询日期的时间范围为五分钟">
        <img :src="img.mapAlarmTitle" alt="">
      </Tooltip>
      <a href="javascript:void(0);" class="searchBut" @click="getPlayUrl"><img :src="img.pole.speak.lookup" />查询</a>
    </div>
    <Modal v-model="modalVideo" title="播放" @on-ok="closePlayer()" @on-cancel="closePlayer()" width="700">
      <div id="videoPlayer" class="history-video"></div>
    </Modal>
  </div>
</template>
<script>
import EZUIKit from 'ezuikit-js';
export default {
  name: 'Home',
  components: {
  },
  props: {
    videlID: {
      type: Number,
      default: -1
    },
  },
  data() {
    return {
      speed: 3,
      testImg: require("@/assets/video-1.jpeg"),
      list: [],
      size: 5,
      subs: [],
      spinShow: false,
      play: -1,
      modalVideo: false,
      filter: {
        start: "",
        end: "",
      }
    }
  },
  watch: {
  },
  mounted: function () {
    let date = new Date();
    this.filter.start = date;
    let min = date.getMinutes();
    this.filter.end = date.getMinutes(min + 5);
  },
  methods: {
    setSubs: function (pageIndex) {
      this.$set(this, 'subs', this.list.slice(this.size * (pageIndex - 1), this.size * pageIndex));
      this.play = -1;
    },
    getPlayUrl: function () {
      this.closePlayer();
      this.modalVideo = true;
      let start = new Date(this.filter.start).format('yyyy-MM-dd HH:mm:ss');
      let today = new Date(this.filter.start);
      let end = new Date(today.setMinutes(today.getMinutes() + 5)).format('yyyy-MM-dd HH:mm:ss');
      this.$axios.post(`//${this.domains.trans}/pole/video/Record`, { id: this.videlID, start, end }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.previewVideo(res.data);
      });
    },
    previewVideo: function ({ url, token }) {
      let id = 'videoPlayer';
      let v = document.getElementById(id);
      // let token = item.token;
      let rect = v.getBoundingClientRect();
      this.player = new EZUIKit.EZUIKitPlayer({
        id: id,
        accessToken: token,
        url: url,
        autoplay: true,
        width: rect.width,
        height: rect.height,
      });
      v.style.overflow = 'hidden';
    },
    closePlayer: function () {
      if (this.player) {
        this.player.stop();
        this.player = null;
        let v = document.getElementById('videoPlayer');
        v.removeChild(v.childNodes[0]);
      }
    },
  }
}
</script>
<style scoped>
.history-item {
  width: 100%;
  display: flex;
  padding: 3px;
  height: 100%;
  flex-direction: column;
}
.history-item-list {
  width: 100%;
  display: flex;
  padding: 3px;
  height: calc(100% - 24px);
  flex-direction: column;
  position: relative;
  justify-content: space-evenly;
}
.history-item-time {
  margin-top: 40px;
}
.history-list-box {
  margin: 5px 0;
}
.history-item-page {
  width: 100%;
  text-align: center;
}
.info-play-icon {
  vertical-align: middle;
  margin-left: 25px;
}
.searchBut {
  padding: 3px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 26px;
  margin-left: 19px;
}
.searchBut img {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 4px;
}
.history-video {
  width: 100%;
  height: 700px;
}
.item-info {
  width: 120px;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.info-title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  height: 20px;
  flex: none;
}
.info-time {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
  height: 20px;
  flex: none;
}
.info-tools {
  display: flex;
  align-items: center;
  height: 20px;
  flex: auto;
  /* border: solid 1px red; */
  display: flex;
  align-items: flex-end;
}
.info-tools img {
  width: 28px;
  height: 28px;
  margin-right: 0px;
  margin-bottom: -5px;
  cursor: pointer;
}
.empty-text {
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -66px;
}
.empty-img {
  width: 100%;
}
</style>
