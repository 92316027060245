<template>
  <Modal v-model="showModal" :mask-closable="false" width="950px">
    <div slot="header">选择媒体</div>
    <div class="modal-area">
      <div class="modal-tree-area">
        <Tree ref="tree" :data="treeData" @on-select-change="nodeChange"></Tree>
      </div>
      <div class="modal-program-area">
        <div class="media-item media-header">待选媒体列表</div>
        <div class="media-item" v-for="(item, idx) in medias" :key="idx">
          <div class="media-item-title">
            [{{mediaTypes[item.type]}}]{{item.name}}
          </div>
          <div class="media-item-btn" @click="previewItem(item)"> &gt;&gt; </div>
        </div>
      </div>
      <div class="modal-preview-area">
        <video ref="videoPlayer" v-if="previewMedia.type == 1 && previewUrl != ''" :src="previewUrl" controls="controls" @loadedmetadata="videoLoaded" class="media-player">您的浏览器不支持 video 标签</video>
        <audio ref="audioPlayer" v-else-if="previewMedia.type == 2 && previewUrl != ''" :src="previewUrl" controls="controls" @canplay="audioLoaded" class="media-player">您的浏览器不支持 audio 标签</audio>
        <img v-else-if="previewMedia.type == 3 && previewUrl != ''" :src="previewUrl" class="media-player" @load="imageLoaded" />
        <span v-else class="media-player">当前无预览节目</span>
      </div>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="closeModal">关闭</Button>
      <Button style="margin-right: 8px" @click="handleDownload">选择</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalSelectMedia',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    onlyMp3: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      showModal: this.value,
      groups: [],
      treeData: [],
      medias: [],
      previewUrl: '',
      previewMedia: {type: 0},
      view:{
        type: 0,
        url: '',
      },
      selectedGroup: {id:0},
    }
  },
  computed: {
    ...mapState('auth', ['user', 'token']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['cmdResultRefresh', 'ledcmd', 'ledStateRefresh', 'ledState', 'commands']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mediaTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(this.showModal){
        this.getGroups();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function(){
  },
  methods: {
    closeModal: function(){
      this.showModal = false;
      if(this.$refs.videoPlayer)this.$refs.videoPlayer.pause();
      if(this.$refs.audioPlayer)this.$refs.audioPlayer.pause();
    },
    handleDownload: function(){
      console.log('handle download', this.$refs.videoPlayer, this.$refs.audioPlayer)
      this.$emit('selected', this.previewMedia);
      this.showModal = false;
      if(this.$refs.videoPlayer)this.$refs.videoPlayer.pause();
      if(this.$refs.audioPlayer)this.$refs.audioPlayer.pause();
    },
    previewItem: function(item){
      this.previewMedia = item;
      this.previewUrl = `http://${this.domains.trans}/station/file/media?id=${item.fileId}&auth=${this.token}`
    },
    getGroups: function(){
      this.$axios.get(`//${this.domains.trans}/media/group`).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'groups', res.data);
        let tree = [];
        this.initTreeData(tree, res.data, 0);
        this.$set(this, 'treeData', tree);
        if(tree.length > 0){
          this.nodeChange(tree, tree[0])
        }
      });
    },
    initTreeData: function(children, list, parentId){
      let ps = list.filter(p => p.parentId == parentId);
      for(let p of ps){
        let node = {id: p.id, title: p.name, data: p, children: [], expand: true}
        this.initTreeData(node.children, list, node.id);
        children.push(node);
      }
    },
    nodeChange: function(nodes, node){
      if(this.selectedGroup.id == node.id)return;
      this.selectedGroup = node;
      this.getMedias();
    },
    getMedias: function(){
      let groupId = 0;
      if(this.selectedGroup.id > 0){
        groupId = this.selectedGroup.id;
      }
      if(groupId == 0){
        this.$set(this, 'medias', []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/media/operation/GetConfirm`, {groupId, type: this.onlyMp3 ? 2 : 0}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'medias', res.data);
      });
    },
    audioLoaded: function(e){
      e.srcElement.play();
    },
    imageLoaded: function(){
    },
    videoLoaded: function(v){
      v.srcElement.play();
    },
  }
}
</script>
<style scoped>

.media-item{
  border: solid 1px rgba(143, 142, 142, 0.404);
  border-radius: 4px;
  height: 30px;
  flex: none;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}
.media-header{
  background-color: rgba(7, 7, 241, 0.342);
  color: white;
  font-size: 20px;
}
.media-item:hover{
  background-color: rgba(109, 161, 161, 0.247);
}
.media-item-title{
  /* border: solid 1px rgba(143, 142, 142, 0.404); */
  width: 100px;
  flex: auto;
}
.media-item-btn{
  border: solid 1px rgba(143, 142, 142, 0.404);
  border-radius: 4px;
  width: 25px;
  flex: none;
  color: blue;
  text-align: center;
  margin: 3px;
}
.modal-area{
  /* border: solid 1px red; */
  display: flex;
  height: 500px;
}
.modal-tree-area{
  border: solid 1px rgba(90, 85, 85, 0.205);
  width: 250px;
  flex: none;
  margin-right: 10px;
  overflow-y: auto;
}
.modal-program-area{
  border: solid 1px rgba(90, 85, 85, 0.205);
  width: 350px;
  flex: none;
  margin-right: 10px;
  padding: 5px;
  overflow-y: auto;
}
.modal-preview-area{
  border: solid 1px rgba(90, 85, 85, 0.205);
  width: 300px;
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.media-player{
  width: 100%;
  max-height:100%;
  border-radius: 4px;
  text-align: center;
}
</style>
