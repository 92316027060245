<template>
  <div class="config-station-container">
    <ModalTaskList :mp3Only="true" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalTaskList from '@/views/media/task/Index'
export default {
  name: 'PoleBroadIndex',
  components: {
    ModalTaskList
  },
  data() {
    return {
      showTaskModal: false
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'commands', 'cmdResultRefresh']),
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {

  },
  mounted: function () {
  },
  methods: {

  }
}
</script>
<style scoped>
.config-station-container {
  width: 100%;
  height: 100%;
}
</style>