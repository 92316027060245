<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">{{showLang('com.op.edit.info')}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="110">
      <FormItem prop="name" :label="showLang('com.tab.title')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" :placeholder="showLang('com.tab.title')"></Input>
        </div>
      </FormItem>
      <FormItem prop="stationId" :label="showLang('com.site.affiliated')" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.stationId" @on-change="stationChanged">
          <Option v-for="(s, idx) in stations" :key="idx" :value="s.id">{{s.name}}</Option>
        </Select>
      </FormItem>
      <FormItem prop="connectType" :label="showLang('com.association.main')" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.content.mid">
          <template v-for="(item, idx) in mains">
            <Option :value="parseInt(item.id)" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="oc" :label="showLang('com.output.ctrl.channel')" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.content.oc">
          <Option :value="0">{{showLang('com.state.not')}}</Option>
          <template v-for="n in 7">
            <Option :value="n" :key="n">{{showLang('com.lighting.output.which',n)}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="sc" :label="showLang('com.door.state.need')" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.content.sc">
          <Option :value="0">{{showLang('com.state.not')}}</Option>
          <template v-for="n in 16">
            <Option :value="n" :key="n">{{showLang('com.lighting.input.which',n)}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="av" :label="showLang('alarm.type.lock.open.val')" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.content.av">
          <Option :value="0">{{showLang('com.state.break')}}</Option>
          <Option :value="1">{{showLang('com.state.connection')}}</Option>
        </Select>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
    <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
  </Modal>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'ModalLockEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    stations: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      mains: [],
      devices: [],
      form: {
        id: 0,
        stationId: 0,
        connectType: 0, //设备接入方式，0为设备直连，其他都是从三方平台或其他SDK接入
        deviceId: 0,
        code: '',
        name: '',
        type: 7,
        content: {
          //基本配置相关
          mid: 0, //关联总配电属性ID
          sc: 0, //状态监测通道
          av: 0, //门开报警状态
          oc: 0, //控制输出通道
        }
      },
      rules: {
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        // console.log('modal level edit mounted', this.item)
        this.isAdd = this.item.isAdd;
        if (!this.isAdd) {
          this.form.id = this.item.data.id;
          this.form.stationId = this.item.data.stationId;
          this.form.name = this.item.data.name;

          this.stationChanged(() => {
            this.form.deviceId = this.item.data.deviceId;
            for (let k in this.form.content) {
              this.form.content[k] = this.item.data.content[k];
            }
          });
        } else {
          this.form.id = 0;
          this.form.stationId = 0;
          this.form.deviceId = 0;
          this.form.name = '';
          this.form.content = {
            //基本配置相关
            mid: 0, //关联总配电属性ID
            sc: 0, //状态监测通道
            av: 0, //门开报警状态
            oc: 0, //控制输出通道
          }
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'deviceTypes', 'productCodes', 'powerBoxTypes']),
    boxType: function () {
      return function (deviceId) {
        // if (!this.selectedStation) return 'none';
        if (deviceId <= 0) return 'none';
        let devs = this.devices.filter(p => p.id == deviceId);
        if (devs.length == 0) return 'none';
        return devs[0].type;
      }
    },
  },
  mounted: function () {
  },
  methods: {
    stationChanged: function (callback) {
      // this.form.content.mid = 0;
      if (this.form.stationId <= 0) {
        // this.$set(this, 'contacts', []);
        this.$set(this, 'devices', []);
      } else {
        this.$axios.post(`//${this.domains.trans}/station/config/QueryMain`, { groupId: 0, stationId: this.form.stationId }).then(res => {
          if (res.code != 0) {
            // this.$Message.error(res.data);
          } else {
            this.$set(this, 'mains', res.data);
            if (callback && `${typeof callback}` == 'function') callback();
          }
        });
      }
    },
    ok: async function () {
      if (this.form.name.trim().length == 0) {
        this.$Message.warning(this.showLang('save.name'));
        return;
      }
      if (this.form.stationId <= 0) {
        this.$Message.warning(this.showLang('com.save.err.station'));
        return;
      }
      if (this.form.content.bid <= 0) {
        this.$Message.warning(this.showLang('sava.dev.associated.branch'));
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/SaveLock`, this.form).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$Message.info(this.showLang('com.tips.save'));
          this.showModal = false;
          this.$emit('saved', { isAdd: this.isAdd, data: res.data });
        }
      })
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>