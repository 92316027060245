import { render, staticRenderFns } from "./PowerBranchTable.vue?vue&type=template&id=f574e5a2&scoped=true"
import script from "./PowerBranchTable.vue?vue&type=script&lang=js"
export * from "./PowerBranchTable.vue?vue&type=script&lang=js"
import style0 from "./PowerBranchTable.vue?vue&type=style&index=0&id=f574e5a2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f574e5a2",
  null
  
)

export default component.exports