<template>
  <div class="config-station-container">
    <div class="station-search-area">
      <template v-if="devId == -1">
        设备：
        <Select v-model="currentStationId" number style="width: 250px; margin-right: 10px">
          <template v-for="(item, idx) in devices">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </template>

      起始时间：
      <DatePicker v-model="filter.start" type="datetime" placeholder="请选择开始时间" style="width: 175px; margin-right: 10px;"></DatePicker>
      截止时间：
      <DatePicker v-model="filter.end" type="datetime" placeholder="请选择截止时间" style="width: 175px; margin-right: 10px;"></DatePicker>
      <Button type="info" :loading="loading" @click="getWillSieHistory" style="margin-right: 10px;">查询</Button>
    </div>
    <div class="station-data-area" ref="table">
      <u-table :data="history" use-virtual style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all :page-sizes="[15, 50, 100, 200, 500]" :pagination-show="true" :total="filter.total" :page-size="filter.pageSize" :current-page="filter.pageIndex" @handlePageSize="handlePageSize">
        <u-table-column type="index" label="序号" width="50"></u-table-column>
        <u-table-column prop="TskStartTime" label="发生时间" sortable width="155">
          <template slot-scope="params">
            {{new Date(params.row.content.time).format('yyyy-MM-dd HH:mm:ss')}}
          </template>
        </u-table-column>
        <u-table-column prop="tid" label="风向角度" sortable>
          <template slot-scope="params">
            {{img.val(params.row.content.da, 0)}} °
          </template>
        </u-table-column>
        <u-table-column prop="dl" label="风向档位" sortable>
          <template slot-scope="params">
            {{img.val(params.row.content.dl, 0)}} 档
          </template>
        </u-table-column>
        <u-table-column prop="spd" label="风速" sortable>
          <template slot-scope="params">
            {{img.val(params.row.content.spd, 1)}} (m/s)
          </template>
        </u-table-column>
        <u-table-column prop="pow" label="风力" sortable>
          <template slot-scope="params">
            {{img.val(params.row.content.pow, 0)}} 级
          </template>
        </u-table-column>
        <u-table-column prop="h" label="湿度" sortable>
          <template slot-scope="params">
            {{img.val(params.row.content.h, 1)}} RH%
          </template>
        </u-table-column>
        <u-table-column prop="t" label="温度" sortable>
          <template slot-scope="params">
            {{img.val(params.row.content.t, 1)}} ℃
          </template> 
        </u-table-column>
        <u-table-column prop="n" label="噪声" sortable>
          <template slot-scope="params">
            {{img.val(params.row.content.n, 1)}} 分贝
          </template>
        </u-table-column>
        <u-table-column prop="pm25" label="PM2.5" sortable>
          <template slot-scope="params">
            {{img.val(params.row.content.pm25, 0)}} μg/m³
          </template>
        </u-table-column>
        <u-table-column prop="pm10" label="PM10" sortable>
          <template slot-scope="params">
            {{img.val(params.row.content.pm10, 0)}} μg/m³
          </template>
        </u-table-column>
        <u-table-column prop="pre" label="大气压值" sortable>
          <template slot-scope="params">
            {{img.val(params.row.content.pre, 1)}} P
          </template>
        </u-table-column>
        <u-table-column prop="lux" label="光照度" sortable>
          <template slot-scope="params">
            {{img.val(params.row.content.lux, 0)}} lux
          </template>
        </u-table-column>
      </u-table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'Device',
  components: {
  },
  props: {
    devId: {
      type: Number,
      default: -1
    },
  },
  data() {
    return {
      previewUrl: '',
      tabHeight: 300,
      loading: false,
      currentStationId: 0,
      filter: {
        total: 0,
        pageIndex: 1,
        pageSize: 15,
        start: '',
        end: '',
      },
      history: [],
      devs: [],
      count: 0,
      getAllData: [],
      getTaskData: [],
      devices: [],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'poleType']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.defaultDev();
      this.getWillSieHistory();
    },
    currentStationId() {
      this.getWillSieHistory();
    },
     devId() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    this.getItems();
    setTimeout(() => {
      this.setTableHeight();
      this.getWillSieHistory();
    }, 20);
    let now = new Date();
    now.setHours(23, 59, 59, 0)
    this.filter.end = now.format('yyyy-MM-dd HH:mm:ss');
    now = new Date();
    now.setMonth(now.getMonth() - 1);
    this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');

  },
  methods: {
    getItems: function () {
      let poleId = 0, groupId = 0;
      if(this.selectedNode.type != this.productCodes.station){
        groupId = this.selectedNode.id;
      }else{
        poleId = this.selectedNode.poleId;
      }
      this.$axios.post(`//${this.domains.trans}/pole/config/QueryEnvironmentList`, {groupId, poleId}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        // res.data.map(p => {
        //   if(!p.lastData){
        //     p.lastData = {u: 3};
        //   }
        // })
        this.$set(this, 'devices', res.data);
        this.defaultDev();
      })
    },
    defaultDev() {
      if (this.devices.length > 0 && this.devId == -1) {
        this.currentStationId = this.devices[0].id;
        return
      }
      if (this.devId != -1) {
        this.currentStationId = this.devId;
      }
    },
    handlePageSize: function (params) {
      this.filter.pageSize = params.size;
      this.filter.pageIndex = params.page;
      this.getWillSieHistory();
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 3;
    },
    getWillSieDevices: async function () {
      let res = await this.$axios.get(`//${this.domains.trans}/pole/WillSieDevice?index=0&size=10000`);
      if (res.code != 0) {
        return;
      }
      this.$set(this, 'devs', res.data);
    },
    getWillSieHistory: async function () {
      if(this.currentStationId <= 0)return;
      let params = {
        paramId: this.currentStationId,
        start: new Date(this.filter.start).format('yyyy-MM-dd HH:mm:ss'),
        end: new Date(this.filter.end).format('yyyy-MM-dd HH:mm:ss'),
        index: this.filter.pageIndex,
        size: this.filter.pageSize,
      };
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/param/PagedHistory`, params).then(res => {
        this.loading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'history', res.data.list);
          this.filter.total = res.data.count;
        }
      });
    },
    pageSizeChange: function (size) {
      this.filter.index = 1;
      this.filter.size = size;
      this.getWillSieDevices();
    },
    pageIndexChange: function (index) {
      this.filter.index = index;
      this.getWillSieDevices();
    },
  }
}
</script>
<style scoped>
.media-player {
  /* visibility: hidden; */
  height: 0;
}
.symbol-download {
  display: flex;
  justify-content: center;
  align-items: center;
}
.symbol-download img {
  margin-right: 10px;
  cursor: pointer;
}
.config-station-container {
  /* padding: 5px;  
  padding-right: 40px; */
  width: 100%;
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 30px;
}
.station-search-area {
  height: 42px;
  width: 100%;
  flex: none;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.station-data-area {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>
