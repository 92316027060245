<template>
  <div class="player-container">
    <div class="player-header" v-if="item" :class="[isVidelSelected?'player-header-selected':'']">
      名称：{{item&&item.name}}
      <div class="space"></div>
    </div>
    <div class="player-content">
      <template v-if="item">
        <div :id="`video${item.id}`" class="video-player"> </div>
      </template>
    </div>
  </div>
</template>
<script>
import EZUIKit from 'ezuikit-js';
import Bus from './bus'
export default {
  name: 'Home',
  components: {
  },
  props: {
    videlIndex: {
      type: Number,
      default: -1
    },
    speed: {
      type: Number,
      default: -1
    },
    index: {
      type: Object,
      default() { return {}; }
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      tools: {
        wiper: '雨刷',
      },
      num: 0,
      tool: 'wiper',
      directionNum: -1,
      open: false,
      isVidelSelected: false,
      aaaa: 0,
      testImg: require("@/assets/video-1.jpeg")
    }
  },
  watch: {
    videlIndex() {
      this.isSelected()
    },
    speed() {
      // this.setSpeed();
    },
    index() {
      this.urlAjax();
    },
    open() {
      this.$emit('action', { open: this.open, tool: this.tool })
    },
  },
  mounted: function () {
    this.urlAjax();
    this.isSelected();
    if (this.videlIndex == (this.index.n - 1) * this.index.playerCountX + (this.index.i - 1)) {
      Bus.$on('setPanel', (data) => {
        this.setDirection(data);
      })
    }
  },
  methods: {
    isSelected() {
      let ind = (this.index.n - 1) * this.index.playerCountX + (this.index.i - 1);
      if (ind == this.videlIndex) {
        this.isVidelSelected = true
      } else {
        this.isVidelSelected = false
      }
    },
    setDirection: async function (data) {
      this.num = (this.index.n - 1) * this.index.playerCountX + (this.index.i - 1);//下标
      let ajaxData = this.item;
      if (ajaxData == undefined) { return }
      if (!this.player) return;
      if (this.num == this.videlIndex) {
        let ajaxDa = {
          move: false,
          direct: data.num,
          ids: [ajaxData.id]
        }
        if (data.is == false) {
          ajaxDa.move = false
          await this.$axios.post(`//${this.domains.trans}/pole/video/Control`, ajaxDa);
        } else {
          ajaxDa.move = true
          await this.$axios.post(`//${this.domains.trans}/pole/video/Control`, ajaxDa);
        }
      }
    },
    urlAjax: async function () {
      this.num = (this.index.n - 1) * this.index.playerCountX + (this.index.i - 1);//下标
      if (this.item.length < 0 || this.item.id == undefined) { return false }
      let ajaxData = this.item;
      if (ajaxData == undefined) { return false }
      let res = await this.$axios.post(`//${this.domains.trans}/pole/video/Preview`, { ids: [ajaxData.id] });
      if (!res.data[ajaxData.id].success) { return false }
      setTimeout(() => {
        let id = `video${this.item.id}`;
        let v = document.getElementById(id);
        let token = res.data[ajaxData.id].token;
        let url = res.data[ajaxData.id].content;
        let rect = v.getBoundingClientRect();
        this.player = new EZUIKit.EZUIKitPlayer({
          id: id,
          accessToken: token,
          url: url,
          autoplay: true,
          width: rect.width,
          height: rect.height,
        });
        v.style.overflow = 'hidden';
        // // 开始初始化直播源地址
        // var player = new EZUIKit.EZUIPlayer('hVideo');
        // // 播放
        // player.play();
        // 结束
        // player.stop();
      }, 100);
    },
  }
}
</script>
<style scoped>
.player-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.player-header.player-header-selected {
  background: #007eff;
  opacity: 1;
  border-radius: 0;
}
.player-header {
  height: 36px;
  flex: none;
  background: #000000;
  opacity: 0.5;
  border-radius: 12px;
  display: flex;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  white-space: nowrap;
}

.space {
  width: 100px;
  flex: auto;
}
.player-content {
  height: 55px;
  flex: auto;
  background-color: white;
}
.player-content img {
  width: 100%;
  height: 100%;
}
.video-player {
  width: 100%;
  height: 100%;
}
</style>
