<template>
<div class="config-station-container">
  <div class="station-search-area">
    <Button type="primary" size="default" v-for="(cmd, idx) in items" :key="idx" style="margin-right: 5px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
    <Button type="success" size="default" style="margin-right: 5px" @click="openTaskList">播放任务管理</Button>
    <!-- <Button opCode='sc' type="success" size="default" style="margin-right: 5px" @click="sendFailed">重发失败</Button> -->
    <!-- <Button opCode='sc' type="success" size="default" style="margin-right: 5px" @click="exportResult">导出结果</Button>
    <Checkbox v-model="clearOldLog">发送指令时，是否清除旧指令记录？</Checkbox> -->
    <div class="row-space"></div>
    <Input v-model="filter.name" style="width: 300px" search clearable placeholder="查询关键字" />
  </div>
  <div class="station-data-area" ref="table">
    <u-table ref="chkTable" :data="devs" use-virtual style="width: 100%;" row-key="id" border stripe size="mini" :height="tabHeight">
      <u-table-column type="selection" label="" width="50"></u-table-column>
      <u-table-column type="index" label="序号" width="50"></u-table-column>
      <u-table-column prop="online" label="状态" width="80">
        <template slot-scope="params">
          <!-- <img :src="params.row.online ? img.pole.icons.env.online : img.pole.icons.env.offline" /> -->
          {{getStatusName(params.row)}}
        </template>
      </u-table-column>
      <u-table-column prop="name" label="名称" sortable width="200">
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <u-table-column prop="stationId" label="所在灯杆" sortable width="200">
        <template slot-scope="params">
          {{params.row.stationName}}
        </template>
      </u-table-column>
      <u-table-column prop="type" label="关联设备" sortable width="200">
        <template slot-scope="params">
          {{params.row.deviceName}}
        </template>
      </u-table-column>
      <u-table-column prop="type" label="音量" width="50">
        <template slot-scope="params">
          {{params.row.lastData.volume}}
        </template>
      </u-table-column>
      <u-table-column prop="install" label="最后通信时间" sortable width="170">
        <template slot-scope="params">
          {{!params.row.fireTime ? '-' : new Date(params.row.fireTime).format('yyyy-MM-dd HH:mm:ss')}}
        </template>
      </u-table-column>
      <u-table-column label="执行结果">
        <template slot-scope="params">
          {{getCmdStatus(params.row)}}
        </template>
      </u-table-column>
    </u-table>
  </div>
  <ModalCommandArgs v-model="showArgModal" :command="editItem" @saved="commandArgSaved" />
  <ModalTaskList v-model="showTaskModal" :mp3Only="true" />
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalCommandArgs from './ModalCommandArgs'
import ModalTaskList from '@/views/media/task/ModalTaskList'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'PoleBroadIndex',
  components:{
    ModalCommandArgs,
    ModalTaskList,
  },
  data () {
    return {
      clearOldLog: false,
      showEditModal: false,
      showTaskModal: false,
      showArgModal: false,
      tabHeight: 300,
      editItem: {isAdd: true, index: -1, data: {}},
      filter: {
        name: '',
      },
      devs: [],
      items: [
        // { needArgs: false, needPswd: true, code: 'openDevice', name: '开屏', img: this.img.light.road.single.ctrl.forceRead },
        // { needArgs: false, needPswd: true, code: 'closeDevice', name: '关屏', img: this.img.light.road.single.ctrl.forceRead },
        // { needArgs: true, needPswd: true, code: 'setBright', name: '设置亮度', img: this.img.light.road.single.ctrl.forceRead },
        { needArgs: true, needPswd: true, code: 'setVolume', name: '设置音量', img: this.img.light.road.single.ctrl.forceRead },
        // { needArgs: false, needPswd: false, code: 'capture', name: '屏幕截图', img: this.img.light.road.single.ctrl.forceRead },
        { needArgs: true, needPswd: true, code: 'playMedia', name: '手动播放', img: this.img.light.road.single.ctrl.forceRead },
        { needArgs: true, needPswd: true, code: 'playTask', name: '计划播放', img: this.img.light.road.single.ctrl.forceRead },
        { needArgs: true, needPswd: true, code: 'stopPlay', name: '停止播放', img: this.img.light.road.single.ctrl.forceRead },
      ],
      poleBroadTypes: {
        1: '熙讯',
        101: '威尔西平台设备',
      },
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'commands', 'cmdResultRefresh']),
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    selectedNode(){
      this.initDevs();
    },
    'filter.name'(){
      this.initDevs();
    },
    cmdResultRefresh(){
      for(let cmd of this.commands){
        let ds = this.devs.filter(p => p.cmdId == cmd.cmdId);
        if(ds.length == 0)continue;
        this.$set(ds[0], 'status', cmd.status);
        if(cmd.status == 9){
          this.$set(ds[0], 'cmdData', cmd.content);
        }
      }
    },
  },
  mounted: function(){
    setTimeout(() => {
      this.setTableHeight();
    }, 200);
    this.initDevs();
  },
  methods: {
    openTaskList: function(){
      this.showTaskModal = true;
    },
    getStatusName: function(row){
      if(!row.online)return '离线';
      if(row.lastData.state == 0) return '关屏';
      if(row.lastData.state == 1) return '自动播放';
      if(row.lastData.state == 2) return '手动播放';
      if(row.lastData.state == 3) return '联动播放';
      return '在线';
    },
    getCmdStatus: function (cmd) {
      switch (cmd.status) {
        case 0: return `${cmd.result}`;
        case 1: return '等待回复';
        case 2: return '指令超时';
        case 3: return `执行失败：${cmd.result}`;
        case 4: return `执行中：${cmd.content.pkg} / ${cmd.content.total}`;
        case 5: return `失败重试：${cmd.result}`;
        case 9:
          // console.log('getCmdStatus', cmd)
          // console.log('cmd status', cmd)
          switch (cmd.cmd) {
            case 'getclock': return `执行成功：${cmd.content.nowTime}`;
            case 'getInfo': return `硬件版本：${cmd.content.hardware}，软件版本：${cmd.content.software}，生产厂商：${cmd.content.manufacture}`;
            case 'getSimInfo': return `ICCID：${cmd.content.iccid}，IMEI：${cmd.content.imei}，RSSI：${cmd.content.rssi}`;
            case 'getNetwork': return `IP:${cmd.content.host}, 端口:${cmd.content.port}, 心跳间隔:${cmd.content.heart}秒`;
            default: return `执行成功`;
          }
      }
    },
    exportResult: function () {
      let cmdData = [];
      this.devices.map(item => {
        cmdData.push({
          name: item.name,
          sname: item.sname,
          type: this.deviceTypes[item.type],
          result: this.getCmdStatus(item)
        })
      });
      let tabHead = {
        name: '设备名称',
        sname: '站点名称',
        type: '设备类型',
        result: '执行结果'
      }
      exportXlsx(cmdData, tabHead, "指令执行结果");
    },
    sendFailed: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let sids = [];
      for (let d of this.devs) {
        if ((d.status == 2 || d.status == 3) && chks.filter(p => p.id == d.id).length > 0) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', '准备重发')
          sids.push(d.id);
          // this.$set(d, 'cmd', c.code)
          // this.$set(d, 'content', c.content)
        } else if (this.clearOldLog) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', '')
        }
      }
      setTimeout(() => {
        this.$store.dispatch('cmd/sendFailed', sids).then(res => {
          if (res.code == 0) {
            for (let c of this.commands) {
              let its = this.devs.filter(p => p.id == c.id);
              if (its.length == 0) continue;
              this.$set(its[0], 'status', c.status)
              this.$set(its[0], 'result', c.result)
              this.$set(its[0], 'cmd', c.code)
              this.$set(its[0], 'content', c.content)
            }
          }
        });
      }, 500)
    },
    commandArgSaved: function(params){
      // console.log('click cmd', params)
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要控制的终端');
        return;
      }
      let ids = chks.map(p => p.id);
      // console.log('get dev list', chks, ids)
      if(params.cmd.code == 'playMedia'){
        // let ids = chks.map(p => p.id);
        this.$axios.post(`//${this.domains.trans}/pole/broad/PlayMedia`, {ids: ids, mediaId: params.args.mediaId}).then(res => {
          if(res.code != 0){
            // this.$Message.error(res.data);
            return;
          }
          for(let id of ids){
            let ds = this.devs.filter(p => p.id == id);
            if(ds.length == 0)continue;
            let dev = ds[0];
            let result = res.data[id];
            if(result.success){
              if(res.isCmd){
                this.$store.commit('cmd/ledCmdSend', {id: id, cmdId: result.content, code: params.cmd.code, status: 1, result: ''});
                this.$set(dev, 'cmdId', result.content);
              }else{
                this.$set(dev, 'status', 9);
                this.$set(dev, 'result', result.content);
                this.$set(dev, 'cmd', params.cmd.code);
              }
            }else{
              this.$set(dev, 'status', 3);
              this.$set(dev, 'result', result.content);
              this.$set(dev, 'cmd', params.cmd.code);
            }
          }
        });
      }else if(params.cmd.code == 'playTask'){
        // let ids = chks.map(p => p.id);
        this.$axios.post(`//${this.domains.trans}/pole/broad/SetTask`, {ids: ids, taskIds: params.args.taskIds}).then(res => {
          if(res.code != 0){
            // this.$Message.error(res.data);
            return;
          }
          for(let id of ids){
            let ds = this.devs.filter(p => p.id == id);
            if(ds.length == 0)continue;
            let dev = ds[0];
            this.$set(dev, 'status', 9);
            this.$set(dev, 'result', '任务已设置');
            this.$set(dev, 'cmd', params.cmd.code);
            // this.$set(dev, 'content', {});
          }
        });
      }else if(params.cmd.code == 'setVolume') {
        this.$axios.post(`//${this.domains.trans}/pole/broad/setVolume`, {ids, vol: params.args.volume}).then(res => {
          if(res.code != 0){
            // this.$Message.error(res.data);
            return;
          }
          // console.log('get cmd result', res.data)
          this.$store.commit('cmd/ledCmdClear');
          for(let id of ids){
            let ds = this.devs.filter(p => p.id == id);
            if(ds.length == 0)continue;
            let dev = ds[0];
            let result = res.data[id];
            if(result.success){
              if(res.isCmd){
                this.$store.commit('cmd/ledCmdSend', {id: id, cmdId: result.content, code: params.cmd.code, status: 1, result: ''});
                this.$set(dev, 'cmdId', result.content);
              }else{
                this.$set(dev, 'status', 9);
                this.$set(dev, 'result', result.content);
                this.$set(dev, 'cmd', params.cmd.code);
                this.$set(dev.lastData, 'volume', params.args.volume);
              }
            }else{
              this.$set(dev, 'status', 3);
              this.$set(dev, 'result', result.content);
              this.$set(dev, 'cmd', params.cmd.code);
            }
          }
        });
      }else if(params.cmd.code == 'stopPlay') {
        this.$axios.post(`//${this.domains.trans}/pole/broad/ControlPlay`, {ids, start: false}).then(res => {
          if(res.code != 0){
            // this.$Message.error(res.data);
            return;
          }
          for(let id of ids){
            let ds = this.devs.filter(p => p.id == id);
            if(ds.length == 0)continue;
            let dev = ds[0];
            let result = res.data[id];
            if(result.success){
              this.$set(dev, 'status', 9);
              this.$set(dev, 'result', result.content);
              this.$set(dev, 'cmd', params.cmd.code);
              // this.$set(dev.lastData, 'volume', params.args.volume);
            }else{
              this.$set(dev, 'status', 3);
              this.$set(dev, 'result', result.content);
              this.$set(dev, 'cmd', params.cmd.code);
            }
          }
        });
      }
    },
    clickCmd: function (params) {
      // console.log('click cmd', params)
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要控制的终端');
        return;
      }
      if(params.needArgs || params.needPswd){
        this.editItem = params;
        this.showArgModal = true;
        return;
      }
    },
    initDevs: function(){
      // console.log('windowOnResize', this.selectedStationIds.join(','))
      let groupId = 0, poleId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        poleId = this.selectedNode.poleId;
      }else{
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/pole/config/QueryBroadList`, {groupId, poleId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'devs', res.data);
          this.$refs.chkTable.toggleAllSelection();
        }
      });
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 3;
    },
    getDate:function(date){
      let d = new Date(date);
      if(d.getFullYear() == 1970)return '';
      return d.format('yyyy-MM-dd');
    },
  }
}
</script>
<style scoped>
.config-station-container{
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  padding: 10px;
  background-color: white;
  border-radius: 8px;
}
.station-search-area{
  height: 42px;
  flex: none;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.row-space{
  width: 100px;
  flex: auto;
}
.station-data-area{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>