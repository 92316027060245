<template>
<Modal v-model="showModal" :mask-closable="false" width="1200px">
  <div slot="header">{{showLang('com.op.edit.info')}}</div>
  <div style="height: 500px; overflow-y:auto">
    <Form ref="form" :model="action" class="form" :label-width="120">
      <FormItem prop="start" :label="showLang('com.date.range')" v-if="editType == 1">
        <DatePicker type="date" v-model="action.start" format="MM-dd" :placeholder="showLang('com.date.start')" style="width: 155px"></DatePicker>
       {{showLang('com.date.to')}}
        <DatePicker type="date" v-model="action.end" format="MM-dd" :placeholder="showLang('com.date.end')" style="width: 155px"></DatePicker>
        <span style="color:red;margin-left:10px">{{showLang('com.date.update.plan')}}</span>
      </FormItem>
      <FormItem prop="start" :label="showLang('com.date.current2')" v-else-if="editType == 0">
        {{month+1}}{{showLang('com.date.month')}}{{editDay}}{{showLang('com.date.sun')}}
      </FormItem>
      <FormItem prop="start" :label="showLang('com.current')" v-else>
        {{weeks[editDay - 1]}}
      </FormItem>
      <!-- <FormItem prop="start" :label="showLang('777')周范围" v-if="editType == 1">
        <div style="display: flex; align-items: center">
          <CheckboxGroup v-model="action.weekEnables" style="width: 525px;">
            <Checkbox v-for="w in weeks" :key="w" ::label="showLang('777')w"></Checkbox>
          </CheckboxGroup>
          <span style="color:red;">只更新此周范围内的时间计划，与上述日期范围叠加</span>
        </div>
      </FormItem> -->
      <FormItem prop="start" :label="showLang('com.op.edit.type')" v-if="editType == 1">
        <div style="display:flex;align-items:center;">
          <i-switch v-model="gpsEdit" size="large" true-color="blue" false-color="green" :title="showLang('com.date.longitude.schedule')">
            <span slot="open">{{showLang('timetable.longitude')}}</span>
            <span slot="close">{{showLang('timetable.constant.val')}}</span>
          </i-switch>
          <Input v-if="gpsEdit" type="number" v-model="form.lng" style="width: 200px; margin-left: 15px">
            <span slot="prepend">{{showLang('com.import.cn.lng')}}</span>
          </Input>
          <Input v-if="gpsEdit" type="number" v-model="form.lat" style="width: 200px; margin-left: 15px">
            <span slot="prepend">{{showLang('com.import.cn.lat')}}</span>
          </Input>
        </div>
      </FormItem>
      <template v-if="!gpsEdit">
        <FormItem v-for="n in 4" :key="n" :label="showLang('com.date.period')+n">
          <div style="display: flex; align-items: center;">
            {{showLang('com.date.lamp')}}
            <TimePicker v-model="action['o'+n]" format="HH:mm" placeholder="Select time" style="width: 120px;margin-left:10px"></TimePicker>
            <Checkbox v-if="editType == 1" v-model="action['eo'+n]"  style="margin:0 12px 0 5px">{{showLang('timetable.update.group',n)}}</Checkbox>
            {{showLang('com.date.turn')}}
            <TimePicker v-model="action['c'+n]" format="HH:mm" placeholder="Select time" style="width: 120px;margin-left:10px"></TimePicker>
            <Checkbox v-if="editType == 1" v-model="action['e'+n]" style="margin:0 12px 0 5px">{{showLang('timetable.update.group',n)}}</Checkbox>
            <i-switch v-model="action['a'+n]" size="large" style="margin:0 10px" true-color="blue" false-color="red" :true-value="1" :false-value="2">
              <span slot="open">{{showLang('com.state.enable')}}</span>
              <span slot="close">{{showLang('com.state.disable')}}</span>
            </i-switch>
            <!-- {{action['a'+n]}} -->
          </div>
        </FormItem>
      </template>
      <template v-else>
        <FormItem v-for="n in 4" :key="n" :label="showLang('com.date.period')+n">
          <div style="float:left"> {{showLang('com.date.lamp')}}</div>
          <Select :placeholder="showLang('save.select')" v-model="action['ro'+n]" style="width: 100px; float:left;margin-left: 10px">
            <Option value="1">{{showLang('com.state.sunrise')}}</Option>
            <Option value="2">{{showLang('com.state.sunset')}}</Option>
          </Select>
          <Select :placeholder="showLang('save.select')" v-model="action['bo'+n]" style="width: 100px; float:left;margin-left: 5px">
            <Option value="1">{{showLang('com.state.normal')}}</Option>
            <Option value="2">{{showLang('com.state.advance')}}</Option>
            <Option value="3">{{showLang('com.state.delay')}}</Option>
          </Select>
          <Input type="number" v-model="action['do'+n]" style="width: 100px; float:left;margin-left: 5px">
            <span slot="append">min</span>
          </Input>
          <div style="float:left;margin-left: 25px">{{showLang('com.date.turn')}}</div>
          <Select :placeholder="showLang('save.select')" v-model="action['rc'+n]" style="width: 100px; float:left;margin-left: 10px">
            <Option value="1">{{showLang('com.state.sunrise')}}</Option>
            <Option value="2">{{showLang('com.state.sunset')}}</Option>
          </Select>
          <Select :placeholder="showLang('save.select')" v-model="action['bc'+n]" style="width: 100px; float:left;margin-left: 5px">
            <Option value="1">{{showLang('com.state.normal')}}</Option>
            <Option value="2">{{showLang('com.state.advance')}}</Option>
            <Option value="3">{{showLang('com.state.delay')}}</Option>
          </Select>
          <Input type="number" v-model="action['dc'+n]" style="width: 100px; float:left;margin-left: 5px">
            <span slot="append">min</span>
          </Input>
          <i-switch v-model="action['a'+n]" size="large" style="margin:0 10px" true-color="blue" false-color="red" :true-value="1" :false-value="2">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Checkbox v-model="action['e'+n]">{{showLang('timetable.update.group',n)}}</Checkbox>
        </FormItem>
      </template>
    </Form>
  </div>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancelDayAction">{{showLang('com.op.cancel')}}</Button>
    <Button type="primary" @click="saveDayAction">{{showLang('com.op.save')}}</Button>
  </div>
</Modal>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
  export default {
    name: 'ModalDayAction',
    components:{
    },
    model: {
      prop: 'value',
      event: 'showChanged'
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      item: {
        type: Object,
        default(){return {};}
      },
    },
    data () {
      return {
        weeks: ['星期一','星期二','星期三','星期四','星期五','星期六','星期日'],
        showModal: this.value,
        action: {},
        gpsEdit: true,
        editType: 0, // 0、单日年表，1、批量年表，2、单日周表
        editDay: 1,
        month: 0,
        form: {
          lng: 0,
          lat: 0,
        },
      }
    },
    watch: {
      value(newVal) {
        this.showModal = newVal;
        if(this.showModal){
          this.form.lng = this.config.lng;
          this.form.lat = this.config.lat;
          this.editType = this.item.type;
          this.editDay = this.item.day;
          this.gpsEdit = this.editType == 1;
          this.month = this.item.month;
          this.action = this.item.action;
        }
      },
      showModal(val){
        this.$emit('showChanged', val);
      }
    },
    mounted: function(){
        this.weeks=[this.showLang('com.date.mon'),this.showLang('com.date.tues'),this.showLang('com.date.wed'),this.showLang('com.date.thurs'),this.showLang('com.date.fri'),this.showLang('com.date.sat'),this.showLang('com.date.sunday')]
    },
    computed: {
      ...mapState('common', ['mapCenter']),
      ...mapState("auth", [ "config",]),
    ...mapGetters('auth', ['funCodes','showLang']),
    },
    methods: {
      cancelDayAction: function(){
        this.showModal = false;
      },
      saveDayAction: function(){
        this.showModal = false;
        this.$emit('dayActionSaved', {type: this.editType, month: this.month, gps: this.gpsEdit, gpsForm: this.form, day: this.editDay, action: this.action});
      },
      cancel () {
        this.showModal = false;
      },
    }
  }
</script>
<style scoped>
</style>