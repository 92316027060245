<template>
<div class="organ-container">
  <div class="organ-header">
    <div class="header-tab-item">
      开始日期：
      <DatePicker type="date" v-model="filter.start" placeholder="请选择开始日期" style="width: 115px;margin-right:10px"></DatePicker>
      结束日期：
      <DatePicker type="date" v-model="filter.end" placeholder="请选择结束日期" style="width: 115px;margin-right:10px"></DatePicker>
      <Button type="primary" @click="getList" style="margin-right:10px">查询</Button>
      <Button type="success" @click="exportData">导出</Button>
    </div>
  </div>
  <div class="organ-content">
    <Spin fix v-if="spin.show">{{spin.content}}</Spin>
    <div class="chart-area">
      <chartsBar :option="chartsLineData" id="chartsLineData" :key="timer"></chartsBar>
    </div>
    <div class="data-area" ref="table">
      <u-table :data="tabData" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="tabHeight" stripe>
        <u-table-column type="index"></u-table-column>
        <u-table-column prop="time" label="日期" sortable fit>
          <template slot-scope="scope">
            {{new Date(scope.row.date).format('yyyy-MM-dd')}}
          </template>
        </u-table-column>
        <u-table-column prop="third" label="报警等级" sortable fit>
          <template slot-scope="scope">
            {{getAlarmLevel(scope.row.level)}}
          </template>
        </u-table-column>
        <u-table-column prop="count" label="报警次数" sortable fit></u-table-column>
      </u-table>
    </div>
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'AnalyseAlarmTrend',
  components: {
  },
  props: {
  },
  data(){
    return {
      spin: {
        show: false,
        content: '加载中...',
      },
      editModal: false,
      tabHeight:500,
      tabData:[],
      filter: {
        start: '',
        end: '',
      },
      timer: 0,
      chartsLineData: {
        color: ['#5470C6', '#91CC75', '#FAC858'],
        title: "",
        x: [],
        unit: "KWh",
        series: [
          {
            name: "预报警",
            data: [],
          },
          {
            name: "普通报警",
            data: [],
          },
          {
            name: "严重报警",
            data: [],
          },
        ],
        setSeries: {
          barWidth: 45,
          smooth: true, //平滑折线图
          areaStyle: {}, //下阴影
        },
      },
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    stationIds: function(){
      return this.selectedDevices.map(p => {
        if(p.id) return p.id;
        return 0;
      });
    },
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    selectedNode(){
      this.getList();
    },
  },
  mounted: function(){
    let now = new Date();
    this.filter.end = now.format('yyyy-MM-dd');
    now.setMonth(now.getMonth() - 1);
    this.filter.start = now.format('yyyy-MM-dd');
    setTimeout(this.setTableHeight, 200);
    this.getList();
  },
  methods: {
    getAlarmLevel: function(level){
      if(this.alarmLevels[level])return this.alarmLevels[level];
      return '未设置';
    },
    exportData(){
      let tab = [];
      this.tabData.map(p => {
        tab.push({time: new Date(p.time).format('yyyy-MM-dd'), third: this.getAlarmLevel(p.third), value: p.value});
      })
      exportXlsx(tab, {
        time: '日期',
        third: '报警等级',
        value: '报警次数',
      }, "导出记录");
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 100;
    },
    getList: function(){
      let start = new Date(this.filter.start).format('yyyy-MM-dd');
      let end = new Date(this.filter.end).format('yyyy-MM-dd');
      this.spin.show = true;
      let args = {
        start: start,
        end: end,
        ids: this.stationIds
      }
      let urlType = 'station/alarm/AnalyseByLevel';
      this.$axios.post(`//${this.domains.trans}/${urlType}`, args).then(res => {
        this.spin.show = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'tabData', res.data);
          let x = [], y10 = [], y20 = [], y30 = [];
          for(let d of res.data){
            let date = new Date(d.date).format('yyyy-MM-dd');
            if(x.indexOf(date) == -1){
              x.push(date);
            }
          }
          for(let d of x){
            // console.log('chk alarm', d)
            let l10s = res.data.filter(p => new Date(p.date).format('yyyy-MM-dd') == d && p.level == 10);
            y10.push(l10s.length == 0 ? 0 : l10s[0].count);
            let l20s = res.data.filter(p => new Date(p.date).format('yyyy-MM-dd') == d && p.level == 20);
            y20.push(l20s.length == 0 ? 0 : l20s[0].count);
            let l30s = res.data.filter(p => new Date(p.date).format('yyyy-MM-dd') == d && p.level == 30);
            y30.push(l30s.length == 0 ? 0 : l30s[0].count);
          }
          this.$set(this.chartsLineData, 'x', x);
          this.$set(this.chartsLineData.series[0], 'data', y10);
          this.$set(this.chartsLineData.series[1], 'data', y20);
          this.$set(this.chartsLineData.series[2], 'data', y30);
          this.timer++;
        }
      });
    },
  }
}
</script>
<style scoped>
.organ-container{
  /* border: solid 1px rgb(180, 109, 3); */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.organ-header{
  border: solid 1px rgba(4, 4, 202, 0.26);
  height: 40px;
  margin: 3px;
  flex: none;
}
.chart-area{
  height: 300px;
  flex: none;
}
.data-area{
  height: 500px;
  flex: auto;
}
.organ-content{
  /* border: solid 1px red; */
  margin: 3px;
  height: 500px;
  flex: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
.header-tab-item{
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 40px;
  /* justify-content: flex-end; */
  /* padding: 0 20px; */
}
</style>
