<template>
  <div class="policeType-control">
    <div class="policeType-title">报警类系统计</div>
    <div class="policeType-con">
      <div class="policeType-chart">
        <chartsPie :option="chartsPieData" id="chartPie" :key="timer"></chartsPie>
      </div>
      <div class="policeType-tips">
        <div class="policeType-tips-box">
          <i class="policeType-tips-color1"></i>
          黑名单
        </div>
        <div class="policeType-tips-box">
          <i class="policeType-tips-color2"></i>
          区域限行
        </div>
        <div class="policeType-tips-box">
          <i class="policeType-tips-color3"></i>
          单双号限行
        </div>
        <div class="policeType-tips-box">
          <i class="policeType-tips-color4"></i>
          车辆限行
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  computed: {

  },
  data() {
    return {
      timer: 20,
      chartsPieData: {
        id: "chartPie",
        color: ["#C69AFF", "#51CC8B", "#92C2FF", "#F58CA7"],
        series: [
          { value: 50, name: "黑名单" },
          { value: 10, name: "区域限行" },
          { value: 20, name: "单双号限行" },
          { value: 30, name: "车辆限行" },
        ],
        content: {},
        setSeries: {
          roseType: "area", //南丁格尔玫瑰图 radius
          radius: ['40%', '90%'],//[内半径，外半径] 饼图的半径
          label: {
            show: false,
          },
          labelLine: {
            show: false
          },
        }
      }
    }
  },
  watch: {

  },
  mounted: function () {

  },
  methods: {

  }
}
</script>
<style scoped>
.policeType-control {
  height: calc(100%);
  padding: 20px 30px;
}
.policeType-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  margin-bottom: 15px;
  font-weight: bold;
}
.policeType-con {
  height: calc(100% - 18px);
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
}
.policeType-chart {
  width: 70%;
  height: calc(100% - 18px);
}
.policeType-tips {
  width: 30%;
  height: calc(100% - 18px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.policeType-tips-box i {
  display: inline-block;
  width: 10px;
  height: 8px;
  vertical-align: middle;
}
.policeType-tips-box {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
}
/*   color: ["#C69AFF", "#51CC8B", "#92C2FF", "#F58CA7"], */
.policeType-tips-color1 {
  background: #c69aff;
}
.policeType-tips-color2 {
  background: #51cc8b;
}
.policeType-tips-color3 {
  background: #92c2ff;
}
.policeType-tips-color4 {
  background: #f58ca7;
}
</style>
