<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        {{showLang('com.date.start')}}：
        <DatePicker v-model="form.start" type="date"  style="width: 150px; margin-right: 10px; flex: none;"></DatePicker>
        {{showLang('com.date.end')}}：
        <DatePicker v-model="form.end" type="date"  style="width: 150px; margin-right: 10px; flex: none;"></DatePicker>
        <Button type="info" :loading="searchLoading" @click="initEnergys" style="margin-right: 10px">{{showLang('com.op.query')}}</Button>
        <Button type="success" :loading="exportLoading" @click="exportData">{{showLang('com.op.export')}}</Button>
        <span style="margin-left: 20px">{{showLang('energy.tisp.total.sites',energys.length)}}, {{showLang('com.stat.q')}} {{totalEnergy}} KWh, {{showLang('energy.total.yuan',totalMoney)}}</span>
      </div>
    </div>
    <div class="search-data-content" ref="table1">
      <vxe-table  :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source ref="chkTable" :data="energys" :height="tabHeight" :row-config="{isHover: true}" :export-config="{}" show-footer :footer-method="footerMethod" footer-cell-class-name="footer-cell-class">
        <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-column field="seq" type="seq" width="90"></vxe-column>
        <vxe-column field="paramName" :title="showLang('energy.name')" header-align="center">
          <template #default="{ row }">
            {{row.paramName}}
          </template>
        </vxe-column>
        <vxe-column field="stationName" :title="showLang('station.belong')" header-align="center">
          <template #default="{ row }">
            {{row.stationName}}
          </template>
        </vxe-column>
        <vxe-column field="startDate" :title="showLang('energy.tab.reading.start.time')"  width="150" header-align="center">
          <template #default="{ row }">
            {{new Date(row.startDate).format('yyyy-MM-dd HH:mm') }}
          </template>
        </vxe-column>
        <vxe-column field="startVal" :title="showLang('energy.tab.reading.start.num')"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.startVal}} KWh
          </template>
        </vxe-column>
        <vxe-column field="endDate" :title="showLang('energy.tab.reading.end.time')"  width="150" header-align="center">
          <template #default="{ row }">
            {{new Date(row.endDate).format('yyyy-MM-dd HH:mm')}}
          </template>
        </vxe-column>
        <vxe-column field="endVal" :title="showLang('energy.tab.reading.end.num')"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.endVal}} KWh
          </template>
        </vxe-column>
        <vxe-column field="usage" :title="showLang('energy.usage1')"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.usage}} KWh
          </template>
        </vxe-column>
        <vxe-column field="money" :title="showLang('energy.money1')"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.money}} {{showLang('com.unit.cash')}}
          </template>
        </vxe-column>
        <vxe-column :title="showLang('com.right.nav.operate')" width="120">
          <template #default="{ row }">
            <Button type="primary" style="margin-right: 5px" @click="handleEdit(row)">{{showLang('energy.View.Details')}}</Button>
            <!-- <Button type="primary" style="margin-right: 5px" @click="handleLog(row)">查看记录</Button> -->
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <ModalEnergyRecord v-model="showEditModal" :item="editItem" />
    <!-- <ModalEnergyLog v-model="showLogModal" :item="editItem" /> -->
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import ModalEnergyRecord from './ModalEnergyRecord'
// import ModalEnergyLog from './ModalEnergyLog'
export default {
  name: 'SearchPowerboxIndex',
  components: {
    ModalEnergyRecord,
    // ModalEnergyLog,
  },
  props: {
  },
  data() {
    return {
      searchLoading: false,
      exportLoading: false,
      tabHeight: 1,
      form: {
        stationId: 0,
        start: "",
        end: "",
      },
      energys: [],
      editItem: {},
      showEditModal: false,
      showLogModal: false,
      footerData: []
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    totalEnergy: function(){
      let sum = 0;
      for(let en of this.energys){
        sum += en.usage;
      }
      return this.img.val(sum, 1);
    },
    totalMoney: function(){
      let sum = 0;
      for(let en of this.energys){
        sum += en.money;
      }
      return this.img.val(sum, 1);
    },
  },
  watch: {
    selectedNode() {
      this.initEnergys();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd');
    now.setMonth(now.getMonth() - 1);
    this.form.start = now.format('yyyy-MM-dd');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.initEnergys();
  },
  destroyed: function () {
  },
  methods: {
    footerMethod: function(){
      return this.footerData;
    },
    handleEdit: function(params){
      let start = new Date(this.form.start).format('yyyy-MM-dd');
      let end = new Date(this.form.end).format('yyyy-MM-dd');
      this.editItem = {id: params.paramId, paramName: params.paramName, stationName: params.stationName, start, end};
      this.showEditModal = true;
    },
    handleLog: function(params){
      let start = new Date(this.form.start).format('yyyy-MM-dd');
      let end = new Date(this.form.end).format('yyyy-MM-dd');
      this.editItem = {id: params.paramId, paramName: params.paramName, stationName: params.stationName, start, end};
      this.showLogModal = true;
    },
    exportData: function(){
      this.$refs.chkTable.exportData({
        filename: this.showLang('energy.site.record')+'.xlsx',
        columnFilterMethod ({ column }) {
          return ['seq', 'paramName', 'stationName', 'startDate', 'startVal', 'endDate', 'endVal', 'usage', 'money'].includes(column.property)
        },
        data: this.$refs.chkTable.getCheckboxRecords(),
        footerFilterMethod: function(params){
          //这里可以通过以下方式修改footer的导出内容，比如重新计算汇总值和记录数等
          params.items[1] = params.items[1]+'1';
          // console.log('show footer params', params)
          return false;
        }
      })
      // let groupId = 0, stationId = 0;
      // if(this.selectedNode.type == 'root' || this.selectedNode.type == 'group'){
      //   groupId = this.selectedNode.id;
      // }else{
      //   stationId = this.selectedNode.id;
      // }
      // let start = new Date(this.form.start).format('yyyy-MM-dd');
      // let end = new Date(this.form.end).format('yyyy-MM-dd');
      // this.exportLoading = true;
      // this.$axios.request({
      //   url: `//${this.domains.trans}/station/analyse/ExportStationEnergyList`,
      //   method: 'post',
      //   data: { groupId, stationId, start, end},
      //   responseType: 'blob'
      // }).then(res => {
      //   this.exportLoading = false;
      //   let url = window.URL.createObjectURL(res);
      //   var a = document.createElement('a')
      //   document.body.appendChild(a)
      //   a.href = url
      //   a.download = `站点抄表数据.xlsx`
      //   a.click();
      //   document.body.removeChild(a);
      //   window.URL.revokeObjectURL(url)
      // })
    },
    initEnergys: function () {
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == 'root' || this.selectedNode.type == 'group'){
        groupId = this.selectedNode.id;
      }else{
        stationId = this.selectedNode.id;
      }
      let start = new Date(this.form.start).format('yyyy-MM-dd');
      let end = new Date(this.form.end).format('yyyy-MM-dd');
      this.searchLoading = true;
      this.$axios.post(`//${this.domains.trans}/station/analyse/QueryStationEnergyList`, { groupId, stationId, start, end}).then(res => {
        this.searchLoading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'energys', res.data);
          let usage = 0, money = 0;
          res.data.map(p => {
            usage += p.usage;
            money += p.money;
          });
          this.$set(this, "footerData", [
            [this.showLang('com.export.footer.name'), res.data.length+this.showLang('energy.record'), '', '', '', '', '', '', this.img.val(usage, 2)+'KWh', this.img.val(money, 2)+this.showLang('com.unit.cash')],
            // [2, 3, 4, 5]
          ]);
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 5;
    },
  }
}
</script>
<style scoped>
>>> .footer-cell-class {
  /* color: red; */
  font-weight: bold;
}
.switchType {
  height: 30px;
  display: flex;
  flex-direction: initial;
  margin: 10px 0 0 10px;
}
.switchType-tab {
  width: 68px;
  height: 30px;
  background: #eef4f9;
  color: #324252;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.fillet-left {
  border-top-left-radius: 6px;
}
.fillet-right {
  border-top-right-radius: 6px;
}
.switchType-tab.tab-active {
  background: #3880fc;
  color: #ffffff;
}
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  margin-top: 10px;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: none;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
.search-data-chart {
  width: calc(100% - 20px);
  margin: 0 auto;
  height: 500px;
  position: relative;
}
.colorDeputy {
  position: absolute;
  right: 40px;
  display: flex;
}
.deputy-list {
  margin-right: 10px;
}
.deputy-list i {
  display: inline-block;
  width: 5px;
  height: 5px;
}
</style>