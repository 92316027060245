<template>
  <div ref="table1" class="power-box">
    <vxe-table :data="lightPowerError" style="width: 100%;" align="center" border stripe :height="tabHeight" default-expand-all :empty-text="showLang('com.data.no')">
      <vxe-column type="seq" width="55" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
      <vxe-column field="stationName" title="站点" align="left">
        <template #default="{ row }">
          <span class="stationName" @click="jumpDetailsDve(row)"> {{row.stationName}}</span>
        </template>
      </vxe-column>
      <vxe-column field="createTime" title="分析时间"></vxe-column>
      <vxe-column field="lightCount" title="单灯总数" sortable></vxe-column>
      <vxe-column field="offlineError" title="离线异常数" sortable>
        <template #default="{ row }">
          <span :class="[row.offlineError>0?'red':'','stationName']" @click="showDetails(0,row,row.offlineError)"> {{row.offlineError}}</span>
        </template>
      </vxe-column>
      <vxe-column field="poweroffError" title="灭灯异常数" sortable>
        <template #default="{ row }">
          <span :class="[row.poweroffError>0?'red':'','stationName']" @click="showDetails(1,row,row.poweroffError)"> {{row.poweroffError}}</span>
        </template>
      </vxe-column>
    </vxe-table>
    <ModelLightPowerError v-model="isOfflineErrorLights" :item='item' :type='showType' />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import ModelLightPowerError from './ModelLightPowerError'
export default {
  name: 'DeviceOffline',
  components: {
    ModelLightPowerError
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    showModal: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isOfflineErrorLights: false,
      tabHeight: 500,
      timeout: null,
      showType: 0,
      item: {},
      timer: null
    }
  },
  watch: {
   
  },
  destroyed: function () {
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('group', ['selectedNode', 'groupLoaded', 'selectedDevices', 'stationAlarmChanged','lightPowerError']),
    ...mapState('common', ['alarmTypes', 'productCodes']),
  },
  mounted: function () {
  },
  methods: {
    showDetails(index, row, num) {
      if (num == 0) return false;
      this.item = row;
      this.showType = index
      this.isOfflineErrorLights = true;
    },
    jumpDetailsDve(params) {
      this.$store.commit('group/selectNodeById', { nodeId: params.stationId, type: this.productCodes.station });
      this.$store.commit('auth/switchStationTabName', 'power');
    },
  },
  beforeDestroy() {
  }
}
</script>
<style scoped>
.power-box {
  width: 100%;
  height: calc(100%);
}
.stationName {
  display: inline-block;
  cursor: pointer;
}
</style>