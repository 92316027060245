var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"snap-control"},[_c('div',{staticClass:"snap-title"},[_vm._v("通过车辆抓拍")]),_c('div',{staticClass:"snap-con"},[_c('div',{staticClass:"snap-con-box"},[_c('img',{attrs:{"src":_vm.testImg}}),_vm._m(0),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"snap-con-box"},[_c('img',{attrs:{"src":_vm.testImg}}),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"snap-con-box"},[_c('img',{attrs:{"src":_vm.testImg}}),_vm._m(6),_vm._m(7),_vm._m(8)]),_c('div',{staticClass:"snap-con-box"},[_c('img',{attrs:{"src":_vm.testImg}}),_vm._m(9),_vm._m(10),_vm._m(11)]),_c('div',{staticClass:"snap-con-box"},[_c('img',{attrs:{"src":_vm.testImg}}),_vm._m(12),_vm._m(13),_vm._m(14)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("粤B S4065")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("南灯杆")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("09:41:45")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("粤B S4065")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("南灯杆")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("09:41:45")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("粤B S4065")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("南灯杆")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("09:41:45")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("粤B S4065")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("南灯杆")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("09:41:45")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("粤B S4065")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("南灯杆")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i'),_vm._v("09:41:45")])
}]

export { render, staticRenderFns }