<template>
<div class="device-item-content" :key="lightRealDataRefresh">
  <div class="device-item-header">
    <img class="device-item-header-icon" :src="img.pole.power.icon" title="集中电源管理" />
    <span class="device-item-header-title">{{item.name}}</span>
    <img class="device-item-header-status-icon" :src="online ? img.pole.online : img.pole.offline" />
    <span class="device-item-header-status">{{online ? '在线' : '离线'}}</span>
  </div>
  <img class="wifi-flag" :src="img.pole.power.flag" />
  <table border="0">
    <tr>
      <td class="table-con" colspan="2">
        <div class="con-content">
          <img :src="img.pole.power.state" />
          <div>
            <div>开关量：<span>{{item.s1 == 0 ? '断开' : '闭合'}}</span></div>
            <div>控制输出：<span>{{item.k1 == 0 ? '断开' : '闭合'}}</span></div>
          </div>
        </div>
      </td>
      <td class="table-con" colspan="2">
        <div class="con-content">
          <img :src="img.pole.power.state" />
          <div>
            <div>POE电压：<span>{{item.pv}} V</span></div>
            <div>状态：<span>{{item.poe == 0 ? '未启用' : '已启用'}}</span></div>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td class="table-title">电压</td>
      <td class="table-value">{{img.val(item.acv, 1)}} V</td>
      <td class="table-title">电流</td>
      <td class="table-value">{{img.val(item.aci, 1)}} A</td>
    </tr>
    <tr>
      <td class="table-title">工作温度</td>
      <td class="table-value">{{img.val(item.temp, 1)}} ℃</td>
      <td class="table-title">功率</td>
      <td class="table-value">{{img.val(item.acp, 1)}} W</td>
    </tr>
  </table>
  <Button type="success" style="position: absolute;top: 80px;right: 15px;width:40px;height:40px;border:solid 0 red;" @click="doReadData">召</Button>
  <Spin fix v-if="spin.show">{{spin.content}}</Spin>
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'PowerStationViewer',
  components:{
  },
  props: {
    item: {
      type: Object,
      default(){return {}},
    },
  },
  data () {
    return {
      spin: {
        show: false,
        content: '加载中...',
      },
      move: false,
      offset: 0,
      bottom: 0,
      start: 0,
      lightRealDataRefresh: 0,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
    ...mapState('group', ['selectedDevices', 'selectedNode', 'groups']),
    online: function(){
      if(!this.item)return false;
      return this.item.online;
    },
  },
  watch: {
    cmdResultRefresh(){
      let cmds = this.commands.filter(p => p.id == this.item.id);
      if(cmds.length == 0)return;
      let cmd = cmds[0];
      switch(cmd.status){
        case 0:
          this.showSpin(`${cmd.result}`);
          break;
        case 1:
          this.showSpin('等待回复');
          break;
        case 2:
          this.showSpin('指令超时');
          break;
        case 3:
          this.showSpin(`执行失败：${cmd.result}`);
          setTimeout(this.hideSpin, 2000);
          break;
        case 9: 
          // if(cmd.code == 'forceRead'){
          //   this.item.fireTime = new Date().getTime();
          //   this.item.lastData.s1 = cmd.content.s1;
          //   this.item.lastData.k1 = cmd.content.k1;
          //   this.item.lastData.pv = cmd.content.poeV;
          //   this.item.lastData.poe = cmd.content.hasPoe;
          //   this.item.lastData.acv = cmd.content.acV;
          //   this.item.lastData.aci = cmd.content.acI;
          //   this.item.lastData.temp = cmd.content.temp;
          //   this.item.lastData.acp = cmd.content.acP;
          // }
            // console.log('cmd result', this.item, cmd)
          this.lightRealDataRefresh++;
          this.showSpin(`执行成功`);
          setTimeout(this.hideSpin, 1000);
          break;
      }
    }
  },
  mounted: function(){
  },
  methods: {
    doReadData: function(){
      // console.log('force read data', this.item)
      let devs = [];
      devs.push({
        id: this.item.id,
        deviceId: this.item.deviceId,
        code: 'forceRead',
        name: '召测',
        args: {}
      });
      this.showSpin('等待指令回复');
      this.$store.dispatch('cmd/sendCommands', devs);
    },
    showSpin: function(msg){
      this.spin.content = msg;
      this.spin.show = true;
    },
    hideSpin: function(){
      this.spin.show = false;
    },
  }
}
</script>
<style scoped>
.con-content{
  /* border:solid 1px red; */
  display: flex;
  align-items: center;
}
.con-content img{
  /* border:solid 1px red; */
  width: 18px;
  height: 18px;
  display: flex;
  margin: 0 10px;
}
.table-con{
  /* border:solid 1px red; */
  height: 70px;
  /* display: flex; */
}
.wifi-flag{
  width: 106px;
  height: 108px;
  position: absolute;
  top: 69px;
  left: 144px;
}
table{
  /* border: solid 1px red; */
  margin: auto;
  /* width: 100%; */
  position: absolute;
  /* top: 180px; */
  left: 20px;
  right: 20px;
  bottom: 20px;
}
.table-title{
  background: transparent;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
  width: 70px;
  height: 32px;
  padding: 0 5px;
  text-align-last: justify;
  white-space: nowrap;
}
.table-value{
  background: #EEF3F8;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
  width: 115px;
  height: 32px;
  padding: 0 10px;
}
.power-data-item-data{
  width: 10px;
  flex: auto;
  background: #EEF3F8;
  display: flex;
  align-items: center;
  height: 32px;
  display: flex;
}
.power-data-item-data img{
  margin: 0 5px;
  flex: none;
}
.power-data-item-data div{
  flex: auto;
  text-align: right;
  padding-right: 5px;
}
.power-data-item{
  display: flex;
  align-items: center;
}
.power-data-item span{
  width: 40px;
  flex: none;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
}
.power-state-item{
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
  line-height: 22px;
}
.power-state-item img{
  margin: 0 10px;
}
.device-item-content{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.device-item-header{
  height: 48px;
  /* background: #E8F1FA; */
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
}
.device-item-header-icon{
  width: 20px;
  height: 20px;
  margin-left: 25px;
  margin-right: 10px;
  flex: none;
}
.device-item-header-title{
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  width: 10px;
  flex: auto;
}
.device-item-header-status-icon{
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
.device-item-header-status{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  flex: none;
  margin-right: 25px;
}
</style>
