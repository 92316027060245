<template>
  <div ref="table1" class="power-box">
    <vxe-table :data="alarmsData" style="width: 100%;" align="center" border stripe :height="tabHeight" default-expand-all :empty-text="showLang('com.data.no')">
      <vxe-column type="seq" width="55" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
      <vxe-column field="stationName" title="站点"></vxe-column>
      <vxe-column field="createTime" title="分析时间"></vxe-column>
      <vxe-column field="lightCount" title="单灯总数" sortable></vxe-column>
      <vxe-column field="offlineCount" title="离线数" sortable>
        <template #default="{ row }">
          <span :class="[row.offlineCount>0?'red':'']" @click="showDetails(2,row,row.offlineCount)"> {{row.offlineCount}}</span>
        </template>
      </vxe-column>
      <vxe-column field="offlineError" title="离线异常数" sortable>
        <template #default="{ row }">
          <span :class="[row.offlineError>0?'red':'']" @click="showDetails(0,row,row.offlineError)"> {{row.offlineError}}</span>
        </template>
      </vxe-column>
      <vxe-column field="powerOffCount" title="灭灯数" sortable>
        <template #default="{ row }">
          <span :class="[row.powerOffCount>0?'red':'']" @click="showDetails(3,row,row.powerOffCount)"> {{row.powerOffCount}}</span>
        </template>
      </vxe-column>
      <vxe-column field="poweroffError" title="灭灯异常数" sortable>
        <template #default="{ row }">
          <span :class="[row.poweroffError>0?'red':'']" @click="showDetails(1,row,row.poweroffError)"> {{row.poweroffError}}</span>
        </template>
      </vxe-column>
      <vxe-column field="voltageUpperCount" title="过压数" sortable>
        <template #default="{ row }">
          <span :class="[row.voltageUpperCount>0?'red':'']" @click="showDetails(4,row,row.voltageUpperCount)"> {{row.voltageUpperCount}}</span>
        </template>
      </vxe-column>
      <vxe-column field="voltageLowerCount" title="欠压数" sortable>
        <template #default="{ row }">
          <span :class="[row.voltageLowerCount>0?'red':'']" @click="showDetails(5,row,row.voltageLowerCount)"> {{row.voltageLowerCount}}</span>
        </template>
      </vxe-column>
    </vxe-table>
    <ModelPowerLineError v-model="isPowerLineError" :item='item' :type='showTypeUrl' />
    <ModelLightPowerError v-model="isLightPowerError" :item='item' :type='showType' />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModelPowerLineError from './ModelPowerLineError'
import ModelLightPowerError from '@/views/light/road/monitor/alarm/ModelLightPowerError'
export default {
  name: 'powerLineError',
  components: {
    ModelPowerLineError,
    ModelLightPowerError,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showType: 0,
      showTypeUrl: 'QueryOfflineCountLight',
      item: {},
      isLightPowerError: false,
      isPowerLineError: false,
      tabHeight: 500,
      alarmsData: [],
    }
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.clockAjax();
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  destroyed: function () {
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('group', ['selectedNode', 'groupLoaded', 'selectedDevices', 'stationAlarmChanged']),
    ...mapState('common', ['alarmTypes', 'productCodes']),
  },
  mounted: function () {
    this.clockAjax();
    setTimeout(this.setTableHeight, 100);
  },
  methods: {
    showDetails(index, row,num) {
      if(num==0) return false;
      this.item = row;
      if (index < 2) {
        this.isLightPowerError = true;
        this.showType = index;
      } else {
        this.isPowerLineError = true;
        switch (index) {
          case 2:
            this.showTypeUrl = 'QueryOfflineCountLight';
            break;
          case 3:
            this.showTypeUrl = 'QueryPoweroffCountLight';
            break;
          case 4:
            this.showTypeUrl = 'QueryVoltageUpperCountLight';
            break;
          case 5:
            this.showTypeUrl = 'QueryVoltageLowerCountLight';
            break;
        }
      }
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight
    },
    clockAjax() {
      this.$axios.post(`//${this.domains.trans}/device/light/QueryPowerLineError`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'alarmsData', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.power-box {
  width: 100%;
  height: calc(100% - 5px);
}
span {
  cursor: pointer;
}
</style>