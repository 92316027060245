<template>
  <Modal v-model="showModal" draggable :mask-closable="false" width="1300px">
    <div slot="header">{{item.stationName}} {{item.day}} {{showLang('export.copy.data.record')}}</div>
    <div class="branch-table">
      <vxe-table :loading="searchLoading" :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source ref="chkTable" :data="energys" :height="tabHeight" :row-config="{isHover: true}" :export-config="{}" show-footer :footer-method="footerMethod" footer-cell-class-name="footer-cell-class">
        <!-- <vxe-column type="checkbox" width="60"></vxe-column> -->
        <vxe-column field="seq" type="seq" width="60"></vxe-column>
        <vxe-column field="paramName" :title="showLang('com.tab.title')" header-align="center">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column field="startDate" :title="showLang('energy.tab.reading.start.time')"  width="150" header-align="center">
          <template #default="{ row }">
            {{new Date(row.startTime).format('yyyy-MM-dd HH:mm:ss') }}
          </template>
        </vxe-column>
        <vxe-column field="startVal" :title="showLang('energy.tab.reading.start.num')"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.startValue}} KWh
          </template>
        </vxe-column>
        <vxe-column field="endDate" :title="showLang('energy.tab.reading.end.time')"  width="150" header-align="center">
          <template #default="{ row }">
            {{new Date(row.endTime).format('yyyy-MM-dd HH:mm:ss')}}
          </template>
        </vxe-column>
        <vxe-column field="endVal" :title="showLang('energy.tab.reading.end.num')"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.endValue}} KWh
          </template>
        </vxe-column>
        <vxe-column field="usage" :title="showLang('energy.usage1')"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.usage}} KWh
          </template>
        </vxe-column>
        <!-- <vxe-column field="money" :title="showLang('energy.money1')"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.money}} {{showLang('com.unit.cash')}}
          </template>
        </vxe-column> -->
        <!-- <vxe-column :title="showLang('com.right.nav.operate')" width="120">
          <template #default="{ row }">
            <Button type="primary" style="margin-right: 5px" @click="handleEdit(row)">{{showLang('energy.View.Details')}}</Button>
          </template>
        </vxe-column> -->
      </vxe-table>
      <!-- <ModalLightEnergyRecordDetail v-model="showEditModal" :item="editItem" /> -->
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      <!-- <Button type="primary" :loading="loading" @click="doCommand">发送</Button> -->
    </div>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex'
// import ModalLightEnergyRecordDetail from './ModalLightEnergyRecordDetail'
export default {
  name: 'ModalLightEnergyRecord',
  components: {
    // ModalLightEnergyRecordDetail,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      form: {
        type: 'day',
        paramId: 0,
        start: '',
        end: '',
      },
      energys: [],
      tabHeight: 600,
      showFormat: '',
      searchLoading: false,
      exportLoading: false,
      showEditModal: false,
      footerData: [],
      editItem: {},
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
      // console.log('handle edit', this.item);
        // this.form.type = 'day';
        // this.form.paramId = this.item.id;
        // this.form.start = this.item.start;
        // this.form.end = this.item.end;
        this.initEnergys();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
  },
  methods: {
    handleEdit: function(params){
      let start = new Date(this.item.start).format('yyyy-MM-dd');
      let end = new Date(this.item.end).format('yyyy-MM-dd');
      this.editItem = {paramId: params.id, paramName: params.name, start, end};
      this.showEditModal = true;
    },
    initEnergys: function () {
      let stationId = this.item.stationId;
      let day = this.item.day;
      this.searchLoading = true;
      this.$axios.post(`//${this.domains.trans}/station/analyse/QueryStationLightMeterEnergy`, { stationId, day}).then(res => {
        this.searchLoading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'energys', res.data);
          let usage = 0;
          res.data.map(p => {
            usage += p.usage;
            // total += p.total;
          });
          this.$set(this, "footerData", [
            [this.showLang('com.export.footer.name'), res.data.length+this.showLang('energy.record'), '', '', '', '', this.img.val(usage, 2)],
            // [2, 3, 4, 5]
          ]);
        }
      });
    },
    footerMethod: function(){
      return this.footerData;
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
>>> .footer-cell-class {
  /* color: red; */
  font-weight: bold;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
.branch-table {
  height: 600px;
  overflow: auto;
}
.table {
  border-collapse: collapse;
  width: 100%;
}
.table-header {
  /* width: 1064px; */
  height: 47px;
  text-align: center;
  background: rgba(10, 26, 68, 0.8);
  box-shadow: inset 0px 0px 24px 0px #04559d;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #52fbfe;
  border: solid 1px black;
}
.table-header img {
  text-align: center;
  vertical-align: middle;
  margin-right: 3px;
}
.column-header {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  border: solid 1px black;
  text-align: center;
  height: 30px;
  /* width: 130px; */
  /* border-collapse: collapse; */
  background: #183992;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.column-header img {
  vertical-align: middle;
  margin-right: 10px;
}
.column-content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #050505;
  background: #2c9ef55e;
  border: solid 1px black;
  text-align: center;
  height: 35px;
  /* width: 130px; */
}
</style>