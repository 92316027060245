<template>
  <div class="peopleLine-control">
    <div class="peopleLine-title">人流量趋势</div>
    <div class="peopleLine-con">
      <chartsLine :option="chartsLineData" id="peopleLineData" :key="timer"></chartsLine>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  computed: {

  },
  data() {
    return {
      timer: 2,
      chartsLineData: {
        id: "peopleLineData",
        color: ["#4FA2FF", "#6A7BFA"],
        x: ["1", "3", "5", "7", "9", "11"],
        unit: '个',
        series: [
          {
            name: '离开人数',
            data: [60, 35, 80, 20, 65, 84, 65],
          },
          {
            name: '进入人数',
            data: [22, 65, 23, 74, 62, 41, 72],
          },
        ],
        cover: {
          legend: {
            left: 'right',
            data: ['离开人数', '进入人数']
          },
        },
        setSeries: {
          smooth: true, //平滑折线图

        }
      }
    }
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
  }
}
</script>
<style scoped>
.peopleLine-control {
  height: calc(100%);
  padding: 20px 30px;
}
.peopleLine-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  margin-bottom: 15px;
  font-weight: bold;
}
.peopleLine-con {
  width: 100%;
  height: calc(100% - 25px);
}
</style>
