<template>
<div class="device-item-content">
  <div class="device-item-header">
    <img class="device-item-header-icon" :src="img.pole.alarm.icon" title="智能井盖" />
    <span class="device-item-header-title">{{item.name}}</span>
    <img class="device-item-header-status-icon" :src="getImage()" />
    <span class="device-item-header-status">{{getStatus()}}</span>
  </div>
  <img class="wifi-flag" :src="img.pole.manhole.flag" />
  <table border="0">
    <tr>
      <td class="table-title">位置状态</td>
      <td class="table-value">{{item.lastData.cp == 0 ? '正常' : '移动'}}</td>
      <td class="table-title">电池电压</td>
      <td class="table-value">{{img.val(item.lastData.u, 1)}} V</td>
    </tr>
    <tr>
      <td class="table-title">气体类型</td>
      <td class="table-value">{{getGasType(item.lastData.gt)}}</td>
      <td class="table-title">气体浓度</td>
      <td class="table-value">{{img.val(item.lastData.gc, 1)}} %</td>
    </tr>
    <tr>
      <td class="table-title">倾斜角度</td>
      <td class="table-value">{{img.val(item.lastData.l, 1)}}</td>
      <td class="table-title">温度</td>
      <td class="table-value">{{img.val(item.lastData.t, 1)}} ℃</td>
    </tr>
    <tr>
      <td class="table-title">震动频次</td>
      <td class="table-value">{{img.val(item.lastData.v, 0)}}</td>
      <td class="table-title">水位状态</td>
      <td class="table-value">{{img.val(item.lastData.w, 0) == 0 ? '正常' : '满溢'}}</td>
    </tr>
  </table>
</div>
</template>
<script>
export default {
  name: 'ManholeStationViewer',
  components:{
  },
  props: {
    item: {
      type: Object,
      default(){return {}},
    },
  },
  data () {
    return {
      info: {},
      count: 0,
      money: 0,
      energy: 0,
      alarms: 0,
    }
  },
  computed: {},
  watch: {},
  mounted: function(){
  },
  methods: {
    getGasType: function(type){
      if(type == 1)return '甲烷';
      else if(type == 2)return '硫化氢';
      else return '不支持';
    },
    getStatus: function(){
      if(!this.info.status)return '离线';
      if(this.info.offline == 1) return '停止服务';
      if(this.info.status == 1) return '在线';
      if(this.info.status == 2) return '充电中';
      return '离线';
    },
    getImage: function(){
      if(!this.info.status)return this.img.pole.offline;
      if(this.info.offline == 1) return this.img.pole.offline;
      if(this.info.status == 1) return this.img.pole.online;
      if(this.info.status == 2) return this.img.pole.online;
      return this.img.pole.offline;
    },
  }
}
</script>
<style scoped>
.wifi-flag{
  width: 106px;
  height: 108px;
  position: absolute;
  top: 69px;
  left: 144px;
}
table{
  /* border: solid 1px red; */
  margin: auto;
  /* width: 100%; */
  position: absolute;
  /* top: 180px; */
  left: 20px;
  right: 20px;
  bottom: 20px;
}
.table-title{
  background: transparent;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
  width: 90px;
  height: 32px;
  padding: 0 5px;
  text-align-last: justify;
  white-space: nowrap;
}
.table-value{
  background: #EEF3F8;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
  width: 165px;
  height: 32px;
  padding: 0 10px;
}
.device-item-content{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.device-item-header{
  height: 48px;
  /* background: #E8F1FA; */
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
}
.device-item-header-icon{
  width: 20px;
  height: 20px;
  margin-left: 25px;
  margin-right: 10px;
  flex: none;
}
.device-item-header-title{
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  width: 10px;
  flex: auto;
}
.device-item-header-status-icon{
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
.device-item-header-status{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  flex: none;
  margin-right: 25px;
}
</style>
