<template>
  <div class="map-container">
    <baidu-map class="map" :ak="ak" :center="center" :map-type="mapType" :double-click-zoom="false" @ready="mapReady" @click="mapClick" :zoom="center.zoom" :scroll-wheel-zoom="true">
      <bm-navigation v-if="map" anchor="BMAP_ANCHOR_TOP_RIGHT" :offset="{ width: 0, height: 80 }"></bm-navigation>
      <ToolStatusTypeSelect v-if="map" v-model="statusSelected" :offset="{x: 20, y: 20}" :counts="statusCount" />
      <ToolDeviceTypeSelect v-if="map && currentMenuName == 'gis'" :offset="{x: 20, y: 20}" @typeChanged="deviceTypeChanged" />
      <ToolMapTypeSelect v-if="map" v-model="mapType" :offset="{x: 20, y: 20}" />
      <ToolChangeDevicePos v-if="map && (currentMenuName == 'gis' || currentMenuName == 'pole')" v-model="enableMoveDevice" :offset="{x: 130, y: 20}" />
      <bm-marker-clusterer v-if="map" :averageCenter="true">
        <bm-marker
          v-if="enableMoveDevice && moveDevicePos.lat != 0 && moveDevicePos.lng != 0"
          :key="0"
          :dragging="false"
          :position="{lng: moveDevicePos.lng, lat: moveDevicePos.lat}">
          <bm-label content="设置目标位置" :offset="{width: -60, height: 35}" :labelStyle="{width: '150px', textAlign:'center', backgroundColor: 'transparent', color: 'red', border: 0, fontSize : '12px'}"/>
        </bm-marker>
        <template v-for="item in devices">
          <PointMaker :key="item.id" :item="item" @click="showMakerInfo" v-if="(typeSelected == -1 || typeSelected == item.type) && (statusSelected == '' || (statusSelected == 'online' && item.online) || (statusSelected == 'offline' && !item.online) || (statusSelected == 'alarm30' && item.alarm) || (statusSelected == 'stop'&& item.repair))"/>
        </template>
      </bm-marker-clusterer>
      <PointInfoWin v-if="map" :item="infoWinItem" @link="infoWinLink" />
    </baidu-map>
    <ModalSetDevicePos v-if="map" v-model="moveDevicePos.show" :pos="moveDevicePos" :productList="productList" @posSaved="posSaved" />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import BmMarkerClusterer from "@/components/vue-baidu-map/components/extra/MarkerClusterer";
import {
  BaiduMap,
  BmLabel,
  // BmControl,
  BmNavigation,
  BmMarker,
} from "@/components/vue-baidu-map/components";
import ToolMapTypeSelect from './ToolMapTypeSelect'
import ToolStatusTypeSelect from './ToolStatusTypeSelect'
import ToolChangeDevicePos from './ToolChangeDevicePos'
import ModalSetDevicePos from '@/components/map/common/ModalSetDevicePos'
import PointMaker from './PointMaker'
import PointInfoWin from './PointInfoWin'
import ToolDeviceTypeSelect from './ToolDeviceTypeSelect'
export default {
  name: "MapDevice",
  components: {
    BaiduMap,
    BmMarkerClusterer,
    BmMarker,
    BmLabel,
    // BmControl,
    BmNavigation,
    ModalSetDevicePos,
    ToolMapTypeSelect,
    ToolStatusTypeSelect,
    ToolChangeDevicePos,
    ToolDeviceTypeSelect,
    PointMaker,
    PointInfoWin,
  },
  props: {},
  data() {
    return {
      statusCount: {
        online: 180,
        offline: 180,
        alarm30: 180,
        stop: 180,
      },
      statusSelected: '',
      typeSelected: -1,
      mapType: "BMAP_NORMAL_MAP",
      center: { lng: 0, lat: 0, zoom: 16 },
      enableMoveDevice: false,
      infoWinItem:{
        show: false,
        data: {},
      },
      moveDevicePos: {
        show: false,
        lat: 0,
        lng: 0,
      },
      search: {
        type: "normal", //status, alarm
        normal: {
          ownerId: 0,
          maintenanceId: 0,
          roadName: "",
          deviceCode: "",
        },
        status: {},
        alarm: {},
      },
      deviceType: "all",
      ak: "MhPU9AQpbKZ0kyQf7bWAv7BvwoKOax88",
      map: null,
      BMap: null,
      mapStyle: {
        styleJson: require("@/assets/custom_map_config.json"),
      },
      makers: [],
      hover: false,
      showInfo: false,
      selected: { id: 0 },
      filter: "",
      currentItem: null,
      showAlarm: false,
      historyType: "",
      showHistoryModal: false,
      showAlarmModal: true,
      showDeviceControlModal: false,
      showTimeTable: false,
      showMutiControlModal: false,
      pos: {
        lng: 0,
        lat: 0,
      },
      ready: false,
      points: [],
      devices: [],
      productList: [],
    };
  },
  computed: {
    ...mapState("auth", ["user", "config", 'currentMenuName']),
    ...mapGetters("group", ["groupPathName"]),
    ...mapState("common", ["productCodes", 'poleTypeNames']),
    ...mapState("group", ["selectedDevices", "poleSelectedNode", "groups"]),
  },
  watch: {
    poleSelectedNode(){
      this.getItems();
      if (this.map) {
        this.getMapCenter();
      }
    },
    currentMenuName(){
      this.getItems();
    }
  },
  mounted: function () {
    this.getItems();
  },
  methods: {
    getItems: function () {
      // console.log('get gis items', this.poleSelectedNode)
      let groupId = '', poleId = '';
      if(this.poleSelectedNode.isLeaf){
        poleId = this.poleSelectedNode.id;
      }else{
        groupId = this.poleSelectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/pole/gis/QueryPoles`, {groupId, poleId, type: this.currentMenuName}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'devices', res.data.list);
        // let list = [];
        // res.data.map(p => {
        //   list.push({
        //     id: p.id,
        //     name: p.name,
        //     paramId: p.id,
        //     stationId: p.sid,
        //     type: p.type,
        //   })
        // })
        // this.$set(this, 'productList', list);
        this.calCount();
      })
    },
    deviceTypeChanged: function(type){
      // console.log('get key', type)
      this.typeSelected = type;
    },
    calCount: function(){
      this.statusCount.online = this.devices.filter(p => p.online).length;
      this.statusCount.offline = this.devices.filter(p => !p.online).length;
      this.statusCount.alarm30 = this.devices.filter(p => p.alarm).length;
      this.statusCount.stop = this.devices.filter(p => p.status).length;
    },
    mapClick: function (e) {
      if (this.enableMoveDevice) {
        this.moveDevicePos.show = true;
        this.moveDevicePos.lat = e.point.lat;
        this.moveDevicePos.lng = e.point.lng;
      }
    },
    posSaved: function(params){
      // console.log('pos saved', params)
      if(params.success){
        let items = this.devices.filter(p => p.id == params.id);
        if(items.length > 0){
          items[0].lat = this.moveDevicePos.lat;
          items[0].lng = this.moveDevicePos.lng;
        }
      }
      this.enableMoveDevice = false;
      this.moveDevicePos.lat = 0;
      this.moveDevicePos.lng = 0;
      this.moveDevicePos.show = false;
    },
    mapReady: function (params) {
      this.map = params.map;
      this.BMap = params.BMap;
      this.map.setMapStyleV2({
        // styleId: '87cbd52ebe13c96dfa17317a35a6ea07',
        styleJson: this.mapStyle.styleJson,
      });
      this.getMapCenter();
    },
    getMapCenter: async function () {
      // let minlat = 90, minlng = 180, maxlat = 0, maxlng = 0;
      this.center.lat = this.config.lat;
      this.center.lng = this.config.lng;
      this.center.zoom = 12;
    },
    getZoom: function (minlat, minlng, maxlat, maxlng) {
      if (!this.BMap) return;
      let zooms = [
        50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 25000, 50000, 100000,
        200000, 500000, 1000000, 2000000,
      ]; //级别18-3对应的比例尺
      let pmin = new this.BMap.Point(minlng, minlat);
      let pmax = new this.BMap.Point(maxlng, maxlat);
      let dis = this.map.getDistance(pmin, pmax);
      for (let i = 0; i < zooms.length; i++) {
        if (zooms[i] > dis) return 18 - i + 4;
      }
      return 16;
    },
    showMakerInfo: function (params) {
      // console.log('show info win', params);
      this.infoWinItem.data = params;
      this.infoWinItem.show = true;
    },
    infoWinLink: function({code, params}){
      this.$store.commit('auth/setPoleMenuName', code);
      if(params.count == 0)return;
      this.center.lat = params.lat;
      this.center.lng = params.lng;
      // this.infoWinItem.data = p;
      // this.infoWinItem.show = true;
    },
  },
};
</script>
<style scoped>
.map-container {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  position: relative;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  /* padding: 10px 0 0 10px; */
}
.map {
  width: 100%;
  height: 100%;
  /* border: solid 1px red; */
}
.item-status-filter {
  /* width: 325px; */
  height: 45px;
  background-color: white;
  display: flex;
}
.filter-item {
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
  cursor: pointer;
  user-select: none;
}
.filter-item img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.filter-active {
  color: #007eff;
}
.menu-btn {
  margin: 5px;
  float: right;
}
</style>