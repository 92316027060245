<template>
<div class="union-container">
  <div class="station-search-area">
    执行设备：
    <Select v-model="filter.paramId" style="width: 200px;flex:none;margin-right:10px;">
      <Option :value="0">全部设备</Option>
      <template v-for="(ss, idx) in currentParams">
        <Option :value="ss.id" :key="idx">[{{ss.typeName}}]:{{ss.name}}</Option>
      </template>
    </Select>
    起始时间：
    <DatePicker v-model="filter.start" type="datetime" placeholder="请选择开始时间" style="width: 175px; margin-right: 10px;"></DatePicker>
    截止时间：
    <DatePicker v-model="filter.end" type="datetime" placeholder="请选择截止时间" style="width: 175px; margin-right: 10px;"></DatePicker>
    <Button type="info" :loading="loading" @click="getList" style="margin-right: 10px;">查询</Button>
  </div>
  <div class="union-content" ref="table">
    <u-table :data="list" use-virtual style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all :page-sizes="[15, 50, 100, 200, 500]" :pagination-show="true" :total="filter.total" :page-size="filter.size" :current-page="filter.index" @handlePageSize="handlePageSize">
      <u-table-column type="index" label="序号" width="50"></u-table-column>
      <u-table-column prop="fireTime" label="发生时间" sortable width="165">
        <template slot-scope="params">
          {{params.row.fireTime}}
        </template>
      </u-table-column>
      <u-table-column prop="name" label="触发规则" width="400">
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <u-table-column prop="stationName" label="所属站点">
        <template slot-scope="params">
          {{params.row.stationName}}
        </template>
      </u-table-column>
      <u-table-column prop="paramName" label="执行设备">
        <template slot-scope="params">
          {{params.row.paramName}}
        </template>
      </u-table-column>
      <u-table-column prop="code" label="执行指令">
        <template slot-scope="params">
          {{getActionName(params)}}
        </template>
      </u-table-column>
      <u-table-column width="75" label="操作">
        <template slot-scope="scope">
          <!-- <AuthButton opCode='se' style="margin-right: 5px" @click="handleEdit(scope)">查看明细</AuthButton> -->
          <AuthButton opCode='sd' type="error" @click="handleDelete(scope)">删除</AuthButton>
        </template>
      </u-table-column>
    </u-table>
  </div>
  <!-- <ModalUnionEdit v-model="showEditModal" :item="editItem" :codes="codes" :actions="actions" @saved="dataSaved" /> -->
</div>
</template>
<script>
import { mapState } from 'vuex'
// import ModalUnionEdit from './ModalUnionEdit'
export default {
  name: 'PoleUnionIndex',
  components: {
    // ModalUnionEdit
  },
  data() {
    return {
      loading: false,
      showEditModal: false,
      tabHeight: 300,
      editItem: {isAdd: true, index: -1, data: {}},
      filter: {
        total: 0,
        index: 1,
        size: 15,
        start: '',
        end: '',
        groupId: 0,
        stationId: 0,
        paramId: 0,
        configId: 0,
      },
      list: [],
      actions: [],
      medias: [],
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('group', ['selectedNode', 'groupLoaded', 'selectedDevices']),
    ...mapState('common', ['alarmTypes', 'productCodes', 'weeks']),
    selectedStationIds: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
    },
    currentParams: function(){
      let ps = [];
      return ps;
    },
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    selectedNode(){
      if(this.selectedNode.type == this.productCodes.station){
        this.filter.groupId = 0;
        this.filter.stationId = this.selectedNode.id;
      }else{
        this.filter.groupId = this.selectedNode.id;
        this.filter.stationId = 0;
      }
      this.filter.index = 1;
      this.filter.paramId = 0;
      this.getList();
    },
  },
  destroyed: function () {
  },
  mounted: function () {
    this.getActions();
    this.getMedias();
    let now = new Date();
    now.setDate(now.getDate() + 1);
    this.filter.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 8);
    this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 200);
    this.getList();
  },
  methods: {
    getActionName: function(params){
      // console.log('get act name', params, this.actions)
      let aa = this.actions.filter(p => p.paramType == params.row.paramType && p.code == params.row.code);
      if(aa.length == 0)return '-';
      let name = aa[0].name;
      if(params.row.paramType == 3){
        if(params.row.code == 'handControl'){
          name += `:${params.row.args.open ? '开灯' : '关灯'}至${params.row.args.expire}`;
        }
      }else if(params.row.paramType == 199){
        if(params.row.code == 'handSingle'){
          name += `: 亮度：${params.row.args.bright}、色温：${params.row.args.color}至${params.row.args.expire}`;
        }else if(params.row.code == 'handGroup'){
          name += `: 分组号：${params.row.args.group}, 亮度：${params.row.args.bright}、色温：${params.row.args.color}至${params.row.args.expire}`;
        }
      }else if(params.row.paramType == 301){
        if(params.row.code == 'goto'){
          name += `: ${params.row.args.point}号`
        }
      }else if(params.row.paramType == 302){
        if(params.row.code == 'bright'){
          name += `: ${params.row.args.bright}%`
        }else if(params.row.code == 'volume'){
          name += `: ${params.row.args.volume}%`
        }else if(params.row.code == 'play'){
          let mm = this.medias.filter(p => p.id == params.row.args.mediaId);
          if(mm.length > 0){
            name += `: ${mm[0].name}`
          }else{
            name += `: [节目已删除]`
          }
        }
      }else if(params.row.paramType == 304){
        if(params.row.code == 'volume'){
          name += `: ${params.row.args.volume}%`
        }else if(params.row.code == 'play'){
          let mm = this.medias.filter(p => p.id == params.row.args.mediaId);
          if(mm.length > 0){
            name += `: ${mm[0].name}`
          }else{
            name += `: [节目已删除]`
          }
        }
      }
      return name;
    },
    getActions: function(){
      this.$axios.get(`//${this.domains.trans}/union/config/action`).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'actions', res.data);
      });
    },
    getMedias: function(){
      this.$axios.post(`//${this.domains.trans}/media/operation/GetAll`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'medias', res.data);
      });
    },
    handlePageSize: function (params) {
      this.filter.pageSize = params.size;
      this.filter.pageIndex = params.page;
      this.getList();
    },
    getDayPlan: function(row){
      return `${new Date(row.config.start1).format('HH:mm')} - ${new Date(row.config.end1).format('HH:mm')}, 
      ${new Date(row.config.start2).format('HH:mm')} - ${new Date(row.config.end2).format('HH:mm')}, 
      ${new Date(row.config.start3).format('HH:mm')} - ${new Date(row.config.end3).format('HH:mm')}, 
      ${new Date(row.config.start4).format('HH:mm')} - ${new Date(row.config.end4).format('HH:mm')}`;
      // console.log('get day plan', new Date(row.config.start1).format('HH:mm'))
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 3;
    },
    getList: function(){
      let start = new Date(this.filter.start).format('yyyy-MM-dd HH:mm:ss');
      let end = new Date(this.filter.end).format('yyyy-MM-dd HH:mm:ss');
      this.filter.start = start;
      this.filter.end = end;
      this.$axios.post(`//${this.domains.trans}/union/log/GetLogs`, this.filter).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.filter.total = res.data.count; 
        this.$set(this, 'list', res.data.list);
      });
    },
    handleAdd: function(){
      let stationId = 0;
      if(this.selectedNode.type == 'station'){
        stationId = this.selectedNode.id;
      }
      this.editItem = {isAdd: true, data: {stationId: stationId}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除该条联动记录吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/union/log/DeleteLogs`, {ids: [params.row.id]}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
              return;
            }
            this.$Message.info('操作成功');
            this.getList();
          });
        }
      });
    },
    handleEnable: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要${params.row.config.enable?'禁用':'启用'}联动配置[${params.row.config.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/union/config/enable`, {id: params.row.config.id, enable: !params.row.config.enable}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
              return;
            }
            this.$Message.info('操作成功');
            this.getList();
          });
        }
      });
    },
    dataSaved: function(){
      this.getList();
    },
  }
}
</script>
<style scoped>
.union-container {
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: stretch;
  /* border: solid 1px red; */
  position: relative;
}
.station-search-area {
  height: 42px;
  flex: none;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.union-header{
  /* border: solid 1px red; */
  height: 42px;
  flex: none;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.union-content{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>