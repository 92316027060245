<template>
  <div class="policeInfo-control">
    <div class="policeInfo-title">报警信息</div>
    <div class="policeInfo-con">
      <div class="policeInfo-con-nav">
        <a href="javascript:void(0);" class="policeInfo-con-but  con-but1 policeInfo-con-active">单双号限行</a>
        <a href="javascript:void(0);" class="policeInfo-con-but con-but2">待处理</a>
      </div>
      <div class="policeInfo-con-info">
        <div class="policeInfo-con-img">
          <img :src="testImg" />
        </div>
        <div class="policeInfo-con-text">
          <div class="policeInfo-con-con">
            <img :src="img.pole.icons.direction.time" alt="">
            <span>2021-02-25 09:41:46</span>
          </div>
          <div class="policeInfo-con-con">
            <img :src="img.pole.icons.direction.automobile" alt="">
            <span>车型-面包车</span>
          </div>
          <div class="policeInfo-con-con">
            <img :src="img.pole.icons.direction.vehicleId" alt="">
            <span>车牌：粤BS4065</span>
          </div>
          <div class="policeInfo-con-con">
            <img :src="img.pole.icons.direction.camera" alt="">
            <span>设备：车牌识别-南灯杆</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  computed: {

  },
  data() {
    return {
      testImg: require("@/assets/video-1.jpeg")
    }
  },
  watch: {

  },
  mounted: function () {

  },
  methods: {

  }
}
</script>
<style scoped>
.policeInfo-control {
  height: calc(100%);
  padding: 20px 30px;
}
.policeInfo-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  margin-bottom: 10px;
  font-weight: bold;
}
.policeInfo-con-nav {
  margin-bottom: 18px;
}
.policeInfo-con-but {
  display: inline-block;
  padding: 5px 10px;
  background: #fce9e9;
  font-size: 14px;
  color: #fd5353;
  text-align: center;
}
.con-but1 {
  border-radius: 4px 0px 0px 4px;
}
.con-but2 {
  border-radius: 0px 4px 4px 0px;
}
.policeInfo-con-active {
  background: #fd5353;
  color: #ffffff;
}
.policeInfo-con-info {
  display: flex;
  flex-direction: initial;
  justify-content: flex-start;
}
.policeInfo-con-text {
  height: 126px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.policeInfo-con-con {
  height: 20px;
  line-height: 20px;
}
.policeInfo-con-con img {
  vertical-align: middle;
}
.policeInfo-con-con span {
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
  margin-left: 3px;
}
.policeInfo-con-img img {
  width: 150px;
  height: 120px;
  border-radius: 6px;
}
</style>
