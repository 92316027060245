<template>
  <div class="power-config-container">
     <div class="power-config-tabs">
      <div v-for="(item, idx) in tabs" :key="idx" :class="['power-config-tab', curTab == item.code ? 'tab-active' : '']" @click="switchTab(item.code)">{{showLang(item.lang)}}</div>
    </div>
     <div class="power-config-tabcontent">
      <template v-for="(item, idx) in tabs">
        <component v-if="item.code == curTab" :key="idx" v-bind:is="item.com" :args="item.args" :stationId="currentStationId" @stationType="obtainStationId"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
// import CableEdit from './CableEdit'
// import MainEdit from './MainEdit'
// import BranchEdit from './BranchEdit'
// import SwitchEdit from './SwitchEdit'
// import OutputEdit from './OutputEdit'
// import ContactEdit from './ContactEdit'
// import DoorEdit from './DoorEdit'
// import LockEdit from './LockEdit'
// import SmokeEdit from './SmokeEdit'
// import WaterEdit from './WaterEdit'
// import LeakageEdit from './LeakageEdit'
// import VoltageEdit from './VoltageEdit'
// import UnionEdit from './UnionEdit'

import MainEdit from '@/components/config/powerbox/main/Index'
import EnergyEdit from '@/components/config/powerbox/energy/Index'
import SwitchEdit from '@/components/config/powerbox/switch/Index'
import BranchEdit from '@/components/config/powerbox/branch/Index'
import OutputEdit from '@/components/config/powerbox/output/Index'
import ContactEdit from '@/components/config/powerbox/contact/Index'
import DoorEdit from '@/components/config/powerbox/door/Index'
import LockEdit from '@/components/config/powerbox/lock/Index'
import SmokeEdit from '@/components/config/powerbox/smoke/Index'
import WaterEdit from '@/components/config/powerbox/water/Index'
import LineEdit from '@/components/config/powerbox/line/Index'
export default {
  name: 'StationIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      curTab: 's1',
      currentStationId:0,
      tabs: [
        { code: 's1', type: 1, name: '总配电', lang:'dev.main',com: MainEdit, args: {} },
        { code: 's2', type: 2, name: '转换开关', lang:'dev.switch',com: SwitchEdit, args: {} },
        { code: 's3', type: 3, name: '控制输出', lang:'dev.output',com: OutputEdit, args: {} },
        { code: 's4', type: 4, name: '接触器', lang:'dev.contact',com: ContactEdit, args: {} },
        { code: 's5', type: 5, name: '支路配电', lang:'com.device.branch',com: BranchEdit, args: {} },
        { code: 's13',type: 16, name: '电表管理', lang:'dev.meter.manage',com: EnergyEdit, args: {} },
        { code: 's6', type: 10, name: '电缆', lang:'com.device.cable',com: LineEdit, args: {} },
        { code: 's7', type: 6, name: '柜门', lang:'dev.door',com: DoorEdit, args: {} },
        { code: 's8', type: 7, name: '门锁', lang:'com.ope.nav.lock',com: LockEdit, args: {} },
        { code: 's9', type: 8, name: '烟雾监测', lang:'dev.smoke.monitoring',com: SmokeEdit, args: {} },
        { code: 's10',type: 9, name: '水浸监测', lang:'com.ins.flood.monitoring',com: WaterEdit, args: {} },
        // { code: 's11',type: 11, name: '漏电监测', com: LeakageEdit, args: {} },
        // { code: 's12',type: 12, name: '电压监测', com: VoltageEdit, args: {} },
        // { code: 's13',type: 13, name: '联动管理', com: UnionEdit, args: {} },
      ],
    }
  },
  computed: {
    ...mapState('group', ['groups', 'selectedNode', 'selectedDevices']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['weeks', 'deviceTypes', 'productCodes', 'powerBoxTypes']),
  },
  watch: {
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    obtainStationId(id){
        this.currentStationId=id;
    },
    switchTab: function (type) {
      this.curTab = type;
    },
  }
}
</script>
<style scoped>
.power-config-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px red; */
  /* padding: 3px 10px; */
}
.power-config-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.power-config-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(59, 170, 127);
  color: white;
}
.power-config-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
  overflow: hidden;
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>