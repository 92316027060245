<template>
<div class="pole-container">
  <!-- <PoleBaseIndex class="content-item" v-if="selectedNode.type == productCodes.station" /> -->
  <PoleGroupIndex :devices="devices" />
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import PoleBaseIndex from './base/Index'
import PoleGroupIndex from './group/Index2'
export default {
  name: 'Home',
  components: {
    // PoleBaseIndex,
    PoleGroupIndex,
  },
  data(){
    return {
      devices: [],
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    selectedNode(){
      this.getItems();
    },
  },
  mounted: function(){
    this.getItems();
  },
  destroyed: function(){
  },
  methods: {
    getItems: function () {
      let poleId = 0, groupId = 0;
      if(this.selectedNode.type != this.productCodes.station){
        groupId = this.selectedNode.id;
      }else{
        poleId = this.selectedNode.poleId;
      }
      this.$axios.post(`//${this.domains.trans}/pole/config/QueryEnvironmentList`, {groupId, poleId}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        // res.data.map(p => {
        //   if(!p.lastData){
        //     p.lastData = {u: 3};
        //   }
        // })
        this.$set(this, 'devices', res.data);
      })
    },
    switchMenu: function(menu){
      this.curMenu = menu;
    },
    returnBase: function(){
      this.curMenu = 'base';
    },
  }
}
</script>
<style scoped>
.pole-container{
  background: #E3E9EF;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  /* border: solid 1px red; */
}
.content-item{
  height: 200px;
  flex: auto;
  /* margin-right: 40px; */
  /* border: solid 1px red; */
  overflow: hidden;
}
</style>
