<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">查看报警数据 - 发生时间: {{item.startTime}}</div>
    <div class="search-data-header">
      <div class="analyse-table">
        <table border="0" cellspacing="0" cellpadding="0" style="table-layout: fixed;width: 100%">
          <tr>
            <td style="width: 100px">所在站点</td>
            <td>{{item.stationName}}</td>
            <td style="width: 100px">发生时间</td>
            <td>{{item.startTime}}</td>
            <td style="width: 100px">消除时间</td>
            <td>{{item.isDone ? item.doneTime : '-'}}</td>
          </tr>
          <tr>
            <td>报警属性</td>
            <td colspan="3">{{item.paramName}}</td>
            <td>报警类型</td>
            <td style="color:#f00">{{item.name}}<Button v-if="item.type == 23 || item.type == 32" style="margin-right: 0; float:right;" size="small" type="primary" @click="showCompareLogs">对比记录</Button></td>
          </tr>
          <tr>
            <td>报障人</td>
            <td colspan="3">{{item.reportName}}</td>
            <td>联系方式</td>
            <td>{{item.mobile}}</td>
          </tr>
          <tr>
            <td>故障现象</td>
            <td colspan="5" style="color:#f00">{{item.extra}}</td>
          </tr>
          <tr v-if="item.source==1">
            <td>联系人</td>
            <td>{{item.sourceName}}</td>
            <td>方式</td> 
            <td>{{item.mobile}}</td>
          </tr>
          <tr>
            <td>故障确认</td>
            <td colspan="5">{{item.isConfirm ? '已确认' : '未确认'}}
              <Button v-if="!item.isConfirm && !item.doneTime" style="margin-right: 0; float:right;" size="default" type="primary" @click="confirmAlarm">确认告警</Button>
              <span v-if="item.isConfirm">: 由 {{item.byUser ? '用户' : '工单系统'}} [{{item.confirmName}}] 确认于 [{{item.confirmTime}}]</span>
            </td>
          </tr>
          <tr>
            <td>图片详情</td>
            <td colspan="5" class="img-list">
              <div class="info-img-item" v-for="(el,index) in item.imageIds" :key="index">
                <img :src="img.light.info.enlargeImg" alt="" class="info-delImg" @click="enlarge(el)" />
                <img :src="`//${domains.trans}/station/config/ViewImage?id=${el}&auth=${token}`" alt="" class="info-img" />
              </div>
            </td>
          </tr>
        </table>
      </div>
      <Modal v-model="isShowImg" :title="showLang('com.img.amplify')" width="1000">
        <div style="text-align: center;overflow:auto;">
          <img v-if="isShowImgUrl" :src="`//${domains.trans}/station/config/ViewImage?id=${isShowImgUrl}&auth=${token}`" alt="" :style="{width:'100%', verticalAlign: 'middle', transform: `rotate(${rotateAngle}deg)`}">
        </div>
        <div slot="footer">
          <Button type="info" style="margin-right: 8px" @click="leftRotate">{{showLang('com.img.Left.Rotation')}}</Button>
          <Button type="info" style="margin-right: 8px" @click="rightRotate">{{showLang('com.img.Right.Rotation')}}</Button>
          <Button style="margin-right: 8px" @click="cancelImage">{{showLang('com.op.close')}}</Button>
        </div>
      </Modal>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalAlarmData',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showCompareModal: false,
      showModal: this.value,
      isShowImg: false,
      isShowImgUrl: '',
      loading: false,
      form: {
        sid: 0,
        start: '',
        end: '',
      },
      alarmTs: 0,
      history: [],
      searchLight: {},
      lightConfig: {},
      lightName: '',
      rotateAngle: 0,
      pos: {}
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('common', ['alarmTypes', 'alarmGroupList', 'alarmLevels', 'dealAlarmTypes', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('auth', ['token']),
    ...mapGetters('auth', ['showLang']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station)
    },
  },
  mounted: function () {
  },
  methods: {
    leftRotate: function () {
      this.rotateAngle -= 90;
    },
    rightRotate: function () {
      this.rotateAngle += 90;
    },
    cancelImage: function(){
      this.isShowImg = false;
    },
    enlarge(el) {//放大
      // console.log(el)
      this.isShowImg = true;
      this.isShowImgUrl = el;
    },
    showCompareLogs: function(){
      this.showCompareModal = true;
    },
    confirmAlarm: function(){
      this.$Modal.confirm({
        title: '确认告警',
        content: `确定已知道该告警吗？`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/alarm/ConfirmReportAlarm`, {id: this.item.id}).then(res => {
            if(res.code == 0){
              if(!res.data.success){
                this.$Message.warning(res.data.message)
                return;
              }
              this.item.byUser = res.data.byUser;
              this.item.confirmId = res.data.confirmId;
              this.item.confirmName = res.data.confirmName;
              this.item.confirmTime = res.data.confirmTime;
              this.item.isConfirm = res.data.isConfirm;
            }
          });
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.info-img-item{
  border: solid 1px lightgray;
  width: 200px;
  height: 200px;
  position: relative;
  margin: 5px;
  float: left;
}
.info-delImg{
  position: absolute;
  top: 10px;
  right: 10px;
}
.info-img{
  width: 100%;
  height: 100%;
}
.log-search-area{
  /* border: solid 1px red; */
  height: 50px;
}
.alarm-color {
  color: red;
}
td {
  height: 40px;
  /* white-space: nowrap; */
  width: 120px;
  border: solid 1px #cfe3f7;
}
.sub-header {
  background-color: #cfe3f7;
  /* background: #CFE3F7; */
  height: 46px;
  text-align: center;
}
.history-item {
  /* border-width: 1px;
  border-collapse: collapse;
  border-color: cyan; */
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
}
.search-data-content {
  height: 500px;
  flex: auto;
  /* border: solid 1px blue; */
  overflow: auto;
}
.analyse-table table {
  width: 100%;
  height: 93px;
  margin-bottom: 20px;
}
.analyse-table table td {
  /* width: 115px; */
  /* text-align: center; */
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #d0d9e9;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  padding: 0 10px;
}
.showImgBox {
  width: 300px;
  height: 300px;
  border: 1px solid #b7afaf;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 5px;
}
.showImgBox img {
  max-width: 297px;
  max-height: 297px;
}
/* .analyse-table table tr:last-child td {
  border-bottom: none;
}
.analyse-table table tr td:last-child {
  border-right: none;
} */
</style>