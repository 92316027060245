<template>
  <div class="countBar-control">
    <div class="countBar-title">当日车辆统计</div>
    <div class="countBar-con">
      <chartsBar :option="chartsLineData" id="chartsBar" :key="timer"></chartsBar>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  computed: {

  },
  data() {
    return {
      timer: 2,
      chartsLineData: {
        color: ["#50A2FF"],
        x: ["面包车", "SUV.MVP", "轿车", "其他"],
        unit: '量',
        series: [
          {
            data: [90, 65, 47, 105],
          }
        ],
        setSeries: {
          showBackground: false, //带背景色
          // backgroundStyle: {
          //   color: "rgba(80, 162, 255, 1)",
          // },
          label: {//柱上显值
            show: true,
            position: "top",
          },
        }
      }
    }
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
  }
}
</script>
<style scoped>
.countBar-control {
  height: calc(100%);
  padding: 20px 30px;
}
.countBar-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  margin-bottom: 15px;
  font-weight: bold;
}
.countBar-con {
  width: 100%;
  height: calc(100% - 25px);
}
</style>
