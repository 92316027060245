<template>
<div class="level1-container">
  <img :src="item.icon" />
  <div class="level1-content">
    <div class="level1-title">{{item.title}}</div>
    <div class="level1-nums">
      <div class="nums-content">{{item.count}}</div>
      <div class="nums-unit" :style="{color: item.color}">{{item.unit}}</div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'Level1Content',
  components: {
  },
  props: {
    item: {
      type: Object,
      default(){return {icon: '', title: '', count: 0, unit: '', color: ''}}
    }
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {
  },
}
</script>
<style scoped>
.level1-container {
  /* border: solid 1px red; */
  width: 205px;
  margin-right: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
}
.level1-container img{
  width: 46px;
  height: 46px;
  flex: none;
  margin: 0 20px;
}
.level1-content{
  width: 50px;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.level1-title{
  height: 25px;
  flex: none;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334A;
}
.level1-nums{
  height: 30px;
  flex: auto;
  display: flex;
  align-items: center;
  align-items: flex-end;
}
.nums-content{
  /* width: 50px;
  flex: none; */
  padding-right: 10px;
  font-size: 26px;
  font-family: Bahnschrift;
  font-weight: bold;
  color: #0A1421;
  height: 30px;
}
.nums-unit{
  width: 30px;
  flex: auto;
  font-size: 14px;
  font-family: Bahnschrift;
  font-weight: 400;
  height: 16px;
}
</style>