<template>
  <div class="asset-container">
    <div class="asset-level1">
      <Level1Content :items="totals" />
    </div>
    <div class="asset-level2">
      <deviceList :devices="devices" />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import Level1Content from './level1/Index2'
import deviceList from '../base/deviceList'
export default {
  name: 'groupLevel1',
  components: {
    Level1Content,
    deviceList,
  },
  props: {
    devices: {
      type: Array,
      default(){return []},
    },
  },
  data() {
    return {
      totals: [
        { icon: this.img.pole.asset.level3.total7, title: '设备总数', count: 25, unit: '', color: '' },
        { icon: this.img.pole.asset.level3.alarm, title: '离线', count: 15, unit: '', color: '#EA6352' },
        { icon: this.img.pole.asset.level3.running, title: '在线', count: 95, unit: '', color: '#35D288' },
        { icon: this.img.pole.asset.level3.create, title: '故障', count: 15, unit: '', color: '#4792EC' },
      ]
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    devices(){
      this.initData();
    },
  },
  created: function () {
  },
  mounted: function () {
    // this.initData()
  },
  methods: {
    initData: function () {
      // console.log('init data', this.devices)
      let total = 0, online = 0, offline = 0, alarm = 0;//, volLess = 0, move = 0, water = 0;
      for (let m of this.devices) {
        total++;
        if (m.online) {
          online++;
        } else {
          offline++;
        }
        if (m.alarm) {
          alarm++;
        }
      }
      this.$set(this.totals[0], 'count', total);
      this.$set(this.totals[1], 'count', online);
      this.$set(this.totals[2], 'count', offline);
      this.$set(this.totals[3], 'count', alarm);
    },
  },
}
</script>
<style scoped>
.asset-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.asset-level1 {
  height: 90px;
  flex: none;
  margin-bottom: 15px;
  display: flex;
}
.asset-level2 {
  height: 305px;
  flex: auto;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
}
</style>