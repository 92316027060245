<template>
  <div class="pole-container">
    <div class="pole-header">
      <template v-for="(item, idx) in menus">
        <div :class="['header-item', item.code == tab ? 'header-active':'']"  :key="idx" @click="switchMenu(item.code)">{{item.name}}</div>
      </template>
    </div>
    <div class="pole-content">
      <template v-for="(item, idx) in menus">
        <component v-if="item.code == tab && item.com " :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import lampPage from './configure/lamp'
import otherPage from './configure/other'

export default {
  name: 'alarmPage',
  components: {
  },

  data() {
    return {
      tab: 's1',
      menus: [
        { code: 's1', name: '单灯告警类型设置', com: lampPage, args: {}, range: [], opCode: [] },
        { code: 's2', name: '其他设置', com: otherPage, args: {}, range: [], opCode: [] },
      ]
    }
  },
  computed: {
    ...mapState('auth', ['appType', 'user', 'ops']),
  },
  watch: {
  },
  mounted: function () {
    // console.log('account index mouted', this.appType, this.args)
  },
  destroyed: function () {
  },
  methods: {
    canOp(opCode) {
      if (this.user && this.user.isSuper) return true;
      if (opCode.length == 0) return true;
      for (let index = 0; index < opCode.length; index++) {
        if (this.ops && this.ops.indexOf(opCode[index]) != -1) {
          return true;
        }
      }
      return false;
    },
    switchMenu: function (menu) {
      this.tab = menu;
    },
  }
}
</script>
<style scoped>
.pole-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: stretch;
  border-radius: 12px;
  /* border: solid 1px red; */
  padding: 10px;
}
.pole-header {
  /* border: solid 1px red; */
  height: 40px;
  flex: none;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}
.header-item {
  padding: 0 10px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #152942;
  cursor: pointer;
}
.header-item::after {
  content: "";
  display: block;
  width: 70%;
  height: 4px;
  margin: 0 auto;
  margin-top: 3px;
  border: solid 2px white;
}
.header-active {
  font-weight: bolder;
  position: relative;
}
.header-active::after {
  border: solid 2px #3781fe;
}
.pole-content {
  /* border: solid 1px red; */
  height: 400px;
  flex: auto;
}
</style>
