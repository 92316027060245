<template>
  <div class="countLine-control">
    <div class="countLine-title">当日车流辆统计</div>
    <div class="countLine-con">
      <chartsLine :option="chartsLineData" id="chartsLineData" :key="timer"></chartsLine>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  computed: {

  },
  data() {
    return {
      timer: 2,
      chartsLineData: {
        id: "chartsLineData",
        color: ["#35AB51", "#4FA2FF", "#6A7BFA", "#54CACB"],
        x: ["00", "04", "08", "12", "16", "20"],
        unit: '量',
        series: [
          {
            name: '面包车',
            data: [60, 35, 80, 20, 65, 84, 65],
          },
          {
            name: 'SUV.MVP',
            data: [22, 65, 23, 74, 62, 41, 72],
          },
          {
            name: '轿车',
            data: [123, 62, 94, 41, 32, 82, 65],
          },
          {
            name: '其他',
            data: [25, 123, 62, 94, 41, 41, 32],
          },

        ],
        setSeries: {
          smooth: true, //平滑折线图
        }
      }
    }
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
  }
}
</script>
<style scoped>
.countLine-control {
  height: calc(100%);
  padding: 20px 30px;
}
.countLine-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  margin-bottom: 15px;
  font-weight: bold;
}
.countLine-con {
  width: 100%;
  height: calc(100% - 25px);
}
</style>
