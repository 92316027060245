<template>
<div class="device-item-content">
  <div class="device-item-header">
    <img class="device-item-header-icon" :src="img.pole.light.icon.base" title="单灯" />
    <span class="device-item-header-title">{{item.name}}<span style="font-size: 10px;margin-left: 5px;">[{{ item.fireTime }}]</span></span>
    <img class="device-item-header-status-icon" :src="online ? img.pole.online : img.pole.offline" />
    <span class="device-item-header-status">{{online ? '在线' : '离线'}}</span>
  </div>
  <div class="form-item">
    <span>亮度</span>
    <Slider v-model="form.bright1" class="flex-auto mr10"></Slider>
    <Input v-model="form.bright1" number type="number" style="width: 55px;flex:none;margin-top:3px;" @on-change="brightChanged" />
  </div>
  <div class="form-item">
    <span>冷光</span>
    <Slider v-model="form.color1" class="flex-auto mr10"></Slider>
    <span style="width: 40px;margin-right:0">暖光</span> 
  </div>
  <div class="form-item">
    <span>持续</span> 
    <Input v-model="form.expire" number type="number" class="flex-auto mr10" @on-change="expireChange"></Input>
    <span style="width: 35px;flex:none;margin-left:0;margin-right:8px">分钟</span> 
    <Button type="success" :loading="loading" @click="doReadData">召测</Button>
  </div>
  <div class="form-item">
    <span>延时至</span> 
    <DatePicker type="datetime" v-model="form.date" transfer format="yyyy-MM-dd HH:mm" class="flex-auto mr10" @on-change="dateChange"></DatePicker>
    <Button type="primary" :loading="loading" @click="doHandControlCommand">操作</Button>
  </div>
  <div class="form-item mt5" style="color: black; padding:0">
    <ul class="light-params">
      <li class="mr10">
        <div class="env-item-title">温度</div>
        <div class="env-item-content">
          <img :src="img.pole.env.speed" />
          <div>{{img.val(item.tempChip,1)}} ℃</div>
        </div>
      </li>
      <li>
        <div class="env-item-title">功率</div>
        <div class="env-item-content">
          <img :src="img.pole.env.speed" />
          <div>{{getValue('power1', 0)}} W</div>
        </div>
      </li>
      <li class="mr10">
        <div class="env-item-title">电压</div>
        <div class="env-item-content">
          <img :src="img.pole.env.speed" />
          <div>{{getValue('voltage1', 2)}} V</div>
        </div>
      </li>
      <li>
        <div class="env-item-title">亮度</div>
        <div class="env-item-content">
          <img :src="img.pole.env.speed" />
          <div>{{getValue('output1', 0)}} %</div>
        </div>
      </li>
      <li class="mr10">
        <div class="env-item-title">电流</div>
        <div class="env-item-content">
          <img :src="img.pole.env.speed" />
          <div>{{getValue('current1', 2)}} A</div>
        </div>
      </li>
      <li>
        <div class="env-item-title">色温</div>
        <div class="env-item-content">
          <img :src="img.pole.env.speed" />
          <div>{{getValue('color1', 0)}} %</div>
        </div>
      </li>
    </ul>
  </div>
  <Spin fix v-if="spin.show" @click="clickspin">{{spin.content}}</Spin>
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'LightStationViewer',
  components:{
  },
  props: {
    item: {
      type: Object,
      default(){return {}},
    },
  },
  data () {
    return {
      loading: false,
      spin: {
        show: false,
        content: '加载中...',
      },
      form: {
        bright1: 0,
        bright2: 0,
        color1: 0,
        color2: 0,
        date: '',
        expire: 60,
      },
      cmd: {
        _cmd: '',
        _cmdId: '',
        _message: '',
        _content: {},
        _status: 0,
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
    ...mapState('group', ['selectedDevices', 'selectedNode', 'groups']),
    online: function(){
      if(!this.item)return false;
      return this.item.online;
    },
  },
  watch: {
    cmdResultRefresh(){
      let cmds = this.commands.filter(p => p.id == this.item.id);
      if(cmds.length == 0)return;
      let cmd = cmds[0];
      switch(cmd.status){
        case 0:
          this.showSpin(`${cmd.result}`);
          break;
        case 1:
          this.showSpin('等待回复');
          break;
        case 2:
          this.showSpin('指令超时');
          break;
        case 3:
          this.showSpin(`执行失败：${cmd.result}`);
          setTimeout(this.hideSpin, 2000);
          break;
        case 9: 
          if(cmd.code == 'forceRead'){
            // console.log('light force read', cmd.content)
            this.item.fireTime = new Date().getTime();
            this.item.lastData.tc = cmd.content.tempChip;
            this.item.lastData.op1 = cmd.content.outRate1;
            this.item.lastData.oc1 = cmd.content.outColor1;
            this.item.lastData.u = cmd.content.voltage;
            this.item.lastData.c1 = cmd.content.current1;
            this.item.lastData.p1 = cmd.content.power1;
            this.form.bright1 = cmd.content.outRate1;
            this.form.color1 = cmd.content.outColor1;
          }
          this.showSpin(`执行成功`);
          setTimeout(this.hideSpin, 1000);
          break;
      }
    }
  },
  mounted: function(){
    // console.log('light info show', this.item)
    let now1 = new Date();
    now1.setHours(now1.getHours() + 1);
    this.form.date = now1.format('yyyy-MM-dd HH:mm');
    window.eventBus.$on('paramCommandComing', params => {
      // console.log('cmd reply', params, this.cmd, this.item);
      if(this.cmd._cmdId != params.commandId)return;
      console.log('get cmd reply', params);
      // this.$set(this.cmd, '_cmd', params.cmdCode);
      this.$set(this.cmd, '_message', params.message);
      this.$set(this.cmd, '_content', params.content);
      this.$set(this.cmd, '_status', params.status);
      if (params.status == 0) {
        this.showSpin(`指令已进入队列`);
      } else if (params.status == 2){
        this.showSpin('等待设备应答');
      } else if(params.status == 7){
        this.showSpin(`指令超时：${this.cmd._message}`);
        setTimeout(this.hideSpin, 2000);
      } else if(params.status == 8) {
        this.showSpin(`执行失败：${this.cmd._message}`);
        setTimeout(this.hideSpin, 2000);
      } else if(params.status == 9){
        this.showSpin(`执行成功`);
        setTimeout(this.hideSpin, 1000);
        switch(this.cmd._cmd){
          case 'forceRead': {
            this.$set(this.item, 'voltage1', this.cmd._content.voltage);
            this.$set(this.item, 'voltage2', 0);
            this.$set(this.item, 'current1', this.cmd._content.current1);
            this.$set(this.item, 'current2', this.cmd._content.current2);
            this.$set(this.item, 'power1', this.cmd._content.power1);
            this.$set(this.item, 'power2', this.cmd._content.power2);
            this.$set(this.item, 'output1', this.cmd._content.outRate1);
            this.$set(this.item, 'output2', this.cmd._content.outRate2);
            this.$set(this.item, 'quality', this.cmd._content.quantity1);
            this.$set(this.item, 'tempChip', this.cmd._content.tempChip);
            this.$set(this.item, 'tempModule', this.cmd._content.tempModule);
            this.$set(this.item, 'fireTime', this.cmd._content.time);
          }
        }
      }
    })
  },
  destroyed: function () {
    window.eventBus.$off('paramCommandComing');
    // clearInterval(this.cmdTimer);
  },
  methods: {
    sendCommand: function(ids, code, args){
      this.$set(this.cmd, '_cmd', code);
      this.$set(this.cmd, '_cmdId', '');
      this.$set(this.cmd, '_message', "指令已创建");
      this.$set(this.cmd, '_content', null);
      this.$set(this.cmd, '_status', 0);
      setTimeout(() => {
        this.$axios.post(`//${this.domains.trans}/station/command/SendLampNew`, {code, list: ids, args}).then(res => {
          if(res.code != 0){
            // this.$Message.error(res.data);
          }else{
            console.log('send led cmd result', res.data, ids)
            for(let cmd of res.data.list){
              if(cmd.id != this.item.id)continue;
              let message = '指令已发送', status = 2, cmdId = cmd.message;
              if(!cmd.success){
                message = cmd.message;
                status = 8;
                cmdId = '';
                this.showSpin(`执行失败：${message}`);
                setTimeout(this.hideSpin, 2000);
              }
              this.$set(this.cmd, '_cmd', code);
              this.$set(this.cmd, '_cmdId', cmdId);
              this.$set(this.cmd, '_message', message);
              this.$set(this.cmd, '_content', null);
              this.$set(this.cmd, '_status', status);
              this.showSpin(`指令已发送，等待设备应答`);
            }
          }
        });
      }, 300);
    },
    getValue: function(col, point){
      if(!this.item) return '-';
      let v = this.item[col];
      return this.img.val(v, point);
    },
    clickspin: function(){
      // console.log('click spin')
    },
    showSpin: function(msg){
      this.spin.content = msg;
      this.spin.show = true;
    },
    hideSpin: function(){
      this.spin.show = false;
    },
    doReadData: function(){
      this.sendCommand([this.item.id], 'forceRead', {})
    },
    doHandControlCommand: function(){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要操作单灯吗?`,
        onOk: async () => {
          let expireTime = new Date(this.form.date).getTime();
          let nowTime = new Date().getTime();
          let expire = parseInt((expireTime - nowTime) / 1000 / 60);
          let args = {bright1: this.form.bright1, color1: this.form.color1, bright2: 100 - this.form.bright1, color2: 0, expire: expire};
          this.sendCommand([this.item.id], 'handSingle', args);
        }
      });
    },
    doCommand: async function(code, name, args){
      let devs = [];
      devs.push({
        id: this.item.id,
        deviceId: this.item.deviceId,
        code: code,
        name: name,
        args: args
      });
      this.$store.dispatch('cmd/sendCommands', devs);
    },
    brightChanged: function(){
      if(this.form.bright1 < 0)this.form.bright1 = 0;
      if(this.form.bright1 > 100)this.form.bright1 = 100;
    },
    expireChange: function(){
      let now = new Date();
      now.setMinutes(now.getMinutes() + this.form.expire);
      this.form.date = now.format('yyyy-MM-dd HH:mm');
    },
    dateChange: function(){
      let date = new Date(this.form.date);
      let expire = parseInt((date.getTime() - new Date().getTime()) / 60000);
      this.form.expire = expire;
    },
  }
}
</script>
<style scoped>
.device-item-content{
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.env-item-title{
  /* border: solid 1px red; */
  text-align-last: justify;
  width: 50px;
  height: 32px;
  flex: none;
  line-height: 32px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
  margin-right: 10px;
  /* padding: 0 8px; */
  /* white-space: nowrap;
  overflow: hidden; */
}
.env-item-content{
  /* border: solid 1px red; */
  width: 107px;
  height: 32px;
  flex: auto;
  background: #EEF3F8;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.env-item-content img{
  width: 18px;
  height: 18px;
  margin: 0 5px;
  flex: none;
}
.env-item-content div{
  margin: 0 5px;
  flex: auto;
}
.light-params{
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}
.light-params li{
  /* border: solid 1px red; */
  margin-top: 5px;
  width: 169px;
  display: flex;
}
.form-item{
  /* border: solid 1px red; */
  /* margin: 5px 0; */
  display: flex;
  color: white;
  /* padding-right: 30px; */
}
.form-item span{
  /* border: solid 1px red; */
  width: 50px;
  flex: none;
  text-align-last: justify;
  /* text-align: justify; */
  /* display: flex;
  justify-content: space-between; */
  line-height: 35px;
  margin-right: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
  /* margin-bottom: 3px;
  display: flex;
  color: white; */
}
.device-item-header{
  height: 48px;
  /* background: #E8F1FA; */
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
}
.device-item-header-icon{
  width: 20px;
  height: 20px;
  margin-left: 25px;
  margin-right: 10px;
  flex: none;
}
.device-item-header-title{
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  width: 10px;
  flex: auto;
}
.device-item-header-status-icon{
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
.device-item-header-status{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  flex: none;
  margin-right: 25px;
}
</style>
