<template>
<div class="config-station-container">
  <div class="station-search-area">
    <span>{{showLang('com.tab.title')}}：</span>
    <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.device.pole.name')" style="width: 200px; margin-right: 10px;"></Input>
    <Button size="default" type="primary" @click="getList" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
    <Button type="primary" size="default" v-for="(cmd, idx) in commands" :key="idx" style="margin-right: 5px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
    <Button type="success" size="default" style="margin-right: 5px" @click="openTaskList">播放任务管理</Button>
  </div>
  <div class="station-data-area" ref="table">
    <vxe-table ref="chkTable" border show-overflow show-header-overflow :data="list" :height="tabHeight" :row-config="{isHover: true}">
      <vxe-column width="50" type="checkbox" field="checkbox"></vxe-column>
      <vxe-column title="状态" width="150">
        <template #default="params">
          {{getStatusName(params)}}
        </template>
      </vxe-column>
      <vxe-column field="name" title="名称" width="200"></vxe-column>
      <vxe-column field="poleName" title="所在灯杆" width="200"></vxe-column>
      <vxe-column field="connectTypeName" title="接入方式" width="100"></vxe-column>
      <vxe-column field="code" title="通信ID" width="120"></vxe-column>
      <vxe-column field="volume" title="音量" width="60"></vxe-column>
      <vxe-column title="执行结果" fixed="right">
        <template #default="params">
          <img v-if="params.row._cmd == 4215 && params.row._status == 9" :src="`data:image/jpeg;base64,${params.row.img}`" />
          <template v-else>
            {{getCmdStatus(params.row)}}
          </template>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
  </div>
  <ModalCommandArgs v-model="showArgModal" :command="editItem" @saved="commandArgSaved" />
  <ModalTaskList v-model="showTaskModal" />
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalCommandArgs from './ModalCommandArgs'
import ModalTaskList from '@/views/media/task/ModalTaskList'
export default {
  name: 'PoleBroadIndex',
  components:{
    ModalCommandArgs,
    ModalTaskList,
  },
  data () {
    return {
      clearOldLog: false,
      showEditModal: false,
      showTaskModal: false,
      showArgModal: false,
      tabHeight: 300,
      editItem: {isAdd: true, index: -1, data: {}},
      filter: {
        groupId: 0,
        poleId: '',
        name: '',
        index: 1,
        size: 20
      },
      list: [],
      total: 0,
      commands: [],
      cmdResults: {},
      cmdTimer: null,
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['weeks', 'pageSizes', 'pageLayouts']),
    ...mapState('group', ['poleSelectedNode']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    poleSelectedNode(){
      this.getList();
    },
  },
  mounted: function(){
    setTimeout(() => {
      this.setTableHeight();
    }, 200);
    this.getCommandTypes();
    this.cmdTimer = setInterval(this.setCmdResult, 500);
    window.eventBus.$on('broadCommandComing', params => {
      this.cmdResults[params.commandId] = params;
    })
    // window.eventBus.$on('commandReplyComing', params => {
    //   // console.log('get cmd reply', params);
    //   this.cmdResults[params.cmdId] = params;
    // })
    this.getList();
  },
  destroyed: function () {
    window.eventBus.$off('broadCommandComing');
    // window.eventBus.$off('commandReplyComing');
    clearInterval(this.cmdTimer);
  },
  methods: {
    getCommandTypes: function(){
      this.$axios.post(`//${this.domains.trans}/pole/command/QueryBroadCmds`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'commands', res.data);
        }
      });
    },
    setCmdResult: function () {
      for (let cmdId in this.cmdResults) {
        let items = this.list.filter(p => p._cmdId == cmdId);
        if(items.length == 0)continue;
        let reply = this.cmdResults[cmdId];
        let item = items[0];
        // this.$set(item, '_cmd', reply.code);
        this.$set(item, '_message', reply.message);
        this.$set(item, '_content', reply.content);
        this.$set(item, '_status', reply.status);
        if(reply.status == 9){
          switch(item._cmd){
            // case 4212:
            // case 4213:
            //   this.$set(item, 'bright', reply.content.bright);
            //   break;
            case 'setVolumn':
              this.$set(item, 'volume', reply.content.volume);
              break;
            // case 4215:
            //   this.$set(item, 'img', reply.content.img);
            //   break;
            case 'playMedia':
              this.$set(item, 'mediaId', reply.content.mediaId);
              this.$set(item, 'mediaName', reply.content.mediaName);
              this.$set(item, 'isTask', reply.content.isTask);
              this.$set(item, 'running', reply.content.running);
              break;
          }
        }
        delete this.cmdResults[cmdId];
      }
    },
    getStatusName: function(params){
      if(!params.row.online)return '离线';
      if(!params.row.running)return '关机';
      if(!params.row.isTask){
        return '播放媒体：' + params.row.mediaName;
      }else{
        return '播放任务：' + params.row.taskName;
      }
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    openTaskList: function(){
      this.showTaskModal = true;
    },
    commandArgSaved: function(params){
      // console.log('click cmd', params)
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要控制的终端');
        return;
      }
      let ids = chks.map(p => p.id);
      this.sendCommand(ids, params.cmd, params.args);
    },
    clickCmd: function (params) {
      // console.log('click cmd', params)
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要控制的终端');
        return;
      }
      if(params.needArgs || params.needPswd){
        this.editItem = params;
        this.showArgModal = true;
        return;
      }
      let ids = chks.map(p => p.id);
      this.sendCommand(ids, params.code, {});
    },
    sendCommand: function(ids, code, args){
      for(let id of ids){
        let items = this.list.filter(p => p.id == id);
        if(items.length == 0)continue;
        let item = items[0];
        this.$set(item, '_cmd', code);
        this.$set(item, '_cmdId', '');
        this.$set(item, '_message', "指令已创建");
        this.$set(item, '_content', null);
        this.$set(item, '_status', 0);
      }
      setTimeout(() => {
        this.$axios.post(`//${this.domains.trans}/device/broad/SendCommand`, {code, list: ids, args}).then(res => {
          if(res.code != 0){
            // this.$Message.error(res.data);
          }else{
            // console.log('send led cmd result', res.data, this.list)
            for(let cmd of res.data.list){
              let items = this.list.filter(p => p.id == cmd.id);
              if(items.length == 0)continue;
              let item = items[0];
              let message = '指令已发送', status = 2, cmdId = cmd.message;
              if(!cmd.success){
                message = cmd.message;
                status = 8;
                cmdId = '';
              }else if(code == 'playTask'){
                message = "播放计划设置成功";
                status = 9;
                cmdId = '';
              }
              this.$set(item, '_cmd', code);
              this.$set(item, '_cmdId', cmdId);
              this.$set(item, '_message', message);
              this.$set(item, '_content', null);
              this.$set(item, '_status', status);
            }
          }
        });
      }, 300);
    },
    getList: function(){
      let groupId = '', poleId = '';
      if(this.poleSelectedNode.isLeaf){
        poleId = this.poleSelectedNode.id;
      }else{
        groupId = this.poleSelectedNode.id;
      }
      this.filter.groupId = groupId;
      this.filter.poleId = poleId;
      this.$axios.post(`//${this.domains.trans}/pole/command/QueryBroadList`, this.filter).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.total = res.data.count;
          res.data.list.map(p => {
            p._cmd = '';
            p._cmdId = '';
            p._message = '';
            p._content = {};
            p._status = 0;
          });
          this.$set(this, 'list', res.data.list);
          // this.$refs.chkTable.toggleAllSelection();
        }
      });
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 43;
    },
    getCmdStatus: function (cmd) {
      switch (cmd._status) {
        case 0: return ``;
        case 2: return `等待设备应答`;
        case 4: return `执行中：${cmd._content.pkg} / ${cmd._content.total}`;
        case 5: return `失败重试：${cmd._message}`;
        case 7: return `指令超时：${cmd._message}`;
        case 8: return `执行失败：${cmd._message}`;
        case 9: {
          // console.log('getCmdStatus', cmd)
          // console.log('cmd status', cmd)
          switch (cmd._cmd) {
            // case 'capture': return `执行成功：${cmd.content.nowTime}`;
            case 'playTask': return '播放计划设置成功';
            default: return `执行成功`;
          }
        }
        default: return `${cmd._message}`;
      }
    },
  }
}
</script>
<style scoped>
.config-station-container{
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  /* padding: 10px; */
  background-color: white;
  border-radius: 8px;
}
.station-search-area{
  height: 42px;
  flex: none;
  border: solid 1px #DCDEE2;
  /* border-radius: 6px; */
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.row-space{
  width: 100px;
  flex: auto;
}
.station-data-area{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>