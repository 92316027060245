<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        {{showLang('com.site')}}：
        <Select :placeholder="showLang('save.select')" v-model="form.stationId" style="width: 150px; margin-right: 10px;">
          <!-- <Option :value="0">所有配电柜</Option> -->
          <template v-for="(item, idx) in selectedDevices">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
        {{showLang('com.date.start')}}：
        <DatePicker v-model="form.start" type="date" style="width: 150px; margin-right: 10px; flex: none;"></DatePicker>
        {{showLang('com.date.end')}}：
        <DatePicker v-model="form.end" type="date" style="width: 150px; margin-right: 10px; flex: none;"></DatePicker>
        <Button type="info" :loading="searchLoading" @click="initEnergys" style="margin-right: 10px">{{showLang('com.op.query')}}</Button>
        <Button type="success" :loading="exportLoading" @click="exportData">{{showLang('com.op.export')}}</Button>
        <span style="margin-left: 20px">{{showLang('energy.tisp.total.sites',energys.length)}} , {{showLang('energy.saving')}} {{totalEnergy}} KWh, {{showLang('com.stat.save.charge1')}} {{totalMoney}} {{showLang('com.unit.cash')}}</span>
      </div>
    </div>
    <div class="search-data-content" ref="table1">
      <vxe-table  :empty-text="showLang('com.data.no')"  border resizable show-overflow show-header-overflow keep-source ref="chkTable" :data="energys" :height="tabHeight" :row-config="{isHover: true}" :export-config="{}" show-footer :footer-method="footerMethod" footer-cell-class-name="footer-cell-class">
        <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-column field="seq" type="seq" width="90"></vxe-column>
        <vxe-column field="name" :title="showLang('com.tab.title')" header-align="center">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column field="date" :title="showLang('com.date.time')"  width="260" header-align="center">
          <template #default="{ row }">
            {{row.date}}
          </template>
        </vxe-column>
        <vxe-column field="minsName" :title="showLang('com.lighting.lamp.duration')"  width="140" header-align="center">
          <template #default="{ row }">
            {{row.minsName}}
          </template>
        </vxe-column>
        <vxe-column field="beforeLight" :title="showLang('energy.before.energy')"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.beforeLight}} KWh
          </template>
        </vxe-column>
        <vxe-column field="afterLight" :title="showLang('energy.after.energy')"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.afterLight}} KWh
          </template>
        </vxe-column>
        <vxe-column field="save" :title="showLang('energy.save.amount')"  width="120" header-align="center">
          <template #default="{ row }">
            {{row.save}} KWh
          </template>
        </vxe-column>
        <vxe-column field="money" :title="showLang('energy.save.money')"  width="120" header-align="center">
          <template #default="{ row }">
            {{row.money}} {{showLang('com.unit.cash')}}
          </template>
        </vxe-column>
        <vxe-column field="rate" :title="showLang('energy.save.rate')"  width="90" header-align="center">
          <template #default="{ row }">
            {{row.rate}} %
          </template>
        </vxe-column>
        <vxe-column :title="showLang('com.right.nav.operate')" width="110">
          <template #default="{ row }">
            <Button type="primary" @click="handleEdit(row)">{{showLang('energy.View.Details')}}</Button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <ModalLightRecord v-model="showEditModal" :item="editItem" />
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import ModalLightRecord from './ModalLightRecord'
export default {
  name: 'SearchPowerboxIndex',
  components: {
    ModalLightRecord,
  },
  props: {
  },
  data() {
    return {
      searchLoading: false,
      exportLoading: false,
      tabHeight: 1,
      form: {
        stationId: 0,
        start: "",
        end: "",
      },
      energys: [],
      editItem: {},
      showEditModal: false,
      showLogModal: false,
      footerData: []
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    totalEnergy: function(){
      let sum = 0;
      for(let en of this.energys){
        sum += en.save;
      }
      return this.img.val(sum, 1);
    },
    totalMoney: function(){
      let sum = 0;
      for(let en of this.energys){
        sum += en.money;
      }
      return this.img.val(sum, 1);
    },
  },
  watch: {
    selectedNode() {
      this.initStationId();
      // this.initEnergys();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd');
    now.setMonth(now.getMonth() - 1);
    this.form.start = now.format('yyyy-MM-dd');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.initStationId();
  },
  destroyed: function () {
  },
  methods: {
    footerMethod: function(){
      return this.footerData;
    },
    initStationId: function(){
      if(this.selectedDevices.length == 0){
        this.form.stationId = 0;
        this.initEnergys();
        return;
      }
      let ss = this.selectedDevices.filter(p => p.id == this.form.stationId);
      if(ss.length == 0){
        this.form.stationId = this.selectedDevices[0].id;
        this.initEnergys();
      }
    },
    handleEdit: function(params){
      let start = new Date(this.form.start).format('yyyy-MM-dd');
      let end = new Date(this.form.end).format('yyyy-MM-dd');
      this.editItem = {id: params.paramId, name: params.name, start, end};
      this.showEditModal = true;
    },
    exportData: function(){
      let ss = this.selectedDevices.filter(p => p.id == this.form.stationId);
      if(ss.length == 0)return;
      this.$refs.chkTable.exportData({
        filename: this.showLang('energy.site.light.energy',ss[0].name)+'.xlsx',
        columnFilterMethod ({ column }) {
          return ['seq', 'name', 'date', 'minsName', 'beforeLight', 'afterLight', 'save', 'money', 'rate'].includes(column.property)
        },
        data: this.$refs.chkTable.getCheckboxRecords(),
        footerFilterMethod: function(params){
          //这里可以通过以下方式修改footer的导出内容，比如重新计算汇总值和记录数等
          params.items[1] = params.items[1]+'1';
          // console.log('show footer params', params)
          return false;
        }
      })
    },
    initEnergys: function () {
      if(this.form.stationId <= 0){
        this.$set(this, 'energys', []);
        return;
      }
      let start = new Date(this.form.start).format('yyyy-MM-dd');
      let end = new Date(this.form.end).format('yyyy-MM-dd');
      this.searchLoading = true;
      this.$axios.post(`//${this.domains.trans}/station/analyse/QueryStationEnergyLight`, { stationId: this.form.stationId, start, end}).then(res => {
        this.searchLoading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'energys', res.data);
          let mins = 0, before = 0, usage = 0, save = 0, money = 0, avg = 0;
          res.data.map(p => {
            mins += p.mins;
            before += p.beforeLight;
            usage += p.afterLight;
            save += p.save;
            money += p.money;
          });
          avg = this.img.val(save * 100 / before, 2);
          let minsName = `${parseInt(mins/60)}小时${mins%60}分钟`
          this.$set(this, "footerData", [
             [this.showLang('com.export.footer.name'), res.data.length+this.showLang('energy.record'), '', '', minsName, this.img.val(before, 2)+'KWh', this.img.val(usage, 2)+'KWh', this.img.val(save, 2)+'KWh', this.img.val(money, 2)+this.showLang('com.unit.cash'), `${avg}%`],
            
            // [2, 3, 4, 5]
          ]);
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 5;
    },
  }
}
</script>
<style scoped>
>>> .footer-cell-class {
  /* color: red; */
  font-weight: bold;
}
.switchType {
  height: 30px;
  display: flex;
  flex-direction: initial;
  margin: 10px 0 0 10px;
}
.switchType-tab {
  width: 68px;
  height: 30px;
  background: #eef4f9;
  color: #324252;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.fillet-left {
  border-top-left-radius: 6px;
}
.fillet-right {
  border-top-right-radius: 6px;
}
.switchType-tab.tab-active {
  background: #3880fc;
  color: #ffffff;
}
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  margin-top: 10px;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: none;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
.search-data-chart {
  width: calc(100% - 20px);
  margin: 0 auto;
  height: 500px;
  position: relative;
}
.colorDeputy {
  position: absolute;
  right: 40px;
  display: flex;
}
.deputy-list {
  margin-right: 10px;
}
.deputy-list i {
  display: inline-block;
  width: 5px;
  height: 5px;
}
</style>