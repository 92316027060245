<template>
<div class="config-station-container">
  <div class="station-search-area">
    <span>{{showLang('com.tab.title')}}：</span>
    <Input type="text" v-model="filter.name" clearable placeholder="灯控网关名称" style="width: 200px; margin-right: 10px;"></Input>
    <Button size="default" type="primary" @click="getList" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
    <template v-for="(cmd, idx) in commands">
      <Button v-if="idx < cmdcnt" :key="idx" type="primary" size="default" style="margin-right: 10px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
    </template>
    <Dropdown style="margin-right: 10px" v-if="commands.length > cmdcnt">
      <Button type="primary">
        {{showLang('com.op.other')}}<Icon type="ios-arrow-down"></Icon>
      </Button>
      <div slot="list" class="btns-flex" style="width: 500px">
        <template v-for="(cmd, idx) in commands">
          <Button v-if="idx >= cmdcnt" :key="idx" type="primary" size="default" style="margin: 3px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
        </template>
      </div>
    </Dropdown>
  </div>
  <div class="station-data-area" ref="table">
    <vxe-table ref="chkTable" border show-overflow show-header-overflow :data="list" :height="tabHeight" :row-config="{isHover: true}">
      <vxe-column width="50" type="checkbox" field="checkbox"></vxe-column>
      <vxe-column title="状态" width="60">
        <template #default="params">
          {{getStatusName(params)}}
        </template>
      </vxe-column>
      <vxe-column field="name" title="名称" width="150"></vxe-column>
      <!-- <vxe-column field="poleName" title="所在灯杆" width="200"></vxe-column> -->
      <vxe-column field="deviceTypeName" title="设备类型" width="150"></vxe-column> 
      <vxe-column field="code" title="通信ID" width="120"></vxe-column>
      <vxe-column field="iccid" width="160" title="iccid"></vxe-column>
      <vxe-column field="imei" width="160" title="imei"></vxe-column>
      <vxe-column field="hardware" title="硬件版本" width="160"></vxe-column>
      <vxe-column field="software" title="软件版本" width="200"></vxe-column>
      <vxe-column title="执行结果" fixed="right">
        <template #default="params">
          <img v-if="params.row._cmd == 4215 && params.row._status == 9" :src="`data:image/jpeg;base64,${params.row.img}`" />
          <template v-else>
            {{getCmdStatus(params.row)}}
          </template>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
  </div>
  <ModalCommandArgs v-model="showArgModal" :command="editItem" @saved="commandArgSaved" />
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalCommandArgs from '@/views/light/road/monitor/station/control/ModalArgEdit'
export default {
  name: 'PoleBroadIndex',
  components:{
    ModalCommandArgs,
  },
  data () {
    return {
      clearOldLog: false,
      showEditModal: false,
      showArgModal: false,
      tabHeight: 300,
      editItem: {isAdd: true, index: -1, data: {}},
      filter: {
        groupId: 0,
        poleId: '',
        name: '',
        index: 1,
        size: 20
      },
      list: [],
      total: 0,
      cmdcnt: 11,
      commands: [],
      cmdResults: {},
      cmdTimer: null,
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['weeks', 'pageSizes', 'pageLayouts']),
    ...mapState('group', ['poleSelectedNode']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    poleSelectedNode(){
      this.getList();
    },
  },
  mounted: function(){
    setTimeout(() => {
      this.setTableHeight();
    }, 200);
    this.getCommandTypes();
    this.cmdTimer = setInterval(this.setCmdResult, 500);
    window.eventBus.$on('paramCommandComing', params => {
      // console.log('get cmd reply', params);
      // this.cmdResults[params.cmdId] = params;
      console.log('get cmd reply', params);
      params.time = new Date().getTime();
      this.cmdResults[params.commandId] = params;
    })
    this.getList();
  },
  destroyed: function () {
    window.eventBus.$off('paramCommandComing');
    clearInterval(this.cmdTimer);
  },
  methods: {
    getCommandTypes: function(){
      this.$axios.post(`//${this.domains.trans}/pole/command/QueryGatewayCmds`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'commands', res.data);
        }
      });
    },
    setCmdResult: function () {
      for (let cmdId in this.cmdResults) {
        let items = this.list.filter(p => p._cmdId == cmdId);
        if(items.length == 0){
          if(new Date().getTime() - this.cmdResults[cmdId].time > 100000){
            console.log('cmd id time out');
            delete this.cmdResults[cmdId];
          }
          continue;
        }
        let reply = this.cmdResults[cmdId];
        let item = items[0];
        this.$set(item, '_status', reply.status);
        // this.$set(ds[0], 'result', item.message);
        this.$set(item, '_content', reply.content);
        this.$set(item, '_message', this.getCmdStatus(item));
        delete this.cmdResults[cmdId]
      }
    },
    getStatusName: function(params){
      if(params.row.online){
        // if(params.row.running){
        //   if(!params.row.isTask){
        //     return '播放媒体：' + params.row.mediaName;
        //   }else{
        //     return '播放任务：' + params.row.taskName;
        //   }
        // }else{
        //   return '关屏';
        // }
        return '在线';
      }else{
        return '离线';
      }
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    getBrightName: function(params){
      if(params.row.bright == 0)return '自动亮度';
      return `${params.row.bright}%`;
    },
    commandArgSaved: function(params){
      console.log('click cmd', params)
      this.showArgModal = false;
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要控制的终端');
        return;
      }
      let ids = chks.map(p => p.id);
      this.sendCommand(ids, params.code, params.args);
    },
    clickCmd: function (params) {
      console.log('click cmd', params)
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要控制的终端');
        return;
      }
      if(params.needArgs || params.needPswd){
        this.editItem = { paramsCode: params.code, code: params.code, name: params.name, pswd: params.needPswd, args: {} }
        this.showArgModal = true;
        // this.editItem = params;
        // this.editItem.pswd = params.needPswd;
        // this.showArgModal = true;
        return;
      }
      let ids = chks.map(p => p.id);
      this.sendCommand(ids, params.code, {});
    },
    sendCommand: function(ids, code, args){
      for(let id of ids){
        let items = this.list.filter(p => p.id == id);
        if(items.length == 0)continue;
        let item = items[0];
        this.$set(item, '_cmd', code);
        this.$set(item, '_cmdId', '');
        this.$set(item, '_message', "指令已创建");
        this.$set(item, '_content', null);
        this.$set(item, '_status', 0);
      }
      setTimeout(() => {
        this.$axios.post(`//${this.domains.trans}/station/command/SendGatewayNew`, {code, list: ids, args}).then(res => {
          if(res.code != 0){
            // this.$Message.error(res.data);
          }else{
            // console.log('send led cmd result', res.data, this.list)
            for(let cmd of res.data.list){
              let items = this.list.filter(p => p.id == cmd.id);
              if(items.length == 0)continue;
              let item = items[0];
              let message = '指令已进入队列', status = 1, cmdId = cmd.message;
              if(!cmd.success){
                message = cmd.message;
                status = 8;
                cmdId = '';
              }else if(code == 3611 || code == 3612){
                message = cmd.message;
                status = 9;
                cmdId = '';
              }
              this.$set(item, '_cmd', code);
              this.$set(item, '_cmdId', cmdId);
              this.$set(item, '_message', message);
              this.$set(item, '_content', null);
              this.$set(item, '_status', status);
            }
          }
        });
      }, 300);
    },
    getList: function(){
      let groupId = '', poleId = '';
      if(this.poleSelectedNode.isLeaf){
        poleId = this.poleSelectedNode.id;
      }else{
        groupId = this.poleSelectedNode.id;
      }
      this.filter.groupId = groupId;
      this.filter.poleId = poleId;
      this.$axios.post(`//${this.domains.trans}/pole/command/QueryGatewayList`, this.filter).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.total = res.data.count;
          res.data.list.map(p => {
            p._cmd = '';
            p._cmdId = '';
            p._message = '';
            p._content = {};
            p._status = 0;
          });
          this.$set(this, 'list', res.data.list);
          // this.$refs.chkTable.toggleAllSelection();
        }
      });
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 43;
    },
    getCmdStatus: function (cmd) {
      switch (cmd._status) {
        case 0: return ``;
        case 2: return `等待设备应答`;
        case 4: return `执行中：${cmd._content.pkg} / ${cmd._content.total}`;
        case 5: return `失败重试：${cmd._message}`;
        case 7: return `指令超时：${cmd._message}`;
        case 8: return `执行失败：${cmd._message}`;
        case 9: {
          // console.log('getCmdStatus', cmd)
          console.log('cmd status', cmd)
          switch (cmd._cmd) {
            case 'getclock': return `${this.showLang('com.ins.success')}：${cmd._content.nowTime}`;
            case 'getInfo': {
              this.$set(cmd, 'hardware', cmd._content.hardware);
              this.$set(cmd, 'software', cmd._content.software);
              return `${this.showLang('com.site.hw')}：${cmd._content.hardware}，${this.showLang('com.site.sw')}：${cmd._content.software}，${this.showLang('com.user.manufacturer')}：${cmd._content.manufacture}`;
            }
            case 'getSimInfo': {
              this.$set(cmd, 'iccid', cmd._content.iccid);
              this.$set(cmd, 'imei', cmd._content.imei);
              return `ICCID：${cmd._content.iccid}，IMEI：${cmd._content.imei}，RSSI：${cmd._content.rssi}`;
            }
            case 'getWhite': {
              if (cmd.type != 'st6038') {
                return `${this.showLang('com.ins.success')}：${cmd._content.list.join(', ')}`;
              }
              let cc = [];
              for (let item of cmd._content.list) {
                cc.push(`${item.id}:${item.no}`);
              }
              return `${this.showLang('com.ins.success')}：${cc.join(', ')}`;
            }
            case 'getNetwork': return `IP:${cmd._content.host}, ${this.showLang('com.ins.port')}:${cmd._content.port}, ${this.showLang('com.ins.heartbeat')}:${cmd._content.heart}s`;
            default: return `执行成功`;
          }
        }
        default: return `${cmd._message}`;
      }
    },
  }
}
</script>
<style scoped>
.config-station-container{
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  /* padding: 10px; */
  background-color: white;
  border-radius: 8px;
}
.station-search-area{
  height: 42px;
  flex: none;
  border: solid 1px #DCDEE2;
  /* border-radius: 6px; */
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.row-space{
  width: 100px;
  flex: auto;
}
.station-data-area{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>