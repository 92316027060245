<template>
  <div class="device-item-content" ref="table">
    <!-- <div class="device-search">
      <span>环境状态</span>
      <Select v-model="state" class="tool-type">
        <template v-for="item in states">
          <Option :value="item.code" :key="item.code">{{item.name}}</Option>
        </template>
      </Select>
      <a href="javascript:void(0);" class="cameraInfo-search-but"><img :src="img.pole.speak.lookup" />查询</a>
    </div> -->
    <div class="device-table">
      <u-table :data="devices" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="tabHeight" stripe>
        <u-table-column prop="name" label="状态" sortable fit align="center">
          <template slot-scope="scope">
            <img :src="getImage(scope.row)" />
          </template>
        </u-table-column>
        <u-table-column prop="name" label="名称" sortable fit align="center">
        </u-table-column>
        <u-table-column prop="branch" label="灯杆编号" sortable fit align="center">
          <template slot-scope="scope">
            {{scope.row.stationName}}
          </template>
        </u-table-column>
        <u-table-column prop="type" label="关联设备" sortable width="200">
          <template slot-scope="params">
            {{params.row.connectTypeName}}
          </template>
        </u-table-column>
        <u-table-column prop="da" label="风向角度" sortable width="105">
          <template slot-scope="params">
            {{img.val(params.row.lastData.da, 0)}}
          </template>
        </u-table-column>
        <u-table-column prop="dl" label="风向档位" sortable width="105">
          <template slot-scope="params">
            {{img.val(params.row.lastData.dl, 0)}}
          </template>
        </u-table-column>
        <u-table-column prop="spd" label="风速" sortable width="80">
          <template slot-scope="params">
            {{img.val(params.row.lastData.spd, 1)}}
          </template>
        </u-table-column>
        <u-table-column prop="pow" label="风力" sortable width="80">
          <template slot-scope="params">
            {{img.val(params.row.lastData.pow, 0)}}
          </template>
        </u-table-column>
        <u-table-column prop="h" label="湿度" sortable width="80">
          <template slot-scope="params">
            {{img.val(params.row.lastData.h, 1)}}
          </template>
        </u-table-column>
        <u-table-column prop="t" label="温度" sortable width="80">
          <template slot-scope="params">
            {{img.val(params.row.lastData.t, 1)}}
          </template>
        </u-table-column>
        <u-table-column prop="n" label="噪声" sortable width="80">
          <template slot-scope="params">
            {{img.val(params.row.lastData.n, 1)}}
          </template>
        </u-table-column>
        <u-table-column prop="pm25" label="PM2.5" sortable width="100">
          <template slot-scope="params">
            {{img.val(params.row.lastData.pm25, 0)}}
          </template>
        </u-table-column>
        <u-table-column prop="pm10" label="PM10" sortable width="90">
          <template slot-scope="params">
            {{img.val(params.row.lastData.pm10, 0)}}
          </template>
        </u-table-column>
        <u-table-column prop="pre" label="大气压值" sortable width="110">
          <template slot-scope="params">
            {{img.val(params.row.lastData.pre, 1)}}
          </template>
        </u-table-column>
        <u-table-column prop="lux" label="光照度" sortable width="90">
          <template slot-scope="params">
            {{img.val(params.row.lastData.lux, 0)}}
          </template>
        </u-table-column>
        <u-table-column prop="install" label="最后通信时间" sortable width="170">
          <template slot-scope="params">
            {{!params.row.fireTime ? '-' : new Date(params.row.fireTime).format('yyyy-MM-dd HH:mm:ss')}}
          </template>
        </u-table-column>
        <u-table-column width="220" label="操作" align="center" fixed="right">
          <template slot-scope="scope">
            <a href="javascript:void(0);" class="cameraInfo-tab-but color1" @click="history(scope)">查看详情</a>
          </template>
        </u-table-column>
      </u-table>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'deviceList',
  components: {
  },
  props: {
    devices: {
      type: Array,
      default(){return []},
    },
  },
  data() {
    return {
      state: "s4",
      states: [
        { name: "全部", code: "s4" },
        { name: "在线", code: "s1" },
        { name: "离线", code: "s2" },
        { name: "报警", code: "s3" },
      ],
      tabData: [],
      tabHeight: 600,
      historyShow: false,
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['deviceTypes', 'productCodes', 'powerBoxTypes', 'mapCenter', 'poleType']),
  },
  watch: {},
  mounted: function () {
    setTimeout(this.setTableHeight, 200);
  },
  methods: {
    history(el) {
      this.$store.commit('group/selectNodeById', { nodeId: el.row.stationId, type: this.productCodes.station });
      this.$store.commit('auth/switchStationTabName', 'env');
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 100;
    },
    getImage: function (item) {
      if (!item.online) return this.img.pole.icons.env.offline;
      if (item.alarm) return this.img.pole.icons.env.alarm;
      return this.img.pole.icons.env.online;
    },
  }
}
</script>
<style scoped>
.device-item-content {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 30px;
}
.tool-type {
  width: 168px;
  height: 32px;
  margin: 0 10px 0 5px;
}
.cameraInfo-search-but {
  padding: 3px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
  margin-left: 19px;
}
.cameraInfo-search-but img {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 2px;
}
.cameraInfo-tab-but {
  padding: 0px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
  margin: 0 5px;
}
.cameraInfo-tab-but img {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 3px;
}

.cameraInfo-tab-but.color1 {
  background: #21a4f8;
}
.cameraInfo-tab-but.color2 {
  background: #3cbf6e;
}
.cameraInfo-tab-but.color3 {
  background: #fd5353;
}
.cameraInfo-tab-but.color4 {
  background: #6485fd;
}
.device-table {
  margin-top: 20px;
}
.device-table /deep/ .plTableBox .el-table th {
  padding: 5px 0;
  background: #eaf1f9;
}
.device-table /deep/ .plTableBox .el-table td {
  padding: 5px 0;
}
</style>
