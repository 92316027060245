<template>
<bm-control anchor="BMAP_ANCHOR_TOP_RIGHT" :offset="{ width: offset.x, height: offset.y }">
  <div class="item-status-filter">
    <div v-for="(item, idx) in status" :key="idx" :class="['filter-item', statusSelected == item.code ? 'filter-active' : '']" @click="switchFilter(item)">
      <img :src="getStatusImgType(item.code)" />
      {{ item.name }} : <span :class="['status-color-text', `status-color-${item.code}`]">{{counts[item.code]}}</span>
    </div>
  </div>
</bm-control>
</template>
<script>
import { mapState } from "vuex";
import { BmControl } from "@/components/vue-baidu-map/components";
export default {
  name: "ToolStatusTypeSelect",
  components: {
    BmControl,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    offset: {
      type: Object,
      default(){return {x: 0, y: 0}}
    },
    counts: {
      type: Object,
      default(){return {
        online: 80,
        offline: 80,
        alarm30: 80,
        stop: 80,
      }},
    },
  },
  data() {
    return {
      status: [
        { code: "online", name: "在线" },
        { code: "offline", name: "离线" },
        { code: "alarm30", name: "报警" },
        { code: "stop", name: "维护" },
      ],
      statusSelected: this.value,
    };
  },
  computed: {
    ...mapState("auth", ["currentMenuName"]),
  },
  watch: {
    value(newVal){
      this.statusSelected = newVal;
    },
    statusSelected(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
  },
  methods: {
    getStatusImgType: function (code) {
      // console.log('get img type', code, this.currentMenuName)
      let imgs = this.img.pole.icons[this.currentMenuName];
      if (!imgs) return this.img.pole.icons.pole[code];
      return imgs[code];
    },
    switchFilter: function (item) {
      if(this.statusSelected == item.code){
        this.statusSelected = '';
      }else{
        this.statusSelected = item.code
      }
    },
  },
};
</script>
<style scoped>
.status-color-text{
  /* border: solid 1px red; */
  font-size: 18px;
  font-family: Bahnschrift;
  font-weight: 400;
  margin-left: 5px;
  height: 23px;
}
.status-color-online{
  color: #2ACC81;
}
.status-color-offline{
  color: #8793A5;
}
.status-color-alarm30{
  color: #FD5353;
}
.status-color-stop{
  color: #8793A5;
}
.item-status-filter {
  /* width: 325px; */
  height: 45px;
  background-color: white;
  display: flex;
}
.filter-item {
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
  cursor: pointer;
  user-select: none;
}
.filter-item img {
  width: 23px;
  height: 24px;
  margin-right: 5px;
}
.filter-active {
  color: #007eff;
}
</style>