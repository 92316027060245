<template>
  <div class="cameraInfo-control">
    <div class="cameraInfo-title">摄像头信息</div>
    <div class="cameraInfo-con">
      <div class="cameraInfo-con-box">
        <span>设备：</span>
        <Select v-model="tool" class="tool-type">
          <template v-for="item in tools">
            <Option :value="item.code" :key="item.code">{{item.name}}</Option>
          </template>
        </Select>
        <a href="javascript:void(0);" class="cameraInfo-con-box-but">搜索</a>
      </div>
      <div class="cameraInfo-con-box">
        <span>灯杆：</span>
        <span class="cameraInfo-con-text">龙华街道大浪社区02号灯杆</span>
      </div>
      <div class="cameraInfo-con-box">
        <span>站点：</span>
        <span class="cameraInfo-con-text">龙华街道大浪社区02号灯杆</span>
      </div>
      <div class="cameraInfo-con-box">
        <span>状态：</span>
        <span class="cameraInfo-con-text green">在线</span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  computed: {

  },
  data() {
    return {
      tool: "s1",
      tools: [
        { name: "车牌识别-南灯杆", code: "s1" },
        { name: "车牌识别-北灯杆", code: "s2" },
        { name: "车牌识别-西灯杆", code: "s3" },
        { name: "车牌识别-东灯杆", code: "s4" },
      ]
    }
  },
  watch: {

  },
  mounted: function () {

  },
  methods: {

  }
}
</script>
<style scoped>
.cameraInfo-control {
  height: calc(100%);
  padding: 20px 30px;
}
.cameraInfo-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  margin-bottom: 15px;
  font-weight: bold;
}
.cameraInfo-con-box-but {
  display: inline-block;
  width: 47px;
  height: 32px;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 32px;
  margin-left: 19px;
}
.tool-type {
  width: 222px;
  height: 32px;
}
.cameraInfo-con {
  height: calc(100% - 41px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cameraInfo-con-box {
  height: 32px;
  line-height: 32px;
}
.cameraInfo-con-text {
  width: 222px;
  height: 32px;
  background: #ebf1f7;
  border-radius: 4px;
  display: inline-block;
  line-height: 32px;
  text-indent: 10px;
}
.green {
  color: #2acc81;
}
</style>
