<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <table class="table-style" border="1" cellspacing="0">
        <tr v-for="(item, idx) in form.levels" :key="idx">
          <td style="width: 100px;text-align:right;">{{showLang('com.data.interval')}}{{item.no}}：</td>
          <td style="width: 200px">
            <Input type="number" v-model="item.low" style="width: 200px; margin: 0 10px;">
            <span slot="prepend">{{showLang('com.data.lower.val')}}</span>
            <span slot="append">A</span>
            </Input>
          </td>
          <td style="width: 200px">
            <Input type="number" v-model="item.high" style="width: 200px; margin: 0 10px;">
            <span slot="prepend">{{showLang('com.data.upper.val')}}</span>
            <span slot="append">A</span>
            </Input>
          </td>
          <td style="width: 200px">
            <Input type="number" v-model="item.diff" style="width: 200px; margin: 0 10px;">
            <span slot="prepend">{{showLang('power.diff')}}</span>
            <span slot="append">A</span>
            </Input>
          </td>
          <td style="width: 200px">
            <Input type="number" v-model="item.rate" style="width: 200px; margin: 0 10px;">
            <span slot="prepend">{{showLang('power.diff.ratio')}}</span>
            <span slot="append">%</span>
            </Input>
          </td>
        </tr>
      </table>
    </div>
    <div class="search-data-header">
      <div class="search-data-query">
        {{showLang('com.site.name')}}：
         <Input  type="text"  v-model="form.sname" placeholder="" style="width:150px; margin-right: 6px;"></Input>
        {{showLang('com.attribute.name')}}：
         <Input  type="text"  v-model="form.pname" placeholder="" style="width:150px; margin-right: 6px;"></Input>
        {{showLang('cmd.phase.mark')}}：
         <Input  type="text"  v-model="form.flag" placeholder="" style="width:150px; margin-right: 6px;"></Input>
        {{showLang('com.op.query.time')}}：
        <DatePicker v-model="form.last" type="datetime" format="yyyy-MM-dd HH:mm" :time-picker-options="{steps: [1, 15]}" style="width: 160px;margin-right: 6px;" />
        {{showLang('com.date.Compare.Day')}}：
        <Input ref="days" type="number" maxlength="32" v-model="form.days" :placeholder="showLang('com.date.Compare.Day')"
          style="width: 100px; margin-right: 6px;">
        <span slot="append">{{showLang('com.date.day')}}</span>
        </Input>
        {{showLang('com.date.Offset.period')}}：
        <Select :placeholder="showLang('save.select')" v-model="form.offset" style="width: 150px; margin-right: 6px;">
          <template v-for="n in 5">
            <Option :value="-1*(6-n)" :key="-1*n">{{showLang('com.date.front.period',6 - n)}}</Option>
          </template>
          <Option :value="0">{{showLang('com.date.no.offset')}}</Option>
          <template v-for="n in 5">
            <Option :value="n" :key="n">{{showLang('com.date.last.period',n)}}</Option>
          </template>
        </Select>
        <Button v-if="funCodes('sccv')" type="info" :loading="loading" @click="getList"
          style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
        <Button v-if="funCodes('scce')" type="success" @click="exportData">{{showLang('com.op.export')}}</Button>
      </div>
    </div>
    <div class="search-data-content" ref="table1">
      <uTable :option="tabData" :tabHead="tabHead" :tabHeight="tabHeight" @rowClick="clickRow"></uTable>
      <Spin size="large" fix v-if="loading"></Spin>
    </div>
    <ModalCurrentHistory v-model="showHistoryModal" :item="editItem" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import uTable from '../table/Index'
import exportXlsx from '@/components/xlsx/exportXlsx'
import ModalCurrentHistory from './ModalCurrentHistory'
export default {
  name: 'CheckNew',
  components: {
    uTable,
    ModalCurrentHistory,
  },
  props: {
  },
  data() {
    return {
      loading: false,
      curTab: 'group',
      tabHeight: 1,
      editItem: {},
      showHistoryModal: false,
      tabData: [//列表数据
      ],
      tabHead: {
        stationName: "站点名称",
        paramName: "属性名称",
        flag: '相位标识',
        curTime: '采集时间',
        cur: "电流值(A)",
        chkTime: '对比时间',
        chk: "对比值(A)",
        diff: "差值(A)",
        rate: "差值比例",
        link: '查看趋势'
      },
      form: {
        last: '',
        sname: '',
        pname: '',
        flag: '',
        days: 5,
        offset: 0,
        levels: [
          {
            no: 1,
            low: 0,
            high: 50,
            rate: 20,
            diff: 5
          },
          {
            no: 2,
            low: 50,
            high: 100,
            rate: 20,
            diff: 10
          },
          {
            no: 3,
            low: 100,
            high: 150,
            rate: 20,
            diff: 15
          },
          {
            no: 4,
            low: 150,
            high: 200,
            rate: 20,
            diff: 20
          },
          {
            no: 5,
            low: 200,
            high: 1000,
            rate: 20,
            diff: 25
          },
        ]
      },
      history: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now = new Date();
    let mins = now.getMinutes();
    mins = parseInt(mins / 15) * 15;
    now.setMinutes(mins);
    this.form.last = now.format('yyyy-MM-dd HH:mm:00');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.tabHead = {
      stationName:this.showLang('com.site.name') ,
      paramName:this.showLang('com.attribute.name') ,
      flag:this.showLang('cmd.phase.mark') ,
      curTime:this.showLang('com.date.Collection') ,
      cur:this.showLang('com.lighting.I.val')+'(A)',
      chkTime:this.showLang('com.state.ratio.time') ,
      chk:this.showLang('com.state.contrast') +'(A)',
      diff:this.showLang('power.diff') +'(A)',
      rate:this.showLang('power.diff.ratio') ,
      link:this.showLang('com.date.view.Trends') 
    }
  },
  destroyed: function () {
  },
  methods: {
    clickRow: function (row) {
      this.editItem = row;
      this.showHistoryModal = true;
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 5;
    },
    exportData() {
      let dataArr = JSON.parse(JSON.stringify(this.tabData));
      let name = this.showLang('history.data.switch.export');
      exportXlsx(dataArr, this.tabHead, name);
    },
    getList() {
      this.loading = true;
      this.form.last = new Date(this.form.last).format('yyyy-MM-dd HH:mm:ss');
      this.$axios.post(`//${this.domains.trans}/station/analyse/QueryCurrentCheck`, this.form).then(res => {
        this.loading = false;
        if (res.code == 0) {
          res.data.map(p => {
            p.diff = p.diff.toFixed(2); //this.img.val(p.diff, 2);
            p.rate = `${p.rate.toFixed(2)} %`; //this.img.val(p.rate, 2);
          });
          this.$set(this, 'tabData', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.table-style,
.table-style td {
  border: solid 1px rgba(7, 156, 156, 0.356);
}
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  /* height: 40px; */
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
</style>