<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">{{item.stationName}} - {{item.paramName}} - {{item.name}}</div>
    <div class="modal-content">
      <div class="modal-query-header">
        {{ showLang('com.date.start') }}：
        <DatePicker type="date" v-model="filter.start" format="yyyy-MM-dd" style="width: 115px; margin-right: 10px"></DatePicker>
        {{ showLang('com.date.end') }}：
        <DatePicker type="date" v-model="filter.end" format="yyyy-MM-dd" style="width: 115px; margin-right: 10px"></DatePicker>
        <Button type="primary" @click="search" style="margin-right: 10px">{{showLang('com.op.query')}}</Button>
        <Button type="primary" @click="searchPre" style="margin-right: 10px">上一时间</Button>
        <Button type="primary" @click="searchNext" style="margin-right: 10px">下一时间</Button>
        <span style="float:right;margin-right: 10px;">参考时间：{{filter.time}}</span>
      </div>
      <div class="modal-data-area">
        <table id="tabMainDataHistory" class="table">
          <tr>
            <td class="column-header" style="width: 50px">{{showLang('com.export.cn.seq')}}</td>
            <td class="column-header" style="width: 200px">{{showLang('com.date.occurrence')}}</td>
            <td class="column-header" style="width:120px">{{showLang('cmd.project.A')}}{{showLang('cmd.data.measurement')}}</td>
            <td class="column-header" v-if="item.type == 23" style="width:120px">{{showLang('cmd.project.B')}}{{showLang('cmd.data.measurement')}}</td>
            <td class="column-header" v-if="item.type == 23" style="width:120px">{{showLang('cmd.project.C')}}{{showLang('cmd.data.measurement')}}</td>
            <!-- <td class="column-header" style="width:120px" >对比值</td> -->
          </tr>
          <template v-for="(branch, index) in history">
            <tr :key="`data-${index}-0`">
              <td class="column-content" style="width: 50px">{{index+1}}</td>
              <td class="column-content">{{branch.fireTime}}</td>
              <td class="column-content" v-if="item.type == 23">{{img.val(branch.a, 2)}} A</td>
              <td class="column-content" v-if="item.type == 23">{{img.val(branch.b, 2)}} A</td>
              <td class="column-content" v-if="item.type == 23">{{img.val(branch.c, 2)}} A</td>
              <td class="column-content" v-if="item.type == 32">{{img.val(branch.a, 2)}} W</td>
            </tr>
          </template>
        </table>
      </div>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalCurrentHistory',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      timer: 0,
      filter: {
        paramId: 0,
        time: '',
        type: 0,
        start: '',
        end: '',
      },
      history: [],
      loading: false,
      config: {
        title: `电流对比历史数据`
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        // console.log('query compare list', this.item)
        this.filter.paramId = this.item.paramId;
        this.filter.time = this.item.startTime;
        this.filter.type = this.item.type;
        let now = new Date();
        this.filter.end = now.format('yyyy-MM-dd');
        now.setDate(now.getDate() - 10);
        this.filter.start = now.format('yyyy-MM-dd');
        this.search();
      }
    },
    device() {
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapGetters('auth', ['showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  methods: {
    searchNext: function(){
      var cur = new Date(this.filter.time);
      cur.setMinutes(cur.getMinutes() + 15);
      this.filter.time = cur.format('yyyy-MM-dd HH:mm:ss');
      this.getList();
    },
    searchPre: function(){
      var cur = new Date(this.filter.time);
      cur.setMinutes(cur.getMinutes() - 15);
      this.filter.time = cur.format('yyyy-MM-dd HH:mm:ss');
      this.getList();
    },
    search: async function () {
      this.filter.time = this.item.startTime;
      this.getList();
    },
    getList: function(){
      this.filter.start = new Date(this.filter.start).format("yyyy-MM-dd");
      this.filter.end = new Date(this.filter.end).format("yyyy-MM-dd");
      this.$axios.post(`//${this.domains.trans}/station/alarm/QueryCompareList`, this.filter).then(res =>{
        if (res.code !== 0) {
          return;
        }
        this.$set(this, "history", res.data);
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.modal-content {
  /* border: solid 1px red; */
  height: 600px;
  display: flex;
  flex-direction: column;
}
.modal-query-header {
  height: 40px;
  flex: none;
}
.modal-chart-area {
  /* border: solid 1px red; */
  height: 200px;
  flex: none;
}
.modal-data-area {
  /* border: solid 1px red; */
  height: 300px;
  flex: auto;
  overflow: auto;
}
.table {
  border-collapse: collapse;
  width: 100%;
}
.table-header {
  /* width: 1064px; */
  height: 47px;
  text-align: center;
  background: rgba(10, 26, 68, 0.8);
  box-shadow: inset 0px 0px 24px 0px #04559d;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #52fbfe;
  border: solid 1px black;
}
.table-header img {
  text-align: center;
  vertical-align: middle;
  margin-right: 3px;
}
.column-header {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  border: solid 1px black;
  text-align: center;
  height: 30px;
  /* width: 130px; */
  /* border-collapse: collapse; */
  background: #183992;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.column-header img {
  vertical-align: middle;
  margin-right: 10px;
}
.column-content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #050505;
  background: #2c9ef55e;
  border: solid 1px black;
  text-align: center;
  height: 35px;
  /* width: 130px; */
}
</style>