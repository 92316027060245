<template>
  <div class="power-time-list" ref="table1">
    <vxe-table :empty-text="showLang('com.data.no')"  border show-overflow show-header-overflow :data="list" :height="tabHeight" :row-config="{isHover: true}">
      <vxe-column field="name" :title="showLang('com.tab.title')"></vxe-column>
      <vxe-column field="isDefault" :title="showLang('plan.is.default')">
        <template #default="params">
          {{params.row.isDefault ? '是' : '否'}}
        </template>
      </vxe-column>
      <vxe-column width="250" :title="showLang('com.right.nav.operate')" fixed="right">
        <template #header>
          <Button v-if="funCodes('tba')" size="small" type="primary" @click="handleAdd" style="margin-right: 5px">{{showLang('com.op.add')}}</Button>
          <Button v-if="funCodes('tbv')" size="small" type="primary" @click="getList"
            style="margin-right: 5px">{{showLang('com.op.refresh')}}</Button>
          <Button v-if="funCodes('tbv')" size="small" type="primary" @click="downloadTemplate"
            style="margin-right: 5px">{{showLang('com.op.template')}}</Button>
          <Button v-if="funCodes('tpv')" size="small" type="primary" @click="handleImport"
            style="margin-right: 5px">{{showLang('com.op.import.all')}}</Button>
        </template>
        <template #default="params">
          <Button v-if="funCodes('tbe')" size="small" type="info" @click="handleEdit(params)"
            style="margin-right: 5px">{{showLang('com.op.edit')}}</Button>
          <Button v-if="funCodes('tbd')" size="small" type="error" @click="handleDelete(params)"
            style="margin-right: 5px">{{showLang('com.op.del')}}</Button>
        </template>
      </vxe-column>
    </vxe-table>
    <ModalTimeTable v-model="showEditModal" :item="editItem" @saved="getList" />
    <ModalTableImport v-model="showImportModal" :type="4" @saved="getList" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalTimeTable from './ModalTimeTable'
import ModalTableImport from '../ModalTableImport'
export default {
  name: 'PowerBoxTimeTableIndex',
  components: {
    ModalTimeTable,
    ModalTableImport,
  },
  data() {
    return {
      showEditModal: false,
      showImportModal: false,
      tabHeight: 300,
      editItem: { isAdd: true, index: -1, data: {} },
      list: [],
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    this.getList();
  },
  methods: {
    handleImport: function () {
      this.showImportModal = true;
    },
    downloadTemplate() {
      this.$axios.request({
        url: `//${this.domains.trans}/station/plan/YearPlanTemplate`,
        method: 'post',
        data: { type: 4 },
        responseType: 'blob'
      }).then(res => {
        // console.log('export', res)
        // const data = res.data;
        let url = window.URL.createObjectURL(res);
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = this.showLang('timetable.chronology') + '.xlsx'
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url)
      })
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 10
    },
    getList: function () {
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryBjYearList`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          this.$set(this, 'list', []);
        } else {
          this.$set(this, 'list', res.data);
        }
      });
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, index: -1, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, index: params.index, data: params.row };
      this.showEditModal = true;
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', params.row.name),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/plan/DeleteBjYear`, { id: params.row.id }).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.getList();
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.power-time-list {
  /* border: solid 1px red; */
  height: 600px;
  flex: auto;
}
</style>