<template>
  <div class="station-search-container">
    <div class="station-search-tabs">
      <div v-for="(item, idx) in tabs" :key="idx" :class="['station-search-tab', curTab == item.code ? 'tab-active' : '']" @click="switchTab(item.code)">{{showLang(item.lang)}}</div>
    </div>
    <div class="station-search-content">
      <template v-for="(item, idx) in tabs">
        <component v-if="item.code == curTab" :key="idx" v-bind:is="item.com" :sources="sources"></component>
      </template>
    </div>
  </div>
</template>
<script>
import PowerboxCommandLog from './Powerbox'
import OutputCommandLog from './Output'
import CurrentCommandLog from './Current'
import GatewayCommandLog from './Gateway'
import LampCommandLog from './Lamp'
import EnergyCommandLog from './Energy'
import { mapGetters } from 'vuex'
export default {
  name: 'StationSearchIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      tabs: [
        { code: 's1', name: '配电柜指令', com: PowerboxCommandLog ,lang:'history.cmd.log.powerbox'},
        { code: 's4', name: '电表指令', com: EnergyCommandLog ,lang:'history.cmd.log.energy'},
        { code: 's15', name: '控制输出指令', com: OutputCommandLog ,lang:'history.cmd.log.output'},
        { code: 's16', name: '电流通道指令', com: CurrentCommandLog ,lang:'history.cmd.log.current'},
        {code: 's2', name: '集中器指令', com: GatewayCommandLog,lang:'history.cmd.log.gateway'},
        { code: 's3', name: '单灯指令', com: LampCommandLog ,lang:'history.cmd.log.lamp'},
      ],
      curTab: 's1',
      sources: [],
    }
  },
   computed: {
    ...mapGetters('auth', ['showLang']),
  },
  watch: {
  },
  mounted: function () {
    this.getSources();
  },
  destroyed: function () {
  },
  methods: {
    getSources() {
      this.$axios.post(`//${this.domains.trans}/station/command/QueryCommandSource`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'sources', res.data);
        }
      });
    },
    switchTab: function (type) {
      this.curTab = type;
    },
  }
}
</script>
<style scoped>
.station-search-container {
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  background-color: white;
}
.station-search-tabs {
  /* border: solid 1px red; */
  height: 48px;
  flex: none;
  display: flex;
}
.station-search-content {
  height: 100px;
  flex: auto;
  margin-top: 10px;
}
.station-search-tab {
  padding: 2px 6px;
  cursor: pointer;
  background-color: #fff;
  color: #324252;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 40px;
}
.tab-active {
  color: #021629;
  font-weight: bold;
  /* border-bottom :2px solid #3880FC; */
}
.tab-active::after {
  content: "";
  width: calc(100%);
  height: 1px;
  display: block;
  margin: -5px auto 0;
  border-bottom: 2px solid #3880fc;
}
</style>