<template>
  <div class="power-config-container">
    <div class="power-config-tabs">
      <template v-for="(item, idx) in tabs">
        <div v-if="funCodes(...item.ops)" :key="idx" :class="['power-config-tab', curTab == item.code ? 'tab-active' : '']" @click="switchTab(item.code)">{{showLang(item.lang)}}</div>
      </template>
    </div>
     <div class="power-config-tabcontent">
      <template v-for="(item, idx) in tabs">
        <component v-if="item.code == curTab" :key="idx" v-bind:is="item.com" :args="item.args" :stationId="currentStationId" @stationType="obtainStationId"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import PtimeyList from './ptimey/Index'
// import PtimewList from './ptimew/Index'
// import BjtimeList from './bjtime/Index'
import BjtimeList from './bjsimple/Index'
import LtimeList6 from './ltime6/Index'
import LtimeList7 from './ltime7/Index'
// import LtimeList8 from './lt168/planLt168/Index'
import LtimeList9 from './lt168/planWeekLt168/Index'
import MtimeList from './mtime/Index'
export default {
  name: 'StationIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      curTab: 's1',
      currentStationId:0,
      tabs: [
        { code: 's1', name: '常规年表', lang:'timetable.ptimey',com: PtimeyList, ops: ['tpv','tpa', 'tpe', 'tpd'], args: {} },
        // { code: 's2', name: '常规周表', lang:'timetable.ptimew',com: PtimewList, ops: ['tpv','tpa', 'tpe', 'tpd'], args: {} },
        { code: 's3', name: '8051B时间表', lang:'timetable.Bjtime',com: BjtimeList, ops: ['tbv','tba', 'tbe', 'tbd'], args: {} },
        { code: 's4', name: '集中器年表', lang:'timetable.mtime',com: MtimeList, ops: ['tmv','tma', 'tme', 'tmd'], args: {} },
        { code: 's5', name: '单灯计时日表', lang:'timetable.ltimeList6',com: LtimeList6, ops: ['tlv','tla', 'tle', 'tld'], args: {} },
        { code: 's6', name: '单灯准时日表', lang:'timetable.ltimeList7',com: LtimeList7, ops: ['tlv','tla', 'tle', 'tld'], args: {} },
        // { code: 's7', name: '115B日表', lang:'timetable.ltimeList8',com: LtimeList8, ops: ['___'], args: {} },
        { code: 's8', name: '115B准时日表', lang:'timetable.ltimeList9',com: LtimeList9, ops: ['___'], args: {} },
      ],
    }
  },
  computed: {
    ...mapState('group', ['groups', 'selectedNode', 'selectedDevices']),
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['weeks', 'deviceTypes', 'productCodes', 'powerBoxTypes']),
  },
  watch: {
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    obtainStationId(id){
        this.currentStationId=id;
    },
    switchTab: function (type) {
      this.curTab = type;
    },
  }
}
</script>
<style scoped>
.power-config-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px red; */
  /* padding: 3px 10px; */
}
.power-config-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.power-config-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(59, 170, 127);
  color: white;
}
.power-config-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>