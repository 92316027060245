<template>
  <div class="vehicleId-box">
    <div class="vehicleId-control">
      <div class="vehicle-side">
        <div class="vehicle-side-chart">
          <PeoplePie />
        </div>
        <div class="vehicle-side-chart">
          <PeopleLine />
        </div>
      </div>
      <div class="vehicle-middle">
        <div class="vehicle-middle-videl">
          <VideoBox :devices="devices"/>
        </div>
        <div class="vehicle-middle-box">
          <SnapBox />
        </div>
      </div>
      <div class="vehicle-side">
        <div class="vehicle-side-chart">
          <AgePie />
        </div>
        <div class="vehicle-side-chart">
          <Contrast />
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { mapState } from 'vuex'
import PeoplePie from './peoplePie'
import PeopleLine from './peopleLine'
import VideoBox from './video'
import SnapBox from './snap'
import AgePie from './agePie'
import Contrast from './contrast'
export default {
  name: 'Home',
  components: {
    PeoplePie,
    PeopleLine,
    VideoBox,
    SnapBox,
    AgePie,
    Contrast,
  },
  props: {
  },
  data() {
    return {
      devices: [],
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    selectedNode() {
      this.getVideos();
    },
  },
  mounted: function () {
    this.getVideos();
  },
  methods: {
    getVideos: function () {
      let poleId = 0, groupId = 0;
      if(this.selectedNode.type != this.productCodes.station){
        groupId = this.selectedNode.id;
      }else{
        poleId = this.selectedNode.poleId;
      }
      this.$axios.post(`//${this.domains.trans}/pole/config/QueryCameraList`, {groupId, poleId}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        res.data.map(p => {
          if(!p.content){
            p.content = {};
          }
          if(!p.content.tasks){
            p.content.tasks = [];
          }
        })
        this.$set(this, 'devices', res.data);
      })
    },
  }
}
</script>
<style scoped>
.vehicleId-box {
  height: 790px;
  overflow-y: revert;
}
.vehicleId-control {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
}
.vehicle-side {
  width: 390px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.vehicle-side-chart {
  width: 100%;
  height: calc((100% - 15px) / 2);
  background: #ffffff;
  border-radius: 10px;
}

.vehicle-middle {
  width: calc(100% - 820px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.vehicle-middle-videl {
  width: 100%;
  height: calc(100% - (100% - 30px) / 2.68 - 15px);
  border-radius: 10px;
}
.vehicle-middle-box {
  width: 100%;
  height: calc((100% - 14px) / 2.68);
  border-radius: 10px;
}
</style>
