<template>
  <div class="tabBox">
    <u-table :data="option" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :max-height="tabHeight"
      stripe :empty-text="showLang('com.data.no')">
      <template v-for="(value, key) in tabHead">
        <u-table-column v-if="key != 'link'" :prop="key" :label="value" :key="value" sortable fit>
        </u-table-column>
        <u-table-column v-else :prop="key" :label="value" :key="value" width="130">
          <template slot-scope="params">
            <a href="#" @click="linkClick(params.row)">{{value}}</a>
          </template>
        </u-table-column>
      </template>
    </u-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  props: {
    option: {
      type: Array,
      default: () => { },
    },
    tabHeight: {
      type: Number,
      default: 500,
    },
    tabHead: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
  },
  mounted: function () {
  },
  destroyed: function () { },
  methods: {
    linkClick: function (row) {
      this.$emit('rowClick', row);
    }
  },
};
</script>
<style scoped>
.tabBox {
  width: 100%;
  float: left;
  height: 100%;
}
/* 重置表格样式 */
.tabBox >>> .plTableBox .el-table tr .umy-table-beyond {
  white-space: break-spaces !important;
  color: #324252;
}
.tabBox >>> .plTableBox .el-table th {
  background-color: #eef6ff !important;
}
.tabBox >>> .plTableBox .el-table td,
.tabBox >>> .plTableBox .el-table th {
  padding: 9px 0;
}
.tabBox >>> .plTableBox .el-table th {
  background-color: #eef6ff !important;
}
</style>