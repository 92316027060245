<template>
  <Modal v-model="showModal" :mask-closable="false" width="1300px">
    <div slot="header">{{showLang('com.op.edit.info')}}</div>
    <div style="height: 500px; overflow-y:auto">
      <Form ref="form" :model="action" class="form" :label-width="120">
        <FormItem prop="start" :label="showLang('com.date.range')" v-if="editType == 1">
          <DatePicker type="date" v-model="action.start" format="MM-dd" :placeholder="showLang('com.date.start')" style="width: 155px"></DatePicker>
          {{showLang('com.date.to')}}
          <DatePicker type="date" v-model="action.end" format="MM-dd" :placeholder="showLang('com.date.end')" style="width: 155px"></DatePicker>
          <span style="color:red;margin-left:10px">{{showLang('com.date.update.plan')}}</span>
        </FormItem>
        <FormItem prop="start" :label="showLang('com.date.current2')" v-else-if="editType == 0">
          {{month+1}}{{showLang('com.date.month')}}{{editDay}}{{showLang('com.date.sun')}}
        </FormItem>
        <FormItem prop="start" :label="showLang('com.current')" v-else>
          {{weeks[editDay - 1]}}
        </FormItem>
        <template>
          <FormItem v-for="n in 6" :key="n" :label="showLang('com.op.action')+n">
            <TimePicker v-model="action['t'+n]" format="HH:mm" placeholder="Select time" style="width: 120px;margin-right:10px"></TimePicker>
            <Select :placeholder="showLang('save.select')" v-model="action['a'+n]" style="width: 100px;margin-right:10px">
              <template v-for="(v,k) in actions">
                <Option :value="parseInt(k)" :key="k">{{v}}</Option>
              </template>
            </Select>
            <Input v-if="action['a'+n] != 1" type="number" number v-model="action['v'+n]" maxlength="20"
              style="width: 120px;margin-right:10px"></Input>
            <Checkbox v-if="editType == 1" v-model="action['c'+n]" style="margin-left: 5px">{{showLang('timetable.update.group',n)}}</Checkbox>
          </FormItem>
        </template>
      </Form>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancelDayAction">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" @click="saveDayAction">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalDayAction',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Object,
      default() { return {}; }
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      weeks: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日',],
      showModal: this.value,
      action: {},
      editType: 0, // 0、单日年表，1、批量年表
      editDay: 1,
      month: 0,
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        this.editType = this.item.type;
        this.editDay = this.item.day;
        this.month = this.item.month;
        this.action = this.item.action;
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
     this.weeks=[this.showLang('com.date.mon'),this.showLang('com.date.tues'),this.showLang('com.date.wed'),this.showLang('com.date.thurs'),this.showLang('com.date.fri'),this.showLang('com.date.sat'),this.showLang('com.date.sunday')]
  },
  computed: {
    ...mapState('common', ['mapCenter']),
    ...mapState("auth", ["config",]),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  methods: {
    cancelDayAction: function () {
      this.showModal = false;
    },
    saveDayAction: function () {
      this.$emit('dayActionSaved', { type: this.editType, month: this.month, day: this.editDay, action: this.action });
      this.showModal = false;
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
</style>