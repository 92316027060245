<template>
  <div class="snap-control">
    <div class="snap-title">通过车辆抓拍</div>
    <div class="snap-con">
      <div class="snap-con-box">
        <img :src="testImg" />
        <span><i></i>粤B S4065</span>
        <span><i></i>南灯杆</span>
        <span><i></i>09:41:45</span>
      </div>
      <div class="snap-con-box">
        <img :src="testImg" />
        <span><i></i>粤B S4065</span>
        <span><i></i>南灯杆</span>
        <span><i></i>09:41:45</span>
      </div>
      <div class="snap-con-box">
        <img :src="testImg" />
        <span><i></i>粤B S4065</span>
        <span><i></i>南灯杆</span>
        <span><i></i>09:41:45</span>
      </div>
      <div class="snap-con-box">
        <img :src="testImg" />
        <span><i></i>粤B S4065</span>
        <span><i></i>南灯杆</span>
        <span><i></i>09:41:45</span>
      </div>
      <div class="snap-con-box">
        <img :src="testImg" />
        <span><i></i>粤B S4065</span>
        <span><i></i>南灯杆</span>
        <span><i></i>09:41:45</span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  computed: {

  },
  data() {
    return {
      testImg: require("@/assets/video-1.jpeg")
    }
  },
  watch: {

  },
  mounted: function () {

  },
  methods: {

  }
}
</script>
<style scoped>
.snap-control {
  height: calc(100%);
  padding: 20px 30px;
  background: #fff;
  border-radius: 10px;
}
.snap-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  margin-bottom: 15px;
  font-weight: bold;
}
.snap-con {
  height: calc(100% - 18px);
  width: 100%;
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
}
.snap-con-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.snap-con-box img {
  width: 115px;
  height: 100px;
  border-radius: 6px;
  margin-bottom: 5px;
}
.snap-con-box span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
  margin-top: 3px;
}
.snap-con-box span i {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #bcd6f0;
  border-radius: 50%;
  margin-right: 12px;
  vertical-align: middle;
}
</style>
