import { render, staticRenderFns } from "./ModalLightRecord.vue?vue&type=template&id=7f6b2ea3&scoped=true"
import script from "./ModalLightRecord.vue?vue&type=script&lang=js"
export * from "./ModalLightRecord.vue?vue&type=script&lang=js"
import style0 from "./ModalLightRecord.vue?vue&type=style&index=0&id=7f6b2ea3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f6b2ea3",
  null
  
)

export default component.exports