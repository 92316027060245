<template>
  <div class="media-container">
    <div class="media-content" ref="table">
      <u-table :data="tasks" use-virtual style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all>
        <u-table-column prop="name" label="名称" sortable>
          <template slot-scope="params">
            {{params.row.name}}
          </template>
        </u-table-column>
        <u-table-column prop="type" label="类型" sortable width="80">
          <template slot-scope="params">
            {{params.row.needView ? '音视频任务' : '音频任务'}}
          </template>
        </u-table-column>
        <u-table-column prop="level" label="优先级" sortable width="100">
          <template slot-scope="params">
            {{params.row.level}}
          </template>
        </u-table-column>
        <u-table-column prop="level" label="播放日期" sortable>
          <template slot-scope="params">
            {{params.row.weeks.map(p => weeks[p]).join(', ')}}
          </template>
        </u-table-column>
        <u-table-column prop="level" label="播放时段" sortable>
          <template slot-scope="params">
            {{getPlayTime(params)}}
          </template>
        </u-table-column>
        <!-- <u-table-column prop="level" label="节目列表" sortable>
        <template slot-scope="params">
          {{getPlayTime(params)}}
        </template>
      </u-table-column> -->
        <u-table-column width="120">
          <template slot="header" slot-scope="scope">
            <AuthButton opCode='mda' style="margin-right: 5px" @click="handleAdd(scope)">新增</AuthButton>
            <!-- <AuthButton opCode='sa' style="margin-right: 5px" @click="handleImport(scope)">导入</AuthButton> -->
          </template>
          <template slot-scope="scope">
            <AuthButton opCode='mde' style="margin-right: 5px" @click="handleEdit(scope)">编辑</AuthButton>
            <AuthButton opCode='mdd' type="error" @click="handleDelete(scope)">删除</AuthButton>
          </template>
        </u-table-column>
      </u-table>
    </div>
    <!-- <ModalGroupEdit v-model="showGroupModal" :item="editItem" :groups="groups" @saved="getGroups" /> -->
    <ModalMediaEdit v-model="showEditModal" :item="editItem" :mp3Only="mp3Only" @saved="getTasks" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
// import ModalGroupEdit from './ModalGroupEdit'
import ModalMediaEdit from './ModalMediaEdit'
export default {
  name: 'MediaIndex',
  components: {
    // ModalGroupEdit,
    ModalMediaEdit,
  },
  props: {
    mp3Only: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      tabHeight: 300,
      treeData: [],
      tasks: [],
      loading: false,
      showGroupModal: false,
      showEditModal: false,
      selectedNode: { id: 0 },
      medias: [],
      count: 0,
      items: [],
      editItem: { isAdd: false, index: -1, data: {} },
      filter: '',
      showPreviewModal: false,
      previewUrl: '',
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['mediaTypes', 'weeks']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 200);
    this.getTasks();
  },
  methods: {
    getPlayTime: function (params) {
      let ts = [];
      let s1 = new Date(params.row.start1).format('HH:mm');
      let s2 = new Date(params.row.start2).format('HH:mm');
      let s3 = new Date(params.row.start3).format('HH:mm');
      let s4 = new Date(params.row.start4).format('HH:mm');
      let e1 = new Date(params.row.end1).format('HH:mm');
      let e2 = new Date(params.row.end2).format('HH:mm');
      let e3 = new Date(params.row.end3).format('HH:mm');
      let e4 = new Date(params.row.end4).format('HH:mm');
      if (s1 != e1) {
        ts.push(`${s1} - ${e1}`);
      }
      if (s2 != e2) {
        ts.push(`${s2} - ${e2}`);
      }
      if (s3 != e3) {
        ts.push(`${s3} - ${e3}`);
      }
      if (s4 != e4) {
        ts.push(`${s4} - ${e4}`);
      }
      return ts.join('; ')
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight;
    },
    getTasks: function () {
      this.$axios.post(`//${this.domains.trans}/station/media/QueryTasks`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        let tsks = res.data;
        if (this.mp3Only) {
          tsks = tsks.filter(p => !p.needView)
        }
        this.$set(this, 'tasks', tsks);
      });
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params.row };
      this.showEditModal = true;
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除播放任务[${params.row.name}]吗?`,
        onOk: async () => {
          let res = await this.$axios.post(`//${this.domains.trans}/station/media/DeleteTask`, {id:params.row.id});
          if (res.code !== 0) {
            // this.$Message.error(res.data);
            return;
          }
          this.getTasks();
          this.$Message.info('删除成功');
        }
      });
    },
  }
}
</script>
<style scoped>
.media-container {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  padding: 3px;
}
.media-slider {
  /* border: solid 1px red; */
  width: 350px;
  flex: none;
  display: flex;
  flex-direction: column;
  margin-right: 3px;
}
.slider-header {
  border: solid 1px rgba(218, 218, 218, 0.568);
  border-radius: 6px;
  margin-bottom: 3px;
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
}
.header-title {
  width: 100px;
  flex: auto;
  font-weight: bolder;
  font-size: 20px;
  padding-left: 5px;
  cursor: pointer;
}
.header-btn {
  margin-right: 5px;
}
.slider-content {
  border: solid 1px rgba(218, 218, 218, 0.568);
  border-radius: 6px;
  height: 40px;
  flex: auto;
  overflow: auto;
}
.media-content {
  /* border: solid 1px red; */
  width: 100%;
  flex: auto;
}
</style>