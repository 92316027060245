<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        {{showLang('com.date.start')}}：
        <DatePicker v-model="form.start" type="date" style="width: 150px; margin-right: 10px; flex: none;"></DatePicker>
        {{showLang('com.date.end')}}：
        <DatePicker v-model="form.end" type="date" style="width: 150px; margin-right: 10px; flex: none;"></DatePicker>
        <Button type="info" :loading="searchLoading" @click="initEnergys" style="margin-right: 10px">{{showLang('com.op.query')}}</Button>
        <!-- <Button type="success" :loading="exportLoading" @click="exportData" style="margin-right: 10px">{{showLang('com.op.export')}}</Button> -->
        <!-- <Button type="success" :loading="exportLoading" @click="exportAllData" style="margin-right: 10px">{{showLang('com.op.export.all')}}</Button> -->
        <Button type="success" :loading="exportLoading" @click="exportTotalData" style="margin-right: 10px">{{showLang('com.op.export.total')}}</Button>
        <Button type="success" :loading="exportLoading" @click="exportDetailData">{{showLang('com.op.export.detail')}}</Button>
        <span style="margin-left: 20px">{{showLang('energy.tisp.total.sites',energys.length)}}, {{showLang('energy.saving')}} {{totalEnergy}} Kwh, {{showLang('com.stat.save.charge1')}} {{totalMoney}} {{showLang('com.unit.cash')}}</span>
      </div>
    </div>
    <div class="search-data-content" ref="table1">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :data="energys" :height="tabHeight" :row-config="{isHover: true}" :export-config="{}" show-footer :footer-method="footerMethod" footer-cell-class-name="footer-cell-class">
        <!-- <vxe-column type="checkbox" width="60"></vxe-column> -->
        <vxe-column field="seq" type="seq" width="90"></vxe-column>
        <vxe-column field="stationName" :title="showLang('com.site.info.region')" header-align="center">
          <template #default="{ row }">
            {{row.area}}
          </template>
        </vxe-column>
        <vxe-column field="date" :title="showLang('com.date.time')"  width="260" header-align="center">
          <template #default="{ row }">
            {{row.date}}
          </template>
        </vxe-column>
        <vxe-colgroup :title="showLang('energy.before.usage')" header-align="center">
          <vxe-column field="beforeLight" :title="showLang('energy.usage.light') + '(KWh)'"  width="150" header-align="center">
            <template #default="{ row }">
              {{row.beforeLight}}
            </template>
          </vxe-column>
        </vxe-colgroup>
        <vxe-colgroup :title="showLang('energy.after.usage')" header-align="center">
          <vxe-column field="afterTotal" :title="showLang('energy.usage.real') + '(Kwh)'"  width="150" header-align="center">
            <template #default="{ row }">
              <!-- <a href="#" @click="showAfterTotal(row)">{{row.afterTotal}} KWh</a> -->
              {{row.afterTotal}}
            </template>
          </vxe-column>
          <vxe-column field="afterLight" :title="showLang('energy.usage.light') + '(KWh)'"  width="150" header-align="center">
            <template #default="{ row }">
              <!-- <a href="#" @click="showAfterLight(row)">{{row.afterLight}} KWh</a> -->
              {{row.afterLight}}
            </template>
          </vxe-column>
        </vxe-colgroup>
        <vxe-column field="save" :title="showLang('energy.save.amount') + '(KWh)'"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.save}}
          </template>
        </vxe-column>
        <vxe-column field="money" :title="showLang('energy.save.electric')+ '('+showLang('com.unit.cash')+ ')'"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.money}}
          </template>
        </vxe-column>
        <vxe-column :title="showLang('com.right.nav.operate')" fixed="right" width="110">
          <template #default="{ row }">
            <Button type="primary" @click="handleEdit(row)">{{showLang('energy.View.Details')}}</Button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <ModalAreaCheckRecord v-model="showEditModal" :item="editItem" />
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import ModalAreaCheckRecord from './ModalAreaCheckRecord'
export default {
  name: 'AreaCheck',
  components: {
    ModalAreaCheckRecord,
  },
  props: {
  },
  data() {
    return {
      searchLoading: false,
      exportLoading: false,
      tabHeight: 1,
      totalEnergy: 0,
      totalMoney: 0,
      form: {
        stationId: 0,
        start: "",
        end: "",
      },
      energys: [],
      editItem: {},
      showEditModal: false,
      showLogModal: false,
      showAfterTotalModal: false,
      showAfterLightModal: false,
      footerData: []
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    selectedNode() {
      this.initEnergys();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd');
    now.setMonth(now.getMonth() - 1);
    this.form.start = now.format('yyyy-MM-dd');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.initEnergys();
  },
  destroyed: function () {
  },
  methods: {
    showAfterTotal: function(params){
      // console.log('show after total', params);
      this.editItem = {stationId: params.stationId, start: params.startDate, end: params.endDate};
      this.showAfterTotalModal = true;
    },
    showAfterLight: function(params){
      // console.log('show after light', params);
      this.editItem = {stationId: params.stationId, start: params.startDate, end: params.endDate};
      this.showAfterLightModal = true;
    },
    footerMethod: function(){
      return this.footerData;
    },
    getLostRate: function(row){
      return row.afterTotal <= 0 ? 0 : this.img.val((row.afterTotal - row.afterLight - row.supply)*100 / row.afterTotal, 2)
    },
    handleEdit: function(params){
      let start = new Date(this.form.start).format('yyyy-MM-dd');
      let end = new Date(this.form.end).format('yyyy-MM-dd');
      this.editItem = {stationName: params.area, start, end};
      this.showEditModal = true;
    },
    handleLog: function(params){
      let start = new Date(this.form.start).format('yyyy-MM-dd');
      let end = new Date(this.form.end).format('yyyy-MM-dd');
      this.editItem = {id: params.paramId, paramName: params.paramName, stationName: params.stationName, start, end};
      this.showLogModal = true;
    },
    exportData: function(){
      // var chks = this.$refs.chkTable.getCheckboxRecords();
      // let ids = [];
      // chks.map(p => ids.push(p.stationId));
      // if(ids.length == 0){
      //   this.$Message.warning('请选择要导出的站点');
      //   return;
      // }
      let start = new Date(this.form.start).format('yyyy-MM-dd');
      let end = new Date(this.form.end).format('yyyy-MM-dd');
      this.exportLoading = true;
      this.$store.dispatch('auth/reqFile', {
        title: '站点抄表数据.xlsx',
        url: `//${this.domains.trans}/station/analyse/ExportStationEnergyCheckListByArea`,
        args: {start, end},
      }).then(() => {
        this.exportLoading = false;
      });
    },
    exportAllData: function(){
      let start = new Date(this.form.start).format('yyyy-MM-dd');
      let end = new Date(this.form.end).format('yyyy-MM-dd');
      this.exportLoading = true;
      this.$store.dispatch('auth/reqFile', {
        title: '站点抄表数据.xlsx',
        url: `//${this.domains.trans}/station/analyse/ExportStationEnergyCheckListAllByArea`,
        args: {start, end},
      }).then(() => {
        this.exportLoading = false;
      });
    },
    exportTotalData: function(){
      let start = new Date(this.form.start).format('yyyy-MM-dd');
      let end = new Date(this.form.end).format('yyyy-MM-dd');
      this.exportLoading = true;
      this.$store.dispatch('auth/reqFile', {
        title: '区域能耗汇总数据导出.xlsx',
        url: `//${this.domains.trans}/station/analyse/ExportStationEnergyCheckListTotalByArea`,
        args: {start, end},
      }).then(() => {
        this.exportLoading = false;
      });
    },
    exportDetailData: function(){
      let start = new Date(this.form.start).format('yyyy-MM-dd');
      let end = new Date(this.form.end).format('yyyy-MM-dd');
      this.exportLoading = true;
      this.$store.dispatch('auth/reqFile', {
        title: '区域能耗明细数据导出.xlsx',
        url: `//${this.domains.trans}/station/analyse/ExportStationEnergyCheckListDetailByArea`,
        args: {start, end},
      }).then(() => {
        this.exportLoading = false;
      });
    },
    initEnergys: function () {
      let start = new Date(this.form.start).format('yyyy-MM-dd');
      let end = new Date(this.form.end).format('yyyy-MM-dd');
      this.searchLoading = true;
      this.$axios.post(`//${this.domains.trans}/station/analyse/QueryStationEnergyCheckListByArea`, { start, end}).then(res => {
        this.searchLoading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'energys', res.data.list);
          // let total = 0, before = 0, usage = 0, save = 0, money = 0;
          // res.data.map(p => {
          //   total += p.energy;
          //   before += p.before;
          //   usage += p.after;
          //   save += p.save;
          //   money += p.money;
          // });
          this.$set(this, "footerData", [
            [
              this.showLang('com.export.footer.name'), 
              res.data.list.length+this.showLang('energy.record'), 
              '', 
              res.data.beforeLight+'KWh', 
              res.data.afterTotal+'KWh', 
              res.data.afterLight+'KWh', 
              res.data.save+'KWh', 
              res.data.money+this.showLang('com.unit.cash')
            ],
          ]);
          this.totalEnergy = res.data.save;
          this.totalMoney = res.data.money;
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 5;
    },
  }
}
</script>
<style scoped>
>>> .footer-cell-class {
  /* color: red; */
  font-weight: bold;
}
.switchType {
  height: 30px;
  display: flex;
  flex-direction: initial;
  margin: 10px 0 0 10px;
}
.switchType-tab {
  width: 68px;
  height: 30px;
  background: #eef4f9;
  color: #324252;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.fillet-left {
  border-top-left-radius: 6px;
}
.fillet-right {
  border-top-right-radius: 6px;
}
.switchType-tab.tab-active {
  background: #3880fc;
  color: #ffffff;
}
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  margin-top: 10px;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: none;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
.search-data-chart {
  width: calc(100% - 20px);
  margin: 0 auto;
  height: 500px;
  position: relative;
}
.colorDeputy {
  position: absolute;
  right: 40px;
  display: flex;
}
.deputy-list {
  margin-right: 10px;
}
.deputy-list i {
  display: inline-block;
  width: 5px;
  height: 5px;
}
</style>