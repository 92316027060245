<template>
  <div class="device-item-content">
    <div class="device-item-top">
      <p class="device-item-topTitle">名称：{{power.name}}</p>
      <p class="device-item-topTime">更新时间：2022-08-08 08:12:00</p>
    </div>
    <div class="device-item-img">
      <img :src="img.pole.powersupply.powerSupply" />
    </div>
    <div class="device-list-box">
      <div class="device-box-con">
        <div class="device-box-title">直流12V开关1</div>
        <div class="device-box-img">
          <img :src="isShowImg(form.relay1)" @click='form.relay1=!form.relay1' />
        </div>
      </div>
      <div class="device-box-con">
        <div class="device-box-title">直流24V开关2</div>
        <div class="device-box-img">
          <img :src="isShowImg(form.relay2)" @click='form.relay2=!form.relay2' />
        </div>
      </div>
      <div class="device-box-con">
        <div class="device-box-title">交流开关1</div>
        <div class="device-box-img">
          <img :src="isShowImg(form.relay3)" @click='form.relay3=!form.relay3' />
        </div>
      </div>
      <div class="device-box-con">
        <div class="device-box-title">交流开关2</div>
        <div class="device-box-img">
          <img :src="isShowImg(form.relay4)" @click='form.relay4=!form.relay4' />
        </div>
      </div>
      <div class="device-box-con">
        <div class="device-box-title">交流开关3</div>
        <div class="device-box-img">
          <img :src="isShowImg(form.relay5)" @click='form.relay5=!form.relay5' />
        </div>
      </div>
    </div>
    <div class="gate-flag-row">
      <a href="javascript:void(0);" @click="ok" class="gate-flag-ok"> <img :src="img.pole.powersupply.ins" alt=""> 发送指令
      </a>
      <!-- <Button type="primary" >发送指令</Button> -->
      <span style="margin-left: 15px" v-if="cmdId != 0">{{cmdMessage}}</span>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import deviceImg from './deviceImg'
// import ACSupply from '../cell/ACSupply'
// import MainPage from '../cell/main'
// import OnePower from '../cell/onePower'
// import PoeSwitch from '../cell/PoeSwitch'
// import ACPowerSupply from '../cell/ACPowerSupply'
export default {
  name: 'deviceBaiMaDetails',
  components: {
    // deviceImg,
    // MainPage,
    // ACPowerSupply,
    // PoeSwitch,
    // OnePower,
    // ACSupply,
  },
  props: {
    // devices: {
    //   type: Array,
    //   default(){return []},
    // },
    power: {
      type: Object,
      default() { return {} },
    },
  },
  data() {
    return {
      form: {
        relay1: false,
        relay2: false,
        relay3: false,
        relay4: false,
        relay5: false,
      },
      cmdId: 0,
      cmdMessage: 0,
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    selectedNode() {
      this.setHigt();
    },
  },
  mounted: function () {
    window.eventBus.$on('paramCommandComing', params => {
      //this.cmdResults[params.cmdId] = params;
      // console.log('cmd result ', params);
      if (params.cmdId == this.cmdId) {
        if (params.status == 9) {
          this.cmdMessage = `执行结果：${params.content.status}`;
        } else {
          this.cmdMessage = params.message;
        }
      }
    });
  },
  destroyed: function () {
    window.eventBus.$off('paramCommandComing');
  },
  methods: {
    isShowImg(is) {
      return is ? this.img.pole.powersupply.energize : this.img.pole.powersupply.outage
    },
    setHigt: function () { },
    ok: function () {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要发送开关指令吗?`,
        onOk: async () => {
          this.cmdId = 0;
          this.cmdMessage = '';
          let cmds = [];
          cmds.push({ id: this.power.id, code: 'handControl', name: '开关控制', deviceId: this.power.deviceId, args: this.form });
          this.$axios.post(`//${this.domains.trans}/station/command/send`, { list: cmds }).then(res => {
            if (res.code == 0) {
              // console.log('send cmd result', res.data);
              this.$Message.info('指令已发送');
              let cmds = res.data.list.filter(p => p.itemId == this.power.id);
              this.cmdId = cmds.length == 0 ? 0 : cmds[0].cmdId;
              this.cmdMessage = cmds.length == 0 ? '' : cmds[0].message;
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.device-list-box {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
}
.device-box-con {
  width: 196px;
  height: 128px;
  background: #ffffff;
  box-shadow: 0px 12px 25px 0px rgba(67, 145, 220, 0.1);
  border-radius: 8px;
}
.device-box-title {
  width: 196px;
  height: 38px;
  background: #2d8cf0;
  border-radius: 8px 8px 0px 0px;
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  line-height: 33px;
}
.device-box-img {
  width: 196px;
  height: 90px;
}
.device-box-img img {
  text-align: center;
  margin-top: 20px;
  cursor:pointer
}
.device-item-content {
  width: 100%;
  height: 100%;
  background-color: #e6e7ef;
  border-radius: 10px;
  padding: 20px 20px;
  text-align: center;
  overflow-y: overlay;
}
.device-item-topTitle {
  color: rgb(51, 51, 51);
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}
.device-item-topTime {
  color: rgb(158, 162, 166);
  font-size: 14px;
  text-align: center;
}
.device-item-img {
  text-align: center;
  padding: 25px 0;
}
.gate-flag-row {
  margin-top: 30px;
}
.gate-flag-ok {
  width: 194px;
  height: 56px;
  background: #007eff;
  border-radius: 8px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  line-height: 56px;
  display: inline-block;
}
.gate-flag-ok img{
  vertical-align: middle;
  margin-right: 10px;
}
</style>
