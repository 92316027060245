<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">查看-{{item.stationName}} - 时间: {{item.createTime}}</div>
    <div class="search-data-header">
      <vxe-table align="center" :data="list" height="500px" border>
        <vxe-column type="seq" width="60" title="序号"></vxe-column>
        <vxe-column field="name" title="单灯名称">
          <template #default="{ row }">
            <span class="stationName" @click="jumpDetailsDve(row)"> {{row.name}}</span>
          </template>
        </vxe-column>
        <vxe-column field="code" title="通信ID" sortable></vxe-column>
        <vxe-column field="time" title="发生时间" sortable></vxe-column>
        <vxe-colgroup title="时间窗口">
          <vxe-column field="count" title="异常总数" sortable></vxe-column>
          <vxe-column field="start" title="起始时间" sortable></vxe-column>
          <vxe-column field="end" title="截止时间" sortable></vxe-column>
        </vxe-colgroup>

      </vxe-table>
    </div>
    <div slot="footer">
      <!-- <Button style="margin-right: 8px" type="primary" @click="exportData">导出</Button> -->
      <Button style="margin-right: 8px" @click="showModal=false">关闭</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModelLightPowerError',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {} }
    },
    type: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      list: [],
      showModal: false
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['alarmTypes', 'productCodes']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.getList();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    getList: function () {
      let url='QueryOfflineErrorLights'//离线异常数字的设备列表
      if(this.type==1){//灭灯异常数字的设备列表
        url='QueryPoweroffErrorLights'
      }
      this.$axios.post(`//${this.domains.trans}/device/light/${url}`, { id: this.item.id, time: this.item.createTime }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
        }
      });
    },
    jumpDetailsDve() {
      this.$store.commit('group/selectNodeById', { nodeId: this.item.stationId, type: this.productCodes.station });
      this.$store.commit('auth/switchStationTabName', 'power');
    },
  }
}
</script>
<style scoped>
</style>