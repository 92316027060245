<template>
  <div class="config-station-container">
    <div class="station-search-area">
      起始时间：
      <DatePicker v-model="filter.start" type="datetime" placeholder="请选择开始时间" style="width: 175px; margin-right: 10px;"></DatePicker>
      截止时间：
      <DatePicker v-model="filter.end" type="datetime" placeholder="请选择截止时间" style="width: 175px; margin-right: 10px;"></DatePicker>
      <Button type="info" :loading="loading" @click="getWillSieHistory" style="margin-right: 10px;">查询</Button>
    </div>
    <div class="station-data-area" ref="table">
      <u-table :data="history" use-virtual style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all :page-sizes="[15, 50, 100, 200, 500]" :pagination-show="true" :total="filter.total" :page-size="filter.pageSize" :current-page="filter.pageIndex" @handlePageSize="handlePageSize">
        <u-table-column type="index" label="序号" width="50"></u-table-column>
        <u-table-column prop="TskStartTime" label="呼叫时间" sortable width="155">
          <template slot-scope="params">
            {{params.row.startTime}}
          </template>
        </u-table-column>
        <u-table-column prop="TskGuid" label="任务ID" sortable>
          <template slot-scope="params">
            {{params.row.taskGuid}}
          </template>
        </u-table-column>
        <u-table-column prop="type" label="呼叫方" sortable>
          <template slot-scope="params">
            {{params.row.callerNickName}}
          </template>
        </u-table-column>
        <u-table-column prop="guid" label="被呼叫方" sortable>
          <template slot-scope="params">
            {{params.row.calleeNickName}}
          </template>
        </u-table-column>
        <!-- <u-table-column prop="location" label="安装位置" sortable width="300">
        <template slot-scope="params">
          {{params.row.lat}} - {{params.row.lng}}
        </template>
      </u-table-column> -->
        <u-table-column prop="ModificationTime" label="结束时间" sortable width="155">
          <template slot-scope="params">
            {{params.row.endTime}}
          </template>
        </u-table-column>
        <u-table-column prop="RecordFile" label="录音播放" sortable width="120">
          <template slot-scope="params">
            <div class="symbol-download">
              <img v-if="params.row.recordFile && params.row.recordFile != ''" :src="img.lightMainSunDown" title="播放录音文件" @click="playFile(`${params.row.recordFile}`)" />
              <a class="symbol-download" v-if="params.row.recordFile && params.row.recordFile != ''" :href="`${params.row.recordFile}`" target="_blank">
                <img :src="img.lightMainRateOnline" title="下载录音文件" />
              </a>
            </div>
          </template>
        </u-table-column>
      </u-table>
    </div>
    <audio ref="audioPlayer" :src="previewUrl" controls="controls" @canplay="audioLoaded" class="media-player">您的浏览器不支持 audio 标签</audio>
    <!-- <ModalSpeakEdit v-model="showEditModal" :item="editItem" :types="poleSpeakTypes" /> -->
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
// import ModelDeviceEdit from './ModelDeviceEdit'
// import AuthMenuItem from '@/components/common/AuthMenuItem'
export default {
  name: 'Device',
  components: {
    // AuthMenuItem
    // ModelDeviceEdit,
  },
  props: {
    devId: {
      type: Number,
      default: -1
    },
  },
  data() {
    return {
      previewUrl: '',

      tabHeight: 300,
      showPreviewModal: false,
      status: { 1: '结束', 5: '呼叫接通', 6: '呼叫未响应', 7: '呼叫失败', 10: '呼叫取消', 11: '呼叫转移', 12: '呼叫拒绝' },
      loading: false,
      filter: {
        total: 0,
        pageIndex: 1,
        pageSize: 15,
        callerId: '',
        calleeId: '',
        start: '',
        end: '',
        taskGuid: '',
      },
      cols: [
        {
          title: '呼叫时间',
          // width: 120,
          key: 'TskStartTime',
          render: (h, params) => {
            return h('div', new Date(params.row.TskStartTime * 1000).format('yyyy-MM-dd HH:mm:ss'));
            // return h('div', params.row.type);
          },
        },
        {
          title: '任务ID',
          // width: 120,
          key: 'TskGuid',
          render: (h, params) => {
            // return h('div', this.status[params.row.TskStatus]);
            return h('div', params.row.TskGuid);
          },
        },
        {
          title: '呼叫方',
          // width: 100,
          key: 'CallerGuid',
          render: (h, params) => {
            // let ds = this.devs.filter(item => item.DeviceGuid == params.row.CallerGuid)
            // return h('div', ds.length > 0 ? ds[0].DeviceNickName : '');
            return h('div', params.row.CallerNickName);
          },
        },
        {
          title: '被呼叫方',
          // width: 200,
          key: 'CalleeGuid',
          render: (h, params) => {
            // let ds = this.devs.filter(item => item.DeviceGuid == params.row.CalleeGuid)
            // return h('div', ds.length > 0 ? ds[0].DeviceNickName : '');
            return h('div', params.row.CalleeNickName);
          },
        },
        {
          title: '对讲音量',
          // width: 200,
          key: 'TalkVolumn',
        },
      ],
      history: [],
      devs: [],
      count: 0,
      eidtItem: { isAdd: false, index: -1, data: {} }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'poleType']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    devId() {
      this.setTableHeight();
      this.getWillSieHistory();
    },
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.getWillSieHistory();
    },
  },
  mounted: function () {
    setTimeout(() => {
      this.setTableHeight();
    }, 20);
    let now = new Date();
    now.setHours(now.getHours() + 1)
    this.filter.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setMonth(now.getMonth() - 1);
    this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');
    // this.getWillSieDevices();
    this.getWillSieHistory();
  },
  methods: {
    handlePageSize: function (params) {
      // console.log('handle page size', params);
      this.filter.pageSize = params.size;
      this.filter.pageIndex = params.page;
      this.getWillSieHistory();
    },
    playFile: function (url) {
      this.previewUrl = url;
      this.$refs.audioPlayer.load();
      this.$refs.audioPlayer.play();
    },
    audioLoaded: function (e) {
      e.srcElement.play();
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 3;
    },
    handleEdit: function (params) {
      this.eidtItem = { isAdd: false, index: params.index, data: params.row };
      this.showPreviewModal = true;
    },
    getWillSieDevices: async function () {
      let res = await this.$axios.get(`//${this.domains.trans}/pole/WillSieDevice?index=0&size=10000`);
      if (res.code != 0) {
        return;
      }
      this.$set(this, 'devs', res.data);
      // console.log("get wills devices", res.data);
    },
    getWillSieHistory: async function () {
      let filter = {
        stationId: 0,
        groupId: 0,
        pageIndex: this.filter.pageIndex,
        pageSize: this.filter.pageSize,
        callerId: "",
        calleeId: "",
        start: new Date(this.filter.start).format('yyyy-MM-dd HH:mm:ss'),
        end: new Date(this.filter.end).format('yyyy-MM-dd HH:mm:ss'),
        taskGuid: this.filter.taskGuid,
      };
      switch (this.selectedNode.type) {
        case "root":
          filter.stationId = 0;
          filter.groupId = 0;
          break;
        case "group":
          filter.stationId = 0;
          filter.groupId = this.selectedNode.groupId;
          break;
        case "Station":
          filter.stationId = this.selectedNode.id;
          filter.groupId = 0;
          break;
      }
      if (this.devId != -1) {
        filter.stationId = this.devId;
        filter.groupId = 0;
      }
      let res = await this.$axios.post(`//${this.domains.trans}/pole/speak/log`, filter);
      if (res.code != 0) {
        return;
      }
      this.$set(this, 'history', res.data.list);
      this.filter.total = res.data.count;
    },
    pageSizeChange: function (size) {
      this.filter.index = 1;
      this.filter.size = size;
      this.getWillSieDevices();
    },
    pageIndexChange: function (index) {
      this.filter.index = index;
      this.getWillSieDevices();
    },
  }
}
</script>
<style scoped>
.media-player {
  /* visibility: hidden; */
  height: 0;
}
.symbol-download {
  display: flex;
  justify-content: center;
  align-items: center;
}
.symbol-download img {
  margin-right: 10px;
  cursor: pointer;
}
.config-station-container {
  /* padding: 5px;  
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  padding: 10px;
  background-color: white;
  border-radius: 8px;
}
.station-search-area {
  height: 42px;
  flex: none;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.station-data-area {
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>
