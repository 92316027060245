<template>
  <div class="power-time-list" ref="table1">
    <vxe-table :empty-text="showLang('com.data.no')" border show-overflow show-header-overflow :data="list" height="auto" :row-config="{isHover: true}">
      <vxe-column field="name" :title="showLang('com.tab.title')"></vxe-column>
      <vxe-column width="150" :title="showLang('com.right.nav.operate')" fixed="right">
        <template #header>
          <Button size="small" type="primary" @click="handleAdd" style="margin-right: 5px">{{showLang('com.op.add')}}</Button>
          <Button size="small" type="primary" @click="getList" style="margin-right: 5px">{{showLang('com.op.refresh')}}</Button>
        </template>
        <template #default="params">
          <!-- v-if="funCodes(2063)" -->
          <Button size="small" type="info" @click="handleEdit(params)" style="margin-right: 5px">{{showLang('com.op.edit')}}</Button>
          <Button size="small" type="error" @click="handleDelete(params)" style="margin-right: 5px">{{showLang('com.op.del')}}</Button>
        </template>
      </vxe-column>
    </vxe-table>
    <ModalLightTable v-model="showEditModal" :item="editItem" @saved="getList" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalLightTable from './ModalLightTable'
export default {
  name: 'LightTableIndex',
  components: {
    ModalLightTable,
  },
  data() {
    return {
      showEditModal: false,
      editItem: { isAdd: true, index: -1, data: {} },
      list: [],
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
  },
  mounted: function () {
    this.getList();
  },
  methods: {
    getList: function () {
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLight8List`, {}).then(res => {
        if (res.code != 0) {
          this.$set(this, 'list', []);
        } else {
          this.$set(this, 'list', res.data);
        }
      });
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, index: -1, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, index: params.index, data: params.row };
      this.showEditModal = true;
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', params.row.name),
        onOk: async () => {
          // this.$axios.post(`device/light/DeletePlanClock`, { id: params.row.id }).then(res => {
          this.$axios.post(`//${this.domains.trans}/station/plan/DeleteLight8`, { id: params.row.id }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.power-time-list {
  height: 100%;
  width: 100%;
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
}
</style>