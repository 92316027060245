<template>
<Modal v-model="showModal" :mask-closable="false" draggable width="1305px">
  <div slot="header">
    报警呼叫列表
  </div>
  <div class="alarm-area">
    <div class="alarm-list">
      <div :class="['alarm-item', item.code == selectedDeviceId ? 'alarm-selected' : '']" v-for="(item, idx) in zhuhaiSanrun.devices" :key="idx" @click="selectVideo(item)">{{item.name}}[{{item.code}}]<Icon v-if="item.running" type="md-call" class="alarm-notify-icon" /></div>
    </div>
    <div class="alarm-content">
      <video ref="alarmPlayer" class="alarm-player" />
    </div>
  </div>
  <div slot="footer">
    <!-- <Button type="info" style="margin-right: 8px" @click="openControl">开闸</Button>
    <Button type="error" style="margin-right: 8px" @click="closeControl">合闸</Button> -->
    <Button type="success" style="margin-right: 8px" @click="startTalk">通话</Button>
    <Button type="warning" style="margin-right: 8px" @click="stopTalk">挂断</Button>
    <!-- <Button type="warning" style="margin-right: 8px" @click="stopPlay">停止视频</Button> -->
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
  </div>
</Modal>
</template>
<script>
import {mapState} from 'vuex'
export default {
  name: 'ModalAlarmList',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      showModal: this.value,
      selectedDeviceId: 0,
    }
  },
  computed: {
    ...mapState('cmd', ['zhuhaiSanrun']),
  },
  watch: {
    value(newVal) {
    // console.log('modal data send rec1')
      this.showModal = newVal;
    },
    showModal(val){
    // console.log('modal data send rec2')
      this.$emit('showChanged', val);
    }
  },
  mounted: function(){
    // console.log('modal data send rec')
  },
  destroyed: function(){
  },
  methods: {
    selectVideo(params){
      console.log('select video', params);
      this.selectedDeviceId = params.code;
    },
    showVideo(params){
      this.selectedDeviceId = params.code;
      this.dhweb.playDeviceAudio(this.selectedDeviceId);
      this.dhweb.playRT(this.$refs.alarmPlayer, this.selectedDeviceId, this.zhuhaiSanrun.login.handle, false);
      if(params.alarm){
        setTimeout(() => {
          this.startTalk();
        }, 500);
      }
    },
    startTalk(){
      console.log('start talk', this.selectedDeviceId)
      if(!this.selectedDeviceId)return;
      this.dhweb.playDeviceAudio(this.selectedDeviceId);
      this.dhweb.playRT(this.$refs.alarmPlayer, this.selectedDeviceId, this.zhuhaiSanrun.login.handle, false);
      setTimeout(() => {
          // this.startTalk();
          this.dhweb.startTalk(this.selectedDeviceId);
        }, 500);
      // this.dhweb.startTalk(this.selectedDeviceId);
    },
    stopTalk(){
      this.dhweb.stopTalk(this.selectedDeviceId);
      this.dhweb.stopRT(this.selectedDeviceId, this.zhuhaiSanrun.login.handle);
      // let items = this.zhuhaiSanrun.devices.filter(p => p.code == this.selectedDeviceId);
      // if(items.length == 0)return;
      // if(items[0].alarm){
      //   this.stopPlay();
      // }
    },
    stopPlay(){
      this.dhweb.stopRT(this.selectedDeviceId, this.zhuhaiSanrun.login.handle);
      this.$Message.info('已断开视频')
    },
    // stopTalk(){
    //   this.dhweb.stopTalk(this.selectedDeviceId);
    //   setTimeout(() => {
    //       // this.startTalk();
    //     this.dhweb.stopRT(this.selectedDeviceId, this.zhuhaiSanrun.login.handle);
    //     }, 500);
    //   // this.dhweb.stopTalk(this.selectedDeviceId);
    //   // let items = this.zhuhaiSanrun.devices.filter(p => p.code == this.selectedDeviceId);
    //   // if(items.length == 0)return;
    //   // if(items[0].alarm){
    //   //   this.stopPlay();
    //   // }
    // },
    // stopPlay(){
    //   this.dhweb.stopRT(this.selectedDeviceId, this.zhuhaiSanrun.login.handle);
    //   this.$Message.info('已断开视频')
    // },
    openControl: function(){
      this.dhweb.doControl(this.selectedDeviceId, this.loginHandle, 1);
    },
    closeControl: function(){
      this.dhweb.doControl(this.selectedDeviceId, this.loginHandle, 2);
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.alarm-area{
  /* border: solid 1px red; */
  width: 100%;
  height: 600px;
  display: flex;
}
.alarm-list{
  border: solid 1px rgba(20, 20, 20, 0.459);
  padding: 3px;
  width: 200px;
  flex: none;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}
.alarm-item{
  border: solid 1px rgb(38, 0, 255);
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 5px;
  cursor: pointer;
}
.alarm-notify-icon{
  /* border: solid 1px rgb(38, 0, 255); */
  /* width: 20px;
  height: 20px; */
  font-size: 20px;
  color: green;
  margin-left: 10px;
}
.alarm-calling{
  background-color: coral;
}
.alarm-dealing{
  background-color: rgba(0, 100, 0, 0.582);
}
.alarm-selected{
  background-color: rgb(62, 174, 240);
}
.alarm-content{
  /* border: solid 1px red; */
  width: 500px;
  flex: auto;
}
.alarm-player{
  width: 100%;
  height: 100%;
  background-color: black;
}
</style>