<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        {{showLang('dev.meter.look.ammeter')}}：
        <Select :placeholder="showLang('save.select')" v-model="form.energyId" style="width: 150px; margin-right: 10px;">
          <!-- <Option :value="0">所有配电柜</Option> -->
          <template v-for="(item, idx) in energys">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
        {{showLang('com.date.start')}}：
        <DatePicker v-model="form.start" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
        {{showLang('com.date.end')}}：
        <DatePicker v-model="form.end" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
        <Button v-if="funCodes('spav')" type="info" :loading="loading" @click="getList">{{showLang('com.op.query')}}</Button>
        <!-- <Button type="success" @click="exportData" style="margin:3px 10px;" >导出</Button> -->
      </div>
    </div>
    <div class="search-data-content" ref="table1">
      <div v-if="history.length == 0" style="height: 400px; background-color: white;text-align:center;line-height: 200px">{{showLang('com.data.no')}}</div>
      <virtual-list v-else class="table-data" :style="{height: `${tabHeight}px`}" :data-key="'fireTime'" :data-sources="history" :data-component="tempHistoryData" :extra-props="pos" />
      <Spin size="large" fix v-if="loading"></Spin>
    </div>
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
// import exportXlsx from '@/components/xlsx/exportXlsx'
import TempHistoryData from './PowerHistoryItem'
import VirtualList from 'vue-virtual-scroll-list'
export default {
  name: 'SearchPowerboxIndex',
  components: {
    VirtualList,
    'virtual-list': VirtualList
  },
  props: {
  },
  data() {
    return {
      loading: false,
      curTab: 'group',
      tabHeight: 1,
      tempHistoryData: TempHistoryData,
      pos: {},
      tabData: [//列表数据
        // {
        //   sex: "未知",
        //   name: "王小狗",
        //   age: "15",
        // }
      ],
      tabHead: { //头部标题
        name: "名字",
        sex: "性别",
        age: "年龄",
      },
      form: {
        stationId: 0,
        start: "",
        end: "",
      },
      showTable: true,
      views: [
        // {code: 'all', name: '全部显示', cols: []},
        { code: 's1c', name: '支路一电流', cols: [] },
        { code: 's1u', name: '支路一电压', cols: [] },
        { code: 's1p', name: '支路一功率', cols: [] },
      ],
      history: [],
      energyId: 0,
      energys: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    selectedNode() {
      this.initEnergys();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 1);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.initEnergys();
  },
  destroyed: function () {
  },
  methods: {
    initEnergys: function () {
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == 'root' || this.selectedNode.type == 'group'){
        groupId = this.selectedNode.id;
      }else{
        stationId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryEnergy`, { groupId, stationId }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'energys', res.data);
          // console.log('get light data', res.data)
          if (res.data.length > 0) {
            this.form.energyId = res.data[0].id;
          } else {
            this.form.energyId = 0;
          }
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 5;
    },
    exportData() {
      var ele = document.querySelector('.table-data').childNodes[0];
      this.getPdf(ele, this.showLang('history.data.ggd'))
    },
    getList() {
      if(!this.form.energyId){
        this.$Message.warning(this.showLang('energy.please.query.meter'));
        return;
      }
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let params = { paramId: this.form.energyId, start: this.form.start, end: this.form.end };
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/param/history`, params).then(res => {
        this.loading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'history', res.data);
        }
      })
    },
  }
}
</script>
<style scoped>
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
.table-data {
  overflow: auto;
}
</style>