<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        {{showLang('com.device.box.own')}}：
        <Select :placeholder="showLang('save.select')" v-model="form.stationId" style="width: 150px; margin-right: 10px;">
          <template v-for="(item, idx) in selectedStations">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
        {{showLang('com.device.lamp')}}：
        <Select :placeholder="showLang('save.select')" v-model="form.lightId" style="width: 250px; margin: 0 10px">
          <Option :value="l.id" v-for="l in lights" :key="l.id">{{l.name}}</Option>
        </Select>
        {{showLang('com.date.start')}}：
        <DatePicker v-model="form.start" type="datetime"  style="width: 170px; margin-right: 10px;"></DatePicker>
         {{showLang('com.date.end')}}：
        <DatePicker v-model="form.end" type="datetime"  style="width: 170px; margin-right: 10px;"></DatePicker>
        <Button v-if="funCodes('slhv')" type="info" :loading="loading" @click="getList" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
        <Button type="success" @click="exportData" style="margin-right: 10px;">{{showLang('com.op.export')}}</Button>
        <Button v-if="funCodes('slhd')" type="error" :loading="loading" @click="deleteLogs" style="margin-right: 10px;">{{showLang('com.op.del.time')}}</Button>
        <Button v-if="funCodes('slhd')" type="error" :loading="loading" @click="deleteLog" style="margin-right: 10px;">{{showLang('com.op.del.select')}}</Button>
      </div>
    </div>
    <div class="search-data-content" ref="table1">
      <vxe-table  :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source ref="chkTable" :export-config="{}" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="tabData" height="600" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',highlight: true}" :merge-cells="mergeData">
        <vxe-column field="id" title="id" width="80">
          <template #default="{ row }">
            {{row.id}}
          </template>
        </vxe-column>
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="fireTime" :title="showLang('com.date.time')" width="200">
          <template #default="{ row }">
            {{row.fireTime}}
          </template>
        </vxe-column>
        <vxe-column field="name" :title="showLang('com.device.pole.name')">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column field="en" :title="showLang('com.tab.lamp.cap')">
          <template #default="{ row }">
            {{row.en}}
          </template>
        </vxe-column>
        <vxe-column field="u" :title="showLang('com.lighting.V')+'(V)'">
          <template #default="{ row }">
            {{row.u}}
          </template>
        </vxe-column>
        <vxe-column field="c" :title="showLang('com.lighting.I')+'(A)'">
          <template #default="{ row }">
            {{row.c}}
          </template>
        </vxe-column>
        <vxe-column field="p" :title="showLang('com.lighting.P')+'(W)'">
          <template #default="{ row }">
            {{row.p}}
          </template>
        </vxe-column>
        <vxe-column field="op" :title="showLang('com.lighting.op')+'(%)'">
          <template #default="{ row }">
            {{row.op}}
          </template>
        </vxe-column>
        <vxe-column field="q" :title="showLang('com.stat.q')+'(KWh)'">
          <template #default="{ row }">
            {{row.q}}
          </template>
        </vxe-column>
      </vxe-table>
      <Spin size="large" fix v-if="loading"></Spin>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'SearchPowerboxIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      loading: false,
      curTab: 'group',
      tabHeight: 1,
      // tempHistoryData: TempHistoryData,
      pos: {},
      tabHead: { //头部标题
        name: "名字",
        sex: "性别",
        age: "年龄",
      },
      form: {
        stationId: 0,
        lightId: 0,
        start: "",
        end: "",
      },
      viewCode: '',
      showTable: true,
      history: [],
      searchLight: null,
      lights: [],
      dataRefresh: 0,
      tabData: [],
      mergeData: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    selectedNode() {
      this.setDefaultStation();
    },
    windowOnResize() {
      this.setTableHeight();
    },
    'form.stationId'() {
      this.initLights();
    },
  },
  mounted: function () {
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 1);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.setDefaultStation();
    // this.getList();
  },
  destroyed: function () {
  },
  methods: {
    deleteLog: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.del.record'));
        return;
      }
      // let list = chks.map(p => p.id);
      let ids = [];
      for(let item of chks){
        if(ids.filter(p => p.id == item.id).length > 0)continue;
        if(chks.filter(p => p.id == item.id).length == 2){
          ids.push({id: item.id, time: item.fireTime});
        }
      }
      console.log("select dels rows", chks, ids)
      let that = this;
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/param/DeleteHistory`, {paramId: this.form.lightId, ids: ids}).then(res => {
            if(res.code == 0){
              that.getList();
              setTimeout(() => {
                that.$Modal.info({
                  title: this.showLang('com.tips.del.success'),
                  content: this.showLang('com.tips.del.num.record',res.data),
                })
              }, 800);
            }
          });
        }
      });
    },
    deleteLogs: function(){
      let start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      let end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let that = this;
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.num.record',start,end),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/param/RemoveHistory`, {paramId: this.form.lightId, start: start, end: end}).then(res => {
            if(res.code == 0){
              setTimeout(() => {
                that.$Modal.info({
                  title: this.showLang('com.tips.del.success'),
                  content: this.showLang('com.tips.del.num.record',res.data),
                })
              }, 800);
            }
          });
        }
      });
    },
    initLights: function () {
      if (this.form.stationId <= 0) {
        this.$set(this, 'lights', []);
        this.form.lightId = 0;
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryLight`, { stationId: this.form.stationId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'lights', res.data);
          if (res.data.length > 0) {
            this.form.lightId = res.data[0].id;
          } else {
            this.form.lightId = 0;
          }
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 5;
    },
    setDefaultStation: function () {
      if (this.selectedStations.length == 0) {
        this.form.stationId = 0;
        return;
      }
      if (this.form.stationId == 0) {
        this.form.stationId = this.selectedStations[0].id;
        return;
      }
      let ss = this.selectedStations.filter(p => p.id == this.form.stationId)
      if (ss.length == 0) {
        this.form.stationId = this.selectedStations[0].id;
      }
    },
    exportData() {
      this.$refs.chkTable.openExport()
    },
    getList() {
      if (this.form.lightId == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.query.lamp'));
        return;
      }
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let params = { sid: this.form.stationId, paramId: this.form.lightId, start: this.form.start, end: this.form.end };
      let ls = this.lights.filter(p => p.id == this.form.lightId);
      if (ls.length > 0) {
        this.searchLight = ls[0];
      } else {
        this.searchLight = null;
      }
      this.$axios.post(`//${this.domains.trans}/station/param/history`, params).then(res => {
        this.loading = false;
        if (res.code == 0) {
          res.data.forEach(el => {
            for (let attr in el.content) {
              if (!isNaN(el.content[attr]) && el.content[attr] < 0) {
                el.content[attr] = 0
              }
            }
          });
          this.$set(this, 'history', res.data);
          this.makeData(res.data)
        }
      });
    },
    selectChangeEvent({rowIndex}) {
      this.tabData.forEach((el, index) => {
        if (el.lid == this.tabData[rowIndex].lid && index != rowIndex) {
          el.checked = this.tabData[rowIndex].checked
        }
      });
      this.$refs.chkTable.reloadRow(this.tabData, null, rowIndex)
    },
    spanData() {
      this.mergeData = [];
      let spanCols = [{
        name: this.showLang('com.export.cn.seq'),
        col: 0
      }, {
        name: this.showLang('com.op.select'),
        col: 1
      }, {
        name: this.showLang('com.date.time'),
        col: 2
      }, {
        name: this.showLang('com.device.pole.name'),
        col: 3
      }, {
        name: this.showLang('com.lighting.V') +'(V)',
        col: 5
      }, {
        name: this.showLang('com.stat.q')+'(KWh)',
        col: 9
      }];
      if (this.tabData.length < 2) {
        return false;
      }
      let data = "";
      let index = { row: 1, col: 1, rowspan: 1, colspan: 1 }
      for (let i = 0; i < this.tabData.length; i++) {
        if (this.tabData[i + 1] && this.tabData[i].lid == this.tabData[i + 1].lid) {
          if (index.rowspan == 1) {
            index.row = i;
          }
          index.rowspan++;
        } else {
          if (index.rowspan > 1) {
            for (let p = 0; p < spanCols.length; p++) {
              index.col = spanCols[p].col;
              data = JSON.parse(JSON.stringify(index));
              this.mergeData.push(data)
            }
          }
          index.rowspan = 1;
        }
      }
      this.dataRefresh++;
    },
    makeData(data) {
      let arr = [];
      data.forEach((el, index) => {
        let oneOb = {
          id: el.id,
          lid: index,
          name: this.searchLight.name,
          en: this.searchLight.content.en1 ? this.searchLight.content.nm1 : '--',
          fireTime: new Date(el.fireTime).format('yyyy-MM-dd HH:mm:ss'),
          u: el.content.u,
          c: this.img.val(el.content.c1, 2),
          p: el.content.p1,
          op: el.content.op1,
          q: el.content.q1,
        }
        let towOb = {
          id: el.id,
          lid: index,
          name: this.searchLight.name,
          en: this.searchLight.content.en2 ? this.searchLight.content.nm2 : '--',
          fireTime: new Date(el.fireTime).format('yyyy-MM-dd HH:mm:ss'),
          u: el.content.u,
          c: el.content.c2,
          p: el.content.p2,
          op: el.content.op2,
          q: el.content.q1,
        }
        arr.push(oneOb)
        arr.push(towOb)
      });
      this.$set(this, 'tabData', arr);
      this.spanData();
    },
  }
}
</script>
<style scoped>
.active {
  background-color: #f2f6fa;
}
td {
  height: 40px;
  white-space: nowrap;
  width: 120px;
  border: solid 1px #cfe3f7;
}
.sub-header {
  background-color: #cfe3f7;
  /* background: #CFE3F7; */
  height: 46px;
  text-align: center;
}
.history-item {
  /* border-width: 1px;
  border-collapse: collapse;
  border-color: cyan; */
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
}
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  overflow-y: auto;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
.table-data {
  overflow: auto;
}
</style>