<template>
<div class="device-item-content">
  <div class="device-item-header">
    <img class="device-item-header-icon" :src="img.pole.led.icon" title="LED广告屏" />
    <span class="device-item-header-title">{{item.name}}</span>
    <img class="device-item-header-status-icon" :src="online ? img.pole.online : img.pole.offline" />
    <span class="device-item-header-status">{{online ? '在线' : '离线'}}</span>
    <!-- <i-switch v-model="ledon" size="large" true-color="#ff4949" false-color="#13ce66" style="margin-right: 10px" @on-change="ledSwitchChanged">
      <span slot="open">关屏</span>
      <span slot="close">开屏</span>
    </i-switch> -->
  </div>
  <div style="width: 129px;height:220px;position: absolute;top: 75px;left: 20px;border: solid 1px #B4C5D6;border-radius: 3px;display: flex;align-items: center;">
    <!-- <video style="width: 100%;height:100%;border-radius: 4px;" :src="previewUrl" id="video0" autoplay loop muted @loadedmetadata="videoLoaded"></video> -->
    <video v-if="view.type == 'Video' && view.url != ''" :src="view.url" autoplay loop muted @loadedmetadata="videoLoaded" class="media-player">您的浏览器不支持 video 标签</video>
    <audio v-else-if="view.type == 'Audio' && view.url != ''" :src="view.url" autoplay loop controls="controls" @canplay="audioLoaded" class="media-player">您的浏览器不支持 audio 标签</audio>
    <img v-else-if="view.type == 'Image' && view.url != ''" :src="view.url" class="media-player" @load="imageLoaded" />
    <div v-else class="media-player">当前无播放任务</div>
  </div>
  <div style="position:absolute;right:25px;left:160px;top:65px;border:solid 0 red;">
    <div style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #1365BC; display: flex;align-items: center;height:42px;line-height:32px;">
      <div style="flex:auto">{{stateName}}</div>
      <!-- <div v-if="stateName == '手动任务状态'" @click="setLedAutoState" class="btn-item" style="width:70px;color: white;margin-right: 0">设为自动</div> -->
    </div>
    <div style="margin: 5px 0;width: 177px;height: 42px;background: #EEF3F8;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #1365BC;display:flex;align-items:center;">
      <img style="width: 18px;height:18px;margin:0 11px" :src="img.pole.led.play" />
      {{taskName}}
    </div>
    <div style="display: flex; align-items:center; width: 100%;height: 40px;border:solid 0px red;">
      <span>亮度</span>
      <Slider v-model="form.bright" :min="1" :max="255" @on-change="brightChange" style="width: 100px;flex: auto;margin-left: 10px"></Slider>
    </div>
    <div style="display: flex; align-items:center; width: 100%;height: 40px;border:solid 0px red;">
      <span>音量</span>
      <Slider v-model="form.vol" :min="0" :max="15" @on-change="volChange" style="width: 100px;flex: auto;margin-left: 10px"></Slider>
    </div>
  </div>
  <div class="float-btns">
    <div @click="handleScreenShot" class="btn-item">屏幕截图</div>
    <div @click="switchVideo" class="btn-item">更换节目</div>
    <div @click="ledSwitchChanged" class="btn-item" :style="{backgroundColor: ledon ? '#ff4949' : '#13ce66'}">{{ledon ? '关屏' : '开屏'}}</div>
  </div>
  <Spin fix v-if="spin.show">{{spin.content}}...{{spin.index}}</Spin>
  <Modal v-model="showDownloadModal" :mask-closable="false" draggable width="950px">
    <div slot="header">下发节目</div>
    <div class="modal-area">
      <div class="modal-tree-area">
        <Tree ref="tree" :data="treeData" @on-select-change="nodeChange"></Tree>
      </div>
      <div class="modal-program-area">
        <div class="media-item media-header">待选媒体列表</div>
        <div class="media-item" v-for="(item, idx) in medias" :key="idx">
          <div class="media-item-title">
            [{{mediaTypes[item.type]}}]{{item.name}}
          </div>
          <div class="media-item-btn" @click="previewItem(item)"> &gt;&gt; </div>
        </div>
      </div>
      <div class="modal-preview-area">
        <video v-if="previewMedia.type == 1 && previewUrl != ''" :src="previewUrl" controls="controls" @loadedmetadata="videoLoaded" class="media-player">您的浏览器不支持 video 标签</video>
        <audio v-else-if="previewMedia.type == 2 && previewUrl != ''" :src="previewUrl" controls="controls" @canplay="audioLoaded" class="media-player">您的浏览器不支持 audio 标签</audio>
        <img v-else-if="previewMedia.type == 3 && previewUrl != ''" :src="previewUrl" class="media-player" @load="imageLoaded" />
        <span v-else class="media-player">当前无预览节目</span>
      </div>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="handleModalClose">关闭</Button>
      <Button style="margin-right: 8px" @click="handleDownload">下发</Button>
    </div>
  </Modal>
  <Modal v-model="showCaptureModal" :mask-closable="false" draggable width="1000px">
    <div slot="header">截屏预览</div>
    <div style="text-align:center;width: 100%; height: 320px; overflow-y:auto">
      <img v-show="captureUrl != ''" style="width: 192px" :src="captureUrl" />
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="showCaptureModal = false">关闭</Button>
    </div>
  </Modal>
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'LedStationViewer',
  components:{
  },
  props: {
    item: {
      type: Object,
      default(){return {}},
    },
    tasks: {
      type: Array,
      default(){return []},
    },
  },
  data () {
    return {
      spin: {
        ticker: null,
        index: 0,
        show: false,
        content: '加载中...',
      },
      form: {
        bright: 0,
        vol: 0,
        type: 0,
      },
      ledon: false,
      showDownloadModal: false,
      showCaptureModal: false,
      captureUrl: '',
      previewUrl: '',
      currentIdx: -1,
      switchPreviewUrl: '',
      selectedProgram: {},
      previewName: '',
      groups: [],
      selectedGroup: {},
      programs: [],
      treeData: [],
      medias: [],
      previewMedia: {type: 0},
      view:{
        type: 0,
        url: '',
      },
      plays: [],
      playIdx: 0,
      ticker: null,
      video: null,
      audio: null,
      cmd: {
        _cmd: '',
        _cmdId: '',
        _message: '',
        _content: {},
        _status: 0,
      }
    }
  },
  computed: {
    ...mapState('auth', ['user', 'token']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['cmdResultRefresh', 'ledcmd', 'ledStateRefresh', 'ledState', 'commands']),
    ...mapState('common', ['mediaTypes']),
    online: function(){
      if(!this.item)return false;
      return this.item.online;
    },
    stateName: function(){
      if(!this.item.running)return '未设置任务状态';
      if(this.item.isTask) return "自动任务状态";
      return '手动任务状态';
    },
    taskName: function(){
      if(!this.item.running)return '未开机';
      if(!this.item.isTask)return '当前无任务';
      // if(!this.item.lastData.taskCfg.task)return '当前无任务';
      return this.item.taskName; //this.item.lastData.taskCfg.task.name;
    },
  },
  watch: {
    // item(){
    //   this.initLedState();
    // },
    cmdResultRefresh(){
      // console.log('led result refresh', this.commands)
      let cmds = this.commands.filter(p => p.id == this.item.id);
      if(cmds.length == 0)return;
      let cmd = cmds[0];
      if(cmd.status != 9)return;
      if(cmd.code == 'Screenshot'){
        if(!cmd.content.screenshot){
          this.showSpin(`未截取到图片`);
          setTimeout(this.hideSpin, 2000);
          return;
        }
        this.captureUrl = `data:image/jpeg;base64,${cmd.content.screenshot}`;
        this.showCaptureModal = true;
        this.hideSpin();
      }else if(cmd.code == 'CameraShot'){
        if(!cmd.content.screenshot){
          this.showSpin(`未截取到图片`);
          setTimeout(this.hideSpin, 2000);
          return;
        }
        this.captureUrl = `data:image/jpeg;base64,${cmd.content.screenshot}`;
        this.showCaptureModal = true;
        this.hideSpin();
      }else{
        this.showSpin(`执行成功`);
        setTimeout(this.hideSpin, 800);
      }
      this.initLedState();
    },
  },
  mounted: function(){
    window.eventBus.$on('ledCommandComing', params => {
      console.log('get led cmd result', this.cmd, this.item, params)
      if(!this.cmd)return;
      if(this.cmd._cmdId != params.commandId)return;
      // this.$set(this.cmd, '_cmd', params.code);
      this.$set(this.cmd, '_message', params.message);
      this.$set(this.cmd, '_content', params.content);
      this.$set(this.cmd, '_status', params.status);
      if (params.status == 0) {
        this.showSpin(`指令已进入队列`);
      } else if (params.status == 2){
        this.showSpin('等待设备应答');
      } else if(params.status == 7){
        this.showSpin(`指令超时：${this.cmd._message}`);
        setTimeout(this.hideSpin, 2000);
      } else if(params.status == 8) {
        this.showSpin(`执行失败：${this.cmd._message}`);
        setTimeout(this.hideSpin, 2000);
      } else if(params.status == 9){
        this.showSpin(`执行成功`);
        setTimeout(this.hideSpin, 1000);
        switch(this.cmd._cmd){
          case 'open':
          case 'close':
            this.$set(this.item, 'running', this.cmd._content.running);
            this.ledon = this.item.online && this.item.running;
            break;
          case 'setBright':
          case 'setAutoBright':
            this.$set(this.item, 'bright', this.cmd._content.bright);
            break;
          case 'setVolumn':
            this.$set(this.item, 'volume', this.cmd._content.volume);
            break;
          case 'capture':
            // this.$set(this.item, 'img', this.cmd._content.img);
            this.captureUrl = `data:image/jpeg;base64,${this.cmd._content.img}`;
            this.showCaptureModal = true;
            break;
          case 'playMedia':
            this.$set(this.item, 'mediaId', this.cmd._content.mediaId);
            this.$set(this.item, 'mediaName', this.cmd._content.mediaName);
            this.$set(this.item, 'isTask', this.cmd._content.isTask);
            this.$set(this.item, 'running', this.cmd._content.running);
            this.getCurrentMedia();
            break;
        }
      }
    })
    this.getGroups();
    this.initLedState();
  },
  destroyed: function () {
    window.eventBus.$off('ledCommandComing');
    // window.eventBus.$off('commandReplyComing');
  },
  methods: {
    initLedState: function(){
      // console.log('led init state', this.item)
      // if(!this.item.lastData)return;
      this.form.bright = this.item.bright || 0;
      this.form.vol = this.item.volume || 0;
      this.ledon = this.item.online && this.item.running;
      this.getCurrentMedia();
    },
    changePlayMedias: function(){
      if(this.plays.length == 0){
        this.view.type = 0;
        this.view.url = '';
      }else{
        let item = this.plays[this.playIdx];
        this.view.type = item.type;
        this.view.url = item.url;
        this.playIdx++;
        if(this.playIdx >= this.plays.length){
          this.playIdx = 0;
        }
      }
    },
    getCurrentMedia: function(){
      this.$axios.post(`//${this.domains.trans}/device/led/GetCurrentMedia`, {id: this.item.id}).then(res => {
        if(res.code == 0){
          this.plays = res.data;
          this.playIdx = 0;
          this.changePlayMedias();
          if(this.ticker){
            clearInterval(this.ticker);
          }
          this.ticker = setInterval(this.changePlayMedias, 10000);
        }else{
          this.view.type = 0;
          this.view.url = '';
        }
      });
    },
    previewItem: function(item){
      this.previewMedia = item;
      this.previewUrl = `http://${this.domains.trans}/station/file/media?id=${item.fileId}&auth=${this.token}`
    },
    getGroups: function(){
      this.$axios.get(`//${this.domains.trans}/media/group`).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'groups', res.data);
        let tree = [];
        this.initTreeData(tree, res.data, 0);
        this.$set(this, 'treeData', tree);
        if(tree.length > 0){
          this.nodeChange(tree, tree[0])
        }
      });
    },
    initTreeData: function(children, list, parentId){
      let ps = list.filter(p => p.parentId == parentId);
      for(let p of ps){
        let node = {id: p.id, title: p.name, data: p, children: [], expand: true}
        this.initTreeData(node.children, list, node.id);
        children.push(node);
      }
    },
    nodeChange: function(nodes, node){
      if(this.selectedGroup.id == node.id)return;
      this.selectedGroup = node;
      this.getMedias();
    },
    getMedias: function(){
      let groupId = 0;
      if(this.selectedGroup.id > 0){
        groupId = this.selectedGroup.id;
      }
      if(groupId == 0){
        this.$set(this, 'medias', []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/media/operation/GetConfirm`, {groupId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'medias', res.data);
      });
    },
    setFormType: function(){
      switch(this.item.extraData.state){ 
        case 1: { //自动任务
          this.form.type = 1;
          break;
        }
        case 2: { //手动任务
          this.form.type = 1;
          break;
        }
        case 3: { //联动任务
          this.form.type = 1;
          break;
        }
        default: {
          this.form.type = 0;
        }
      }
    },
    audioLoaded: function(e){
      this.video = null;
      this.audio = e.srcElement;
      e.srcElement.play();
    },
    imageLoaded: function(){
    },
    showSpin: function(msg){
      this.spin.content = msg;
      this.spin.index = 0;
      if(!this.spin.ticker){
        let that = this;
        this.spin.ticker = setInterval(() => {
          that.spin.index++;
          if(that.spin.index > 30){
            that.hideSpin();
          }
        }, 1000);
      }
      this.spin.show = true;
    },
    hideSpin: function(){
      this.spin.show = false;
      if(this.spin.ticker){
        clearInterval(this.spin.ticker);
        this.spin.ticker = null;
      }
    },
    sendCommand: function(ids, code, args){
      this.$set(this.cmd, '_cmd', code);
      this.$set(this.cmd, '_cmdId', '');
      this.$set(this.cmd, '_message', "指令已创建");
      this.$set(this.cmd, '_content', null);
      this.$set(this.cmd, '_status', 0);
      setTimeout(() => {
        this.$axios.post(`//${this.domains.trans}/device/led/SendCommand`, {code, list: ids, args}).then(res => {
          if(res.code != 0){
            // this.$Message.error(res.data);
          }else{
            // console.log('send led cmd result', res.data, this.list)
            for(let cmd of res.data.list){
              if(cmd.id != this.item.id)continue;
              let message = '指令已发送', status = 2, cmdId = cmd.message;
              if(!cmd.success){
                message = cmd.message;
                status = 8;
                cmdId = '';
                this.showSpin(`执行失败：${message}`);
                setTimeout(this.hideSpin, 2000);
              }
              this.$set(this.cmd, '_cmd', code);
              this.$set(this.cmd, '_cmdId', cmdId);
              this.$set(this.cmd, '_message', message);
              this.$set(this.cmd, '_content', null);
              this.$set(this.cmd, '_status', status);
            }
          }
        });
      }, 300);
    },
    brightChange: function(){
      this.sendCommand([this.item.id], 'setBright', {bright: this.form.bright})
    },
    volChange: function(){
      this.sendCommand([this.item.id], 'setVolumn', {volume: this.form.vol})
    },
    switchVideo: function(){
      this.showDownloadModal = true;
    },
    videoLoaded: function(v){
      this.audio = null;
      this.video = v.srcElement;
      v.srcElement.play();
    },
    ledSwitchChanged: function(){
      let act = !this.ledon;
      this.sendCommand([this.item.id], act ? 'open' : 'close', {});
    },
    handleScreenShot: function(){
      this.sendCommand([this.item.id], 'capture', {});
    },
    setLedAutoState: function(){
      this.sendCommand([this.item.id], 'playTask', {taskId: 0});
      this.$Message.info('已设为自动');
      // if(!this.item.code){
      //   this.$Message.warning('当前未绑定设备');
      //   return;
      // }
      // this.$axios.post(`//${this.domains.trans}/pole/led/SetAuto`, {ids: [this.item.id]}).then(res => {
      //   if(res.code != 0){
      //     // this.$Message.error(res.data);
      //     return;
      //   }
      //   this.$Message.info('已设为自动');
      // });
    },
    handleDownload: function(){
      this.sendCommand([this.item.id], 'playMedia', {mediaId: this.previewMedia.id});
      // if(!this.item.code){
      //   this.$Message.warning('当前未绑定设备');
      //   return;
      // }
      // if(!this.previewMedia.id){
      //   this.$Message.warning('请选择要下发的节目');
      //   return;
      // }
      // this.$axios.post(`//${this.domains.trans}/pole/led/PlayMedia`, {ids: [this.item.id], mediaId: this.previewMedia.id}).then(res => {
      //   if(res.code != 0){
      //     // this.$Message.error(res.data);
      //     return;
      //   }
      //   let result = res.data[this.item.id];
      //   if(result.success){
          this.$Message.info('指令已下发');
          if(this.video)this.video.pause();
          if(this.audio)this.audio.pause();
          this.showDownloadModal = false;
      //   }else{
      //     this.$Message.warning(result.content);
      //   }
      // });
    },
    handleModalClose: function(){
      this.showDownloadModal = false;
      if(this.video)this.video.pause();
      if(this.audio)this.audio.pause();
    },
  }
}
</script>
<style scoped>

.media-item{
  border: solid 1px rgba(143, 142, 142, 0.404);
  border-radius: 4px;
  height: 30px;
  flex: none;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}
.media-header{
  background-color: rgba(7, 7, 241, 0.342);
  color: white;
  font-size: 20px;
}
.media-item:hover{
  background-color: rgba(109, 161, 161, 0.247);
}
.media-item-title{
  /* border: solid 1px rgba(143, 142, 142, 0.404); */
  width: 100px;
  flex: auto;
}
.media-item-btn{
  border: solid 1px rgba(143, 142, 142, 0.404);
  border-radius: 4px;
  width: 25px;
  flex: none;
  color: blue;
  text-align: center;
  margin: 3px;
}
.modal-area{
  /* border: solid 1px red; */
  display: flex;
  height: 500px;
}
.modal-tree-area{
  border: solid 1px rgba(90, 85, 85, 0.205);
  width: 250px;
  flex: none;
  margin-right: 10px;
  overflow-y: auto;
}
.modal-program-area{
  border: solid 1px rgba(90, 85, 85, 0.205);
  width: 350px;
  flex: none;
  margin-right: 10px;
  padding: 5px;
  overflow-y: auto;
}
.modal-preview-area{
  border: solid 1px rgba(90, 85, 85, 0.205);
  width: 300px;
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.media-player{
  width: 100%;
  max-height:100%;
  border-radius: 4px;
  text-align: center;
}
.float-btns{
  /* border: solid 1px red; */
  position:absolute;
  height: 32px;
  width: 300px;
  right:20px;
  bottom:20px;
  font-size: 14px;
  border-radius: 4px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
}
.btn-item{
  width:74px;
  height:32px;
  line-height:32px;
  text-align:center;
  cursor:pointer;
  background: #1365BC;
  border-radius: 4px;
  margin-left: 10px;
}
.device-item-content{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.device-item-header{
  height: 48px;
  /* background: #E8F1FA; */
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
}
.device-item-header-icon{
  width: 20px;
  height: 20px;
  margin-left: 25px;
  margin-right: 10px;
  flex: none;
}
.device-item-header-title{
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  width: 10px;
  flex: auto;
}
.device-item-header-status-icon{
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
.device-item-header-status{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  flex: none;
  margin-right: 25px;
}
</style>
