<template>
  <div class="people-con">
    <div class="people-search">
      <span class="people-title">统计类型</span>
      <Select v-model="tool" class="tool-type">
        <template v-for="item in tools">
          <Option :value="item.code" :key="item.code">{{item.name}}</Option>
        </template>
      </Select>
      <span class="people-title">时间</span>
      <DatePicker v-model="start" type="datetime" format="yyyy-MM-dd HH:mm" placeholder="请选择开始时间" style="margin-right: 10px; width: 155px;"></DatePicker>
      <a href="javascript:void(0);" class="people-search-but">
        <img :src="img.light.factory.light.lampList.but8" alt="">搜索
      </a>
    </div>
    <div class="people-chart">
      <chartsLine :option="chartsLineData" id="chartsLineData" :key="timer"></chartsLine>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  data() {
    return {
      start: "",
      tool: "s1",
      timer: 0,
      chartsLineData: {
        id: "chartsLineData",
        title: '',
        x: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun5"],
        unit: '%',
        series: [
          {
            name: '线1',
            data: [180, 130, 154, 338, 215, 207, 312],
          },
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {}//下阴影
        }
      },
      tools: [
        { name: "-日统计-", code: "s1" },
        { name: "-月统计-", code: "s2" },
        { name: "-年统计-", code: "s3" },
      ]
    }
  },
  watch: {

  },
  mounted: function () {
    let now = new Date();
    now.setDate(now.getDate() - 1);
    this.start = now.format('yyyy-MM-dd HH:mm:00');
  },
  methods: {

  }
}
</script>
<style scoped>
.people-con {
  height: 100%;
}
.player-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.people-search {
  margin-top: 20px;
}
.tool-type {
  width: 150px;
  flex: auto;
  margin-right: 10px;
}
.people-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
  margin: 0 15px;
}
.people-search-but img {
  vertical-align: middle;
  width: 14px;
  height: 14px;
  margin-right: 3px;
}
.people-search-but {
  width: 67px;
  height: 32px;
  background: #007eff;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  line-height: 32px;
}
.people-chart {
  width: 100%;
  height: calc(100% - 80px);
  margin-top: 20px;
}
</style>
