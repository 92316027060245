<template>
  <div class="station-alarm-container">
    <div class="station-alarm-tabs">
      <template v-for="(item, idx) in tabs">
        <div v-if="funCodes(...item.opCodes)" :key="idx" :class="['station-alarm-tab', curTab == item.code ? 'tab-active' : '']" @click="switchTab(item.code)">{{showLang(item.lang)}}</div>
      </template>
    </div>
    <div class="station-alarm-tabcontent">
      <template v-for="(item, idx) in tabs">
        <component v-if="item.code == curTab && funCodes(...item.opCodes)" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
// import AlarmHour24 from './hour24/Index'
// import AlarmHour48 from './hour48/Index'
// import AlarmOther from './other/Index'
// import AlarmDone from './done/Index'
import AlarmOffline from './offline/Index'
import AlarmPowerboxCurrent from './powerbox/Current'
import AlarmPowerboxHistory from './powerbox/History'
import AlarmLightCurrent from './light/Current'
import AlarmLightHistory from './light/History'
import AlarmReportCurrent from './report/Current'
import powerLineError from './light/powerLineError'
export default {
  name: 'StationAlarmIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      curTab: 's1',
      tabs: [
        { code: 's7', lang: 'manage.nav.offline.list', name: '3小时内离线', opCodes: ['aos'], com: AlarmOffline, args: { start: 0, end: 3 } },
        { code: 's1', lang: 'alarm.powerbox.current', name: '配电柜当前报警', opCodes: ['ars', 'arc', 'ard1'], com: AlarmPowerboxCurrent, args: {} },
        { code: 's2', lang: 'alarm.powerbox.history', name: '配电柜历史报警', opCodes: ['ars', 'arc', 'ard1'], com: AlarmPowerboxHistory, args: {} },
        { code: 's3', lang: 'alarm.light.current', name: '单灯当前报警', opCodes: ['ars', 'arc', 'ard1'], com: AlarmLightCurrent, args: {} },
        { code: 's4', lang: 'alarm.light.history', name: '单灯历史报警', opCodes: ['ars', 'arc', 'ard1'], com: AlarmLightHistory, args: {} },
        { code: 's5', lang: 'alarm.report.current', name: '人工报障', opCodes: ['ars', 'arc', 'ard1'], com: AlarmReportCurrent, args: {} },
        { code: 's8', lang: 'alarm.light.powerLineError', name: '单灯供电统计', opCodes: ['7630'], com: powerLineError, args: {} },
        // {code: 's8', lang:'manage.nav.offline24',name: '24小时内离线', opCodes: ['aos'], com: AlarmOffline, args: {start: 3, end: 24}},
        // {code: 's9', lang:'manage.nav.offline25',name: '24小时外离线', opCodes: ['aos'], com: AlarmOffline, args: {start: 24, end: 24000}},
        // {code: 's1', lang:'manage.nav.alarm24',name: '24小时内报警', opCodes: ['ars', 'arc', 'ard1'], com: AlarmHour24, args: {}},
        // {code: 's2', lang:'manage.nav.alarm48',name: '48小时内报警', opCodes: ['ars', 'arc', 'ard1'], com: AlarmHour48, args: {}},
        // {code: 's3', lang:'manage.nav.alarm49',name: '48小时外报警', opCodes: ['ars', 'arc', 'ard1'], com: AlarmOther, args: {}},
        // {code: 's4', lang:'manage.nav.alarm.remove',name: '已消除报警', opCodes: ['ars', 'arc', 'ard1'], com: AlarmDone, args: {}},

      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
  },
  mounted: function () {
    this.$store.dispatch('common/getAlarmTypes');
  },
  destroyed: function () {
  },
  methods: {
    switchTab: function (type) {
      this.curTab = type;
    },
  }
}
</script>
<style scoped>
.station-alarm-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px red; */
  background-color: white;
}
.station-alarm-tabs {
  height: 48px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 2px 10px;
  cursor: pointer;
  background-color: #fff;
  color: #324252;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 48px;
}
.tab-active {
  color: #021629;
  font-weight: bold;
}
.tab-active::after {
  content: "";
  width: calc(100%);
  height: 1px;
  display: block;
  margin: -5px auto 0;
  border-bottom: 2px solid #3880fc;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px blue; */
  overflow: hidden;
}
</style>