<template>
  <div class="device-item-content">
    <template v-for="(item,index) in devices">
      <div class="device-info" :key="index" ref="infoHight">
        <div class="device-info-img">
          <img :src="getImage(item)" alt="">
        </div>
        <div class="device-info-tab">
          <div class="device-info-icon">
            <img :src="img.pole.asset.index.name" alt="">
          </div>
          <div class="device-info-con">
            <div class="device-info-val">{{item.name}}</div>
            <div class="device-info-title">设备名称</div>
          </div>
        </div>
        <div class="device-info-tab">
          <div class="device-info-icon"><img :src="img.pole.asset.index.pole" alt=""></div>
          <div class="device-info-con">
            <div class="device-info-val"> {{item.stationName}}</div>
            <div class="device-info-title">所属灯杆</div>
          </div>
        </div>
        <div class="device-info-tab">
          <div class="device-info-icon"><img :src="img.pole.asset.index.type" alt=""></div>
          <div class="device-info-con">
            <div class="device-info-val">{{item.connectTypeName}}</div>
            <div class="device-info-title">设备类型</div>
          </div>
        </div>
        <div class="device-info-tab">
          <div class="device-info-icon"><img :src="img.pole.asset.index.date" alt=""></div>
          <div class="device-info-con">
            <div class="device-info-val">{{item.buildDate}}</div>
            <div class="device-info-title">安装日期</div>
          </div>
        </div>
      </div>
    </template>
    <div class="device-chart" style="height:560px">
      <recordPage :devId="devId" />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import recordPage from '../history/Index'
export default {
  name: 'deviceList',
  components: {
    recordPage
  },
  props: {
    devices: {
      type: Array,
      default(){return []},
    },
    oneId: {
      type: String,
      default: "",
    }
  },
  data() {
    return {

      timer: 0,
      filter: {
        start: "",
        end: "",
      },
      devId: -1,
      chartHeight: 600,
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    selectedNode() {
      this.setHigt();
    },
  },
  mounted: function () {
    let now = new Date();//getDate
    now.setHours(now.getHours() + 1)
    this.filter.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 7);
    this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');

    this.setHigt();
  },
  methods: {
    setHigt() {
      if (this.$refs.infoHight.length > 3) {
        return false;
      }
      let num = this.$refs.infoHight[0].clientHeight == 89 ? 89 : 159;
      if (this.$refs.infoHight.length == 1) {
        this.chartHeight = num == 89 ? 620 : 520;
      }
      if (this.$refs.infoHight.length == 2) {
        this.chartHeight = num == 89 ? 500 : 370;
      }
    },
    getImage: function (item) {
      if (!item.online) return this.img.pole.icons.garbage.offline3;
      return this.img.pole.icons.garbage.online3;
    },
  }
}
</script>
<style scoped>
.cameraInfo-search-but {
  padding: 3px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
  margin-left: 19px;
}
.cameraInfo-search-but img {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
}
.device-item-content {
  width: 100%;
}
.device-info {
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 20px;
  margin-bottom: 14px;
}
.device-info-img {
  text-align: center;
  margin-right: 30px;
  margin-top: 15px;
}
.device-info-img img {
  margin-top: 2px;
}
.device-info-tab {
  display: flex;
  justify-content: flex-start;
}
.device-chart {
  width: calc(100%);
  background: #fff;
  border-radius: 10px;
}
.device-info-icon img {
  margin-top: 5px;
  margin-right: 14px;
}
.device-info-con {
  width: 140px;
}
.device-info-val {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
}
.device-info-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8793a5;
}
</style>
