<template>
  <Modal v-model="showModal" :mask-closable="false" draggable width="1500px">
    <div slot="header">召测结果查询</div>
    <Form :model="form" :label-width="80" inline>
      <FormItem :label="showLang('com.date.start')">
        <DatePicker v-model="form.start" type="datetime" format="yyyy-MM-dd HH:mm" style="margin-right: 10px; width: 155px;"></DatePicker>
      </FormItem>
      <FormItem :label="showLang('com.date.end')">
        <DatePicker v-model="form.end" type="datetime" format="yyyy-MM-dd HH:mm" style="margin-right: 10px; width: 155px;"></DatePicker>
      </FormItem>
      <FormItem :label-width="0">
        <Button type="primary" style="margin-right: 5px" :loading="loading" @click="getHistory">{{showLang('com.op.query.history')}}</Button>
        <Button type="primary" style="margin-right: 5px" :loading="loading" @click="getLast">{{showLang('com.op.query.last')}}</Button>
        <Button type="success" :loading="exportLoading" @click="exportData">{{showLang('com.op.export')}}</Button>
      </FormItem>
    </Form>
    <div class="search-data-content" ref="table1">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :data="history" :height="tabHeight" :row-config="{isHover: true}" :export-config="{}">
        <vxe-column field="seq" type="seq" width="60"></vxe-column>
        <vxe-column field="stationName" :title="showLang('com.site.name')" sortable width="150" header-align="center">
          <template #default="{ row }">
            {{row.stationName}}
          </template>
        </vxe-column>
        <vxe-column field="fireTime" :title="showLang('com.date.time')" sortable width="150" header-align="center">
          <template #default="{ row }">
            {{row.fireTime}}
          </template>
        </vxe-column>
        <vxe-column field="branchName" :title="showLang('com.device.branch.name')" sortable width="150" header-align="center">
          <template #default="{ row }">
            {{row.branchName}}
          </template>
        </vxe-column>
        <vxe-column field="ca" :title="showLang('cmd.project.A.I')" sortable width="100" header-align="center">
          <template #default="{ row }">
            {{fd(row.ca)}} A
          </template>
        </vxe-column>
        <vxe-column field="cb" :title="showLang('cmd.project.B.I')" sortable width="100" header-align="center">
          <template #default="{ row }">
            {{fd(row.cb)}} A
          </template>
        </vxe-column>
        <vxe-column field="cc" :title="showLang('cmd.project.C.I')" sortable width="100" header-align="center">
          <template #default="{ row }">
            {{fd(row.cc)}} A
          </template>
        </vxe-column>
        <vxe-column field="outputName" :title="showLang('com.ins.ch.name')" sortable width="150" header-align="center">
          <template #default="{ row }">
            {{row.outputName}}
          </template>
        </vxe-column>
        <vxe-column field="outputChannel" :title="showLang('com.ins.ch.order')" sortable width="100" header-align="center">
          <template #default="{ row }">
            K{{row.outputChannel}}
          </template>
        </vxe-column>
        <vxe-column field="outputValue" :title="showLang('com.output.status')" sortable width="100" header-align="center">
          <template #default="{ row }">
            {{ov(row.outputValue)}}
          </template>
        </vxe-column>
        <vxe-column field="ua" :title="showLang('cmd.project.A.V')" sortable width="100" header-align="center">
          <template #default="{ row }">
            {{fd(row.ua)}} V
          </template>
        </vxe-column>
        <vxe-column field="ub" :title="showLang('cmd.project.B.V')" sortable width="100" header-align="center">
          <template #default="{ row }">
            {{fd(row.ub)}} V
          </template>
        </vxe-column>
        <vxe-column field="uc" :title="showLang('cmd.project.C.V')" sortable width="100" header-align="center">
          <template #default="{ row }">
            {{fd(row.uc)}} V
          </template>
        </vxe-column>
        <vxe-column field="timeName" :title="showLang('com.export.cn.plan.relate')" sortable width="150" header-align="center">
          <template #default="{ row }">
            {{row.timeName}}
          </template>
        </vxe-column>
        <vxe-column field="timeValue" :title="showLang('com.ins.timeTable.status')" sortable width="150" header-align="center">
          <template #default="{ row }">
            {{row.timeValue == 1 ? showLang('com.state.lighting.period') : showLang('alarm.type.power.close.period')}}
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'ModalForceRead',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      exportLoading: false,
      tabHeight: 600,
      form: {
        start: '',
        end: '',
        groupId: 0,
        stationId: 0,
      },
      history: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['needCheckPswd','showLang']),
    ...mapState('group', ['groups', 'selectedNode', 'selectedGroups', 'rootTitle']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        let now = new Date();
        now.setMinutes(now.getMinutes() + 15);
        this.form.end = now.format('yyyy-MM-dd HH:mm');
        now.setMinutes(now.getMinutes() - 30);
        this.form.start = now.format('yyyy-MM-dd HH:mm');
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  methods: {
    fd: function(v){
      if(v < 0)return '-';
      return v.toFixed(2)
    },
    ov: function(v){
      return v == 1 ? this.showLang('com.state.close') : this.showLang('com.state.break')
    },
    getHistory: function(){
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == 'root' || this.selectedNode.type == 'group'){
        groupId = this.selectedNode.id;
      }else{
        stationId = this.selectedNode.id;
      }
      let params = {
        start: new Date(this.form.start).format('yyyy-MM-dd HH:mm:00'),
        end: new Date(this.form.end).format('yyyy-MM-dd HH:mm:00'),
        groupId: groupId,
        stationId: stationId,
      };
      // console.log('get history', params);
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/analyse/QueryBoxHistory`, params).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'history', res.data);
        }
      });
    },
    getLast: function(){
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == 'root' || this.selectedNode.type == 'group'){
        groupId = this.selectedNode.id;
      }else{
        stationId = this.selectedNode.id;
      }
      let params = {
        groupId: groupId,
        stationId: stationId,
      };
      // console.log('get history', params);
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/analyse/QueryBoxLast`, params).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'history', res.data);
        }
      });
    },
    exportData: function(){
      let cmdData = [];
      let seq = 1;
      this.history.map(item => {
        cmdData.push({
          seq: seq++,
          stationName: item.stationName,
          fireTime: item.fireTime,
          branchName: item.branchName,
          ca: this.fd(item.ca),
          cb: this.fd(item.cb),
          cc: this.fd(item.cc),
          outputName: item.outputName,
          outputChannel: `K${item.outputChannel}`,
          outputValue: this.ov(item.outputValue),
          ua: this.fd(item.ua),
          ub: this.fd(item.ub),
          uc: this.fd(item.uc),
          timeName: item.timeName,
          timeValue: item.timeValue == 1 ? this.showLang('com.state.lighting.period') : this.showLang('alarm.type.power.close.period'),
        })
      });
      let tabHead = {
        seq:'#',
        stationName:this.showLang('com.site.name'),
        fireTime: this.showLang('com.date.time'),
        branchName:this.showLang('com.device.branch.name'),
        ca:this.showLang('cmd.project.A.I'),
        cb:this.showLang('cmd.project.B.I'),
        cc:this.showLang('cmd.project.C.I'),
        outputName:this.showLang('com.ins.ch.name'),
        outputChannel:this.showLang('com.ins.ch.order'),
        outputValue:this.showLang('com.output.status'),
        ua:this.showLang('cmd.project.A.V'),
        ub:this.showLang('cmd.project.B.V'),
        uc:this.showLang('cmd.project.C.V'),
        timeName:this.showLang('com.export.cn.plan.relate'),
        timeValue:this.showLang('com.ins.timeTable.status'),
      }
      exportXlsx(cmdData, tabHead, this.showLang('com.ins.get.result'));
    },
    cancel () {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.demo-drawer-footer{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form{
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly{
  background: #eee
}
</style>