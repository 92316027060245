<template>
  <div class="tool-container">
    <span class="tool-operation-title">预置点</span>
    <Select v-model="tool" clearable class="tool-type">
      <template v-for="(el, index) in tools">
        <Option :value="el.id" :key="index">{{el.name}}</Option>
      </template>
    </Select>
    <img :src="img.pole.icons.video.presetAdd" alt="" @click="addPreset()">
    <img :src="img.pole.icons.video.presetDel" alt="">
    <a href="javascript:void(0);" class="people-search-but" @click="movePoint()">转到</a>
    <Modal v-model="modalShow" title="添加预置点" @on-ok="okPresetName">
      <Input v-model="presetName" placeholder="请输入预置点名称" style="width: 300px"></Input>
    </Modal>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
  },
  props: {
    videlID: {
      type: Number,
      default: -1
    },
  },
  data() {
    return {
      modalShow: false,
      presetName: "",
      tools: {},
      tool: -1,
      open: false,
    }
  },
  watch: {
    open() {
      this.$emit('action', { open: this.open, tool: this.tool })
    },
  },
  mounted: function () {
    this.getPoints()
  },
  methods: {
    getPoints: async function () {//查询设备所有预置点
      let res = await this.$axios.post(`//${this.domains.trans}/pole/video/GetPoints`, { id: this.videlID });
      if (res.code != 0) {
        return false;
      }
      this.$set(this, "tools", res.data);
    },
    addPreset() {
      this.modalShow = true;
    },
    okPresetName() {
      if (this.presetName == "") {
        this.$Message.info('请输入预置点名称');
        return false;
      }
      this.addPoint();
    },
    addPoint: async function () {//设置当前设备预置点
      let res = await this.$axios.post(`//${this.domains.trans}/pole/video/AddPoint`, { id: this.videlID, name: this.presetName });
      if (res.code != 0) {
        this.$Message.warning('添加预置点失败');
        return false;
      }
      this.getPoints();
    },
    removePoint() {
      if (this.tool == undefined || this.tool == -1) {
        this.$Message.info('请选择删除的预置点');
        return false;
      }
      this.$Modal.info({
        title: "删除设备预置点",
        content: "你确定删除这个预置点",
        onOk: () => {
          this.removePointAjax()
        },
      });

    },
    removePointAjax: async function () {//删除设备预置点
      let res = await this.$axios.post(`//${this.domains.trans}/pole/video/RemovePoint`, { id: this.videlID, index: this.tool });
      if (res.code != 0) {
        this.$Message.warning('删除预置点失败');
        return false;
      }
      this.$Message.success('删除预置点成功');
    },
    movePoint: async function () {//移动当前设备到预置点  
      if (this.tool == undefined || this.tool == -1) {
        this.$Message.info('请选择预置点');
        return false;
      }
      let res = await this.$axios.post(`//${this.domains.trans}/pole/video/MovePoint`, { id: this.videlID, index: this.tool });
      if (res.code != 0) {
        this.$Message.warning('移动预置点失败');
        return false;
      }
    },
  }
}
</script>
<style scoped>
.tool-container {
  width: 100%;
  display: flex;
}
.tool-container img {
  width: 28px;
  height: 28px;
  margin: 0 5px;
}

.tool-operation-title {
  width: 55px;
  line-height: 32px;
}
.tool-type {
  width: 110px;
  margin: 0 15px 0 0;
}
.tool-act {
  width: 55px;
  flex: none;
}
.people-search-but {
  padding: 5px 8px;
  background: #007eff;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  margin-left: 5px;
}
</style>
