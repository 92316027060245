<template>
<div class="config-station-container">
  <div class="station-search-area">
    <Button type="primary" size="default" v-for="(cmd, idx) in items" :key="idx" style="margin-right: 5px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
    <Button opCode='sc' type="success" size="default" style="margin-right: 5px" @click="sendFailed">重发失败</Button>
    <div class="row-space"></div>
    <Input v-model="filter.name" style="width: 300px" search clearable placeholder="查询关键字" />
  </div>
  <div class="station-data-area" ref="table">
    <u-table ref="chkTable" :data="devs" use-virtual style="width: 100%;" row-key="id" border stripe size="mini" :height="tabHeight">
      <u-table-column type="selection" label="" width="50"></u-table-column>
      <u-table-column type="index" label="序号" width="50"></u-table-column>
      <u-table-column prop="online" label="状态" width="50">
        <template slot-scope="params">
          <img :src="params.row.online ? img.pole.icons.env.online : img.pole.icons.env.offline" />
        </template>
      </u-table-column>
      <u-table-column prop="name" label="名称" sortable width="200">
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <u-table-column prop="stationId" label="所在灯杆" sortable width="200">
        <template slot-scope="params">
          {{params.row.stationName}}
        </template>
      </u-table-column>
      <u-table-column prop="type" label="连接方式" sortable width="200">
        <template slot-scope="params">
          {{params.row.connectTypeName}}
        </template>
      </u-table-column>
      <u-table-column prop="type" label="设备通信ID" sortable width="200">
        <template slot-scope="params">
          {{params.row.code}}
        </template>
      </u-table-column>
      <u-table-column prop="da" label="风向角度" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.da, 0)}}
        </template>
      </u-table-column>
      <u-table-column prop="dl" label="风向档位" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.dl, 0)}}
        </template>
      </u-table-column>
      <u-table-column prop="spd" label="风速" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.spd, 1)}}
        </template>
      </u-table-column>
      <u-table-column prop="pow" label="风力" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.pow, 0)}}
        </template>
      </u-table-column>
      <u-table-column prop="h" label="湿度" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.h, 1)}}
        </template>
      </u-table-column>
      <u-table-column prop="t" label="温度" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.t, 1)}}
        </template>
      </u-table-column>
      <u-table-column prop="n" label="噪声" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.n, 1)}}
        </template>
      </u-table-column>
      <u-table-column prop="pm25" label="PM2.5" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.pm25, 0)}}
        </template>
      </u-table-column>
      <u-table-column prop="pm10" label="PM10" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.pm10, 0)}}
        </template>
      </u-table-column>
      <u-table-column prop="pre" label="大气压值" sortable width="100">
        <template slot-scope="params">
          {{img.val(params.row.lastData.pre, 1)}}
        </template>
      </u-table-column>
      <u-table-column prop="lux" label="光照度" sortable width="90">
        <template slot-scope="params">
          {{img.val(params.row.lastData.lux, 0)}}
        </template>
      </u-table-column>
      <u-table-column prop="rain" label="雨量" sortable width="90">
        <template slot-scope="params">
          {{img.val(params.row.lastData.rain, 0)}}
        </template>
      </u-table-column>
      <u-table-column prop="install" label="最后通信时间" sortable width="170">
        <template slot-scope="params">
          {{!params.row.fireTime ? '-' : new Date(params.row.fireTime).format('yyyy-MM-dd HH:mm:ss')}}
        </template>
      </u-table-column>
      <!-- <u-table-column width="300" label="执行结果" fixed="right">
        <template slot-scope="params">
          {{getCmdStatus(params.row)}}
        </template>
      </u-table-column> -->
      <u-table-column width="300" label="执行结果" fixed="right">
        <template slot-scope="params">
          {{params.row.message}}
        </template>
      </u-table-column>
    </u-table>
  </div>
  <!-- <ModalEnvEdit v-model="showEditModal" :item="editItem" /> -->
</div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'PoleBroadIndex',
  components:{
    // ModalEnvEdit,
  },
  data () {
    return {
      clearOldLog: false,
      showEditModal: false,
      tabHeight: 300,
      editItem: {isAdd: true, index: -1, data: {}},
      filter: {
        name: '',
      },
      devs: [],
      items: [
        { needArgs: false, needPswd: false, code: 'forceRead', name: '召测数据', img: this.img.light.road.single.ctrl.forceRead },
      ],
      cmdResults: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['windowOnResize', 'commands', 'cmdResultRefresh']),
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedNode']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    selectedNode(){
      this.initDevs();
    },
    // cmdResultRefresh() {
    //   // console.log('click commands', this.commands, this.cmdResultRefresh);
    //   for (let c of this.commands) {
    //     let its = this.devs.filter(p => p.id == c.id);
    //     if (its.length == 0) continue;
    //     this.$set(its[0], 'status', c.status)
    //     this.$set(its[0], 'result', c.result)
    //     this.$set(its[0], 'cmd', c.code)
    //     this.$set(its[0], 'content', c.content)
    //     if(c.code == 'forceRead' && c.status == 9){
    //       this.$set(its[0].lastData, 'da', c.content.directAngle);
    //       this.$set(its[0].lastData, 'dl', c.content.directLevel);
    //       this.$set(its[0].lastData, 'h', c.content.humidity);
    //       this.$set(its[0].lastData, 'lux', c.content.lux);
    //       this.$set(its[0].lastData, 'n', c.content.noise);
    //       this.$set(its[0].lastData, 'pm10', c.content.pm10);
    //       this.$set(its[0].lastData, 'pm25', c.content.pm25);
    //       this.$set(its[0].lastData, 'pow', c.content.power);
    //       this.$set(its[0].lastData, 'pre', c.content.press);
    //       this.$set(its[0].lastData, 'spd', c.content.speed);
    //       this.$set(its[0].lastData, 't', c.content.temperature);
    //       this.$set(its[0].lastData, 'rain', c.content.rainLevel);
    //       this.$set(its[0], 'fireTime', c.content.time);
    //       this.$set(its[0], 'online', true)
    //     }
    //   }
    // },
  },
  mounted: function(){
    setTimeout(() => {
      this.setTableHeight();
    }, 200);
    this.initDevs();
    this.timer = setInterval(this.setCmdResult, 500);
    window.eventBus.$on('paramCommandComing', params => {
      this.cmdResults[params.cmdId] = params;
    });
  },
  destroyed: function () {
    window.eventBus.$off('paramCommandComing');
    clearInterval(this.timer);
  },
  methods: {
    setCmdResult: function () {
      for (let cmdId in this.cmdResults) {
        let ds = this.devs.filter(p => p.cmdId == cmdId);
        if (ds.length == 0) {
          continue;
        } else {
          let item = this.cmdResults[cmdId];
          this.$set(ds[0], 'status', item.status);
          this.$set(ds[0], 'result', item.message);
          this.$set(ds[0], 'content', item.content);
          this.$set(ds[0], 'message', this.getCmdStatus(ds[0]));
          delete this.cmdResults[cmdId]
        }
      }
    },
    getCmdStatus: function (cmd) {
      switch (cmd.status) {
        case 0: return `${cmd.result}`;
        case 1: return '等待回复';
        case 2: return '指令超时';
        case 3: return `执行失败：${cmd.result}`;
        case 4: return `执行中：${cmd.content.pkg} / ${cmd.content.total}`;
        case 5: return `失败重试：${cmd.result}`;
        case 9:
          // console.log('getCmdStatus', cmd)
          // console.log('cmd status', cmd)
          switch (cmd.cmd) {
            case 'getclock': return `执行成功：${cmd.content.nowTime}`;
            case 'getInfo': return `硬件版本：${cmd.content.hardware}，软件版本：${cmd.content.software}，生产厂商：${cmd.content.manufacture}`;
            case 'getSimInfo': return `ICCID：${cmd.content.iccid}，IMEI：${cmd.content.imei}，RSSI：${cmd.content.rssi}`;
            case 'getNetwork': return `IP:${cmd.content.host}, 端口:${cmd.content.port}, 心跳间隔:${cmd.content.heart}秒`;
            case 'forceRead': {
              let c = cmd;
              let its = this.devs.filter(p => p.id == c.id);
              if (its.length == 0) return '执行成功';
              this.$set(its[0], 'status', c.status)
              this.$set(its[0], 'result', c.result)
              this.$set(its[0], 'cmd', c.code)
              this.$set(its[0], 'content', c.content)
              this.$set(its[0].lastData, 'da', c.content.directAngle);
              this.$set(its[0].lastData, 'dl', c.content.directLevel);
              this.$set(its[0].lastData, 'h', c.content.humidity);
              this.$set(its[0].lastData, 'lux', c.content.lux);
              this.$set(its[0].lastData, 'n', c.content.noise);
              this.$set(its[0].lastData, 'pm10', c.content.pm10);
              this.$set(its[0].lastData, 'pm25', c.content.pm25);
              this.$set(its[0].lastData, 'pow', c.content.power);
              this.$set(its[0].lastData, 'pre', c.content.press);
              this.$set(its[0].lastData, 'spd', c.content.speed);
              this.$set(its[0].lastData, 't', c.content.temperature);
              this.$set(its[0].lastData, 'rain', c.content.rainLevel);
              this.$set(its[0], 'fireTime', c.content.time);
              this.$set(its[0], 'online', true)
              return `执行成功`;
            }
            default: return `执行成功`;
          }
      }
    },
    sendFailed: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let cmds = [];
      for (let d of this.devs) {
        if ((d.status == 2 || d.status == 3) && chks.filter(p => p.id == d.id).length > 0) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', this.showLang('com.lighting.repeat.preparation'))
          this.$set(d, 'message', this.getCmdStatus(d))
          cmds.push({ id: d.id, code: d.cmd, name: d.name, deviceId: d.devId, args: d.args });
        } else if (this.clearOldLog) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', '')
        }
      }
      setTimeout(() => {
        this.$set(this, 'cmdResults', {});
        this.$axios.post(`//${this.domains.trans}/station/command/send`, { list: cmds }).then(res => {
          if (res.code == 0) {
            // console.log('send cmd result', res.data);
            for (let item of res.data.list) {
              let ds = this.devs.filter(p => p.id == item.itemId);
              if (ds.length == 0) continue;
              this.$set(ds[0], 'cmdId', item.cmdId);
              this.$set(ds[0], 'status', item.status);
              this.$set(ds[0], 'result', item.message);
              this.$set(ds[0], 'message', this.getCmdStatus(ds[0]));
            }
          }
        });
      }, 500)
    },
    clickCmd: function (params) {
      // console.log('click cmd', params)
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要控制的终端');
        return;
      }
      if (params.code == 'setclock') {
        this.cmdItem = { code: params.code, name: params.name, pswd: params.needPswd, args: {} };
        this.showArgModal = true;
        return;
      } else {
        if (this.clearOldLog) {
          for (let item of this.devs) {
            item.result = '';
            item.status = 0;
          }
        }
        
        let cmds = []
        for (let chk of chks) {
          chk.result = '';
          chk.status = 0;
          chk.cmd = params.code;
          chk.name = params.name;
          chk.args = {};
          chk.message = this.getCmdStatus(chk);
          cmds.push({ id: chk.id, code: params.code, name: params.name, deviceId: chk.deviceId, args: {} });
        }
        this.$set(this, 'cmdResults', {});
        this.$axios.post(`//${this.domains.trans}/station/command/send`, { list: cmds }).then(res => {
          if (res.code == 0) {
            // console.log('send cmd result', res.data);
            for (let item of res.data.list) {
              let ds = this.devs.filter(p => p.id == item.itemId);
              if (ds.length == 0) continue;
              this.$set(ds[0], 'cmdId', item.cmdId);
              this.$set(ds[0], 'status', item.status);
              this.$set(ds[0], 'result', item.message);
              this.$set(ds[0], 'message', this.getCmdStatus(ds[0]));
            }
          }
        });
      }
    },
    initDevs: function(){
      // console.log('windowOnResize', this.selectedStationIds.join(','))
      let groupId = 0, poleId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        poleId = this.selectedNode.poleId;
      }else{
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/pole/config/QueryEnvironmentList`, {groupId, poleId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          res.data.map(p => {
            p.cmdId = 0;
            p.cmd = '';
            p.args = {};
            p.cmdContent = {};
            p.time = 0;
            p.status = 0;
            p.result = '';
            p.message = '';
          });
          this.$set(this, 'devs', res.data);
          this.$refs.chkTable.toggleAllSelection();
        }
      });
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 3;
    },
  }
}
</script>
<style scoped>
.config-station-container{
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  padding: 10px;
  background-color: white;
  border-radius: 8px;
}
.station-search-area{
  height: 42px;
  flex: none;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.row-space{
  width: 100px;
  flex: auto;
}
.station-data-area{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>