<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">{{showLang('com.device.box')}} {{item.stationName}} {{showLang('dev.meter.ammeter')}} {{item.paramName}} {{showLang('export.copy.data.record')}}</div>
    <Form ref="form" :label-width="0">
      <FormItem :label="showLang('com.state.type')" :label-width="80">
        <div style="display: flex">
          <Select :placeholder="showLang('save.select')" v-model="form.type" style="width: 100px; margin-right: 10px;">
            <Option value="day">{{showLang('com.date.day.degree')}}</Option>
            <Option value="month">{{showLang('com.date.monthly')}}</Option>
            <Option value="year">{{showLang('com.date.annual')}}</Option>
          </Select>
          {{showLang('com.date.start')}}：
          <DatePicker v-model="form.start" type="date" :format="selectedFormat" style="width: 150px; margin-right: 10px;"></DatePicker>
          {{showLang('com.date.end')}}：
          <DatePicker v-model="form.end" type="date" :format="selectedFormat" style="width: 150px; margin-right: 10px;"></DatePicker>
          <Button type="info" :loading="searchLoading" @click="initEnergys" style="margin-right: 10px">{{showLang('com.op.query')}}</Button>
          <Button type="success" :loading="exportLoading" @click="exportData">{{showLang('com.op.export')}}</Button>
          <!-- <span style="margin-left: 20px">总计 {{energys.length}} 条记录, 能耗 {{totalEnergy}} KWh, 电费 {{totalMoney}} 元</span> -->
        </div>
      </FormItem>
      <FormItem>
        <div class="branch-table">
          <vxe-table  :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source ref="chkTable" :data="energys" :height="600" :row-config="{isHover: true}" :export-config="{}" show-footer :footer-method="footerMethod" footer-cell-class-name="footer-cell-class">
            <vxe-column type="checkbox" width="60"></vxe-column>
            <vxe-column field="seq" type="seq" width="90"></vxe-column>
            <vxe-column field="name" :title="showLang('com.date.time')" width="150" header-align="center">
              <template #default="{ row }">
                {{new Date(row.day).format(showFormat) }}
              </template>
            </vxe-column>
            <vxe-column field="minsName" :title="showLang('com.lighting.lamp.duration')"  width="120" header-align="center">
              <template #default="{ row }">
                {{row.minsName}}
              </template>
            </vxe-column>
            <vxe-column field="beforeLight" :title="showLang('energy.before.energy')"  width="120" header-align="center">
              <template #default="{ row }">
                {{row.ens}} KWh
              </template>
            </vxe-column>
            <vxe-column field="afterLight" :title="showLang('energy.after.energy')"  width="120" header-align="center">
              <template #default="{ row }">
                {{row.after}} KWh
              </template>
            </vxe-column>
            <vxe-column field="save" :title="showLang('energy.save.amount')"  width="120" header-align="center">
              <template #default="{ row }">
                {{row.save}} KWh
              </template>
            </vxe-column>
            <vxe-column field="money" :title="showLang('energy.save.money')"  width="120" header-align="center">
              <template #default="{ row }">
                {{row.money}} {{showLang('com.unit.cash')}}
              </template>
            </vxe-column>
            <vxe-column field="rate" :title="showLang('energy.save.rate')"  width="120" header-align="center">
              <template #default="{ row }">
                {{row.rate}} %
              </template>
            </vxe-column>
          </vxe-table>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      <!-- <Button type="primary" :loading="loading" @click="doCommand">发送</Button> -->
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalLightRecord',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      form: {
        type: 'day',
        paramId: 0,
        start: '',
        end: '',
      },
      energys: [],
      showFormat: '',
      searchLoading: false,
      exportLoading: false,
      footerData: []
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
      // console.log('handle edit', this.item);
        this.form.type = 'day';
        this.form.paramId = this.item.id;
        this.form.start = this.item.start;
        this.form.end = this.item.end;
        this.initEnergys();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes',"showLang"]),
    ...mapState('cmd', ['singleCmdResult']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedFormat: function(){
      switch(this.form.type){
        case 'day': return 'yyyy-MM-dd';
        case 'month': return 'yyyy-MM';
        case 'year': return 'yyyy';
        default: return 'yyyy-MM-dd';
      }
    },
    totalEnergy: function(){
      let sum = 0;
      for(let en of this.energys){
        sum += en.val;
      }
      return this.img.val(sum, 1);
    },
    totalMoney: function(){
      let sum = 0;
      for(let en of this.energys){
        sum += en.money;
      }
      return this.img.val(sum, 1);
    },
  },
  methods: {
    footerMethod: function(){
      return this.footerData;
    },
    exportData: function () {
      this.$refs.chkTable.exportData({
        filename:this.item.name+ this.showLang('com.stat.energy')+".xlsx",
        columnFilterMethod ({ column }) {
          return ['seq', 'name', 'minsName', 'beforeLight', 'afterLight', 'save', 'money', 'rate'].includes(column.property)
        },
        data: this.$refs.chkTable.getCheckboxRecords(),
        footerFilterMethod: function(params){
          //这里可以通过以下方式修改footer的导出内容，比如重新计算汇总值和记录数等
          params.items[1] = params.items[1]+'1';
          // console.log('show footer params', params)
          return false;
        }
      })
    },
    initEnergys: async function () {
      let format = 'yyyy-MM-dd';
      let showFormat = 'yyyy-MM-dd';
      switch(this.form.type){
        case 'month':{
          format = 'yyyy-MM-01';
          showFormat = 'yyyy-MM';
          break;
        }
        case 'year':{
          format = 'yyyy-01-01';
          showFormat = 'yyyy';
          break;
        }
        default:{
          format = 'yyyy-MM-dd';
          showFormat = 'yyyy-MM-dd';
          break;
        }
      }
      let start = new Date(this.form.start).format(format);
      let end = new Date(this.form.end).format(format);
      this.searchLoading = true;
      this.$axios.post(`//${this.domains.trans}/station/analyse/QueryStationEnergyLightItems`, { type: this.form.type, paramId: this.form.paramId, start, end}).then(res => {
        this.searchLoading = false;
        if (res.code == 0) {
          this.showFormat = showFormat;
          this.$set(this, 'energys', res.data);
          let mins = 0, before = 0, usage = 0, save = 0, money = 0, avg = 0;
          res.data.map(p => {
            mins += p.mins;
            before += p.ens;
            usage += p.after;
            save += p.save;
            money += p.money;
          });
          avg = this.img.val(save * 100 / before, 2);
          let minsName = `${parseInt(mins/60)}小时${mins%60}分钟`
          this.$set(this, "footerData", [
             [this.showLang('com.export.footer.name'), res.data.length+this.showLang('energy.record'), "",  minsName, this.img.val(before, 2)+'KWh', this.img.val(usage, 2)+'KWh', this.img.val(save, 2)+'KWh', this.img.val(money, 2)+this.showLang('com.unit.cash'), `${avg}%`],
            // [2, 3, 4, 5]
          ]);
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
>>> .footer-cell-class {
  /* color: red; */
  font-weight: bold;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
.branch-table {
  height: 600px;
  overflow: auto;
}
.table {
  border-collapse: collapse;
  width: 100%;
}
.table-header {
  /* width: 1064px; */
  height: 47px;
  text-align: center;
  background: rgba(10, 26, 68, 0.8);
  box-shadow: inset 0px 0px 24px 0px #04559d;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #52fbfe;
  border: solid 1px black;
}
.table-header img {
  text-align: center;
  vertical-align: middle;
  margin-right: 3px;
}
.column-header {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  border: solid 1px black;
  text-align: center;
  height: 30px;
  /* width: 130px; */
  /* border-collapse: collapse; */
  background: #183992;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.column-header img {
  vertical-align: middle;
  margin-right: 10px;
}
.column-content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #050505;
  background: #2c9ef55e;
  border: solid 1px black;
  text-align: center;
  height: 35px;
  /* width: 130px; */
}
</style>