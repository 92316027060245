<template>
  <div class="agePie-control">
    <div class="agePie-title">年龄分布</div>
    <div class="agePie-con">
      <chartsPie :option="chartsAgeData" id="chartsAgePie" :key="timer"></chartsPie>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  computed: {

  },
  data() {
    return {
      timer: 2,
      chartsAgeData: {
        id: "chartsPeoplePie",
        series: [
          { value: 30, name: "少年" },
          { value: 25, name: "青少年" },
          { value: 10, name: "青年" },

          { value: 10, name: "中年" },
          { value: 25, name: "壮年" },
          { value: 25, name: "老年" },

          { value: 25, name: "孩童" },
          { value: 25, name: "未知" },
        ],
        color: ["#64DB6A", "#51CC8B", "#92DCFF", "#92C2FF", "#C69AFF", "#92ACFF", "#FCD788", "#F58CA7"],
        content: {
          legend: {
            orient: 'horizontal',
            bottom: 'auto',
            top: 'top'
          },
        },
        setSeries: {
          roseType: "area", //南丁格尔玫瑰图 radius
          radius: ['35%', '70%'],//[内半径，外半径] 饼图的半径
          top: "10%",
          itemStyle: {
          },
        }
      },
    }
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
  }
}
</script>
<style scoped>
.agePie-control {
  height: calc(100%);
  padding: 20px 30px;
}
.agePie-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  margin-bottom: 15px;
  font-weight: bold;
}
.agePie-con {
  width: 100%;
  height: calc(100% - 25px);
}
</style>
