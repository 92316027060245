<template>
  <div class="device-item-content">
    <template v-for="(item,index) in devices">
      <div class="device-info" :key="index" ref="infoHight">
        <div class="device-info-img">
          <img :src="getImage(item)" alt="">
        </div>
        <div class="device-info-box">
          <div class="device-info-upper">
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.environment.windSpeed" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val"> {{img.val(item.lastData.spd, 1)}} m/s</div>
                <div class="device-info-title">风速</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.environment.windPower" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.pow, 0)}} 级</div>
                <div class="device-info-title">风力</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.environment.PM25" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.pm25, 1)}} μg/m³</div>
                <div class="device-info-title">PM2.5</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.environment.temperature" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.t, 1)}} ℃</div>
                <div class="device-info-title">温度</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.environment.noise" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.n, 1)}} 分贝</div>
                <div class="device-info-title">噪音</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.environment.atmos" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.pre, 1)}} P</div>
                <div class="device-info-title">大气压</div>
              </div>
            </div>
          </div>
          <div class="device-info-lower">
            <div class="device-info-tab">
              <div class="device-info-icon">
                <img :src="img.pole.asset.index.environment.windGrade" alt="">
              </div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.dl, 0)}}档</div>
                <div class="device-info-title">风向档位</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.environment.windAngle" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val"> {{img.val(item.lastData.da, 0)}} °</div>
                <div class="device-info-title">风向角度</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.environment.PM10" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.pm10, 1)}} μg/m³</div>
                <div class="device-info-title">PM10</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.environment.humidity" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.h, 1)}} RH%</div>
                <div class="device-info-title">湿度</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.environment.brightness" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.lux, 0)}} lux</div>
                <div class="device-info-title">亮度</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.environment.date" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{item.lastData.time > 0 ? new Date(item.lastData.time).format('yyyy-MM-dd HH:mm:ss') : '--'}}</div>
                <div class="device-info-title">刷新时间</div>
              </div>
            </div>
          </div>
        </div>
        <div class="device-info-but">
          <a href="javascript:void(0);" class="cameraInfo-tab-but" @click="testBut(item)">
            <img :src="img.pole.icons.env.test" alt="">
            数据召测</a>
        </div>
      </div>
    </template>
    <Spin fix v-if="spin.show">{{spin.content}}</Spin>
    <div class="device-chart" style="height:560px">
      <recordPage :devId="devId" />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import recordPage from '../history/Index'
export default {
  name: 'deviceList',
  components: {
    recordPage
  },
  props: {
    devices: {
      type: Array,
      default(){return []},
    },
    oneId: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      timer: 0,
      filter: {
        start: "",
        end: "",
      },
      devId: -1,
      chartHeight: 600,
      spin: {
        show: false,
        content: '加载中...',
      },
      devsId: "",
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    cmdResultRefresh() {
      let cmds = this.commands.filter(p => p.id == this.devsId);
      if (cmds.length == 0) return;
      let cmd = cmds[0];
      switch (cmd.status) {
        case 0:
          this.showSpin(`${cmd.result}`);
          break;
        case 1:
          this.showSpin('等待回复');
          break;
        case 2:
          this.showSpin('指令超时');
          break;
        case 3:
          this.showSpin(`执行失败：${cmd.result}`);
          setTimeout(this.hideSpin, 1000);
          break;
        case 9:
          this.showSpin(`执行成功`);
          setTimeout(this.hideSpin, 1000);
          break;
      }
    }
  },
  mounted: function () {
    let now = new Date();//getDate
    now.setHours(now.getHours() + 1)
    this.filter.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 7);
    this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');
  },
  methods: {
    testBut(item) {
      this.devsId = item.id;
      let devs = [];
      devs.push({
        id: item.id,
        deviceId: item.deviceId,
        code: 'forceRead',
        name: '召测',
        args: {}
      });
      this.showSpin('等待指令回复');
      this.$store.dispatch('cmd/sendCommands', devs);
    },
    showSpin: function (msg) {
      this.spin.content = msg;
      this.spin.show = true;
    },
    hideSpin: function () {
      this.spin.show = false;
    },
    getImage: function (item) {
      if (!item.online) return this.img.pole.icons.env.offline3;
      return this.img.pole.icons.env.online3;
    },
  }
}
</script>
<style scoped>
.cameraInfo-search-but {
  padding: 3px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
  margin-left: 19px;
}
.cameraInfo-search-but img {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
}
.device-item-content {
  width: 100%;
}
.device-info {
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 30px;
  margin-bottom: 14px;
}
.device-info-img {
  text-align: center;
  margin-right: 30px;
  margin-top: 15px;
}
.device-info-img img {
  margin-top: 2px;
}
.device-info-tab {
  display: flex;
  justify-content: flex-start;
}
.device-chart {
  width: calc(100%);
  background: #fff;
  border-radius: 10px;
}
.device-info-icon img {
  margin-top: 5px;
  margin-right: 14px;
}
.device-info-con {
  width: 140px;
}
.device-info-val {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
}
.device-info-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8793a5;
}
.device-info-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.device-info-upper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
}
.device-info-lower {
  display: flex;
  justify-content: flex-start;
}
.device-info-but {
  margin-top: 45px;
  margin-left: 20px;
}
.device-info-but img {
  vertical-align: middle;
}
.cameraInfo-tab-but {
  padding: 3px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
  margin: 0 5px;
}
</style>
