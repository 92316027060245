<template>
<div class="organ-container">
  <div class="organ-header">
    <div class="header-tab-item">
      开始日期：
      <DatePicker type="date" v-model="filter.start" placeholder="请选择开始日期" style="width: 115px;margin-right:10px"></DatePicker>
      结束日期：
      <DatePicker type="date" v-model="filter.end" placeholder="请选择结束日期" style="width: 115px;margin-right:10px"></DatePicker>
      <Button type="primary" @click="getList" style="margin-right:10px">查询</Button>
      <Button type="success" @click="exportData">导出</Button>
    </div>
  </div>
  <div class="organ-content">
    <Spin fix v-if="spin.show">{{spin.content}}</Spin>
    <div class="chart-area">
      <chartsLine :option="chartsLineData" id="chartsLineData" :key="timer"></chartsLine>
    </div>
    <div class="data-area" ref="table">
      <u-table :data="tabData" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="tabHeight" stripe>
        <u-table-column type="index"></u-table-column>
        <u-table-column prop="time" label="日期" sortable fit>
          <template slot-scope="scope">
            {{new Date(scope.row.time).format('yyyy-MM-dd')}}
          </template>
        </u-table-column>
        <u-table-column prop="value" label="能耗值(KWh)" sortable fit></u-table-column>
      </u-table>
    </div>
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'AnalyseEnergyTrend',
  components: {
  },
  props: {
  },
  data(){
    return {
      spin: {
        show: false,
        content: '加载中...',
      },
      editModal: false,
      tabHeight:500,
      tabData:[],
      filter: {
        start: '',
        end: '',
      },
      timer: 0,
      chartsLineData: {
        title: "",
        x: [],
        unit: "KWh",
        series: [
          {
            name: "能耗",
            data: [],
          },
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {}, //下阴影
        },
      },
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    stationIds: function(){
      return this.selectedDevices.map(p => {
        if(p.id) return p.id;
        return 0;
      });
    },
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    selectedNode(){
      this.getList();
    },
  },
  mounted: function(){
    let now = new Date();
    this.filter.end = now.format('yyyy-MM-dd');
    now.setMonth(now.getMonth() - 1);
    this.filter.start = now.format('yyyy-MM-dd');
    setTimeout(this.setTableHeight, 200);
    this.getList();
  },
  methods: {
    exportData(){
      let tab = [];
      this.tabData.map(p => {
        tab.push({time: new Date(p.time).format('yyyy-MM-dd'), value: p.value});
      })
      exportXlsx(tab, {
        time: '日期',
        value: '能耗值',
      }, "能耗趋势导出记录");
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 100;
    },
    getList: function(){
      let start = new Date(this.filter.start).format('yyyy-MM-dd');
      let end = new Date(this.filter.end).format('yyyy-MM-dd');
      this.spin.show = true;
      let args = {
        start: start,
        end: end,
        ids: this.stationIds
      }
      this.$axios.post(`//${this.domains.trans}/station/analyse/QueryEnergyByDay`, args).then(res => {
        this.spin.show = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'tabData', res.data);
          let x = [], y = [];
          for(let d of res.data){
            x.push(new Date(d.time).format('yyyy-MM-dd'));
            y.push(d.value);
          }
          this.$set(this.chartsLineData, 'x', x);
          this.$set(this.chartsLineData.series[0], 'data', y);
          this.timer++;
        }
      });
    },
  }
}
</script>
<style scoped>
.organ-container{
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.organ-header{
  border: solid 1px rgba(4, 4, 202, 0.26);
  height: 40px;
  margin: 3px;
  flex: none;
}
.chart-area{
  height: 300px;
  flex: none;
}
.data-area{
  height: 500px;
  flex: auto;
}
.organ-content{
  /* border: solid 1px red; */
  margin: 3px;
  height: 500px;
  flex: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
.header-tab-item{
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 40px;
  /* justify-content: flex-end; */
  /* padding: 0 20px; */
}
</style>
