<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">{{showLang('save.batch.pole.info')}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="110">
      <FormItem prop="groupId" label="所在分区" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.groupId">
          <template v-for="(s, idx) in selectedGroups">
            <Option v-if="s.id != '0'" :key="idx" :value="parseInt(s.id)">{{s.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="poleType" :label="showLang('com.import.cn.pole.type')" style="margin-right: 15px">
        <Select v-model="form.poleTypeId" style="width: 200px; margin-right: 10px;">
          <Option v-for="(s, idx) in poleTypes" :key="idx" :value="s.id">{{s.name}}</Option>
        </Select>
      </FormItem>
      <FormItem prop="poleType" :label="showLang('com.import.cn.arm.type')" style="margin-right: 15px">
        <Select v-model="form.armTypeId" style="width: 200px; margin-right: 10px;">
          <Option v-for="(s, idx) in armTypes" :key="idx" :value="s.id">{{s.name}}</Option>
        </Select>
      </FormItem>
      <FormItem prop="height" :label="showLang('com.import.cn.pole.height')" style="margin-right: 15px">
        <Input type="number" number v-model="form.height">
          <span slot="append">M</span>
        </Input>
      </FormItem>
      <FormItem prop="name" :label="showLang('com.tab.prefix.name')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" :placeholder="showLang('com.tab.title')"></Input>
        </div>
      </FormItem>
      <FormItem prop="height" :label="showLang('com.tab.suffix.num')" style="margin-right: 15px">
        <div style="display: flex;">
          <Input type="number" number v-model="form.start">
            <span slot="prepend">{{showLang('com.op.start')}}</span>
          </Input>
          <Input type="number" number v-model="form.count">
            <span slot="prepend">{{showLang('com.num')}}</span>
            <span slot="append">{{showLang('com.device.pole.num')}}</span>
          </Input>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'ModalBatchAdd',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    armTypes: {
      type: Array,
      default() { return []; }
    },
    poleTypes: {
      type: Array,
      default() { return []; }
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      mains: [],
      devices: [],
      form: {
        id: 0,
        groupId: 0,
        name: '',
        start: 0,
        count: 0,
        armTypeId: "",
        poleTypeId: '',
        height: 10,
      },
      rules: {
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.form.id = 0;
        this.form.groupId = 0;
        this.form.name = '';
        this.form.start = 0;
        this.form.count = 0;
        this.form.armTypeId = "";
        this.form.poleTypeId = "";
        this.form.height = 10;
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('group', ['selectedGroups']),
    ...mapGetters('auth', ['funCodes','showLang']),
  },
  mounted: function(){
  },
  methods: {
    ok: async function () {
      if(this.form.groupId <= 0){
        this.$Message.warning("请选择所属分区");
        return;
      }
      if(this.form.poleTypeId == ''){
        this.$Message.warning("请选择灯杆类型");
        return;
      }
      if(this.form.armTypeId == ''){
        this.$Message.warning("请选择杆臂类型");
        return;
      }
      if(this.form.height <= 0){
        this.$Message.warning("请设置灯杆高度");
        return;
      }
      if(this.form.name.trim().length == 0){
        this.$Message.warning('请输入前缀名称');
        return;
      }
      if(this.form.start <= 0){
        this.$Message.warning("请设置开始数字");
        return;
      }
      if(this.form.count <= 0){
        this.$Message.warning("请设置灯杆数量");
        return;
      }
      this.$axios.post(`//${this.domains.trans}/pole/config/BatchSavePole`, this.form).then(res => {
        if(res.code == 0){
          this.$Message.info(this.showLang('com.tips.save'));
          this.showModal = false;
          this.$emit('saved', {isAdd: this.isAdd, data: res.data});
          window.eventBus.$emit('poleChanged');
        }
      })
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>