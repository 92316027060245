<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">查看报警数据 - 发生时间: {{item.startTime}}</div>
    <div class="search-data-header">
      <div class="analyse-table">
        <table border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td style="width: 100px">所在站点</td>
            <td>{{item.stationName}}</td>
            <td style="width: 100px">发生时间</td>
            <td>{{item.startTime}}</td>
            <td style="width: 100px">消除时间</td>
            <td>{{item.doneTime || '-'}}</td>
          </tr>
          <tr>
            <td>报警属性</td>
            <td colspan="3">{{item.paramName}}</td>
            <td>报警类型</td>
             <!-- v-if="item.type == 13 || item.type == 23" -->
            <td style="color:#f00">{{item.name}}</td>
          </tr>
          <tr>
            <td>故障现象</td>
            <td colspan="5" style="color:#f00">{{item.extra}}</td>
          </tr>
          <tr>
            <td>故障确认</td>
            <td colspan="5">{{item.isConfirm ? '已确认' : '未确认'}}
              <Button v-if="!item.isConfirm && !item.doneTime" style="margin-right: 0; float:right;" size="default" type="primary" @click="confirmAlarm">确认告警</Button>
              <span v-if="item.isConfirm">: 由 {{item.byUser ? '用户' : '工单系统'}} [{{item.confirmName}}] 确认于 [{{item.confirmTime}}]</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="log-search-area">
      {{ showLang('com.date.start') }}：
      <DatePicker v-model="form.start" type="datetime" format="yyyy-MM-dd HH:mm" style="margin-right: 10px; width: 155px;"></DatePicker>
      {{ showLang('com.date.end') }}：
      <DatePicker v-model="form.end" type="datetime" format="yyyy-MM-dd HH:mm" style="margin-right: 10px; width: 155px;"></DatePicker>
      <Button size="default" style="margin-right: 5px" @click="getList">{{ showLang('com.op.query') }}</Button>
    </div>
    <div class="search-data-content" ref="table1">
      <div v-if="history.length == 0" style="height: 400px; background-color: white;text-align:center;line-height: 200px">暂无数据</div>
      <table v-else class="history-item">
        <tr class="sub-header">
          <td>时间</td>
          <td>灯杆名称</td>
          <td>灯头名称</td>
          <td>电压(V)</td>
          <td>电流(A)</td>
          <td>功率(W)</td>
          <td>亮度(%)</td>
          <td>温度(℃)</td>
        </tr>
        <template v-for="(item, idx) in history">
          <tr :class="[idx%2==1?'active':'']" :key="`row1-${idx}`">
            <td rowspan="2">{{new Date(item.fireTime).format('yyyy-MM-dd HH:mm:ss')}}</td>
            <td rowspan="2">{{lightName}}</td>
            <td>{{lightConfig.en1 ? lightConfig.nm1 : '--'}}</td>
            <td rowspan="2" :class="[(item.content.u > lightConfig.uh || item.content.u < lightConfig.ul) ? 'alarm-color' : '']">
              {{item.content.u.toFixed(2)}} ({{lightConfig.ul}} - {{lightConfig.uh}})
            </td>
            <td  :class="[(item.content.c1 > lightConfig.ch || item.content.c1 < lightConfig.cl) ? 'alarm-color' : '']">{{item.content.c1.toFixed(2)}}</td>
            <td>{{item.content.p1.toFixed(2)}}</td>
            <td>{{item.content.op1.toFixed(2)}}</td>
            <td :class="[(item.content.tc > lightConfig.tl) ? 'alarm-color' : '']">芯片：{{item.content.tc.toFixed(1)}}</td>
            <!-- <td :class="[item.content.tc > lightConfig.t ? 'alarm-color' : '']">模块：{{item.content.tm.toFixed(2)}}</td> -->
          </tr>
          <tr :class="[idx%2==1?'active':'']" :key="`row2-${idx}`">
            <td>{{lightConfig.en2 ? lightConfig.nm2: '--'}}</td>
            <td>{{item.content.c2.toFixed(2)}}</td>
            <td>{{item.content.p2.toFixed(2)}}</td>
            <td>{{item.content.op2.toFixed(2)}}</td>
            <td :class="[(item.content.tm > lightConfig.tl) ? 'alarm-color' : '']">模块：{{item.content.tm.toFixed(1)}}</td>
            <!-- <td :class="[item.content.tc > lightConfig.tr ? 'alarm-color' : '']">芯片：{{item.content.tc.toFixed(2)}}</td> -->
          </tr>
        </template>
      </table>
      <Spin size="large" fix v-if="loading"></Spin>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalAlarmData',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showCompareModal: false,
      showModal: this.value,
      loading: false,
      form: {
        paramId: 0,
        start: '',
        end: '',
      },
      alarmTs: 0,
      history: [],
      searchLight: {},
      lightConfig: {},
      lightName: '',
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        let time = new Date(this.item.startTime);
        this.alarmTs = time.getTime();
        this.form.paramId = this.item.paramId;
        time.setMinutes(time.getMinutes() - 65);
        this.form.start = time.format('yyyy-MM-dd HH:mm:ss');
        time.setMinutes(time.getMinutes() + 130);
        this.form.end = time.format('yyyy-MM-dd HH:mm:ss');
        // console.log('modal level edit mounted', this.item)
        this.getList();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    'form.dealType'() {
      if (this.form.dealType == 2) {
        let now = new Date();
        now.setDate(now.getDate() + 7);
        this.$set(this.form.method, 'expire', now.format('yyyy-MM-dd HH:mm:ss'));
      }
    }
  },
  computed: {
    ...mapState('common', ['alarmTypes', 'alarmGroupList', 'alarmLevels', 'dealAlarmTypes', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapGetters('auth', ['showLang']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station)
    },
  },
  mounted: function () {
  },
  methods: {
    showCompareLogs: function(){
      this.showCompareModal = true;
    },
    imgListAjax(id) {
      return `//${this.domains.trans}/station/file/${id}`;
    },
    confirmAlarm: function(){
      this.$Modal.confirm({
        title: '确认告警',
        content: `确定已知道该告警吗？`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/alarm/ConfirmLightAlarm`, {id: this.item.id}).then(res => {
            if(res.code == 0){
              if(!res.data.success){
                this.$Modal.error({
                  title: '确认失败',
                  content: res.data.message,
                });
                return;
              }
              this.item.byUser = res.data.byUser;
              this.item.confirmId = res.data.confirmId;
              this.item.confirmName = res.data.confirmName;
              this.item.confirmTime = res.data.confirmTime;
              this.item.isConfirm = res.data.isConfirm;
            }
          });
        }
      });
    },
    getList() {
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      this.$axios.post(`//${this.domains.trans}/station/param/HistoryWithCfg`, this.form).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.lightConfig = res.data.cfg;
          this.lightName = res.data.name;
          let deltaTs = 99990;
          var list = res.data.list;
          list.forEach(el => {
            let delta = Math.abs(this.alarmTs - el.fireTime);
            if (delta < deltaTs) {
              deltaTs = delta;
            }
            for (let attr in el.content) {
              if (!isNaN(el.content[attr]) && el.content[attr] < 0) {
                el.content[attr] = 0
              }
            }
          });
          this.$set(this, 'history', list);
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.log-search-area{
  /* border: solid 1px red; */
  height: 50px;
}
.alarm-color {
  color: red;
}
td {
  height: 40px;
  /* white-space: nowrap; */
  width: 120px;
  border: solid 1px #cfe3f7;
}
.sub-header {
  background-color: #cfe3f7;
  /* background: #CFE3F7; */
  height: 46px;
  text-align: center;
}
.history-item {
  /* border-width: 1px;
  border-collapse: collapse;
  border-color: cyan; */
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
}
.search-data-content {
  height: 500px;
  flex: auto;
  /* border: solid 1px blue; */
  overflow: auto;
}
.analyse-table table {
  width: 100%;
  height: 93px;
  margin-bottom: 20px;
}
.analyse-table table td {
  /* width: 115px; */
  /* text-align: center; */
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #d0d9e9;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  padding: 0 10px;
}
.showImgBox {
  width: 300px;
  height: 300px;
  border: 1px solid #b7afaf;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 5px;
}
.showImgBox img {
  max-width: 297px;
  max-height: 297px;
}
/* .analyse-table table tr:last-child td {
  border-bottom: none;
}
.analyse-table table tr td:last-child {
  border-right: none;
} */
</style>