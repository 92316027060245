<template>
  <div class="level1-box-container" :style="{'--percent': items.length,'--percent2': items.length-1,'--itemWidth': itemWidth}">
    <div class="level1-container" v-for="(item,index) in items" :key="index">
      <img :src="item.icon" />
      <div class="level1-content">
        <div class="level1-title">{{item.title}}</div>
        <div class="level1-nums">
          <div class="nums-content">{{item.count}}</div>
          <div class="nums-unit" :style="{color: item.color}">{{item.unit}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Level1Content',
  components: {
  },
  props: {
    items: {
      type: Array,
      default() { return []; }
    }
  },
  data() {
    return {
      itemWidth: '28px',
    }
  },
  computed: {
  },
  watch: {
  },
  created: function () {
  },
  mounted: function () {
    if (this.items.length > 4) {
      this.itemWidth = '15px'
    }
  },
  methods: {

  },
}
</script>
<style scoped>
.level1-box-container {
  width: 100%;
  display: flex;
  flex-direction: initial;
  justify-content: space-around;
}
.level1-container {
  width: calc((100% - var(--percent2) * var(--itemWidth)) / var(--percent));
  height: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  margin-left: -20px;
}
.level1-container img {
  width: 89px;
  height: 89px;
  flex: none;
  margin: 12px 5px 0 20px;
}
.level1-content {
  width: 50px;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.level1-title {
  height: 25px;
  flex: none;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
  margin-top: -5px;
}
.level1-nums {
  height: 30px;
  flex: auto;
  display: flex;
  align-items: center;
  align-items: flex-end;
}
.nums-content {
  height: 30px;
  padding-right: 10px;
  font-size: 30px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #0a1421;
}
.nums-unit {
  width: 30px;
  flex: auto;
  font-size: 14px;
  font-family: Bahnschrift;
  font-weight: 400;
  height: 16px;
}
</style>