<template>
<bm-control :offset="{ width: offset.x, height: offset.y }">
  <div class="map-tool-drag">
    <span class="tool-header">设置位置</span>
    <i-switch v-model="enableMoveDevice" size="large" true-color="green" false-color="#6D8194" title="打开可拖动设备以改变设备位置">
      <span slot="open">开启</span>
      <span slot="close">禁止</span>
    </i-switch>
  </div>
</bm-control>
</template>
<script>
import { BmControl } from "@/components/vue-baidu-map/components";
export default {
  name: "ToolChangeDevicePos",
  components: {
    BmControl,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    offset: {
      type: Object,
      default(){return {x: 0, y: 0}}
    }
  },
  data() {
    return {
      enableMoveDevice: this.value,
    };
  },
  computed: {
  },
  watch: {
    value(newVal) {
      this.enableMoveDevice = newVal;
    },
    enableMoveDevice(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
  },
  methods: {
    switchMapType: function (type) {
      this.mapType = type;
    },
    // changePos: function(lat, lng){
    //   console.log('change pos at: ', lat, lng);
    // }
  },
};
</script>
<style scoped>
.map-tool-drag {
  display: flex;
  align-items: center;
  background-color: white;
  height: 28px;
  /* padding: 0 5px; */
  border-radius: 3px;
  border: solid 1px #1b5fa8;
  padding-right: 10px;
}
.tool-header {
  height: 28px;
  line-height: 28px;
  margin-right: 10px;
  padding: 0 10px;
  /* margin-left: 5px; */
  background-color: #1b5fa8;
  color: white;
}
</style>