<template>
  <div class="alarm-setting-container">
    <div class="setting-header">
      <Button v-if="funCodes('abe')" size="default" type="primary" class="mr10" @click="saveSetting">{{showLang('com.op.save')}}</Button>
      <Button v-if="funCodes('abv')" size="default" type="primary" class="mr10" @click="getList">{{showLang('com.op.refresh')}}</Button>
      <!-- <Button v-if="old.count > 0" size="default" type="primary" @click="getOldAlarmCount">历史报警数量:{{old.count}}</Button> -->
    </div>
    <div class="setting-content">
      <Form ref="form" :model="form" :label-width="160" class="alarm-base-area">
        <FormItem prop="stationId" :label="showLang('alarm.clock.wrong.threshold')">
          <Input v-model="form.clockError" maxlength="100" type="number">
            <span slot="append">s</span>
          </Input>
        </FormItem>
        <FormItem prop="paramId" :label="showLang('alarm.is.automatic.timing')">
          <i-switch v-model="form.autoSetClock" size="large" true-color="green" false-color="red" style="margin-left: 10px;">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
        </FormItem>
        <FormItem prop="daySetClock" :label="showLang('alarm.daily.automatic.timing')">
          <Select :placeholder="showLang('save.select')" v-model="form.daySetClock">
            <Option :value="0">{{showLang('alarm.no.automatic.timing')}}</Option>
            <Option :value="1">{{showLang('alarm.daily.times',1)}}</Option>
            <Option :value="2">{{showLang('alarm.daily.times',2)}}</Option>
            <Option :value="3">{{showLang('alarm.daily.times',3)}}</Option>
            <Option :value="4">{{showLang('alarm.daily.times',4)}}</Option>
            <Option :value="6">{{showLang('alarm.daily.times',6)}}</Option>
            <Option :value="8">{{showLang('alarm.daily.times',8)}}</Option>
            <Option :value="12">{{showLang('alarm.daily.times',12)}}</Option>
            <Option :value="24">{{showLang('alarm.daily.times',24)}}</Option>
          </Select>
        </FormItem>
        <FormItem prop="maxStationStatusTime" :label="showLang('alarm.max.site.service')">
          <Input v-model="form.maxStationStatusTime" maxlength="100" type="number">
            <span slot="append">{{showLang('com.date.hour')}}</span>
          </Input>
        </FormItem>
        <FormItem prop="expired" :label="showLang('alarm.tips.switch.light.duration')">
          <Input v-model="form.lightControlConfirmTime" maxlength="100" type="number">
            <span slot="append">{{showLang('com.date.min')}}</span>
          </Input>
        </FormItem>
        <FormItem prop="LightOfflineHours" :label="showLang('alarm.tips.offline.hours')">
          <Input v-model="form.lightOfflineHours" maxlength="100" type="number">
            <span slot="append">{{showLang('com.date.hour')}}</span>
          </Input>
        </FormItem>
        <FormItem prop="lightOnHours" :label="showLang('alarm.tips.lighton.hours')">
          <Input v-model="form.lightOnHours" maxlength="100" type="number">
            <span slot="append">{{showLang('com.date.hour')}}</span>
          </Input>
        </FormItem>
      </Form>
    
    </div>
  </div>
  </template>
  <script>
  import { mapState,mapGetters } from 'vuex'
  export default {
    name: 'AlarmSettingIndex',
    components:{
    },
    props: {
    },
    data () {
      return {
        tabHeight: 600,
        loading: false,
        form: {
          autoSetClock: false,
          clockError: 90,
          maxStationStatusTime: 24,
          daySetClock: 0, //每日校时次数，为0不校时，取值范围：[0, 1, 2, 3, 4, 6, 8. 12, 24]，都是24小时的整点
          lightControlConfirmTime: 5, //开关灯报警确认时长
          lightOfflineHours: 24, //单灯离线阈值
          lightOnHours: 1, //单灯亮灯时间计算阈值
          powerConfirmTime: 35, //功率对比确认时长
          powerCompareDays: 5, //功率报警对比天数
          powerSetting: { //功率报警设置
            total: false,
            low: 1200,
            high: 1200,
            range: [
              {val: 3000, low: 500, high: 500},
              {val: 6000, low: 650, high: 650},
              {val: 9000, low: 800, high: 800},
              {val: 12000, low: 1000, high: 1000},
            ]
          },
          currentConfirmTime: 35, //电流对比确认时长，单位分钟
          currentCompareDays: 5, //电流报警对比天数
          currentSetting: { //电流报警设置
            total: false,
            low: 5.45,
            high: 5.45,
            range: [
              {val: 13.64, low: 2.27, high: 2.27},
              {val: 27.27, low: 2.95, high: 2.95},
              {val: 40.91, low: 3.64, high: 3.64},
              {val: 54.55, low: 4.55, high: 4.55},
            ]
          },
        },
        old: {
          count: 0,
          list: []
        },
      }
    },
    computed: {
      ...mapGetters('auth', ['funCodes', 'showLang']),
      ...mapState('cmd', ['windowOnResize', 'cmds']),
      ...mapState('common', ['alarmLevels', 'productCodes', 'alarmTypes', 'alarmGroupList']),
    },
    watch: {
      windowOnResize(){
      },
    },
    mounted: function(){
      this.getList();
      // this.getOldAlarmCount();
    },
    destroyed: function(){
    },
    methods: {
      getOldAlarmCount: function(){
        this.$axios.post(`//${this.domains.trans}/third/test/QueryOldAlarmCount`, {}).then(res => {
          if(res.code == 0){
            this.$set(this, 'old', res.data);
          }
        });
      },
      delPower: function(idx){
        if(idx < 0)return;
        this.form.powerSetting.range.splice(idx, 1);
      },
      addPower: function(idx){
        if(idx <= 0){
          this.$set(this.form.powerSetting.range, this.form.powerSetting.range.length, {val: 100, low: 30, high: 30});
        }else{
          this.form.powerSetting.range.splice(idx - 1, 0, {val: 101, low: 30, high: 30});
        }
      },
      delCurrent: function(idx){
        if(idx < 0)return;
        this.form.currentSetting.range.splice(idx, 1);
      },
      addCurrent: function(idx){
        if(idx <= 0){
          this.$set(this.form.currentSetting.range, this.form.currentSetting.range.length, {val: 10, low: 3, high: 3});
        }else{
          this.form.currentSetting.range.splice(idx - 1, 0, {val: 10, low: 3, high: 3});
        }
      },
      getList: function(){
        this.$axios.post(`//${this.domains.trans}/station/alarm/QueryBase`, {}).then(res => {
          if(res.code == 0){
            this.$set(this, 'form', res.data);
          }
        });
      },
      saveSetting: function(){
        let max = 0;
        for(let item of this.form.powerSetting.range){
          if(max >= item.val){
            this.$Message.warning(this.showLang('alarm.tips.power.threshold.magnify'));
            return;
          }
          max = item.val;
        }
        max = 0;
        for(let item of this.form.currentSetting.range){
          if(max >= item.val){
            this.$Message.warning(this.showLang('alarm.tips.current.threshold.magnify'));
            return;
          }
          max = item.val;
        }
        this.loading = true;
        this.$axios.post(`//${this.domains.trans}/station/alarm/SaveBase`, this.form).then(res => {
        this.loading = false;
          if(res.code == 0){
            this.$Message.info(this.showLang('com.tips.save'))
          }
        });
      },
    }
  }
  </script>
  <style scoped>
  .alarm-setting-container{
    /* border: solid 1px red; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .setting-header{
    border: solid 1px rgba(82, 189, 216, 0.315);
    height: 50px;
    flex: none;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
  }
  .setting-content{
    /* border: solid 1px rgba(82, 189, 216, 0.315); */
    height: 500px;
    flex: auto;
    display: flex;
  }
  .alarm-base-area{
    border: solid 1px rgba(82, 189, 216, 0.315);
    border-radius: 6px;
    /* margin: 20px 0; */
    padding: 10px;
    width: 100%;
    flex: none;
  }
  .alarm-power-area{
    border: solid 1px rgba(82, 189, 216, 0.315);
    border-radius: 6px;
    /* margin-top: 30px; */
    width: 550px;
    flex: auto;
    /* margin: 0 10px; */
    margin-left: 10px;
    padding: 10px;
    overflow-y: auto;
  }
  .range-set-title{
    font-size: 30px;
    text-align: center;
  }
  .chk-item{
    /* border: solid 1px rgba(219, 175, 175, 0.568); */
    margin-bottom: 5px;
  }
  .alarm-type{
    /* border: solid 1px rgba(219, 175, 175, 0.568); */
    margin: 5px;
    padding: 5px;
  }
  .alarm-type-name{
    padding: 5px 0 1px 0;
    border-bottom: solid 1px rgba(219, 175, 175, 0.568);
    margin-bottom: 10px;
  }
  .alarm-level-container{
    width: 100%;
    height: 100%;
    display: flex;
    /* flex-direction: column; */
    /* align-content: stretch; */
    /* padding-right: 40px; */
    /* border: solid 1px red; */
  }
  .alarm-level-item{
    border: solid 1px rgba(219, 175, 175, 0.568);
    padding: 3px;
    width: 300px;
    flex: auto;
    margin: 5px;
    display: flex;
    flex-direction: column;
  }
  .level-item-header{
    border: solid 1px rgba(219, 175, 175, 0.568);
    padding: 10px;
    height: 40px;
    flex: none;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  .level-item-content{
    border: solid 1px rgba(219, 175, 175, 0.568);
    height: 400px;
    flex: auto;
  }
  .header-title{
    /* border: solid 1px red; */
    width: 100px;
    flex: auto;
    font-weight: bolder;
    font-size: 20px;
  }
  .header-btn{
    /* margin: 10px; */
    flex: none;
    /* border: solid 1px red; */
  }
  .alarm-data-area{
    /* border: solid 1px red; */
    height: 100px;
    flex: auto;
    /* border: solid 1px #DCDEE2; */
    border-radius: 6px;
  }
  .station-alarm-tabs{
    height: 30px;
    flex: none;
    display: flex;
  }
  .station-alarm-tab{
    padding: 3px 8px;
    margin: 3px 3px 0 3px;
    border: solid 1px lightgray;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    background-color: white;
  }
  .tab-active{
    border: solid 1px rgb(135, 159, 224);
    background-color: rgb(135, 159, 224);
    color: white;
  }
  .station-alarm-tabcontent{
    height: 500px;
    flex: auto;
    /* border: solid 1px red; */
  }
  /* .box-body{
    height: 0;
    flex: auto;
    margin-top: 5px;
    border: solid 1px red;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
  }
  .box-body::-webkit-scrollbar {
    display: none;
  } */
  </style>