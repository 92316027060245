<template>
<Modal v-model="showModal" :mask-closable="false" width="1300px">
  <div slot="header">导入年表</div>
  <Form ref="form" :model="form" :label-width="70">
    <FormItem prop="name" label="名称" style="margin-bottom: 5px;width: 400px">
      <Input ref="name" type="text" maxlength="20" v-model="form.name" placeholder=""></Input>
    </FormItem>
    <FormItem :label-width="0">
      <div class="preview-area">
        <div class="preview-head">
          选择文件：<input class="file-control" ref="file" type="file" accept=".xlsx" @change="fileChanged"/>
        </div>
        <div class="preview-content">
          <!-- <video v-if="form.type == 1 && url != ''" :src="url" controls="controls" @loadedmetadata="videoLoaded" class="media-player">您的浏览器不支持 video 标签</video>
          <audio v-else-if="form.type == 2 && url != ''" :src="url" controls="controls" @canplay="audioLoaded" class="media-player">您的浏览器不支持 audio 标签</audio>
          <img ref="img" v-else-if="form.type == 3 && url != ''" :src="url" class="media-player" @load="imageLoaded" />
          <span v-else class="media-player">请选择媒体文件</span> -->
        </div>
      </div>
    </FormItem>
  </Form>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    <Button type="primary" :loading="loading" @click="ok">保存</Button>
  </div>
</Modal>
</template>
<script>
  export default {
    name: 'ModalTableImport',
    components:{
    },
    model: {
      prop: 'value',
      event: 'showChanged'
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      type: {
        type: Number,
        default: 1
      },
    },
    data () {
      return {
        showModal: this.value,
        loading: false,
        form: {
          name: '',
          type: 1,
          file: null,
        }
      }
    },
    watch: {
      value(newVal) {
        this.showModal = newVal;
        if(this.showModal){
          this.form.name = '';
          this.form.type = this.type;
          this.form.file = null;
          this.$refs.file.value = '';
        }
      },
      showModal(val){
        this.$emit('showChanged', val);
      }
    },
    mounted: function(){
    },
    methods: {
      fileChanged: function(e){
        // console.log('file change', e)
        if(e.target.files.length == 0)return;
        let file = e.target.files[0];
        this.form.file = file;
      },
      ok: async function(){
        this.form.name = this.form.name.trim();
        if(!this.form.name || this.form.name.length > 20){
          this.$Message.warning('时间表名称不能为空且长度不能超过20字符');
          return;
        }
        if(!this.form.file){
          this.$Message.warning('请选择时间表文件');
          return;
        }
        var frm = new FormData();
        frm.append('file', this.form.file);
        frm.append("name", this.form.name);
        frm.append("type", this.form.type);
        if(this.type == 12){
          // this.$axios.post(`//${this.domains.trans}/station/plan/ImportYearPlan12`, frm)
          this.$axios.request({
            url: `//${this.domains.trans}/station/plan/ImportYearPlan12`,
            method: 'post',
            data: frm,
            responseType: 'json',
            myType: 'file'
          }).then(res => {
            if(res.code !== 0){
              // this.$Message.error(res.data);
              return;
            }
            // this.form.id = res.data;
            this.$Message.info('保存成功');
            this.$emit('saved');
            setTimeout(() => {
              this.showModal = false;
            }, 800);
          })
        }else{
          // this.$axios.post(`//${this.domains.trans}/station/plan/ImportYearPlan`, frm)
          this.$axios.request({
            url: `//${this.domains.trans}/station/plan/ImportYearPlan`,
            method: 'post',
            data: frm,
            responseType: 'json',
            myType: 'file'
          }).then(res => {
            if(res.code !== 0){
              // this.$Message.error(res.data);
              return;
            }
            // this.form.id = res.data;
            this.$Message.info('保存成功');
            this.$emit('saved');
            setTimeout(() => {
              this.showModal = false;
            }, 800);
          })
        }
      },
      cancel () {
        this.showModal = false;
      },
    }
  }
</script>
<style scoped>
.set-table tr td{
  border: solid 1px gray;
  text-align: center;
  white-space:nowrap
}
.set-table{
  width: 100%;
}
.time-span{
  display: flex;
}
.min-width{
  width: 70px;
  flex: none;
  margin: 0;
}
.ch-width{
  width: 120px;
  flex: none;
  margin: 0 10px;
  display: flex;
}
.ch-width span{
  width: 25px;
  flex: none;
}
.ch-width div{
  width: 40px;
  flex: auto;
}
</style>