<template>
<div class="alarm-rule-container">
  <div class="query-data-area">
    <AuthButton opCode='pd2' size="default" type="error" @click="deleteParams" style="margin: 0 5px;">{{showLang('com.op.batch.del')}}</AuthButton>
  </div>
  <div class="alarm-data-area" ref="table1">
    <u-table ref="chkTable" :data="list" style="width: 100%;" use-virtual row-key="id" border stripe :height="tabHeight" default-expand-all>
      <u-table-column type="index" header-align="center" fixed="left"></u-table-column>
      <u-table-column type="selection" header-align="center" fixed="left"></u-table-column>
      <u-table-column prop="name" :label="showLang('com.tab.title')" width="150" header-align="center" fixed="left">
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <u-table-column prop="stationName" :label="showLang('station.belong')" sortable width="250" header-align="center">
        <template slot-scope="params">
          {{params.row.stationName}}
        </template>
      </u-table-column>
      <u-table-column prop="mainName" :label="showLang('com.association.main')" sortable width="150" header-align="center">
        <template slot-scope="params">
          {{params.row.mainName}}
        </template>
      </u-table-column>
      <u-table-column prop="hc" :label="showLang('com.switch.state.hand')" sortable header-align="center">
        <template slot-scope="params">
          {{params.row.content.hc > 0 ? `S${params.row.content.hc}` : showLang('com.not.set')}}
        </template>
      </u-table-column>
      <u-table-column prop="rc" :label="showLang('com.switch.state.remote')" sortable header-align="center">
        <template slot-scope="params">
          {{params.row.content.rc > 0 ? `S${params.row.content.rc}` : showLang('com.not.set')}}
        </template>
      </u-table-column>
      <u-table-column prop="tc" :label="showLang('com.switch.state.time')" sortable header-align="center">
        <template slot-scope="params">
          {{params.row.content.tc > 0 ? `S${params.row.content.tc}` : showLang('com.not.set')}}
        </template>
      </u-table-column>
      <u-table-column width="120" fixed="right">
        <template slot="header">
          <AuthButton opCode='pa2' style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</AuthButton>
          <AuthButton opCode='pv2' style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}</AuthButton>
        </template>
        <template slot-scope="scope">
          <AuthButton opCode='pe2' style="margin-right: 5px" @click="handleEdit(scope)">{{showLang('com.op.edit')}}</AuthButton>
          <AuthButton opCode='pd2' type="error" @click="handleDelete(scope)">{{showLang('com.op.del')}}</AuthButton>
        </template>
      </u-table-column>
    </u-table>
    <ModalSwitchEdit v-model="showEditModal" :item="editItem" :stations="selectedStations" @saved="itemSaved" />
    <!-- <ModalArgEdit v-model="showArgModal" /> -->
  </div>
</div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import ModalSwitchEdit from './ModalSwitchEdit'
// import ModalArgEdit from './ModalArgEdit'
export default {
  name: 'ConfigSwitchIndex',
  components:{
    ModalSwitchEdit,
    // ModalArgEdit,
  },
  props: {
  },
  data () {
    return {
      curTab: 'hour24',
      list: [],
      tabHeight: 600,
      editItem: {isAdd: true, data: {}},
      showEditModal: false,
      showArgModal: false,
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function(){
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function(){
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => {return p.id});
      return pids;
    },
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    selectedNode(){
      this.getList();
    }
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 100);
    this.getList();
  },
  destroyed: function(){
  },
  methods: {
    setTableHeight: function(){
      this.tabHeight = this.$refs.table1.clientHeight - 10;
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    itemSaved: function(){
      this.getList();
    },
    deleteParams: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if(chks.length == 0){
        this.$Message.warning(this.showLang('com.tips.sel.del.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, {list: list}).then(res => {
            if(res.code == 0){
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name',params.row.name),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, {list: [params.row.id]}).then(res => {
            if(res.code == 0){
              this.getList();
            }
          });
        }
      });
    },
    getList: function(){
      // console.log('windowOnResize', this.selectedStationIds.join(','))
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        stationId = this.selectedNode.id;
      }else{
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QuerySwitch`, {groupId, stationId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'list', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-rule-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area{
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  border: solid 1px rgba(143, 138, 138, 0.452);
  margin-bottom: 5px;
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  padding-left: 10px;
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>