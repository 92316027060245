<template>
<bm-info-window class="info-win-container" :show="item.show" @close="infoWinClosed" :title="item.data.name" @open="infoWindowOpen" :position="{ lat: item.data.lat, lng: item.data.lng }" :offset="{ width: 0, height: -20 }">
  <div class="info-win-icons" v-if="item.data.type == 0">
    <template v-if="subCount > 0">
      <template v-for="(sb, code) in item.data.path">
        <img v-if="sb.count > 0" :src="img.pole.icons.links[code]" :title="subNames[code]" :key="code" @click="subClick(code, sb)" />
      </template>
    </template>
    <div v-else class="sub-area">
      未配置任何设备
    </div>
    <!-- <img v-for="(img, code) in item.data.subs" :src="img.pole.icons.links[code]" :key="code" /> -->
  </div>
  <div class="info-win-content">
    <table>
      <tr>
        <td>名称</td>
        <td>{{item.data.name}}</td>
        <td v-if="item.data.type != 'pole'">站点</td>
        <td v-if="item.data.type != 'pole'">{{item.data.stationName}}</td>
      </tr>
      <tr>
        <td>路段</td>
        <td>{{groupPathName(item.data.groupId)}}</td>
        <td>定位</td>
        <td>{{fixed(item.data.lng, 6)}}, {{fixed(item.data.lat, 6)}}</td>
      </tr>
    </table>
  </div>
</bm-info-window>
</template>
<script>
import {mapState,mapGetters} from 'vuex'
import { BmInfoWindow } from "@/components/vue-baidu-map/components";
export default {
  name: "PointInfoWin",
  components: {
    BmInfoWindow,
    // img.pole.icons.links
  },
  props: {
    item: {
      type: Object,
      default(){return {};}
    },
  },
  data() {
    return {
      subNames: {
        pole: '灯杆',
        light: '单灯',
        video: '视频',
        broad: '广播',
        speak: '对讲',
        led: '广告屏',
        env: '环境监测',
        wifi: 'WIFI',
        charge: '充电桩',
        manhole: '智能井盖',
        garbage: '智能垃圾桶',
        phone: '手机充电',
        traffic: '交通',
      },
    };
  },
  computed: {
    ...mapState('auth', ['currentMenuName']),
    ...mapGetters("group", ["groupPathName"]),
    subCount: function(){
      let t = 0;
      for(let key in this.item.data.path){
        t += this.item.data.path[key].count;
      }
      return t;
    },
  },
  watch: {
    'item.show'(){
      if(this.item.show){
        // console.log('item show', this.item, this.subCount)
      }
    },
  },
  mounted: function () {
  },
  methods: {
    subClick: function(code, sb){
      this.$emit('link', {code, params: sb});
    },
    fixed: function(val, len){
      if(val)return val.toFixed(len);
      return '-';
    },
    getImgType: function (dev) {
      let imgs = this.img.pole.icons[this.currentMenuName];
      if (!imgs) return this.img.pole.icons.pole.offline;
      if (!dev.online) {
        return imgs.offline;
      } else if (dev.alarms.length > 0) {
        return imgs.alarm30;
      } else {
        return imgs.online;
      }
    },
    showMakerInfo: function () {
      this.$emit('click', this.item);
    },
    infoWindowOpen: function(){},
    infoWinClosed: function(){
      this.item.show = false;
    },
  },
};
</script>
<style scoped>
.sub-area{
  border: solid 1px rgba(238, 236, 236, 0.815);
  margin: 10px;
  text-align: center;
  line-height: 36px;
  border-radius: 8px;
}
.info-win-container {
  /* border: solid 1px red; */
  width: 450px;
  display: flex;
  flex-direction: column;
}
.info-win-icons{
  height: 56px;
  flex: none;
}
.info-win-icons img{
  cursor: pointer;
}
.info-win-content{
  height: 60px;
  flex: none;
}
.info-win-content table{
  width: 100%;
}
.info-win-content table td {
  /* width: 115px; */
  /* text-align: center; */
  white-space: nowrap;
  padding: 0 5px;
  box-sizing: border-box;
  /* border-right: 1px solid #d0d9e9;
  border-bottom: 1px solid #d0d9e9; */
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.info-win-content table tr:last-child td {
  border-bottom: none;
}
.info-win-content table tr td:last-child {
  border-right: none;
}
</style>