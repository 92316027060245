 <!--独立电源模块-->
  <template>
  <div class="supply-public">
    <div class="supply-top">独立电源模块</div>
    <div class="supply-supply-con"></div>
    <div class="supply-con">
      <div class="supply-modularBox mt20">
        <div class="supply-fl supply-modularBoxFl"><span class="supply-valueBox">12.57V</span> <span class="supply-nameBox">输出电压</span>
        </div>
        <div class="supply-fr supply-modularBoxFr"><a href="javaScript:" class="supply-butPolice">设置输出电压</a> <a href="javaScript:" class="supply-butPolice supply-mt10">查询输出电压</a></div>
        <div class="supply-mt10 supply-fl"><a href="javaScript:" class="supply-butPolice">设置报警限</a> <a href="javaScript:" class="supply-butPolice" style="margin-left: 15px;">查询报警限</a></div>
      </div>
      <div class="supply-modularBox supply-mt20">
        <div class="supply-fl supply-modularBoxFl"><span class="supply-valueBox">12.29V</span> <span class="supply-nameBox">输出电压</span>
        </div>
        <div class="supply-fr supply-modularBoxFr"><a href="javaScript:" class="supply-butPolice">设置输出电压</a> <a href="javaScript:" class="supply-butPolice mt10">查询输出电压</a></div>
        <div class="supply-mt10 supply-fl"><a href="javaScript:" class="supply-butPolice">设置报警限</a> <a href="javaScript:" class="supply-butPolice" style="margin-left: 15px;">查询报警限</a></div>
      </div>
      <div class="supply-but" style="bottom:65px;">
        模块<img src="http://211.162.119.217:39008/M_Monitor/img/index/open.png" alt="" class="supply-v-align-m supply-ml10"></div>
      <div class="supply-mt5 supply-but"><a href="javaScript:" class="supply-butPolice">设置时间表</a> <a href="javaScript:" class="supply-butPolice supply-ml20">查询时间表</a></div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'supply4',
  components: {
  },
  props: {
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    selectedNode() {
      this.setHigt();
    },
  },
  mounted: function () {

  },
  methods: {
    setHigt: function(){},

  }
}
</script>
<style scoped>
.supply-mt10 {
  margin-top: 10px;
}
.supply-ml10 {
  margin-left: 10px;
}
.supply-ml20 {
  margin-left: 20px;
}
.supply-fl {
  float: left;
}
.supply-fr {
  float: right;
}
.supply-v-align-m {
  vertical-align: middle;
}
.device-item-content {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 20px;
}
.supply-public {
  width: 280px;
  height: 600px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
}
.supply-top {
  width: 100%;
  background-color: #05c29c;
  height: 60px;
  border-radius: 8px 8px 0 0;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  line-height: 60px;
  text-align: center;
}
.supply-con {
  padding: 0 10px;
  text-align: center;
}
.supply-modularBox {
  width: calc(100%);
  height: 145px;
  background: #ffffff;
  border: 1px solid #05c29c;
  box-shadow: 0px 4px 12px 0px rgb(131 207 192 / 54%) inset;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;
}
.supply-modularBoxFl {
  width: 96px;
  height: 62px;
  background: #ffffff;
  border: 1px solid #05c29c;
  box-shadow: 0px 4px 12px 0px rgb(131 207 192 / 54%) inset;
  border-radius: 4px;
}
.supply-valueBox {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 22px;
  color: #021629;
}
.supply-butPolice {
  width: 98px;
  height: 26px;
  background: linear-gradient(0deg, #3965c8, #51d1fa);
  box-shadow: 0px 1px 4px 0px rgb(32 39 43 / 10%);
  border-radius: 4px;
  font-size: 14px;
  display: inline-block;
  color: #ffffff;
  line-height: 26px;
}
.supply-but {
  position: absolute;
  bottom: 32px;
  text-align: center;
  width: 100%;
}

.supply-modularBoxFr {
  width: 96px;
}
</style>

