<template>
  <div class="peoplePie-control">
    <div class="peoplePie-title">实时人流量
      <div class="peoplePie-title-time">2021-02-22 10:10:52</div>
    </div>
    <div class="peoplePie-con">
      <p class="peoplePie-con-ratio">男女比例</p>
      <div class="peoplePie-con-num">进入:<span class="peoplePie-num-blue">100</span> <i></i>离开:<span class="peoplePie-num-blue">50</span></div>
      <div style="height:250px;width:100%">
        <chartsPie :option="chartsLineData" id="chartsPeoplePie" :key="timer"></chartsPie>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  computed: {

  },
  data() {
    return {
      timer: 2,
      chartsLineData: {
        id: "chartsPeoplePie",
        series: [
          { value: 30, name: "女" },
          { value: 70, name: "男" },
        ],
        color: ["#C091FD", "#54D390"],
        content: {
          legend: {
            orient: 'horizontal',
            bottom: 'auto',
            top: 'bottom'
          },
        },
        setSeries: {
          type: 'pie',
          radius: "83%",
          bottom: "10%",
          itemStyle: {
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          },
        }
      },
    }
  },
  watch: {

  },
  mounted: function () {

  },
  methods: {

  }
}
</script>
<style scoped>
.peoplePie-control {
  height: calc(100%);
  padding: 20px 30px;
}
.peoplePie-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  margin-bottom: 15px;
  font-weight: bold;
}
.peoplePie-title-time {
  float: right;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8793a5;
}
.peoplePie-con-ratio {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
  text-align: center;
}
.peoplePie-con-num {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
  text-align: center;
  margin-top: 10px;
}
.peoplePie-con-num i {
  margin-left: 20px;
}
.peoplePie-con-num .peoplePie-num-blue {
  color: #007eff;
}
</style>
