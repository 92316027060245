<template>
  <div class="device-item-content">
    <div class="device-search">
      <span>状态</span>
      <Select v-model="state" class="tool-type">
        <template v-for="item in states">
          <Option :value="item.code" :key="item.code">{{item.name}}</Option>
        </template>
      </Select>
      <a href="javascript:void(0);" class="cameraInfo-search-but"><img :src="img.pole.speak.lookup" /> 查询</a>
    </div>
    <div class="device-table" ref="table">
      <u-table :data="devices" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="tabHeight" stripe>
        <u-table-column prop="name" label="状态" sortable fit align="center">
          <template slot-scope="scope">
            <img :src="getImage(scope.row)" />
          </template>
        </u-table-column>
        <u-table-column prop="branch" label="灯杆编号" sortable fit align="center">
          <template slot-scope="scope">
            {{scope.row.stationName}}
          </template>
        </u-table-column>
        <u-table-column prop="name" label="终端名称" sortable fit align="center"></u-table-column>
        <u-table-column prop="name" label="设备类型" sortable fit align="center">
          <template slot-scope="scope">
            {{scope.row.connectTypeName}}
          </template>
        </u-table-column>
        <u-table-column prop="name" label="电量" sortable fit align="center">
          <template slot-scope="scope">
            {{`${img.val((scope.row.lastData.u || 4.5) * 100 / 5, 0)}%`}}
          </template>
        </u-table-column>
        <u-table-column prop="line" label="所在分组" sortable fit align="center">
          <template slot-scope="scope">
            {{boxRoad(scope.row.stationId)}}
          </template>
        </u-table-column>
        <u-table-column width="220" label="操作" align="center" fixed="right">
          <template slot-scope="scope">
            <a href="javascript:void(0);" class="cameraInfo-tab-but color1" @click="history(scope)">查看详情</a>
          </template>
        </u-table-column>
      </u-table>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'deviceList',
  components: {
  },
  props: {
    devices: {
      type: Array,
      default(){return []},
    },
  },
  data() {
    return {
      state: "s4",
      states: [
        { name: "全部", code: "s4" },
        { name: "在线", code: "s1" },
        { name: "离线", code: "s2" },
        { name: "报警", code: "s3" },
      ],
      tabData: [],
      tabHeight: 600,
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {},
  mounted: function () {
    setTimeout(this.setTableHeight, 200);
  },
  methods: {
    history(el) {
      this.$store.commit('group/selectNodeById', { nodeId: el.row.stationId, type: this.productCodes.station });
      this.$store.commit('auth/switchStationTabName', 'phone');
    },
    boxRoad(stationId) {
      let ss = this.selectedDevices.filter(p => p.id == stationId);
      if (ss.length == 0) return '--';
      return `${this.groupPathName(ss[0].groupId)}`;
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 20;
    },
    getImage: function (item) {
      if (!item.online) return this.img.pole.icons.phone.offline;
      if (item.alarm) return this.img.pole.icons.phone.alarm;
      return this.img.pole.icons.phone.online;
    },
  }
}
</script>
<style scoped>
.device-item-content {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 30px;
}
.tool-type {
  width: 168px;
  height: 32px;
  margin: 0 10px 0 5px;
}
.cameraInfo-search-but {
  padding: 3px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 32px;
  margin-left: 19px;
}
.cameraInfo-search-but img {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 2px;
}
.cameraInfo-tab-but {
  padding: 0px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
  margin: 0 5px;
}
.cameraInfo-tab-but img {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 3px;
}

.cameraInfo-tab-but.color1 {
  background: #21a4f8;
}
.cameraInfo-tab-but.color2 {
  background: #3cbf6e;
}
.cameraInfo-tab-but.color3 {
  background: #fd5353;
}
.cameraInfo-tab-but.color4 {
  background: #6485fd;
}
.device-table {
  margin-top: 20px;
}
</style>
