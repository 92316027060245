<template>
  <Modal v-model="showModal" :mask-closable="false" width="800px">
    <div slot="header">相位检测结果</div>
    
    <div class="search-data-content" ref="table1">
      <div v-if="devs.length == 0">
        <Spin fix size="large">
          <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
          <div style="margin-top: 10px">正在等待检测结果 ... {{time}}</div>
        </Spin>
      </div>
      <div v-else class="dev-code-list">
        <div v-for="(code, idx) in devs" :key="code">
          {{idx+1}}、{{code}}: {{flags[idx%3]}}
        </div>
      </div>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalWaiting',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    }
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      time: 0,
      max: 0,
      devs: [],
      flags: ['A相', 'B相', 'C相']
    }
  },
  computed: {
    ...mapGetters('auth', ['needCheckPswd','showLang']),
    ...mapState('group', ['groups', 'selectedNode', 'selectedGroups', 'rootTitle']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        console.log('show wait modal',this.item)
        this.time = 0;
        this.max = parseInt(Math.random() * 10) + 25;
        this.waitResult();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  methods: {
    getLights: function(){
      this.$set(this, 'devs', []);
      this.$axios.post(`//${this.domains.trans}/station/config/QueryLightsByMonitor`, { mid: this.item.id }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'devs', res.data);
        }
      });
    },
    waitResult: function(){
      this.time ++;
      if(this.time < this.max){
        setTimeout(this.waitResult, 1000);
      }else{
        this.getLights();
      }
    },
    cancel () {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.dev-code-list{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.demo-spin-col .circular {
  width:25px;
  height:25px;
}
.demo-spin-icon-load{
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from { transform: rotate(0deg);}
  50%  { transform: rotate(180deg);}
  to   { transform: rotate(360deg);}
}
.demo-spin-col{
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
.search-data-content{
  position: relative;
  height: 300px;
  /* border: solid 1px red; */
}
.demo-drawer-footer{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form{
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly{
  background: #eee
}
</style>