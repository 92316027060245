<template>
<div class="config-container">
  <div class="config-header">
    <div :class="['header-tab-item', item.code == current ? 'header-item-selected' : '']" v-for="(item, idx) in menus" :key="idx" @click="clickMenu(item)">{{item.name}}</div>
  </div>
  <div class="config-content">
    <template v-for="(item, idx) in menus">
      <component v-if="item.code == current" :key="idx" v-bind:is="item.com" :args="item.args"></component>
    </template>
  </div>
</div>
</template>
<script>
import AnalyseLightonTrend from '@/components/analyse/status/lighton/Index'
export default {
  name: 'Home',
  components: {
    // AuthMenuItem
  },
  props: {
    sys: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      current: 's1',
      menus: [
        {code: 's1', name: '亮灯率趋势统计', com: AnalyseLightonTrend, args: {}},
        // {code: 's2', name: '资产道路统计', com: AssetAnalyseRoad, args: {}},
        // {code: 's3', name: '资产类型统计', com: AssetAnalyseType, args: {}},
        // {code: 's4', name: '配电柜资产统计', com: AssetAnalyseBox, args: {}},
        // {code: 's5', name: '资产使用单位统计', com: AssetRecordOrgan, args: {}},
        // {code: 's6', name: '资产使用道路统计', com: AssetRecordRoad, args: {}},
        // {code: 's7', name: '资产使用类型统计', com: AssetRecordType, args: {}},
      ],
    }
  },
  watch: {
  },
  mounted: function(){
  },
  methods: {
    clickMenu: function(item){
      this.current = item.code;
    },
  }
}
</script>
<style scoped>
.config-container{
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 3px; */
  padding-top: 5px;
  background-color: white;
}
.config-header{
  border-bottom: solid 1px rgba(58, 46, 46, 0.445);
  height: 30px;
  /* margin: 3px; */
  flex: none;
  display: flex;
}
.header-tab-item{
  border: solid 1px rgba(58, 46, 46, 0.445);
  border-bottom: 0;
  cursor: pointer;
  margin-left: 5px;
  padding: 0 10px;
  line-height: 30px;
}
.header-tab-item:hover{
  background-color: rgba(0, 255, 255, 0.164);
}
.header-item-selected{
  background-color: rgba(5, 37, 221, 0.322);
}
.config-content{
  /* border: solid 1px rgba(58, 46, 46, 0.445); */
  /* margin: 3px; */
  height: 500px;
  flex: auto;
}
</style>
