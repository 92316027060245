<template>
  <div :ref="`t${node.type}i${node.id}`" class="tree-node">
    <div :class="['node-item', (selectedNode.id == node.id && selectedNode.type == node.type) ? 'node-selected':'']" @click="nodeClick">
      <div :class="['node-item-flag', (selectedNode.id == node.id && selectedNode.type == node.type) ? 'node-selected-flag':'']"></div>
      <div :style="{width: `${(this.curPath.length - 1) * 10}px`, height: '44px', flex: 'none'}"></div>
      <img class="node-img" :src="getImgType()">
      <span :class="nodeTextClass" :title="node.name">{{node.name}}</span>
      <img v-if="node.children && node.children.length > 0" class="expand-img" :src="node.expand ? img.pole.icons.tree.collect : img.pole.icons.tree.expand">
    </div>
    <div v-if="node.expand" class="tree-nodes">
      <template v-if="node.children">
        <MyTreeNode v-for="(n, idx) in node.children" :path="curPath" :level="idx" :key="n.id" :node="n" />
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MyTreeNode from './MyTreeNode'
export default {
  name: 'MyTreeNode',
  components:{
    MyTreeNode,
  },
  props: {
    node: {
      type: Object,
      default(){ return {};}
    },
    path: {
      type: Array,
      default(){ return [];}
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      curPath: [...this.path, this.level],
      otherMenu: ['gis', 'union', 'analyse'],
    }
  },
  computed: {
    ...mapState('group', ['selectedNode']),
    ...mapState('auth', ['currentMenuName']),
    nodeTextClass: function () {
      if (this.node.type == 'root') {
        return 'node-text-root';
      } else if (this.node.type == 'group') {
        return 'node-text-group';
      } else {
        return 'node-text-dev';
      }
    },
  },
  mounted: function () {
  },
  destroyed: function(){
  },
  methods: {
    getImgType: function(){
      switch (this.node.type) {
        case 'root': return this.img.pole.icons.tree.root;
        case 'group': return this.img.pole.icons.tree.group;
        default:{
          let type = this.currentMenuName;
          if(this.otherMenu.indexOf(this.currentMenuName) != -1){
            type = 'pole';
          }
          let imgs = this.img.pole.icons[type];
          if(!imgs)return this.img.pole.icons.pole.online;
          if(!this.node.online){
            return imgs.offline;
          }else if(this.node.alarm){
            return imgs.alarm30;
          }else{
            return imgs.online;
          }
        }
      }
    },
    nodeClick: function(){
      if (this.selectedNode.id == this.node.id && this.selectedNode.type == this.node.type) {
        this.node.expand = !this.node.expand;
      } else {
        this.$store.commit('group/selectNode', this.node);
        if (!this.node.expand) this.node.expand = true;
      }
    },
  }
}
</script>
<style scoped>
.tree-node{
  width: 100%;
}
.node-item{
  /* border: solid 1px red; */
  height: 48px;
  display: flex;
  align-items: center;
  /* background-color: #041B32; */
}
.node-item-flag{
  width: 4px;
  height: 48px;
  flex: none;
  margin-right: 3px;
  background-color: transparent;
}
/* .node-item-flag {
  width: 6px;
  background-color: transparent;
} */
.node-text-group {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
  margin-left: 5px;
  height: 44px;
  line-height: 44px;
  user-select: none;
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.node-text-root {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
  margin-left: 5px;
  height: 44px;
  line-height: 44px;
  user-select: none;
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.node-text-dev {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  margin-left: 5px;
  height: 44px;
  line-height: 44px;
  user-select: none;
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.node-text{
  margin-left: 5px;
  height: 44px;
  line-height: 44px;
  user-select:none;
  flex: auto;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #0A1421;
}
.node-img{
  width: 28px;
  height: 30px;
  /* margin: 15px 0 0 15px; */
  flex: none;
  /* border: solid 1px red; */
}
.expand-img{
  width: 12px;
  height: 8px;
  margin-right: 10px;
  /* margin: 15px 15px 0 0; */
  flex: none;
}
.node-selected{
  background: #DDEFFE;
}
.node-selected-flag{
  background-color: #3880FC;
}
</style>