<template>
  <div class="device-item-content">
    <template v-for="(item,index) in devices">
      <div class="device-info" :key="index" ref="infoHight">
        <div class="device-info-img">
          <img :src="getImage(item)" alt="">
        </div>
        <div class="device-info-box">
          <div class="device-info-upper">
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.cover.location" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{item.lastData.cp == 0 ? '正常' : '移动'}}</div>
                <div class="device-info-title">位置状态</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.cover.cell" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.u, 1)}} V</div>
                <div class="device-info-title">电池电压</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.cover.gas" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{getGasType(item.lastData.gt)}}</div>
                <div class="device-info-title">气体类型</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.cover.XLEL" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.gc, 1)}} %</div>
                <div class="device-info-title">气体浓度</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.cover.signal" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{item.extraData.e}}</div>
                <div class="device-info-title">无线信号覆盖度</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.cover.RSSI" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{item.extraData.r}}</div>
                <div class="device-info-title">信号接收强度</div>
              </div>
            </div>
          </div>
          <div class="device-info-lower">
            <div class="device-info-tab">
              <div class="device-info-icon">
                <img :src="img.pole.asset.index.cover.angle" alt="">
              </div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.l, 1)}}</div>
                <div class="device-info-title">倾斜角度</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.cover.temperature" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.t, 1)}} ℃</div>
                <div class="device-info-title">温度</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.cover.shock" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.v, 0)}}</div>
                <div class="device-info-title">震动频次</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.cover.Overflow" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{img.val(item.lastData.w, 0) == 0 ? '正常' : '满溢'}}</div>
                <div class="device-info-title">水位状态</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.cover.interference" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{item.extraData.s}}</div>
                <div class="device-info-title">信号与干扰度</div>
              </div>
            </div>
            <div class="device-info-tab">
              <div class="device-info-icon"><img :src="img.pole.asset.index.cover.time" alt=""></div>
              <div class="device-info-con">
                <div class="device-info-val">{{item.lastData.time > 0 ? new Date(item.lastData.time).format('yyyy-MM-dd HH:mm:ss') : '--'}}</div>
                <div class="device-info-title">刷新时间</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="device-chart" style="height:560px">
      <recordPage :devId="devId" />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import recordPage from '../history/Index'
export default {
  name: 'deviceList',
  components: {
    recordPage
  },
  props: {
    devices: {
      type: Array,
      default(){return []},
    },
    oneId: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      timer: 0,
      filter: {
        start: "",
        end: "",
      },
      devId: -1,
      chartHeight: 600,
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    selectedNode() {
      this.setHigt();
    },
  },
  mounted: function () {
    let now = new Date();//getDate
    now.setHours(now.getHours() + 1)
    this.filter.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 7);
    this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');

    this.setHigt();
  },
  methods: {
    setHigt() {
      if (this.$refs.infoHight.length > 3) {
        return false;
      }
      let num = this.$refs.infoHight[0].clientHeight == 89 ? 89 : 159;
      if (this.$refs.infoHight.length == 1) {
        this.chartHeight = num == 89 ? 620 : 520;
      }
      if (this.$refs.infoHight.length == 2) {
        this.chartHeight = num == 89 ? 500 : 370;
      }
    },
    getGasType: function (type) {
      if (type == 1) return '甲烷';
      else if (type == 2) return '硫化氢';
      else return '不支持';
    },
    getImage: function (item) {
      if (!item.online) return this.img.pole.icons.manhole.offline3;
      return this.img.pole.icons.manhole.online3;
    },
  }
}
</script>
<style scoped>
.cameraInfo-search-but {
  padding: 3px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
  margin-left: 19px;
}
.cameraInfo-search-but img {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
}
.device-item-content {
  width: 100%;
}
.device-info {
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 30px;
  margin-bottom: 14px;
}
.device-info-img {
  text-align: center;
  margin-right: 30px;
  margin-top: 15px;
}
.device-info-img img {
  margin-top: 2px;
}
.device-info-tab {
  display: flex;
  justify-content: flex-start;
}
.device-chart {
  width: calc(100%);
  background: #fff;
  border-radius: 10px;
}
.device-info-icon img {
  margin-top: 5px;
  margin-right: 14px;
}
.device-info-con {
  width: 140px;
}
.device-info-val {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
}
.device-info-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8793a5;
}
.device-info-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.device-info-upper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
}
.device-info-lower {
  display: flex;
  justify-content: flex-start;
}
</style>
