<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">{{showLang('com.but.edit.branch.info')}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="110">
      <div class="form-item-group">
        <div class="group-header">{{showLang('com.but.basic.info')}}</div>
        <FormItem prop="name" :label="showLang('com.device.branch.name')" style="margin-right: 15px">
          <div class="flex-row">
            <Input type="text" v-model="form.name" :placeholder="showLang('com.device.branch.title')" style="width: 200px; margin-right: 23px;"></Input>
            {{showLang('com.state.measured.type')}}
            <i-switch v-model="form.content.tt" size="large" true-color="green" false-color="blue" style="margin: 0 10px;width:56px">
              <span slot="open">{{showLang('com.data.total')}}</span>
              <span slot="close">{{showLang('com.branch')}}</span>
            </i-switch>
          </div>
        </FormItem>
        <FormItem prop="cid" :label="showLang('com.site.affiliated')" style="margin-right: 15px">
          <div class="flex-row">
            <Select :placeholder="showLang('save.select')" v-model="form.stationId" @on-change="stationChanged" style="width: 200px; margin-right: 10px;">
              <Option v-for="(s, idx) in stations" :key="idx" :value="s.id">{{s.name}}</Option>
            </Select>
            {{showLang('com.association.main')}}
            <Select :placeholder="showLang('save.select')" v-model="form.content.mid" style="width: 200px; margin: 0 10px;" @on-change="getUseablePlan">
              <template v-for="(s, idx) in mains">
                <Option :value="s.id" :key="idx">{{s.name}}</Option>
              </template>
            </Select>
          </div>
        </FormItem>
        <FormItem prop="count" :label="showLang('com.Install.lamp.num')" style="margin-right: 15px">
          <div class="flex-row">
            <Input type="number" v-model="form.content.count" :placeholder="showLang('com.state.lamp.num')" style="width: 200px; margin-right: 23px;"><span
              slot="append">{{showLang('com.unit.calyx')}}</span></Input>
            {{showLang('com.device.lamp.power')}}
            <Input type="number" v-model="form.content.power" :placeholder="showLang('com.state.lamp.power')" style="width: 200px; margin: 0 10px;"><span
              slot="append">W</span></Input>
          </div>
        </FormItem>
      </div>
      <div class="form-item-group">
        <div class="group-header">{{showLang('com.stat.q.correlation')}}</div>
        <FormItem prop="enp" :label="showLang('com.is.enable')" style="margin-right: 15px">
          <div class="flex-row">
            <i-switch v-model="form.content.enp" size="large" true-color="green" false-color="red" style="margin-right: 10px;width:56px">
              <span slot="open">{{showLang('com.state.enable')}}</span>
              <span slot="close">{{showLang('com.state.disable')}}</span>
            </i-switch>
            {{showLang('com.branch.contact.need')}}
            <Select :placeholder="showLang('save.select')" v-model="form.content.cid" style="width: 155px; margin: 0 10px;">
              <template v-for="(s, idx) in contacts">
                <Option :value="s.id" :key="idx">{{s.name}}</Option>
              </template>
            </Select>
            {{showLang('com.state.police.type')}}
            <i-switch v-model="form.content.abc" size="large" true-color="green" false-color="blue" style="margin: 0 10px;width:56px">
              <span slot="open">{{showLang('com.lighting.I')}}</span>
              <span slot="close">{{showLang('com.lighting.P')}}</span>
            </i-switch>
            {{showLang('com.state.police.method')}}
            <i-switch v-model="form.content.abr" size="large" true-color="green" false-color="blue" style="margin: 0 10px;width:56px">
              <span slot="open">{{showLang('com.lighting.rated')}}</span>
              <span slot="close">{{showLang('com.state.ratio')}}</span>
            </i-switch>
          </div>
        </FormItem>
        <div v-show="form.content.enp">
          <FormItem prop="par" :label="showLang('com.lighting.capacity.A')" style="margin-right: 15px">
            <div class="flex-row">
              <Input type="number" v-model="form.content.par" style="width: 125px; margin-right: 10px"><span slot="append">W</span></Input>
              {{showLang('com.lighting.pah')}}
              <Input type="number" v-model="form.content.pah" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>
              {{showLang('com.lighting.paf')}}
              <Input type="number" v-model="form.content.paf" style="width: 100px; margin: 0 10px;"></Input>
            </div>
          </FormItem>
          <FormItem prop="pbr" :label="showLang('com.lighting.capacity.B')" style="margin-right: 15px">
            <div class="flex-row">
              <Input type="number" v-model="form.content.pbr" style="width: 125px; margin-right: 10px"><span slot="append">W</span></Input>
              {{showLang('com.lighting.pah')}}
              <Input type="number" v-model="form.content.pbh" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>
              {{showLang('com.lighting.paf')}}
              <Input type="number" v-model="form.content.pbf" style="width: 100px; margin: 0 10px;"></Input>
            </div>
          </FormItem>
          <FormItem prop="pcr" :label="showLang('com.lighting.capacity.C')" style="margin-right: 15px">
            <div class="flex-row">
              <Input type="number" v-model="form.content.pcr" style="width: 125px; margin-right: 10px"><span slot="append">W</span></Input>
              {{showLang('com.lighting.pah')}}
              <Input type="number" v-model="form.content.pch" style="width: 100px; margin: 0 10px;"><span slot="append">%</span></Input>
              {{showLang('com.lighting.paf')}}
              <Input type="number" v-model="form.content.pcf" style="width: 100px; margin: 0 10px;"></Input>
            </div>
          </FormItem>
          <FormItem prop="cac" :label="showLang('cmd.project.A.I')" style="margin-right: 15px">
            <div class="flex-row">
              <Select :placeholder="showLang('save.select')" v-model="form.content.cac" style="width: 125px; margin-right: 5px">
                <Option :value="0">{{showLang('com.state.not')}}</Option>
                <Option v-for="n in 72" :key="n" :value="n">{{showLang('com.lighting.I.which',n)}}</Option>
              </Select>
              <Input type="number" v-model="form.content.cat" style="width: 165px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.cat')}}</span><span
                slot="append">{{showLang('com.data.times')}}</span></Input>
              <Input type="number" v-model="form.content.car" style="width: 150px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.rated.v.val')}}</span><span
                slot="append">A</span></Input>
              <Input type="number" v-model="form.content.cal" style="width: 150px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower')}}</span><span
                slot="append">%</span></Input>
              <Input type="number" v-model="form.content.cah" style="width: 150px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper')}}</span><span
                slot="append">%</span></Input>
              <Input type="number" v-model="form.content.exta" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('power.external.usage')}}</span><span
                slot="append">A</span></Input>
            </div>
          </FormItem>
          <FormItem prop="cbc" :label="showLang('cmd.project.B.I')" style="margin-right: 15px">
            <div class="flex-row">
              <Select :placeholder="showLang('save.select')" v-model="form.content.cbc" style="width: 125px; margin-right: 5px">
                <Option :value="0">{{showLang('com.state.not')}}</Option>
                <Option v-for="n in 72" :key="n" :value="n">{{showLang('com.lighting.I.which',n)}}</Option>
              </Select>
              <Input type="number" v-model="form.content.cbt" style="width: 165px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.cat')}}</span><span
                slot="append">{{showLang('com.data.times')}}</span></Input>
              <Input type="number" v-model="form.content.cbr" style="width: 150px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.rated.v.val')}}</span><span
                slot="append">A</span></Input>
              <Input type="number" v-model="form.content.cbl" style="width: 150px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower')}}</span><span
                slot="append">%</span></Input>
              <Input type="number" v-model="form.content.cbh" style="width: 150px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper')}}</span><span
                slot="append">%</span></Input>
              <Input type="number" v-model="form.content.extb" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('power.external.usage')}}</span><span
                slot="append">A</span></Input>
            </div>
          </FormItem>
          <FormItem prop="ccc" :label="showLang('cmd.project.C.I')" style="margin-right: 15px">
            <div class="flex-row">
              <Select :placeholder="showLang('save.select')" v-model="form.content.ccc" style="width: 125px; margin-right: 5px">
                <Option :value="0">{{showLang('com.state.not')}}</Option>
                <Option v-for="n in 72" :key="n" :value="n">{{showLang('com.lighting.I.which',n)}}</Option>
              </Select>
              <Input type="number" v-model="form.content.cct" style="width: 165px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.cat')}}</span><span
                slot="append">{{showLang('com.data.times')}}</span></Input>
              <Input type="number" v-model="form.content.ccr" style="width: 150px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.rated.v.val')}}</span><span
                slot="append">A</span></Input>
              <Input type="number" v-model="form.content.ccl" style="width: 150px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower')}}</span><span
                slot="append">%</span></Input>
              <Input type="number" v-model="form.content.cch" style="width: 150px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper')}}</span><span
                slot="append">%</span></Input>
              <Input type="number" v-model="form.content.extc" style="width: 180px; margin-right: 5px;"><span slot="prepend">{{showLang('power.external.usage')}}</span><span
                slot="append">A</span></Input>
            </div>
          </FormItem>
        </div>
      </div>
      <div class="form-item-group">
        <div class="group-header">{{showLang('com.lighting.cl.info')}}</div>
        <FormItem prop="enl" :label="showLang('com.lighting.leakage')" style="margin-right: 15px">
          <div class="flex-row">
            <i-switch v-model="form.content.enl" size="large" true-color="green" false-color="red" style="margin-right: 10px;width:56px">
              <span slot="open">{{showLang('com.state.enable')}}</span>
              <span slot="close">{{showLang('com.state.disable')}}</span>
            </i-switch>
            {{showLang('com.ins.timeTable.alarm')}}
            <Select :placeholder="showLang('save.select')" v-model="form.content.ltid" style="width: 230px; margin: 0 10px;">
              <Option :value="0">{{showLang('com.state.not')}}</Option>
              <template v-for="(s, idx) in timeTables">
                <Option :value="s.id" :key="idx">[{{s.mode}}][{{s.type}}]{{s.name}}</Option>
              </template>
            </Select>
          </div>
        </FormItem>
        <div v-show="form.content.enl">
          <FormItem prop="lc" :label="showLang('com.lighting.leakage.ch')" style="margin-right: 15px">
            <div class="flex-row">
              <Select :placeholder="showLang('save.select')" v-model="form.content.lc" style="width: 150px; margin-right: 10px;">
                <Option :value="0">{{showLang('com.state.not')}}</Option>
                <Option v-for="n in 24" :key="n" :value="n">{{showLang('com.lighting.cl.which',n)}}</Option>
              </Select>
              {{showLang('com.i.parameters')}}
              <Input type="number" v-model="form.content.lt" style="width: 150px; margin: 0 10px;"><span slot="append">{{showLang('com.data.times')}}</span></Input>
            </div>
          </FormItem>
          <FormItem prop="ll" :label="showLang('alarm.type.leakage.1')" style="margin-right: 15px">
            <div class="flex-row">
              <Input type="number" v-model="form.content.ll" style="width: 150px; margin-right: 10px;"><span slot="prepend">{{showLang('com.data.threshold')}}</span><span
                slot="append">mA</span></Input>
              {{showLang('com.ope.nav.channel')}}
              <Select :placeholder="showLang('save.select')" v-model="form.content.chl" style="width: 150px; margin: 0 10px;">
                <Option :value="0">{{showLang('com.state.not')}}</Option>
                <template v-for="n in 7">
                  <Option :value="n" :key="n"> {{showLang('com.lighting.output.which',n)}} </Option>
                </template>
              </Select>
              <Checkbox v-model="form.content.cmdl">{{showLang('com.op.if.linkage')}}？</Checkbox>
              <i-switch v-model="form.content.openl" size="large" true-color="green" false-color="red" style="margin: 0 5px;width:56px">
                <span slot="open">{{showLang('com.state.energize')}}</span>
                <span slot="close">{{showLang('com.state.outage')}}</span>
              </i-switch>
              <Input type="number" v-model="form.content.expirel" style="width: 160px; margin-right: 10px;"><span slot="prepend">{{showLang('com.date.delayed')}}</span><span
                slot="append">min</span></Input>
            </div>
          </FormItem>
          <FormItem prop="lh" :label="showLang('alarm.type.leakage.2')" style="margin-right: 15px">
            <div class="flex-row">
              <Input type="number" v-model="form.content.lh" style="width: 150px; margin-right: 10px;"><span slot="prepend">{{showLang('com.data.threshold')}}</span><span
                slot="append">mA</span></Input>
              {{showLang('com.ope.nav.channel')}}
              <Select :placeholder="showLang('save.select')" v-model="form.content.chh" style="width: 150px; margin: 0 10px;">
                <Option :value="0">{{showLang('com.state.not')}}</Option>
                <template v-for="n in 7">
                  <Option :value="n" :key="n">{{showLang('com.lighting.output.which')}}</Option>
                </template>
              </Select>
              <Checkbox v-model="form.content.cmdh">{{showLang('com.op.if.linkage')}}？</Checkbox>
              <i-switch v-model="form.content.openh" size="large" true-color="green" false-color="red" style="margin: 0 5px;width:56px">
                <span slot="open">{{showLang('com.state.energize')}}</span>
                <span slot="close">{{showLang('com.state.outage')}}</span>
              </i-switch>
              <Input type="number" v-model="form.content.expireh" style="width: 160px; margin: 0 5px;"><span slot="prepend">{{showLang('com.date.delayed')}}</span><span
                slot="append">min</span></Input>
            </div>
          </FormItem>
          <FormItem prop="lx" :label="showLang('alarm.type.leakage.3')" style="margin-right: 15px">
            <div class="flex-row">
              <Input type="number" v-model="form.content.lx" style="width: 150px; margin-right: 10px;"><span slot="prepend">{{showLang('com.data.threshold')}}</span><span
                slot="append">mA</span></Input>
              {{showLang('com.ope.nav.channel')}}
              <Select :placeholder="showLang('save.select')" v-model="form.content.chx" style="width: 150px; margin: 0 10px;">
                <Option :value="0">{{showLang('com.state.not')}}</Option>
                <template v-for="n in 7">
                  <Option :value="n" :key="n">{{showLang('com.lighting.output.which')}}</Option>
                </template>
              </Select>
              <Checkbox v-model="form.content.cmdx">{{showLang('com.op.if.linkage')}}？</Checkbox>
              <i-switch v-model="form.content.openx" size="large" true-color="green" false-color="red" style="margin: 0 5px;width:56px">
                <span slot="open">{{showLang('com.state.energize')}}</span>
                <span slot="close">{{showLang('com.state.outage')}}</span>
              </i-switch>
              <Input type="number" v-model="form.content.expirex" style="width: 160px; margin: 0 5px;"><span slot="prepend">{{showLang('com.date.delayed')}}</span><span
                slot="append">min</span></Input>
            </div>
          </FormItem>
        </div>
      </div>
      <div class="form-item-group">
        <div class="group-header">{{showLang('com.anti.theft.info')}}</div>
        <FormItem prop="ens" :label="showLang('com.anti.theft')" style="margin-right: 15px">
          <i-switch v-model="form.content.ens" size="large" true-color="green" false-color="red" style="margin-left: 10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
        </FormItem>
        <div v-show="form.content.ens">
          <FormItem prop="sc" :label="showLang('com.anti.theft.ch')" style="margin-right: 15px">
            <Select :placeholder="showLang('save.select')" v-model="form.content.sc">
              <Option :value="0">{{showLang('com.state.not')}}</Option>
              <Option v-for="n in 24" :key="n" :value="parseInt(n)"> {{showLang('com.lighting.anti.which',n)}} </Option>
            </Select>
          </FormItem>
          <FormItem prop="sh" :label="showLang('com.anti.theft.time')" style="margin-right: 15px">
            <Input type="number" v-model="form.content.sh" :placeholder="showLang('com.anti.theft.time')"><span slot="append">秒</span></Input>
          </FormItem>
          <FormItem prop="chs" :label="showLang('com.ope.association.ch')" style="margin-right: 15px">
            <Select :placeholder="showLang('save.select')" v-model="form.content.chs">
              <Option :value="0">{{showLang('com.state.not')}}</Option>
              <template v-for="n in 7">
                <Option :value="n" :key="n">{{showLang('com.lighting.output.which')}}</Option>
              </template>
            </Select>
          </FormItem>
          <FormItem prop="cmds" :label="showLang('com.op.if.linkage')" style="margin-right: 15px">
            <i-switch v-model="form.content.cmds" size="large" true-color="green" false-color="red" style="margin-left: 10px;width:56px">
              <span slot="open">{{showLang('com.state.enable')}}</span>
              <span slot="close">{{showLang('com.state.disable')}}</span>
            </i-switch>
          </FormItem>
          <FormItem prop="opens" :label="showLang('com.sending.ins.type')" style="margin-right: 15px">
            <i-switch v-model="form.content.opens" size="large" true-color="green" false-color="red" style="margin-left: 10px;width:56px">
              <span slot="open">上电</span>
              <span slot="close">{{showLang('com.state.outage')}}</span>
            </i-switch>
          </FormItem>
          <FormItem prop="expires" :label="showLang('com.time.ins.delay')" style="margin-right: 15px">
            <Input type="number" v-model="form.content.expires" :placeholder="showLang('com.time.ins.delay')"><span slot="append">min</span></Input>
          </FormItem>
        </div>

      </div>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
    <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalBranchEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    stations: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      contacts: [],
      mains: [],
      devices: [],
      timeTables: [],
      form: {
        id: 0,
        stationId: 0,
        connectType: 0, //设备接入方式，0为设备直连，其他都是从三方平台或其他SDK接入
        deviceId: 0,
        name: '',
        type: 5,
        content: {
          //基本配置相关
          exta: 0, //外接电A相电流阈值，超过此值视为亮灯状态
          extb: 0, //外接电B相电流阈值，超过此值视为亮灯状态
          extc: 0, //外接电C相电流阈值，超过此值视为亮灯状态
          tt: false, //是否支路测量总值，否则为支路测量分值
          mid: 0, //关联总配电属性ID
          // oid: 0, //关联控制输出属性ID
          cid: 0, //关联接触器属性ID
          abc: false, //是否以电流监测、配置值报警,否则以功率监测、配置值报警
          abr: true, //是否以额定值及上下限值报警,否则以对比值报警
          count: 0, //灯数量
          power: 100, //灯功率
          //功率相关
          enp: false, //是否启用功率监测
          // ptid: 0, //功率报警时间表
          par: 220, //A相额定容量
          pbr: 220, //B相额定容量
          pcr: 220, //C相额定容量
          pah: 80, //A相功率过载阈值
          pbh: 80, //B相功率过载阈值
          pch: 80, //C相功率过载阈值
          paf: 0.8, //A相额定功率因数
          pbf: 0.8, //B相额定功率因数
          pcf: 0.8, //C相额定功率因数
          //电流相关
          // enc: false, //是否启用电流监测
          // ctid: 0, //总电流报警时间表
          cac: 0, //A相电流通道
          cat: 3000, //A相电流倍率
          car: 1, //A相电流额定值
          cal: 80, //A相电流下限值
          cah: 120, //A相电流上限值
          cbc: 0, //B相电流通道
          cbt: 3000, //B相电流倍率
          cbr: 1, //B相电流额定值
          cbl: 80, //B相电流下限值
          cbh: 120, //B相电流上限值
          ccc: 0, //C相电流通道
          cct: 3000, //C相电流倍率
          ccr: 1, //C相电流额定值
          ccl: 80, //C相电流下限值
          cch: 120, //C相电流上限值
          //漏电监测相关
          enl: false, //是否启用漏电监测
          ltid: 0, //漏电报警时间表
          lc: 0, //漏电电流通道
          lt: 1, //漏电电流倍率
          ll: 100, //一级漏电阈值
          chl: 0, //关联控制通道
          cmdl: false, //是否自动发送控制指令
          openl: false, //指令发送开或关
          expirel: 100, //指令控制延时时间，单位分钟
          lh: 1000, //二级漏电阈值
          chh: 0, //关联控制通道
          cmdh: false, //是否自动发送控制指令
          openh: false, //指令发送开或关
          expireh: 100, //指令控制延时时间，单位分钟
          lx: 2000, //三级漏电阈值
          chx: 0, //关联控制通道
          cmdx: false, //是否自动发送控制指令
          openx: false, //指令发送开或关
          expirex: 100, //指令控制延时时间，单位分钟
          //防盗监测相关
          ens: false, //是否启用防盗监测
          sc: 0, //防盗电流通道号
          sh: 30, //防盗时间阈值
          chs: 0, //关联控制通道
          cmds: false, //是否自动发送控制指令
          opens: false, //指令发送开或关
          expires: 100, //指令控制延时时间，单位分钟
        }
      },
      rules: {
        // name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
        // topCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
        // location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        // monitorCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        // console.log('modal level edit mounted', this.item)
        this.isAdd = this.item.isAdd;
        if (!this.isAdd) {
          this.form.id = this.item.data.id;
          this.form.stationId = this.item.data.stationId;
          this.form.name = this.item.data.name;

          this.stationChanged(() => {
            this.form.deviceId = this.item.data.deviceId;
            for (let k in this.form.content) {
              this.form.content[k] = this.item.data.content[k];
            }
            this.getUseablePlan();
          });
        } else {
          this.form.id = 0;
          this.form.stationId = 0;
          this.form.deviceId = 0;
          this.form.name = '';
          this.form.content = {
            //基本配置相关
            exta: 0, //外接电A相电流阈值，超过此值视为亮灯状态
            extb: 0, //外接电B相电流阈值，超过此值视为亮灯状态
            extc: 0, //外接电C相电流阈值，超过此值视为亮灯状态
            tt: false, //是否支路测量总值，否则为支路测量分值
            mid: 0, //关联总配电属性ID
            // oid: 0, //关联控制输出属性ID
            cid: 0, //关联接触器属性ID
            abc: false, //是否以电流监测、配置值报警,否则以功率监测、配置值报警
            abr: true, //是否以额定值及上下限值报警,否则以对比值报警
            count: 0, //灯数量
            power: 100, //灯功率
            //功率相关
            enp: false, //是否启用功率监测
            // ptid: 0, //功率报警时间表
            par: 220, //A相额定容量
            pbr: 220, //B相额定容量
            pcr: 220, //C相额定容量
            pah: 80, //A相功率过载阈值
            pbh: 80, //B相功率过载阈值
            pch: 80, //C相功率过载阈值
            paf: 0.8, //A相额定功率因数
            pbf: 0.8, //B相额定功率因数
            pcf: 0.8, //C相额定功率因数
            //电流相关
            // enc: false, //是否启用电流监测
            // ctid: 0, //总电流报警时间表
            cac: 0, //A相电流通道
            cat: 3000, //A相电流倍率
            car: 1, //A相电流额定值
            cal: 80, //A相电流下限值
            cah: 120, //A相电流上限值
            cbc: 0, //B相电流通道
            cbt: 3000, //B相电流倍率
            cbr: 1, //B相电流额定值
            cbl: 80, //B相电流下限值
            cbh: 120, //B相电流上限值
            ccc: 0, //C相电流通道
            cct: 3000, //C相电流倍率
            ccr: 1, //C相电流额定值
            ccl: 80, //C相电流下限值
            cch: 120, //C相电流上限值
            //漏电监测相关
            enl: false, //是否启用漏电监测
            ltid: 0, //漏电报警时间表
            lc: 0, //漏电电流通道
            lt: 1, //漏电电流倍率
            ll: 100, //一级漏电阈值
            chl: 0, //关联控制通道
            cmdl: false, //是否自动发送控制指令
            openl: false, //指令发送开或关
            expirel: 100, //指令控制延时时间，单位分钟
            lh: 1000, //二级漏电阈值
            chh: 0, //关联控制通道
            cmdh: false, //是否自动发送控制指令
            openh: false, //指令发送开或关
            expireh: 100, //指令控制延时时间，单位分钟
            lx: 2000, //三级漏电阈值
            chx: 0, //关联控制通道
            cmdx: false, //是否自动发送控制指令
            openx: false, //指令发送开或关
            expirex: 100, //指令控制延时时间，单位分钟
            //防盗监测相关
            ens: false, //是否启用防盗监测
            sc: 0, //防盗电流通道号
            sh: 30, //防盗时间阈值
            chs: 0, //关联控制通道
            cmds: false, //是否自动发送控制指令
            opens: false, //指令发送开或关
            expires: 100, //指令控制延时时间，单位分钟
          }
        }
        // console.log('modal level edit mounted', this.form.content, this.item.data.content)
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    // 'form.stationId'(){
    //   this.form.paramId = 0;
    // },
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'deviceTypes', 'productCodes', 'powerBoxTypes']),
    boxType: function () {
      return function (deviceId) {
        // if (!this.selectedStation) return 'none';
        if (deviceId <= 0) return 'none';
        let devs = this.devices.filter(p => p.id == deviceId);
        if (devs.length == 0) return 'none';
        return devs[0].type;
      }
    },
  },
  mounted: function () {
    // this.getProjects();
    // console.log('modal level edit mounted', this.alarmTypes, this.alarmGroupList)
  },
  methods: {
    getUseablePlan: function () {
      if (!this.form.content.mid || this.form.content.mid <= 0) {
        this.$set(this, 'timeTables', []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryUseable`, { type: "param", id: this.form.content.mid }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeTables', res.data);
        }
      });
    },
    stationChanged: function (callback) {
      if (this.form.stationId <= 0) {
        this.$set(this, 'contacts', []);
        this.$set(this, 'devices', []);
      } else {
        this.$axios.post(`//${this.domains.trans}/station/config/QueryMain`, { groupId: 0, stationId: this.form.stationId }).then(res => {
          if (res.code == 0) {
            this.$set(this, 'mains', res.data);
          }
        });
        this.$axios.post(`//${this.domains.trans}/station/config/QueryContact`, { groupId: 0, stationId: this.form.stationId }).then(res => {
          if (res.code == 0) {
            this.$set(this, 'contacts', res.data);
            if (callback && `${typeof callback}` == 'function') callback();
          }
        });
      }
    },
    ok: async function () {
      if (this.form.name.trim().length == 0) {
        this.$Message.warning(this.showLang('save.dev.main.name'));
        return;
      }
      if (this.form.stationId <= 0) {
        this.$Message.warning(this.showLang('com.save.err.station'));
        return;
      }
      if (this.form.content.ll <= 0) {
        this.$Message.warning(this.showLang('save.light.range.leakage1', 0));
        return;
      }
      if (this.form.content.lh <= this.form.content.ll) {
        this.$Message.warning(this.showLang('save.light.range.leakage2'));
        return;
      }
      if (this.form.content.lx <= this.form.content.lh) {
        this.$Message.warning(this.showLang('save.light.range.leakage3'));
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/SaveBranch`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info(this.showLang('com.tips.save'));
          this.showModal = false;
          this.$emit('saved', { isAdd: this.isAdd, data: res.data });
        }
      })
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>