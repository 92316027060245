<template>
<div class="device-item-content">
  <div class="device-item-header">
    <img class="device-item-header-icon" :src="img.pole.alarm.icon" title="智能垃圾桶" />
    <span class="device-item-header-title">{{item.name}}</span>
    <img class="device-item-header-status-icon" :src="getImage()" />
    <span class="device-item-header-status">{{getStatus()}}</span>
  </div>
  <img class="wifi-flag" :src="img.pole.garbage.flag" />
</div>
</template>
<script>
export default {
  name: 'GarbageStationViewer',
  components:{
  },
  props: {
    item: {
      type: Object,
      default(){return {}},
    },
  },
  data () {
    return {
      info: {},
      count: 0,
      money: 0,
      energy: 0,
      alarms: 0,
    }
  },
  computed: {},
  watch: {},
  mounted: function(){
    // console.log('charge hub mounted', this.item)
  },
  methods: {
    getStatus: function(){
      if(!this.info.status)return '离线';
      if(this.info.offline == 1) return '停止服务';
      if(this.info.status == 1) return '在线';
      if(this.info.status == 2) return '充电中';
      return '离线';
    },
    getImage: function(){
      if(!this.info.status)return this.img.pole.offline;
      if(this.info.offline == 1) return this.img.pole.offline;
      if(this.info.status == 1) return this.img.pole.online;
      if(this.info.status == 2) return this.img.pole.online;
      return this.img.pole.offline;
    },
  }
}
</script>
<style scoped>
.wifi-flag{
  width: 106px;
  height: 108px;
  position: absolute;
  top: 89px;
  left: 144px;
}
table{
  /* border: solid 1px red; */
  margin: auto;
  width: 80%;
}
.table-title{
  background: #EEF3F8;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
  width: 164px;
  height: 20px;
  padding: 3px 20px;
}
.table-value{
  background: #EEF3F8;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
  width: 165px;
  height: 20px;
  padding: 3px 20px;
}
.device-item-content{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.device-item-header{
  height: 48px;
  /* background: #E8F1FA; */
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
}
.device-item-header-icon{
  width: 20px;
  height: 20px;
  margin-left: 25px;
  margin-right: 10px;
  flex: none;
}
.device-item-header-title{
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  width: 10px;
  flex: auto;
}
.device-item-header-status-icon{
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
.device-item-header-status{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  flex: none;
  margin-right: 25px;
}
</style>
