<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">{{showLang('com.site')}} 【{{item.stationName}}】 【{{item.paramName}}】 3{{showLang('history.data.i.contrast.data')}}</div>
    <div class="modal-content">
      <div class="modal-query-header">
        <DatePicker type="datetime" v-model="filter.start" format="yyyy-MM-dd HH:mm" style="width: 155px; margin-right: 10px"></DatePicker>
        {{showLang('com.date.to')}}
        <DatePicker type="datetime" v-model="filter.end" format="yyyy-MM-dd HH:mm" style="width: 155px; margin-left: 10px"></DatePicker>
        <Button type="primary" style="margin-left: 10px" @click="search">{{showLang('com.op.query')}}</Button>
        <Button type="primary" style="margin-left: 10px" @click="exportData">{{showLang('com.op.export')}}</Button>
        <span style="margin-left: 20px">{{showLang('com.data.sum.record',history.length)}}</span>
      </div>
      <div class="modal-data-area">
        <table id="tabMainDataHistory" class="table">
          <tr>
            <td class="column-header" style="width: 50px">{{showLang('com.export.cn.seq')}}</td>
            <td class="column-header" style="width: 200px">{{showLang('com.date.occurrence')}}</td>
            <td class="column-header" style="width:120px">{{showLang('cmd.project.A')}}{{showLang('cmd.data.measurement')}}</td>
            <td class="column-header" style="width:120px">{{showLang('cmd.project.B')}}{{showLang('cmd.data.measurement')}}</td>
            <td class="column-header" style="width:120px">{{showLang('cmd.project.C')}}{{showLang('cmd.data.measurement')}}</td>
            <!-- <td class="column-header" style="width:120px" >对比值</td> -->
          </tr>
          <template v-for="(branch, index) in history">
            <tr :key="`data-${index}-0`">
              <td class="column-content" style="width: 50px">{{index+1}}</td>
              <td class="column-content">{{branch.fireTime}}</td>
              <td class="column-content">{{img.val(branch.ca, 2)}} A</td>
              <td class="column-content">{{img.val(branch.cb, 2)}} A</td>
              <td class="column-content">{{img.val(branch.cc, 2)}} A</td>
              <!-- <td class="column-content">{{img.val(branch.checkValue, 2)}} A</td> -->
            </tr>
          </template>
        </table>
      </div>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
      <!-- <Button type="primary" :loading="loading" @click="doCommand">发送</Button> -->
    </div>
  </Modal>
</template>
<script>
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalCurrentHistory',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // device: {
    //   type: Object,
    //   default(){ return {};}
    // },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      timer: 0,
      filter: {
        start: '',
        end: '',
      },
      history: [],
      loading: false,
      config: {
        title: `电流对比历史数据`
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        let now = new Date();
        this.filter.end = now.format('yyyy-MM-dd 23:59:59');
        now.setDate(now.getDate() - 10);
        this.filter.start = now.format('yyyy-MM-dd 00:00:00');
        this.search();
      }
    },
    device() {
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapGetters('auth', ['showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  methods: {
    np: function (u, c, p) {
      if (!u || !c || !p) return '--';
      if (u < 0 || c < 0 || p < 0) return '-';
      return (u * c - p).toFixed(2);
    },
    exportData: function () {
      var xlsxParam = { raw: true };//转换成excel时，使用原始的格式
      var wb = XLSX.utils.table_to_book(document.querySelector("#tabMainDataHistory"), xlsxParam);//outTable为列表id
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream;charset=utf-8" }), this.showLang('com.lighting.leakage.val') + ".xlsx"
        );
      } catch (e) {
        // if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    currentStatus: function (params, type) {
      if (params.timeValue == 1 && params[`current${type}Value`] < (params[`current${type}Rated`] * params[`current${type}Low`] / 100)) return this.showLang('alarm.type.current.under');
      if (params.timeValue == 1 && params[`current${type}Value`] > (params[`current${type}Rated`] * params[`current${type}High`] / 100)) return this.showLang('alarm.type.current.over');
      return this.showLang('com.state.normal');
    },
    currentVStatus: function (params, type) {
      if (params.timeValue == 1 && params[`voltage${type}Value`] < (params[`voltage${type}Rated`] * params[`voltage${type}Low`] / 100)) return this.showLang('alarm.type.voltage.under');
      if (params.timeValue == 1 && params[`voltage${type}Value`] > (params[`voltage${type}Rated`] * params[`voltage${type}High`] / 100)) return this.showLang('alarm.type.voltage.over');
      return this.showLang('com.state.normal');
    },
    search: async function () {
      let ajaxData = {
        paramId: this.item.paramId,//属性ID
        seq: this.item.seq,
        flag: this.item.flag,
        start: new Date(this.filter.start).format('yyyy-MM-dd HH:mm:00'),
        end: new Date(this.filter.end).format('yyyy-MM-dd HH:mm:59')
      }
      let res = await this.$axios.post(`//${this.domains.trans}/station/analyse/QueryCurrentHistory`, ajaxData);
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return;
      }
      this.$set(this, "history", res.data);
      this.timer++;
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.modal-content {
  /* border: solid 1px red; */
  height: 600px;
  display: flex;
  flex-direction: column;
}
.modal-query-header {
  height: 40px;
  flex: none;
}
.modal-chart-area {
  /* border: solid 1px red; */
  height: 200px;
  flex: none;
}
.modal-data-area {
  /* border: solid 1px red; */
  height: 300px;
  flex: auto;
  overflow: auto;
}
.table {
  border-collapse: collapse;
  width: 100%;
}
.table-header {
  /* width: 1064px; */
  height: 47px;
  text-align: center;
  background: rgba(10, 26, 68, 0.8);
  box-shadow: inset 0px 0px 24px 0px #04559d;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #52fbfe;
  border: solid 1px black;
}
.table-header img {
  text-align: center;
  vertical-align: middle;
  margin-right: 3px;
}
.column-header {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  border: solid 1px black;
  text-align: center;
  height: 30px;
  /* width: 130px; */
  /* border-collapse: collapse; */
  background: #183992;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.column-header img {
  vertical-align: middle;
  margin-right: 10px;
}
.column-content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #050505;
  background: #2c9ef55e;
  border: solid 1px black;
  text-align: center;
  height: 35px;
  /* width: 130px; */
}
</style>