<template>
<Modal v-model="showModal" :mask-closable="false" width="400px">
  <div slot="header">批量修改单灯名称</div>
  <div class="import-container">
    <Form ref="form" :label-width="100" class="import-form">
      <FormItem prop="areaId" label="导出文件">
        <Button type="info" size="default" :loading="loading" @click="exportLightNameFile">导出单灯名称文件</Button>
      </FormItem>
      <FormItem prop="lng" label="选择文件">
        <div style="display: flex">
          <div class="logo-upload">
            <input ref="fileInput" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" :disabled="loading" />
          </div>
        </div>
      </FormItem>
      <FormItem v-if="resultGot" prop="areaId" :label-width="0" label="">
        <div class="import-result" v-if="result.length == 0">全部修改成功</div>
        <div class="import-result" v-else>
          <div class="failed-item">
            <div class="failed-item-num">序号</div>
            <div class="failed-item-num">单灯ID</div>
            <div class="failed-item-content">错误内容</div>
          </div>
          <div class="failed-item" v-for="(item, idx) in result" :key="idx">
            <div class="failed-item-num">{{ idx + 1 }}</div>
            <div class="failed-item-num">{{ item.id }}</div>
            <div class="failed-item-content">{{ item.name }}</div>
          </div>
        </div>
      </FormItem>
    </Form>
  </div>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    <Button type="success" style="margin-left: 8px;height: 40px;" :loading="loading" @click="ok">导入文件并修改名称</Button>
  </div>
</Modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalExportChangeName',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      resultGot: false,
      result:[],
    }
  },
  computed: {
    ...mapState('auth', ['appType']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'deviceTypes', 'newLightTypes', 'smallTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode', 'selectedGroups']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        console.log('modal level edit mounted', this.item)
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  mounted: function(){
  },
  methods: {
    exportLightNameFile: function(){
      this.$store.dispatch('auth/reqFile', {url: `//${this.domains.trans}/station/config/ExportLampNamesByFilter`, args: this.item, title: '单灯名称文件.xlsx'})
    },
    ok: async function () {
      this.resultGot = false;
      this.$set(this, 'result', []);
      if(this.$refs.fileInput.value == ''){
        this.$Message.warning(this.showLang('com.import.file.select'));
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.fileInput.files[0]);
      this.$axios.request({
        url: `//${this.domains.trans}/station/config/ImportLampNamesFile`,
        method: 'post',
        data: formData,
        responseType: 'json',
        myType: 'file'
      }).then(res => {
        if(res.code != 0)return;
        this.$set(this, 'result', res.data);
        this.resultGot = true;
      });
    },
    cancel () {
      this.showModal = false;
      this.$emit('saved', {});
    },
  }
}
</script>
<style scoped>
.import-result{
  display: flex;
  flex-direction: column;
  text-align: center;
  max-height: 400px;
  overflow-y: auto;
}
.failed-item{
  height: 30px;
  flex: none;
  margin-bottom: 5px;
  border-bottom: solid 1px rgba(129, 124, 124, 0.377);
  display: flex;
}
.failed-item-num{
  width: 50px;
  flex: none;
}
.failed-item-content{
  width: 50px;
  flex: auto;
}
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}

.import-container{
  /* border: solid 1px red; */
  /* height: 100%; */
  display: flex;
}
.import-form{
  width: 350px;
  flex: none;
}
.import-data{
  width: 500px;
  flex: auto;
  margin-left: 35px;
}
.logo-upload{
  width: 100%;
  /* height:40px; */
  border:solid 1px #dcdee2;
  cursor: pointer;
}
.logo-upload input{
  /* visibility: hidden; */
  width: 100%;
}
.logo-upload button{
  /* visibility: hidden; */
  width: 100%;
  /* height: 40px; */
}
</style>