<template>
<div class="config-station-container">
  <!-- <div class="station-search-area">
    <Input v-model="filter.name" style="width: 300px" search clearable placeholder="查询关键字" />
  </div> -->
  <div class="station-data-area" ref="table">
    <u-table ref="plTable" :data="selectedDevices" use-virtual style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all :empty-text="showLang('com.data.no')">
      <u-table-column type="index" :label="showLang('com.export.cn.seq')" width="50"></u-table-column>
      <u-table-column type="selection" width="55"></u-table-column>
      <u-table-column prop="status" :label="showLang('com.state')" sortable width="80">
        <template slot-scope="params">
          {{powerBoxStatus[params.row.status]}}
        </template>
      </u-table-column>
      <u-table-column prop="name" :label="showLang('com.tab.title')" sortable width="200">
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <u-table-column prop="type" :label="showLang('com.state.type')" sortable width="100">
        <template slot-scope="params">
          {{getStationType(params.row.stationType)}}
        </template>
      </u-table-column>
      <u-table-column prop="area" :label="showLang('com.site.info.region')" sortable>
        <template slot-scope="params">
          {{params.row.area}}
        </template>
      </u-table-column>
      <u-table-column prop="location" :label="showLang('com.Installation.position')" sortable width="300">
        <template slot-scope="params">
          {{params.row.location}}
        </template>
      </u-table-column>
      <u-table-column prop="install" :label="showLang('com.date.installation')" sortable width="120">
        <template slot-scope="params">
          {{getDate(params.row.install)}}
        </template>
      </u-table-column>
      <u-table-column prop="groupId" :label="showLang('com.site.path')" sortable>
        <template slot-scope="params">
          {{groupPathName(params.row.groupId)}}
        </template>
      </u-table-column>
      <u-table-column prop="lowFlow" :label="showLang('station.flow.mode')" sortable>
        <template slot-scope="params">
          {{groupLowFlowName(params.row)}}
        </template>
      </u-table-column>
      <u-table-column width="150">
        <template slot="header" slot-scope="scope">
          <AuthButton opCode='sa' style="margin-right: 5px" @click="handleAdd(scope)">{{showLang('com.op.add')}}</AuthButton>
          <AuthButton opCode='sd' type="error" @click="handleMutiDelete(scope)">{{showLang('com.op.batch.del')}}</AuthButton>
        </template>
        <template slot-scope="scope">
          <AuthButton opCode='se' style="margin-right: 5px" @click="handleEdit(scope)">{{showLang('com.op.edit')}}</AuthButton>
          <AuthButton opCode='sd' type="error" @click="handleDelete(scope)">{{showLang('com.op.del')}}</AuthButton>
        </template>
      </u-table-column>
    </u-table>
  </div>
  <ModalStationEdit v-model="showEditModal" :item="editItem" :stationType="args.stationType" />
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalStationEdit from './ModalStationEdit'
export default {
  name: 'ConfigStationIndex',
  components:{
    ModalStationEdit,
  },
  props: {
    args: {
      type: Object,
      default(){ return {
        stationType: 0
      };}
    },
  },
  data () {
    return {
      showEditModal: false,
      tabHeight: 300,
      editItem: {isAdd: true, index: -1, data: {}},
      filter: {
        name: '',
      },
    }
  },
  computed: {
    ...mapState('auth', ['user', 'appType']),
    ...mapGetters('auth', ['showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'powerBoxStatus', 'poleType', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    setTimeout(() => {
      this.setTableHeight();
    }, 200);
  },
  methods: {
    groupLowFlowName: function(row){
      if(row.lowFlow){
        return `低流量：${row.lowStart}-${row.lowEnd}`;
      }else{
        return '正常流量';
      }
    },
    getStationType: function(type){
      // if(this.appType == 'pole'){
      //   return this.poleType[type];
      // }else{
      //   return this.powerBoxTypes[type];
      // }
      return this.powerBoxTypes[type];
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 3;
    },
    getDate:function(date){
      let d = new Date(date);
      if(d.getFullYear() == 1970)return '';
      return d.format('yyyy-MM-dd');
    },
    handleAdd: function(){
      let node = this.selectedNode;
      let grpId = 0;
      if(node.type == this.productCodes.station){
        let sta = this.selectedDevices.filter(p => p.id == node.id)[0];
        grpId = sta.groupId;
      }else{
        grpId = node.id;
        if(grpId == 0 && node.children){
          let chs = node.children.filter(p => p.type == 'group');
          if(chs.length > 0){
            grpId = chs[0].id;
          }
        }
      }
      this.editItem = {isAdd: true, data: {groupId: grpId}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content:this.showLang('com.tips.ok.del.name',params.row.name),
        onOk: async () => {
          this.$store.dispatch('group/deleteStation', [params.row.id]).then(res => {
            if(res.code == 0){
              window.eventBus.$emit('stationChanged');
            }
          });
        }
      });
    },
    handleMutiDelete: function(){
      let chks = this.$refs.plTable.getCheckboxRecords();
      if(chks.length == 0){
        this.$Message.warning(this.showLang('del.err.station.empty'));
        return;
      }
      let sids = chks.map(p => p.id);
      // console.log("delete stations", chks, sids)
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content:this.showLang('com.tips.ok.del.sids.leng',sids.length) ,
        onOk: async () => {
          this.$store.dispatch('group/deleteStation', sids).then(res => {
            if(res.code == 0){
              window.eventBus.$emit('stationChanged');
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.config-station-container{
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  /* padding: 10px; */
  background-color: white;
  border-radius: 8px;
}
.station-search-area{
  height: 42px;
  flex: none;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.station-data-area{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>