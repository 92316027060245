<template>
<Modal v-model="showModal" :mask-closable="false" width="500px">
  <div slot="header">批量设置灯杆参数</div>
  <div class="import-container">
    <Form ref="form" :label-width="100" class="import-form">
      <FormItem v-if="item.type == 'height'" prop="height" label="灯杆高度">
        <Input type="number" v-model="form.height"><span slot="append">米</span></Input>
      </FormItem>
  </Form>
</div>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
    <Button type="success" style="margin-left: 8px;height: 40px;" :loading="loading" @click="ok">设置参数</Button>
  </div>
  <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
</Modal>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ModalSetArgs',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      form: {
        height: 8,
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.form.height = 8;
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  mounted: function(){
  },
  methods: {
    ok: async function () {
      let args = null;
      switch(this.item.type){
        case 'height': args = {type: this.item.type, height: this.form.height}; break;
        default: {
          this.$Message.warning('非法参数类型');
          return
        }
      }
      this.$emit('saved', args);
      this.showModal = false;
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}

.import-container{
  /* border: solid 1px red; */
  /* height: 100%; */
  display: flex;
}
.import-form{
  width: 350px;
  flex: none;
}
.import-data{
  width: 500px;
  flex: auto;
  margin-left: 35px;
}
.logo-upload{
  width: 100%;
  height:40px;
  border:solid 1px #dcdee2;
  cursor: pointer;
}
.logo-upload input{
  /* visibility: hidden; */
  width: 100%;
}
.logo-upload button{
  /* visibility: hidden; */
  width: 100%;
  height: 40px;
}
</style>