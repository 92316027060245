<template>
  <div class="station-search-container">
    <div class="station-search-tabs">
      <div v-for="(item, idx) in tabs" :key="idx" :class="['station-search-tab', curTab == item.code ? 'tab-active' : '']"
        @click="switchTab(item.code)">{{showLang(item.lang)}}</div>
    </div>
    <div class="station-search-content">
      <template v-for="(item, idx) in tabs">
        <component v-if="item.code == curTab" :key="idx" v-bind:is="item.com"></component>
      </template>
    </div>
  </div>
</template>
<script>
import EnergyDeviceHistory from './Single'
import EnergyDeviceAllHistory from './AllList'
import EnergyDeviceRecord from './Record'
import EnergyDeviceCheck from './Check'
import EnergyAreaCheck from './AreaCheck'
import EnergyDeviceLight from './Light'
import { mapGetters } from 'vuex'
export default {
  name: 'StationSearchIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      tabs: [
        { code: 's18', name: '能耗报表', lang: 'energy.nav.stat', com: EnergyDeviceRecord },
        { code: 's19', name: '能耗对比', lang: 'energy.nav.contrast', com: EnergyDeviceCheck },
        { code: 's15', name: '区域能耗对比', lang: 'energy.nav.contrast.area', com: EnergyAreaCheck },
        { code: 's20', name: '单灯能耗', lang: 'energy.nav.lamp', com: EnergyDeviceLight },
        { code: 's16', name: '电表历史数据(全)', lang: 'energy.nav.history.all', com: EnergyDeviceAllHistory },
        { code: 's17', name: '电表历史数据(单)', lang: 'energy.nav.history.one', com: EnergyDeviceHistory },
      ],
      curTab: 's15',
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
  },
  watch: {
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    switchTab: function (type) {
      this.curTab = type;
    },
  }
}
</script>
<style scoped>
.station-search-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  background-color: white;
  /* border: solid 1px red; */
}
.station-search-tabs {
  /* border: solid 1px red; */
  height: 28px;
  flex: none;
  display: flex;
}
.station-search-content {
  height: 100px;
  flex: auto;
  margin-top: 10px;
}
.station-search-tab {
  /* border: solid 1px red; */
  padding: 2px 6px;
  cursor: pointer;
  background-color: #fff;
  color: #324252;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 28px;
}
.tab-active {
  color: #021629;
  font-weight: bold;
  /* border-bottom :2px solid #3880FC; */
}
.tab-active::after {
  content: "";
  width: calc(100%);
  height: 1px;
  display: block;
  margin: -5px auto 0;
  border-bottom: 2px solid #3880fc;
}
</style>