<template>
<div class="device-item-content">
  <div class="device-item-header">
    <img class="device-item-header-icon" :src="img.pole.video" title="视频监控" />
    <span class="device-item-header-title">{{item.name}}</span>
    <img class="device-item-header-status-icon" :src="player ? img.pole.online : img.pole.offline" />
    <span class="device-item-header-status">{{player ? '在线' : '离线'}}</span>
  </div>
  <div :id="`videoPlayer${item.id}`" class="video-player"></div>
  <div style="border: solid 0px red;display:flex;margin:10px 0;">
    <div @mousedown="startControl(0)" @mouseup="endControl(0)" style="width: 32px;height: 28px;line-height:28px;text-align:center;border: 1px solid #1365BC;border-radius: 4px;cursor:pointer;flex:none;margin-right:9px;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #1365BC;">上</div>
    <div @mousedown="startControl(1)" @mouseup="endControl(1)" style="width: 32px;height: 28px;line-height:28px;text-align:center;border: 1px solid #1365BC;border-radius: 4px;cursor:pointer;flex:none;margin-right:9px;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #1365BC;">下</div>
    <div @mousedown="startControl(2)" @mouseup="endControl(2)" style="width: 32px;height: 28px;line-height:28px;text-align:center;border: 1px solid #1365BC;border-radius: 4px;cursor:pointer;flex:none;margin-right:9px;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #1365BC;">左</div>
    <div @mousedown="startControl(3)" @mouseup="endControl(3)" style="width: 32px;height: 28px;line-height:28px;text-align:center;border: 1px solid #1365BC;border-radius: 4px;cursor:pointer;flex:none;margin-right:9px;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #1365BC;">右</div>
    <div style="flex:auto;"></div>
    <Button style="margin-top: 3px;" size="small" type="primary" :loading="loading" @click="handleCapture">抓拍</Button>
    <!-- <div @click="handleCapture" style="width: 48px;height: 28px;line-height:28px;text-align:center;background: #1365BC;border-radius: 4px;cursor:pointer;flex:none;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #FFFFFF;">{{loading ? '执行':'抓拍'}}</div> -->
    <Modal v-model="showCaptureModal" :mask-closable="false" draggable width="1000px">
      <div slot="header">抓拍预览</div>
      <div style="text-align:center;width: 100%; height: 580px; overflow-y:auto">
        <img v-show="captureUrl != ''" style="width: 100%" :src="captureUrl" />
      </div>
      <div slot="footer">
        <Button style="margin-right: 8px" @click="closeCaptureModal">关闭</Button>
      </div>
    </Modal>
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import  EZUIKit from 'ezuikit-js';
export default {
  name: 'VideoStationViewer',
  components:{
  },
  props: {
    item: {
      type: Object,
      default(){return {}},
    },
  },
  data () {
    return {
      loading: false,
      showCaptureModal: false,
      captureUrl: '',
      player: null,
      timer: null,
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
  },
  watch: {
    windowOnResize: function(){
      this.resizeRect();
    },
  },
  mounted: function(){
    this.previewVideo();
  },
  methods: {
    resizeRect: function(){
      if(this.timer){
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        let v = document.getElementById('videoPlayer');
        let rect = v.getBoundingClientRect();
        if(!v || !rect || v.childNodes.length == 0)return;
        v.childNodes[0].height = rect.height;
        v.childNodes[0].width = rect.width;
      }, 50);
    },
    handleCapture: async function(){
      if(!this.player)return;
      if(this.loading)return;
      this.loading = true;
      let res = await this.$axios.post(`//${this.domains.trans}/pole/video/capture`, {ids: [this.item.id]});
      // console.log('cap view video', res)
      this.loading = false;
      // this.loading = false;
      if(res.code !== 0){
        // this.$Message.error(res.data);
        return;
      }
      let item = res.data[this.item.id];
      if(!item.success){
        return;
      }
      this.captureUrl = item.content;
      this.showCaptureModal = true;
    },
    closeCaptureModal: function(){
      this.showCaptureModal = false;
    },
    closePlayer: function(){
      if(this.player){
        this.player.stop();
        this.player = null;
        let v = document.getElementById('videoPlayer');
        v.removeChild(v.childNodes[0]);
      }
    },
    startControl: async function(flag){
      if(!this.player)return;
      await this.$axios.post(`//${this.domains.trans}/pole/video/Control`, {ids: [this.item.id], move: true, direct: flag});
    },
    endControl: async function(flag){
      if(!this.player)return;
      await this.$axios.post(`//${this.domains.trans}/pole/video/Control`, {ids: [this.item.id], move: false, direct: flag});
    },
    previewVideo: async function(){
      // let res = await this.$axios.get(`//${this.domains.trans}/video/preview/${this.item.id}?code=${this.item.content.code}`);
      let res = await this.$axios.post(`//${this.domains.trans}/pole/video/Preview`, {ids: [this.item.id]});
      // this.loading = false;
      if(res.code !== 0){
        // this.$Message.error(res.data);
        // v.innerHTML = res.data;
        return;
      }
      let item = res.data[this.item.id];
      if(!item.success){
        return;
      }
      let id = `videoPlayer${this.item.id}`;
      let v = document.getElementById(id);
      let token = item.token;
      let url = item.content;
      let rect = v.getBoundingClientRect();
      this.player = new EZUIKit.EZUIKitPlayer({
        id:id,
        accessToken: token,
        url: url,
        autoplay: true,
        width: rect.width,
        height: rect.height,
      });
      v.style.overflow='hidden';
    },
  }
}
</script>
<style scoped>
.video-player{
  /* border: solid 1px red; */
  /* width:321px; */
  height:216px;
  /* margin:10px 25px 10px 25px; */
  margin: 10px 0;
  background-color:black;
  color:white;
}
.device-item-content{
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.device-item-header{
  /* border: solid 1px red; */
  height: 48px;
  /* background: #E8F1FA; */
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
}
.device-item-header-icon{
  width: 20px;
  height: 20px;
  margin-left: 25px;
  margin-right: 10px;
  flex: none;
}
.device-item-header-title{
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  width: 10px;
  flex: auto;
}
.device-item-header-status-icon{
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
.device-item-header-status{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  flex: none;
  margin-right: 25px;
}
</style>
