<template>
<div class="pole-container">
  <div class="pole-header">
    <div :class="['header-item', item.code == tab ? 'header-active':'']" v-for="(item, idx) in menus" :key="idx" @click="switchMenu(item.code)">{{item.name}}</div>
  </div>
  <div class="pole-content">
    <template v-for="(item, idx) in menus">
      <component v-if="item.code == tab && item.com" :key="idx" v-bind:is="item.com" :args="item.args"></component>
    </template>
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import UnionConfigIndex from './config/Index'
import UnionActionIndex from './action/Index'
export default {
  name: 'Home',
  components: {
  },
  data(){
    return {
      tab: 's1',
      menus: [
        { code: 's1', name: '联动配置', com: UnionConfigIndex, args: {} },
        { code: 's2', name: '联动记录', com: UnionActionIndex, args: {} },
      ]
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    // selectedNode(){
    // },
  },
  mounted: function(){
    // this.$store.commit('group/setDeviceTypes', [this.productCodes.station])
  },
  destroyed: function(){
  },
  methods: {
    switchMenu: function(menu){
      this.tab = menu;
    },
  }
}
</script>
<style scoped>
.pole-container{
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: stretch;
  border-radius: 12px;
  /* border: solid 1px red; */
  padding: 10px;
}
.pole-header{
  /* border: solid 1px red; */
  height: 40px;
  flex: none;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}
.header-item{
  padding: 0 10px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #152942;
  cursor: pointer;
}
.header-item::after{
  content: '';
  display: block;
  width: 70%;
  height: 4px;
  margin: 0 auto;
  margin-top: 3px;
  border: solid 2px white;
}
.header-active{
  font-weight: bolder;
  position: relative;
}
.header-active::after{
  border: solid 2px #3781FE;
}
.pole-content{
  /* border: solid 1px red; */
  height: 400px;
  flex: auto;
}
.content-item{
  height: 200px;
  flex: auto;
  /* margin-right: 40px; */
  /* border: solid 1px red; */
  overflow: hidden;
}
</style>
