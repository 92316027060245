<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">查看报警数据 - 统计时间: {{item.createTime}}</div>
    <div class="search-data-header">
      <vxe-table align="center" :data="list" height="500px">
        <vxe-column type="seq" width="60" title="序号"></vxe-column>
        <vxe-column field="stationName" title="站点"></vxe-column>
        <vxe-column field="createTime" title="分析时间"></vxe-column>
        <vxe-column field="lightCount" title="单灯总数"></vxe-column>
        <vxe-column field="offlineCount" title="离线数"></vxe-column>
        <vxe-column field="powerOffCount" title="灭灯数"></vxe-column>
        <vxe-column field="voltageUpperCount" title="过压数"></vxe-column>
        <vxe-column field="voltageLowerCount" title="欠压数"></vxe-column>
      </vxe-table>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" type="primary" @click="exportData">导出</Button>
      <Button style="margin-right: 8px" @click="showModal=false">关闭</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PowerLineHistory',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {} }
    }
  },
  data() {
    return {
      list: [],
      showModal: false
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.getList();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    exportData() {
      
      this.$store.dispatch('auth/reqFile', {
        title: '线路异常分析历史明细.xlsx',
        url: `//${this.domains.trans}/device/light/ExportPowerLineHistoryDesc`,
        args: { id: this.item.id },
      }).then(() => {
        // this.exportLoading = false;
      });
    },
    getList: function () {
      this.$axios.post(`//${this.domains.trans}/device/light/QueryPowerLineHistoryDesc`, { id: this.item.id }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
</style>