<template>
<div class="level2-container">
  <div class="level2-item" v-for="(item, idx) in list" :key="idx">
    <img :src="item.icon" />
    <div class="level2-title">{{item.title}}:</div>
    <div class="level2-count">{{item.count}}</div>
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'Level2Content',
  components: {
  },
  props: {
    subs: {
      type: Object,
      default(){return {icon: '', title: '', count: 0, unit: '', color: ''}}
    }
  },
  data() {
    return {
      list: [
        {icon: this.img.pole.asset.level2.box, title: '智慧云盒', type: 309, count: 3},
        {icon: this.img.pole.asset.level2.light, title: '智慧照明', type: 199, count: 3},
        {icon: this.img.pole.asset.level2.video, title: '安防监控', type: 301, count: 3},
        {icon: this.img.pole.asset.level2.led, title: '广告发布', type: 302, count: 3},
        {icon: this.img.pole.asset.level2.env, title: '环境监测', type: 303, count: 3},
        {icon: this.img.pole.asset.level2.speak, title: '一键报警', type: 305, count: 3},
        {icon: this.img.pole.asset.level2.broad, title: '广播音柱', type: 304, count: 3},
        {icon: this.img.pole.asset.level2.elec, title: '强电管理', type: 1, count: 3},
        {icon: this.img.pole.asset.level2.charge, title: '充电桩', type: 307, count: 3},
        {icon: this.img.pole.asset.level2.gryo, title: 'WIFI', type: 306, count: 3},
        {icon: this.img.pole.asset.level2.water, title: '智能井盖', type: 320, count: 3},
        {icon: this.img.pole.asset.level2.lock, title: '智能门锁', type: 311, count: 3},
      ],
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['weeks', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'poleSelectedNode']),
  },
  watch: {
    subs(){
      this.initParamCount();
    },
  },
  created: function () {
  },
  mounted: function () {
    this.initParamCount();
  },
  methods: {
    initParamCount: function () {
      for(let l of this.list){
        l.count = this.subs[l.type] || 0;
      }
    },
  },
}
</script>
<style scoped>
.level2-container {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  flex-wrap: wrap;
}
.level2-item{
  width: 196px;
  border-bottom: solid 1px #E9EDF4;
  flex: none;
  display: flex;
  align-items: center;
  /* border: solid 1px red; */
}
.level2-item img{
  width: 32px;
  height: 32px;
  flex: none;
  margin: 0 20px;
}
.level2-title{
  width: 75px;
  flex: none;
}
</style>