<template>
  <Modal v-model="showModal" :mask-closable="false" width="920px">
    <div slot="header">编辑灯控设备信息</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="100">
      <FormItem prop="poleId" label="所属灯杆">
        <div class="flex-row">
          <Select v-model="form.poleId" style="width: 200px" class="mr10">
            <template v-for="(item, idx) in selectedDevices">
              <Option :value="item.id" :key="idx">{{item.name}}</Option>
            </template>
          </Select>
          灯控名称：
          <Input ref="name" type="text" v-model="form.name" placeholder="请输入名称" style="width: 200px" class="mr10"></Input>
          应用类型：
          <Select v-model="form.useType" style="width: 200px" class="mr10">
            <template v-for="(item, idx) in useTypes">
              <Option :value="item.id" :key="idx">{{item.name}}</Option>
            </template>
          </Select>
        </div>
      </FormItem>
      <FormItem prop="deviceType" label="设备类型">
        <div class="flex-row">
          <Select v-model="form.deviceType" style="width: 200px; margin-right: 24px" class="mr10">
            <template v-for="(item, idx) in deviceTypes">
              <Option :value="item.id" :key="idx">{{item.name}}</Option>
            </template>
          </Select>
          通信ID：
          <Input ref="code" type="text" v-model="form.code" placeholder="请输入设备ID" style="width: 200px" class="mr10"></Input>
          安装日期：
          <DatePicker ref="buildDate" type="date" format="yyyy-MM-dd" v-model="form.buildDate" style="width: 200px" placeholder="请选择安装日期" :editable="false"></DatePicker>
        </div>
      </FormItem>
      <FormItem prop="gatewayId" label="通信网关">
        <div class="flex-row">
          <Select v-model="form.gatewayId" style="width: 200px;" class="mr10">
            <Option value="none">不使用</Option>
            <template v-for="(item, idx) in gateways">
              <Option :value="item.id" :key="idx">{{item.name}}</Option>
            </template>
          </Select>
          上行通道：
          <Input ref="channel" type="number" v-model="form.channel" placeholder="请输入上行通道" style="width: 200px; margin-right: 14px"></Input>
          SIM卡号：
          <Input ref="iccid" type="text" v-model="form.iccid" placeholder="请输入SIM卡号" style="width: 200px" class="mr10"></Input>
        </div>
      </FormItem>
      <FormItem prop="voltageAlarm" label="电压报警">
        <div class="flex-row">
          <i-switch v-model="form.voltageAlarm" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.voltageLower" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span slot="append">V</span></Input>
          <Input type="number" v-model="form.voltageUpper" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span slot="append">V</span></Input>
        </div>
      </FormItem>
      <FormItem prop="currentAlarm" label="电流报警">
        <div class="flex-row">
          <i-switch v-model="form.currentAlarm" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.currentLower" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.lower.val')}}</span><span slot="append">A</span></Input>
          <Input type="number" v-model="form.currentUpper" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.data.upper.val')}}</span><span slot="append">A</span></Input>
        </div>
      </FormItem>
      <FormItem prop="tempAlarm" label="温度报警">
        <div class="flex-row">
          <i-switch v-model="form.tempAlarm" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.tempLevel1" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.T.min')}}</span><span slot="append">℃</span></Input>
          <Input type="number" v-model="form.tempLevel2" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.T.max')}}</span><span slot="append">℃</span></Input>
        </div>
      </FormItem>
      <FormItem prop="leakageCurrentAlarm" label="漏电电流报警">
        <div class="flex-row">
          <i-switch v-model="form.leakageCurrentAlarm" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.leakageCurrentUpper" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.cl')}}</span><span slot="append">mA</span></Input>
        </div>
      </FormItem>
      <FormItem prop="leakageVoltageAlarm" label="漏电电压报警">
        <div class="flex-row">
          <i-switch v-model="form.leakageVoltageAlarm" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.leakageVoltageUpper" style="width: 250px; margin: 0 5px;"><span slot="prepend">{{showLang('com.lighting.lu')}}</span><span slot="append">V</span></Input>
        </div>
      </FormItem>
      <FormItem prop="enable1" :label="showLang('com.ope.way.channel.1')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.enable1" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.after1" style="width: 170px; margin-right:10px"><span slot="prepend">{{showLang('com.lighting.pr1')}}</span><span slot="append">W</span></Input>
          <Input type="number" v-model="form.before1" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.p')}}</span><span slot="append">W</span></Input>
          <Input type="number" v-model="form.count1" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.num')}}</span></Input>
        </div>
      </FormItem>
      <FormItem prop="enable2" :label="showLang('com.ope.way.channel.2')" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.enable2" size="large" true-color="green" false-color="red" style="margin-right:10px;width:56px">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
          <Input type="number" v-model="form.after2" style="width: 170px; margin-right:10px"><span slot="prepend">{{showLang('com.lighting.pr1')}}</span><span slot="append">W</span></Input>
          <Input type="number" v-model="form.before2" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.p')}}</span><span slot="append">W</span></Input>
          <Input type="number" v-model="form.count2" style="width: 180px; margin-right:10px"><span slot="prepend">{{showLang('com.import.cn.lamp.num')}}</span></Input>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalItemEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    deviceTypes: {
      type: Array,
      default(){ return [];}
    },
    useTypes: {
      type: Array,
      default(){ return [];}
    },
    gateways: {
      type: Array,
      default(){ return [];}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      groupName: '',
      accounts: [],
      form: {
        id: 0,
        poleId: '',
        gatewayId: '',
        name: '',
        useType: 0,
        deviceType: 0,
        code: '',
        channel: 6,
        iccid: '',
        voltageAlarm: false,
        voltageLower: 80,
        voltageUpper: 280, //电压上限值
        currentAlarm: false, //电流是否报警
        currentLower: 0.3, //电流下限值
        currentUpper: 10, //电流上限值
        tempAlarm: false, //温度是否报警
        tempLevel1: 50, //一级温度阈值
        tempLevel2: 80, //二级温度阈值
        leakageCurrentAlarm: false, //漏电电流是否报警
        leakageCurrentUpper: 500, //漏电电流阈值
        leakageVoltageAlarm: false, //漏电电压是否报警
        leakageVoltageUpper: 100, //漏电电压阈值
        gyroAlarm: false, //倾斜是否报警
        enable1: true, //一路是否启用
        after1: 100, //一路额定功率
        before1: 250, //一路改造前功率
        count1: 1, //一路光源数量
        enable2: false, //二路是否启用
        after2: 100, //二路额定功率
        before2: 250, //二路改造前功率
        count2: 1, //二路光源数量
        buildDate: '',
      },
      rules: {
        name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
        // topCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
        // location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        // monitorCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.isAdd = this.item.isAdd;
        // console.log('modal open', this.item, this.selectedDevices)
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.poleId = this.item.data.poleId;
          this.form.gatewayId = this.item.data.gatewayId;
          this.form.name = this.item.data.name;
          this.form.useType = this.item.data.useType;
          this.form.deviceType = this.item.data.deviceType;
          this.form.code = this.item.data.code;
          this.form.channel = this.item.data.channel;
          this.form.iccid = this.item.data.iccid;
          this.form.voltageAlarm = this.item.data.voltageAlarm;
          this.form.voltageLower = this.item.data.voltageLower;
          this.form.voltageUpper = this.item.data.voltageUpper;
          this.form.currentAlarm = this.item.data.currentAlarm;
          this.form.currentLower = this.item.data.currentLower;
          this.form.currentUpper = this.item.data.currentUpper;
          this.form.tempAlarm = this.item.data.tempAlarm;
          this.form.tempLevel1 = this.item.data.tempLevel1;
          this.form.tempLevel2 = this.item.data.tempLevel2;
          this.form.leakageCurrentAlarm = this.item.data.leakageCurrentAlarm;
          this.form.leakageCurrentUpper = this.item.data.leakageCurrentUpper;
          this.form.leakageVoltageAlarm = this.item.data.leakageVoltageAlarm;
          this.form.leakageVoltageUpper = this.item.data.leakageVoltageUpper;
          this.form.gyroAlarm = this.item.data.gyroAlarm;
          this.form.enable1 = this.item.data.enable1;
          this.form.after1 = this.item.data.after1;
          this.form.before1 = this.item.data.before1;
          this.form.count1 = this.item.data.count1;
          this.form.enable2 = this.item.data.enable2;
          this.form.after2 = this.item.data.after2;
          this.form.before2 = this.item.data.before2;
          this.form.count2 = this.item.data.count2;
          this.form.buildDate = this.item.data.buildDate;
        }else{
          this.form.id = '';
          this.form.poleId = this.item.data.poleId;
          this.form.gatewayId = "", //灯控网关ID
          this.form.name = '';
          this.form.useType = 101, //应用类型
          // this.form.connectType = 1;
          this.form.deviceType = 0;
          this.form.code = '';
          this.form.deviceType = 31, //设备类型
          this.form.code = "", //通信ID
          this.form.channel = 6, //上行通道号
          this.form.iccid = "", //SIM卡号
          this.form.voltageAlarm = false, //电压是否报警
          this.form.voltageLower = 80, //电压下限值
          this.form.voltageUpper = 280, //电压上限值
          this.form.currentAlarm = false, //电流是否报警
          this.form.currentLower = 0.3, //电流下限值
          this.form.currentUpper = 10, //电流上限值
          this.form.tempAlarm = false, //温度是否报警
          this.form.tempLevel1 = 50, //一级温度阈值
          this.form.tempLevel2 = 80, //二级温度阈值
          this.form.leakageCurrentAlarm = false, //漏电电流是否报警
          this.form.leakageCurrentUpper = 500, //漏电电流阈值
          this.form.leakageVoltageAlarm = false, //漏电电压是否报警
          this.form.leakageVoltageUpper = 100, //漏电电压阈值
          this.form.gyroAlarm = false, //倾斜是否报警
          this.form.enable1 = true, //一路是否启用
          this.form.after1 = 100, //一路额定功率
          this.form.before1 = 250, //一路改造前功率
          this.form.count1 = 1, //一路光源数量
          this.form.enable2 = false, //二路是否启用
          this.form.after2 = 100, //二路额定功率
          this.form.before2 = 250, //二路改造前功率
          this.form.count2 = 1, //二路光源数量
          this.form.buildDate = new Date().format('yyyy-MM-dd');
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapState('group', ['selectedGroups', 'selectedDevices', 'poleSelectedNode']),
    ...mapGetters('auth', ['showLang']),
  },
  mounted: function(){
    // this.getProjects();
  },
  methods: {
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        return;
      }
      if(this.form.poleId == ''){
        this.$Message.warning('请选择一个灯杆');
        return;
      }
      this.form.buildDate = new Date(this.form.buildDate).format('yyyy-MM-dd');
      this.$axios.post(`//${this.domains.trans}/pole/config/SaveLampInfo`, this.form).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved');
        }
      })
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>