<template>
  <Modal v-model="showModal" draggable :mask-closable="false" width="1200px">
    <div slot="header">{{this.showLang('com.device.box')}} {{item.stationName}} 外接电计算明细</div>
    <div class="branch-table">
      <vxe-table  :empty-text="showLang('com.data.no')"  border resizable show-overflow show-header-overflow keep-source ref="chkTable" :data="energys" :height="600" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',highlight: true}">
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="startTime" title="时段开始"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.startTime}}
          </template>
        </vxe-column>
        <vxe-column field="endTime" title="时段结束"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.endTime}}
          </template>
        </vxe-column>
        <vxe-column field="firstTime" title="首值时间"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.firstTime}}
          </template>
        </vxe-column>
        <vxe-column field="firstEnergy" title="首值(KWh)"  width="100" header-align="center">
          <template #default="{ row }">
            {{row.firstEnergy}}
          </template>
        </vxe-column>
        <vxe-column field="lastTime" title="末值时间"  width="150" header-align="center">
          <template #default="{ row }">
            {{row.lastTime}}
          </template>
        </vxe-column>
        <vxe-column field="lastEnergy" title="末值(KWh)"  width="100" header-align="center">
          <template #default="{ row }">
            {{row.lastEnergy}}
          </template>
        </vxe-column>
        <vxe-column field="diff" title="差值(KWh)"  width="100" header-align="center">
          <template #default="{ row }">
            {{row.diff}}
          </template>
        </vxe-column>
        <vxe-column field="timediff" title="时间差(分)"  width="100" header-align="center">
          <template #default="{ row }">
            {{img.val(row.timediff)}}
          </template>
        </vxe-column>
        <vxe-column field="result" title="外接电(KWh)" header-align="center">
          <template #default="{ row }">
            <span :title="`(差值(${row.diff}) / 时间差(${row.timediff})) X 1440`">{{row.result}}</span>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      <!-- <Button type="primary" :loading="loading" @click="doCommand">发送</Button> -->
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalExternalSupply',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      form: {
        stationId: 0,
        day: '',
      },
      energys: [],
      searchLoading: false,
      exportLoading: false,
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
      // console.log('handle edit', this.item);
        this.form.stationId = this.item.stationId;
        this.form.day = this.item.day;
        this.initEnergys();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['singleCmdResult']),
    ...mapGetters('group', ['groupPathName']),
  },
  methods: {
    initEnergys: async function () {
      this.searchLoading = true;
      this.$axios.post(`//${this.domains.trans}/station/analyse/QuerySupplyContent`, this.form).then(res => {
        this.searchLoading = false;
        if (res.code == 0) {
          this.$set(this, 'energys', res.data);
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
.branch-table {
  height: 600px;
  overflow: auto;
}
.table {
  border-collapse: collapse;
  width: 100%;
}
.table-header {
  /* width: 1064px; */
  height: 47px;
  text-align: center;
  background: rgba(10, 26, 68, 0.8);
  box-shadow: inset 0px 0px 24px 0px #04559d;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #52fbfe;
  border: solid 1px black;
}
.table-header img {
  text-align: center;
  vertical-align: middle;
  margin-right: 3px;
}
.column-header {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  border: solid 1px black;
  text-align: center;
  height: 30px;
  /* width: 130px; */
  /* border-collapse: collapse; */
  background: #183992;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.column-header img {
  vertical-align: middle;
  margin-right: 10px;
}
.column-content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #050505;
  background: #2c9ef55e;
  border: solid 1px black;
  text-align: center;
  height: 35px;
  /* width: 130px; */
}
</style>