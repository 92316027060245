<template>
  <!-- AC供电 -->
  <div class="supply-public">
    <div class="supply-top">AC供电</div>
    <div class="supply-con">
      <div class="supply-boxBorder">
        <div class="supply-showAalue"><span class="supply-name">AC电压</span> <span class="supply-value">241.00V</span></div>
        <div class="supply-mt10"><a href="javaScript:" class="supply-butPolice">设置报警限</a> <a href="javaScript:" class="supply-butPolice ml20">查询报警限</a></div>
        <div class="supply-showAalue"><span class="supply-name">AC总电流</span> <span class="supply-value">0.19A</span></div>
        <div class="supply-showAalue"><span class="supply-name">AC总功率</span> <span class="supply-value">46.98W</span></div>
      </div>
      <div class="supply-showAalue">
        <span class="supply-name">AC输出电流</span> <span class="supply-value">0.00A</span>
      </div>
      <div class="supply-mt10"><a href="javaScript:" class="supply-butPolice">设置报警限</a> <a href="javaScript:" class="supply-butPolice ml20">查询报警限</a></div>
      <div class="supply-showAalue">
        <span class="supply-name">DC输出电流</span> <span class="supply-value">0.00A</span>
      </div>
      <div class="supply-mt10"><a href="javaScript:" class="supply-butPolice">设置报警限</a> <a href="javaScript:" class="supply-butPolice ml20">查询报警限</a></div>
      <div class="supply-showAalue">
        <span class="supply-name">外接测量电流</span> <span class="supply-value">0.00A</span>
      </div>
      <div class="supply-mt10"><a href="javaScript:" class="supply-butPolice">设置报警限</a> <a href="javaScript:" class="supply-butPolice ml20">查询报警限</a></div>
      <div class="supply-mt10" style="text-align: left;">
        AC输出供电控制<img src="http://211.162.119.217:39008/M_Monitor/img/index/open.png" alt="" class="supply-v-align-m ml10"></div>
      <div class="supply-mt10"><a href="javaScript:" class="supply-butPolice">设置时间表</a> <a href="javaScript:" class="supply-butPolice supply-ml20">查询时间表</a></div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'supply',
  components: {
  },
  props: {
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    selectedNode() {
      this.setHigt();
    },
  },
  mounted: function () {

  },
  methods: {
    setHigt: function(){},

  }
}
</script>
<style scoped>
.supply-mt10 {
  margin-top: 10px;
}
.supply-ml20 {
  margin-left: 20px;
}
.supply-v-align-m {
  vertical-align: middle;
}
.device-item-content {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 20px;
}
.supply-public {
  width: 280px;
  height: 600px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
}
.supply-top {
  width: 100%;
  background-color: #05c29c;
  height: 60px;
  border-radius: 8px 8px 0 0;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  line-height: 60px;
  text-align: center;
}
.supply-con {
  padding: 0 10px;
  text-align: center;
}
.supply-boxBorder {
  border: 1px solid #05c29c;
  box-shadow: 0px 4px 12px 0px rgb(131 207 192 / 54%) inset;
  padding: 0 10px 10px;
  margin-top: 10px;
}
.supply-showAalue {
  width: 98%;
  border: 1px solid #05c29c;
  box-shadow: 0px 4px 12px 0px rgb(131 207 192 / 54%) inset;
  border-radius: 6px;
  margin: 10px auto 0;
  position: relative;
  height: 35px;
  line-height: 35px;
}
.supply-name {
  font-size: 14px;
  color: #324252;
  text-indent: -40px;
  display: inline-block;
}
.supply-value {
  font-size: 20px;
  color: #021629;
}
.supply-butPolice {
  width: 96px;
  height: 26px;
  background: linear-gradient(0deg, #3965c8, #51d1fa);
  box-shadow: 0px 1px 4px 0px rgb(32 39 43 / 10%);
  border-radius: 4px;
  font-size: 14px;
  display: inline-block;
  color: #ffffff;
  line-height: 26px;
}
</style>
