<template>
<div class="station-config-container">
  <div class="station-config-tabs">
    <div :class="['station-config-tab', curTab == 'group' ? 'tab-active' : '']" @click="switchTab('group')">能耗统计</div>
    <div :class="['station-config-tab', curTab == 'station' ? 'tab-active' : '']" @click="switchTab('station')">设备通信异常统计</div>
    <div :class="['station-config-tab', curTab == 'device' ? 'tab-active' : '']" @click="switchTab('device')">电能质量分析</div>
    <div :class="['station-config-tab', curTab == 'power' ? 'tab-active' : '']" @click="switchTab('power')">报警统计</div>
  </div>
  <div class="station-config-tabcontent">
    <!-- <GroupList v-if="curTab == 'group'" />
    <StationList v-else-if="curTab == 'station'" />
    <DeviceList v-else-if="curTab == 'device'" />
    <PowerList v-else-if="curTab == 'power'" />
    <LightList v-else-if="curTab == 'light'" />
    <LuxList v-else-if="curTab == 'lux'" />
    <PtimeList v-else-if="curTab == 'ptime'" />
    <LtimeList v-else-if="curTab == 'ltime'" />
    <LuxRuleItem v-else-if="curTab == 'luxrule'" /> -->
  </div>
</div>
</template>
<script>
// import { mapState, mapGetters } from 'vuex'
// import GroupList from './group/Index'
// import StationList from './station/Index'
// import DeviceList from './device/Index'
// import PowerList from './power/Index'
// import LightList from './light/Index'
// import LuxList from './lux/Index'
// import PtimeList from './ptime/Index'
// import LtimeList from './ltime/Index'
// import LuxRuleItem from './luxrule/Index'
export default {
  name: 'StationConfigIndex',
  components:{
    // GroupList,
    // StationList,
    // DeviceList,
    // PowerList,
    // LightList,
    // LuxList,
    // PtimeList,
    // LtimeList,
    // LuxRuleItem,
  },
  props: {
  },
  data () {
    return {
      curTab: 'group',
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function(){
  },
  destroyed: function(){
  },
  methods: {
    switchTab: function(type){
      this.curTab = type;
    },
  }
}
</script>
<style scoped>
.station-config-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px red; */
}
.station-config-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-config-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-config-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>