<template>
<div class="light-container">
  <!-- <div class="power-areas">
    <CommonTree :args="{base: 'powerbox'}" />
  </div> -->
  <StationStatus class="station-content" v-if="!selectedNode.isChanghe" />
  <ChangheStatus class="station-content" v-if="selectedNode.isChanghe" />
  
  <div class="float-btns" v-if="!selectedNode.isChanghe">
    <div v-if="isType=='Station'" :class="['box-button-item', showStationMenu == 'info' ? 'box-button-active' : '']" @click.stop="switchMenu('info')"><img :src="showStationMenu == 'info' ? img.light.road.single.menu.infoActive : img.light.road.single.menu.infoCommon" /><span>{{showLang('com.right.nav.info')}}</span></div>
    <div v-if="isType=='Station'" class="box-button-line"></div>
    <div :class="['box-button-item', showStationMenu == 'control' ? 'box-button-active' : '']" @click.stop="switchMenu('control')"><img :src="showStationMenu == 'control' ? img.light.road.single.menu.alarmActive : img.light.road.single.menu.alarmCommon" /><span>{{showLang('com.right.nav.operate')}}</span></div>
    <div class="box-button-line"></div>
    <div :class="['box-button-item', showStationMenu == 'search' ? 'box-button-active' : '']" @click.stop="switchMenu('search')"><img :src="showStationMenu == 'search' ? img.light.road.single.menu.historyActive : img.light.road.single.menu.historyCommon" /><span>{{showLang('com.right.nav.query')}}</span></div>
    <div class="box-button-line"></div>
    <div :class="['box-button-item', showStationMenu == 'alarm' ? 'box-button-active' : '']" @click.stop="switchMenu('alarm')"><img :src="showStationMenu == 'alarm' ? img.light.road.single.menu.alarmActive : img.light.road.single.menu.alarmCommon" /><span>{{showLang('com.right.nav.alarm')}}</span></div>
    <div class="box-button-line"></div>
    <div :class="['box-button-item', showStationMenu == 'config' ? 'box-button-active' : '']" @click.stop="switchMenu('config')"><img :src="showStationMenu == 'config' ? img.light.road.single.menu.cfgActive : img.light.road.single.menu.cfgCommon" /><span>{{showLang('com.right.nav.config')}}</span></div>
  </div>
  <div class="police-icon" @click="policeClose" v-if="!selectedNode.isChanghe">
    <img :src="img.light.road.single.menu.police"/>
  </div>
  <AlarmFloatWin v-model="isShowPoliceBox" v-if="!selectedNode.isChanghe"/>
  
</div>
</template>
<script>
import Vue from 'vue';
import { mapState,mapGetters } from 'vuex'
// import CommonTree from '../tree/Index'
import StationStatus from './station/Index'
import ChangheStatus from './station/Changhe'
import AlarmFloatWin from './alarm/Index'
import { sessionData } from '@/plugins/cookie'
Vue.prototype.$sessionData = { sessionData }
export default {
  name: 'Home',
  components: {
    // CommonTree,
    StationStatus,
    AlarmFloatWin,
    ChangheStatus,
  },
  data() {
    return {
      isType: "root",
      editItem: {},
      showAlarmDataModal: false,
      isShowPoliceBox: false,
      policeTypeData: [],
    }
  },
  watch: {
    selectedNode() {
      this.isType = this.selectedNode.type;
    },
  },

  destroyed: function () {
  },
  computed: {
    ...mapState('group', ['selectedNode', 'groupLoaded', 'selectedDevices']),
    ...mapState('common', ['alarmTypes', 'productCodes']),
    ...mapState('auth', ['showStationMenu']),
    ...mapGetters('auth', ['showLang']),
  },
  mounted: function () {
    let isShow = this.$sessionData.sessionData('get', "policeBox", "");
    if (isShow == true || isShow == 'true') {
      this.isShowPoliceBox = true;
    } else {
      this.isShowPoliceBox = false;
    }
    this.isType = this.selectedNode.type;
  },
  methods: {
    policeClose() {
      this.isShowPoliceBox = !this.isShowPoliceBox
      this.$sessionData.sessionData('set', "policeBox", this.isShowPoliceBox);
    },
    switchMenu: function (menu) {
      this.$store.commit('auth/setStationMenu', menu);
    },
    showDeviceDetail: function (dev) {
      this.$refs.tree.selectNodeByData(dev);
    },
  }

}
</script>
<style scoped>
.power-areas {
  /* border: solid 1px red; */
  width: 300px;
  margin: 0;
  flex: none;
  background-color: #001f37;
  display: flex;
  flex-direction: column;
}
.light-container {
  background: #e3e9ef;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  /* border: solid 1px red; */
  position: relative;
}
.station-content {
  width: 300px;
  flex: auto;
  /* border: solid 1px red; */
}
.float-btns {
  position: absolute;
  top: 160px;
  right: 0;
  width: 37px;
  height: 400px;
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 200;
  background: #ffffff;
  box-shadow: 0px 4px 40px 0px rgba(56, 128, 252, 0.5);
  border-radius: 12px 0px 0px 12px;
  padding: 10px;
}
.btn-item {
  width: 36px;
  height: 102px;
  cursor: pointer;
}
.box-button-line {
  width: 20px;
  height: 1px;
  flex: none;
  background: #c0c5cb;
}
.box-button-item {
  flex: none;
  width: 20px;
  padding: 10px;
  color: #324252;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.box-button-item img {
  width: 14px;
  height: 14px;
}
.box-button-item span {
  width: 18px;
  margin: 5px 0 0 6px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
}
.box-button-active {
  color: #3880fc;
}
.police-icon {
  width: 110px;
  height: 40px;
  border: 4px solid #e1eaf8;
  border-radius: 10px;
  background: linear-gradient(0deg, #c30f0b, #f8514e);
  position: fixed;
  right: -70px;
  bottom: 35px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  /* text-align: center; */
  z-index: 500;
  display: flex;
  /* justify-content: center; */
  padding-top: 3px;
  cursor: pointer;
}
.police-icon img {
  vertical-align: middle;
  width: 26px;
  height: 25px;
  margin: 0 5px;
}
</style>