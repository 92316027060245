<template>
<div class="device-item-content">
  <div class="device-item-header">
    <img class="device-item-header-icon" :src="img.pole.alarm.icon" title="WIFI" />
    <span class="device-item-header-title">{{item.name}}</span>
    <img class="device-item-header-status-icon" :src="getImage()" />
    <span class="device-item-header-status">{{getStatus()}}</span>
  </div>
  <!-- <img class="wifi-flag" :src="img.pole.wifi.flag" /> -->
  <table border="0">
    <tr>
      <td class="table-title">主频：</td>
      <td class="table-value">{{info.system.cpu || '-'}}, 使用率:{{info.system.cpu_use}}%</td>
    </tr>
    <tr>
      <td class="table-title">存储：</td>
      <td class="table-value">闪存{{info.system.flash || '-'}}M, 内存{{info.system.ram || '-'}}M, 使用率:{{info.system.memory_use || '-'}}%</td>
    </tr>
    <tr>
      <td class="table-title">运行时间：</td>
      <td class="table-value">{{getRunTime(info.system.runtime)}}</td>
    </tr>
    <tr>
      <td class="table-title">无线广播数：</td>
      <td class="table-value">{{info.wifi.total || '-'}}信道：[{{getChannels(info.wifi)}}]</td>
    </tr>
    <tr>
      <td class="table-title">最大用户数：</td>
      <td class="table-value">{{getMaxUserCount(info.wifi)}}</td>
    </tr>
    <tr>
      <td class="table-title">ssid列表：</td>
      <td class="table-value">{{getWifiVaps(info.wifi)}}</td>
    </tr>
    <tr>
      <td class="table-title">当前用户数：</td>
      <td class="table-value">{{info.user.total || '-'}}, 共 {{getConnCnt(info.user)}} 连接</td>
    </tr>
    <tr>
      <td class="table-title">上传流量：</td>
      <td class="table-value">{{getUpload(info.user)}}</td>
    </tr>
    <tr>
      <td class="table-title">下载流量：</td>
      <td class="table-value">{{getDownLoad(info.user)}}</td>
    </tr>
  </table>
</div>
</template>
<script>
export default {
  name: 'WifiStationViewer',
  components:{
  },
  props: {
    item: {
      type: Object,
      default(){return {}},
    },
  },
  data () {
    return {
      info: {
        network: {},
        system: {},
        time_reboot: {},
        user: {
          list: [],
          total: 0,
        },
        wifi: {
          total: 0,
          timer: {},
          list: []
        }
      },
      param: {

      },
      count: 0,
      money: 0,
      energy: 0,
      alarms: 0,
      gb: 1024*1024*1024,
      mb: 1024*1024,
      kb: 1024,
    }
  },
  computed: {},
  watch: {
    item: function(){
      this.getInfo();
    }
  },
  mounted: function(){
    this.getInfo();
  },
  methods: {
    getConnCnt: function(user){
      if(!user.list)return '-';
      let cnt = 0;
      user.list.map(p => {
        cnt += parseInt(p.conn);
      });
      return cnt;
    },
    getUpload: function(user){
      if(!user.list)return '-';
      let pkgs = 0, bytes = 0;
      user.list.map(p => {
        pkgs += parseInt(p.up_pkts);
        bytes += parseInt(p.up_bytes);
      });
      if(bytes > this.gb){
        return `${pkgs}包, 共${(bytes/this.gb).toFixed(2)}Gb`;
      }else if(bytes > this.mb){
        return `${pkgs}包, 共${(bytes/this.mb).toFixed(2)}Mb`;
      }else if(bytes > this.kb){
        return `${pkgs}包, 共${(bytes/this.kb).toFixed(2)}Kb`;
      }else{
        return `${pkgs}包, 共${(bytes)}字节`;
      }
    },
    getDownLoad: function(user){
      if(!user.list)return '-';
      let pkgs = 0, bytes = 0;
      user.list.map(p => {
        pkgs += parseInt(p.down_pkts);
        bytes += parseInt(p.down_bytes);
      });
      if(bytes > this.gb){
        return `${pkgs}包, 共${(bytes/this.gb).toFixed(2)}Gb`;
      }else if(bytes > this.mb){
        return `${pkgs}包, 共${(bytes/this.mb).toFixed(2)}Mb`;
      }else if(bytes > this.kb){
        return `${pkgs}包, 共${(bytes/this.kb).toFixed(2)}Kb`;
      }else{
        return `${pkgs}包, 共${(bytes)}字节`;
      }
    },
    getWifiVaps: function(wifi){
      if(!wifi.list)return '-';
      let chs = [];
      wifi.list.map(p => {
        p.vap.map(q => {
          if(q.enable == '1'){
            let ssid = `${q.ssid}:${q.password}`;
            if(chs.indexOf(ssid) == -1){
              chs.push(ssid);
            }
          }
        });
      });
      return chs.join(',');
    },
    getChannels: function(wifi){
      if(!wifi.list)return '-';
      let chs = [];
      wifi.list.map(p => {
        chs.push(p.channel);
      });
      return chs.join(',');
    },
    getMaxUserCount: function(wifi){
      if(!wifi.list)return '-';
      let cnt = 0;
      wifi.list.map(p => {
        cnt += parseInt(p.max_sta);
      });
      return cnt;
    },
    getRunTime: function(secs){
      let hour = Math.floor(secs/3600);
      let remain = secs - hour * 3600;
      let min = Math.floor(remain / 60);
      remain -= min * 60;
      return `${hour}小时${min}分${remain}秒`;
    },
    getInfo: function(){
      if(!this.item || !this.item.id)return;
      this.$axios.post(`//${this.domains.trans}/station/wifi/QueryInfo`, {id: this.item.id}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          this.$set(this, 'info', {
            network: {},
            system: {},
            time_reboot: {},
            user: {
              list: [],
              total: 0,
            },
            wifi: {
              total: 0,
              timer: {},
              list: []
            }
          });
        }else{
          this.$set(this, 'param', res.data.param);
          this.$set(this, 'info', res.data.extra);
        }
        console.log('get wifi info', res, this.info, this.param)
      });
    },
    getStatus: function(){
      if(!this.item.online)return '离线';
      else return '在线';
    },
    getImage: function(){
      if(!this.item.online)return this.img.pole.offline;
      else return this.img.pole.online;
      // if(this.info.status == 1) return this.img.pole.online;
      // if(this.info.status == 2) return this.img.pole.online;
      // return this.img.pole.offline;
    },
  }
}
</script>
<style scoped>
.wifi-flag{
  width: 106px;
  height: 108px;
  position: absolute;
  top: 89px;
  left: 144px;
}
table{
  /* border: solid 1px red; */
  margin: auto;
  width: 100%;
}
.table-title{
  background: #EEF3F8;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
  width: 90x;
  height: 20px;
  padding: 3px 10px;
}
.table-value{
  background: #EEF3F8;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
  /* width: 165px; */
  height: 20px;
  padding: 3px 10px;
}
.device-item-content{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.device-item-header{
  height: 48px;
  /* background: #E8F1FA; */
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
}
.device-item-header-icon{
  width: 20px;
  height: 20px;
  margin-left: 25px;
  margin-right: 10px;
  flex: none;
}
.device-item-header-title{
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  width: 10px;
  flex: auto;
}
.device-item-header-status-icon{
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
.device-item-header-status{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  flex: none;
  margin-right: 25px;
}
</style>
