<template>
  <div class="config-device-container">
    <div class="device-search-area">
      <span class="ml10">{{showLang('com.tab.title')}}：</span>
      <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.export.cn.devname')"
        style="width: 200px; margin-right: 10px;"></Input>
      <span class="ml10">{{showLang('com.export.cn.connid')}}：</span>
      <Input type="text" v-model="filter.code" clearable :placeholder="showLang('com.export.cn.connid')"
        style="width: 200px; margin-right: 10px;"></Input>
      <span class="ml10">{{showLang('com.export.cn.category.big')}}：</span>
      <Select :placeholder="showLang('save.select')" ref="bigType" v-model="filter.bigType" number
        style="width: 200px; margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(item, idx) in bigTypes">
          <Option :value="item.code" :key="idx">{{item.name}}</Option>
        </template>
      </Select>
      <span class="ml10">{{showLang('com.export.cn.category.small')}}：</span>
      <Select :placeholder="showLang('save.select')" ref="smallType" v-model="filter.smallType" number
        style="width: 200px; margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(item, idx) in smallTypes">
          <Option v-if="item.type == filter.bigType" :value="item.code" :key="idx">{{item.name}}</Option>
        </template>
      </Select>
      <!-- <span class="ml10">硬件版本信息：</span>
      <Input type="text" v-model="filter.hardware" clearable :placeholder="showLang('com.export.cn.devname')"
        style="width: 200px; margin-right: 10px;"></Input>
      <span class="ml10">软件版本信息：</span>
      <Input type="text" v-model="filter.software" clearable :placeholder="showLang('com.export.cn.devname')"
        style="width: 200px; margin-right: 10px;"></Input> -->

      <Button size="default" type="primary" @click="initList"
        style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
      <!-- @click="exportData" -->
      <Button v-if="funCodes('de')" type="primary" style="margin-right: 5px"
        @click="batchEditTopDevice">{{showLang('edit.batch.top.device')}}</Button>
    </div>
    <div class="device-data-area" ref="table">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source
        ref="plTable" @checkbox-change="selectChangeEvent" :data="devices" :height="tabHeight"
        :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',highlight: true}">
        <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="name" :title="showLang('com.export.cn.devname')" sortable width="200" header-align="center">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column field="bigType" :title="showLang('com.export.cn.category.big')" sortable width="120">
          <template #default="{ row }">
            {{getBigTypeName(row.bigType)}}
          </template>
        </vxe-column>
        <vxe-column field="smallType" :title="showLang('com.export.cn.category.small')" sortable width="200">
          <template #default="{ row }">
            {{getSmallTypeName(row.smallType)}}
          </template>
        </vxe-column>
        <vxe-column field="type" :title="showLang('com.protocol.type')" sortable width="250">
          <template #default="{ row }">
            {{deviceTypes[row.type]}}
          </template>
        </vxe-column>
        <vxe-column field="code" :title="showLang('com.ins.last.id')" sortable width="180"></vxe-column>
        <vxe-column field="stationId" :title="showLang('station.belong')" sortable width="200">
          <template #default="{ row }">
            {{getStationName(row.stationId)}}
          </template>
        </vxe-column>
        <vxe-column field="parentName" :title="showLang('com.device.parent')" sortable width="200"></vxe-column>
        <vxe-column field="parentCode" :title="showLang('com.device.parent.comm.id')" sortable width="180"></vxe-column>
        <vxe-column field="simCard" :title="showLang('com.but.sim')" sortable width="150"></vxe-column>
        <vxe-column field="imei" title="IMEI" sortable width="150"></vxe-column>
        <vxe-column field="rssi" title="RSSI" sortable width="150"></vxe-column>
        <vxe-column field="hardwareVersion" :title="showLang('com.site.hw')" sortable width="200"></vxe-column>
        <vxe-column field="softwareVersion" :title="showLang('com.site.sw')" sortable width="200"></vxe-column>
        <!-- <vxe-column field="connectTime" :title="showLang('com.date.connection')" sortable width="160">
          <template #default="{ row }">
            {{row.connectTime == 0 ? '':`${new Date(row.connectTime).format('yyyy-MM-dd HH:mm:ss')}`}}
          </template>
        </vxe-column>
        <vxe-column field="fireTime" :title="showLang('com.ins.time.last')" sortable width="160">
          <template #default="{ row }">
            {{row.fireTime == 0 ? '':`${new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss')}`}}
          </template>
        </vxe-column>
        <vxe-column field="interval" :title="showLang('com.ins.time.Interval')" sortable width="140">
          <template #default="{ row }">
            {{row.interval == 0 ? showLang('com.state.no.inspection'):showLang('com.state.inspection.every.time',row.interval)}}
          </template>
        </vxe-column> -->
        <vxe-column width="288" fixed="right">
          <template slot="header">
            <AuthButton opCode='da' style="margin-right: 5px" @click="handleAddTop()">{{showLang('com.device.add.top')}}
            </AuthButton>
            <AuthButton type="error" opCode='dd'  @click="batchDelete()">
              {{showLang('com.op.batch.del')}}</AuthButton>
          </template>
          <template #default="{row}">
            <AuthButton opCode='da' style="margin-right: 5px" @click="handleAddSub(row)">
              {{showLang('com.device.add.sub')}}</AuthButton>
            <AuthButton opCode='de' style="margin-right: 5px" @click="handleEdit(row)">{{showLang('com.op.edit')}}
            </AuthButton>
            <AuthButton opCode='dd' type="error" @click="handleDelete(row)">{{showLang('com.op.del')}}</AuthButton>
          </template>
        </vxe-column>
      </vxe-table>
      <p> <vxe-pager :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
          :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes'
          @page-change="handlePageChange">
        </vxe-pager></p>
      <ModalDeviceEdit v-model="editModal" :item="editItem" :types="deviceTypes" :stations="selectedDevices"
        :devices="devices" @saved="itemDataSaved" />
      <ModalBatchTopDevice v-model="showBatchTopDevice" :item="editItem" :types="deviceTypes" :devices="devices"
        @saved="itemDataSaved" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalDeviceEdit from './ModalDeviceEdit'
import ModalBatchTopDevice from './ModalBatchTopDevice'
export default {
  name: 'ConfigDeviceIndex',
  components: {
    ModalDeviceEdit,
    ModalBatchTopDevice,
  },
  props: {
  },
  data() {
    return {
      tabHeight: 300,
      total: 0,
      editModal: false,
      pageSizes: [20, 100, 500, 1000, 5000],
      showBatchTopDevice: false,
      checked: true,
      loading: false,
      editItem: {
        isAdd: true,
        data: {}
      },
      devices: [],
      filter: {
        "groupId": 0,//当前分组ID
        "stationId": 0,//当前站点ID
        "name": "",//设备名称
        "code": "",//通信ID
        "bigType": 0,//设备大类
        "smallType": 0,//设备小类
        "simCard": "",//sim卡信息
        "imei": "",//imei信息
        "hardware": "",//硬件版本信息
        "software": "",//软件版本信息
        "size": 20,
        "index": 1,
      },
    }
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.filter.index=1;
      this.initList();
    },
  },
  computed: {
    ...mapState('group', ['groups', 'selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('common', ['productCodes', 'deviceTypes', 'bigTypes', 'smallTypes',]),
    ...mapGetters('common', ['getBigTypeName', 'getSmallTypeName', 'getSmallTypes']),
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 50);
    this.initList();
    // console.log('station device mounted', this.list)
  },
  methods: {
    selectChangeEvent({ rowIndex }) {
      this.$refs.plTable.reloadRow(this.devices, null, rowIndex)
    },
    batchEditTopDevice: function () {
      let chks = this.$refs.plTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要设置上级的设备');
        return;
      }
      let ids = [];
      chks.map(p => {
        ids.push(p.id);
      })
      this.editItem = { ids };
      this.showBatchTopDevice = true;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.initList()
    },
    initList: function () {
      this.filter.groupId = 0, this.filter.stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        this.filter.stationId = this.selectedNode.id;
      } else {
        this.filter.groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryDeviceByFilter`, this.filter).then(res => {
        if (res.code == 0) {
          // res.data.list.forEach(el => {
          //   let data = res.data.list.filter(item => item.id == el.parentId && el.topId != 0);
          //   if (data.length > 0) {
          //     el.parentName = data[0].name;
          //     el.parentCode = data[0].code;
          //   } else {
          //     el.parentName = "-";
          //     el.parentCode = "-";
          //   }
          // });
          this.$set(this, 'devices', res.data.list);
          this.$set(this, 'total', res.data.count);
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 60;
    },
    getStationName: function (id) {
      let ss = this.selectedDevices.filter(p => p.id == id);
      if (ss.length == 0) return '';
      return ss[0].name;
    },
    handleAddTop: function () {
      this.editItem = { isAdd: true, data: { stationId: this.selectedDevices.length > 0 ? this.selectedDevices[0].id : 0, parentId: 0 } };
      this.editModal = true;
    },
    batchDelete() {
      let chks = this.$refs.plTable.getCheckboxRecords();
      let list = [];
      chks.forEach(el => {
        list.push(el.id)
      });
      let ps = []
      list.forEach(el => {
        if (el != 0) {
          ps.push(...this.devices.filter(p => p.topId == el));
        }
      });
      ps.forEach((el, index) => {
        list.forEach(element => {
          if (el.id == element) {
            ps.splice(index, 1, false);
          }
        });
      });
      let num = ps.filter(p => p !== false);
      if (num.length > 0) {
        this.$Message.warning(this.showLang('com.device.sub.no.del'));
        return false;
      } else {
        this.$Modal.confirm({
          title: this.showLang('com.op.prompt'),
          content: this.showLang('com.op.batch.del.dev'),
          onOk: async () => {
            let res = await this.$axios.post(`//${this.domains.trans}/station/config/DeleteStationDevice`, { list: list });
            if (res.code == 0) {
              this.initList();
              this.$Message.info(this.showLang('com.tips.del.success'), { timeout: 4000 });
            } else {
              // this.$Message.error({
              //   content: `删除失败：${res.data}`,
              //   duration: 4
              // });
            }
          }
        });
      }
    },
    handleAddSub: function (params) {
      this.editItem = { isAdd: true, data: { stationId: params.stationId, parentId: params.id } };
      this.editModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params };
      this.editModal = true;
    },
    handleDelete: function (params) {
      let ps = this.devices.filter(p => p.topId == params.id);
      if (ps.length > 0) {
        this.$Message.warning(this.showLang('com.device.sub.no.del'));
        return;
      }
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name.code', params.name, params.code),
        onOk: async () => {
          let res = await this.$axios.post(`//${this.domains.trans}/station/config/DeleteStationDevice`, { list: [params.id] });
          if (res.code == 0) {
            this.initList();
            this.$Message.info(this.showLang('com.tips.del.success'), { timeout: 4000 });
          } else {
            // this.$Message.error({
            //   content: `删除失败：${res.data}`,
            //   duration: 4
            // });
          }
        }
      });
    },
    itemDataSaved: function () {
      this.initList();
    },
  }
}
</script>
<style scoped>
.config-device-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  background-color: white;
  border-radius: 8px;
}
.device-search-area {
  height: 42px;
  flex: none;
  /* border: solid 1px #dcdee2; */
  border-radius: 6px;
  margin-bottom: 5px;
  /* padding: 0 3px; */
  display: flex;
  align-items: center;
  align-items: center;
  margin: 5px 0;
  padding: 5px 3px;
}
.device-data-area {
  height: 100%;
  flex: auto;
  border-radius: 6px;
}
</style>