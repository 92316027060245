<template>
  <div class="alarm-rule-container">
    <div class="query-data-area">
      <AuthButton opCode='pd9' size="default" type="error" @click="deleteParams" style="margin: 0 5px;">{{showLang('com.op.batch.del')}}</AuthButton>
    </div>
    <div class="alarm-data-area" ref="table1">
      <u-table ref="chkTable" :data="list" style="width: 100%;" use-virtual row-key="id" border stripe :height="tabHeight" default-expand-all>
        <u-table-column type="index" header-align="center"></u-table-column>
        <u-table-column type="selection" header-align="center"></u-table-column>
        <u-table-column prop="name" :label="showLang('com.tab.title')" width="150" header-align="center">
          <template slot-scope="params">
            {{params.row.name}}
          </template>
        </u-table-column>
        <u-table-column prop="stationName" :label="showLang('station.belong')" sortable width="150" header-align="center">
          <template slot-scope="params">
            {{params.row.stationName}}
          </template>
        </u-table-column>
        <u-table-column prop="mainName" :label="showLang('com.association.main')" sortable width="150" header-align="center">
          <template slot-scope="params">
            {{params.row.mainName}}
          </template>
        </u-table-column>
        <u-table-column :label="showLang('water.type.level.1')" header-align="center">
          <u-table-column prop="sc1" :label="showLang('com.ins.ch.order')" sortable width="100" header-align="center">
            <template slot-scope="params">
              {{params.row.content.sc1 == 0 ? '-' : `S${params.row.content.sc1}`}}
            </template>
          </u-table-column>
          <u-table-column prop="av1" :label="showLang('alarm.type.alarm.val')" sortable width="100" header-align="center">
            <template slot-scope="params">
              {{params.row.content.av1 == 1 ? showLang('com.state.connection') : showLang('com.state.break')}}
            </template>
          </u-table-column>
          <u-table-column prop="ch1" :label="showLang('com.ope.nav.channel')" sortable width="110" header-align="center">
            <template slot-scope="params">
              {{params.row.content.ch1 == 0 ? '-' : `K${params.row.content.ch1}`}}
            </template>
          </u-table-column>
          <u-table-column prop="cmd1" :label="showLang('com.op.if.linkage')" sortable width="110" header-align="center">
            <template slot-scope="params">
              {{params.row.content.cmd1 ? showLang('com.state.enable'):showLang('com.state.disable')}}
            </template>
          </u-table-column>
          <u-table-column prop="open1" :label="showLang('com.op.linkage.switch')" sortable width="110" header-align="center">
            <template slot-scope="params">
              {{params.row.content.open1 ? showLang('com.op.linkage.on') : showLang('com.op.linkage.close')}}
            </template>
          </u-table-column>
          <u-table-column prop="expire1" :label="showLang('com.time.delay')" sortable width="110" header-align="center">
            <template slot-scope="params">
              {{params.row.content.expire1}} min
            </template>
          </u-table-column>
        </u-table-column>
        <u-table-column :label="showLang('water.type.level.2')" header-align="center">
          <u-table-column prop="sc2" :label="showLang('com.ins.ch.order')" sortable width="100" header-align="center">
            <template slot-scope="params">
              {{params.row.content.sc2 == 0 ? '-' : `S${params.row.content.sc2}`}}
            </template>
          </u-table-column>
          <u-table-column prop="av2" :label="showLang('alarm.type.alarm.val')" sortable width="100" header-align="center">
            <template slot-scope="params">
              {{params.row.content.av2 == 1 ? showLang('com.state.connection') : showLang('com.state.break')}}
            </template>
          </u-table-column>
          <u-table-column prop="ch2" :label="showLang('com.ope.nav.channel')" sortable width="110" header-align="center">
            <template slot-scope="params">
              {{params.row.content.ch2 == 0 ? '-' : `K${params.row.content.ch2}`}}
            </template>
          </u-table-column>
          <u-table-column prop="cmd2" :label="showLang('com.op.if.linkage')" sortable width="110" header-align="center">
            <template slot-scope="params">
              {{params.row.content.cmd2 ? showLang('com.state.enable'):showLang('com.state.disable')}}
            </template>
          </u-table-column>
          <u-table-column prop="open2" :label="showLang('com.op.linkage.switch')" sortable width="110" header-align="center">
            <template slot-scope="params">
              {{params.row.content.open2 ? showLang('com.op.linkage.on') : showLang('com.op.linkage.close')}}
            </template>
          </u-table-column>
          <u-table-column prop="expire2" :label="showLang('com.time.delay')" sortable width="110" header-align="center">
            <template slot-scope="params">
              {{params.row.content.expire2}} min
            </template>
          </u-table-column>
        </u-table-column>
        <u-table-column :label="showLang('water.type.level.3')" header-align="center">
          <u-table-column prop="sc3" :label="showLang('com.ins.ch.order')" sortable width="100" header-align="center">
            <template slot-scope="params">
              {{params.row.content.sc3 == 0 ? '-' : `S${params.row.content.sc3}`}}
            </template>
          </u-table-column>
          <u-table-column prop="av3" :label="showLang('alarm.type.alarm.val')" sortable width="100" header-align="center">
            <template slot-scope="params">
              {{params.row.content.av3 == 1 ? showLang('com.state.connection') : showLang('com.state.break')}}
            </template>
          </u-table-column>
          <u-table-column prop="ch3" :label="showLang('com.ope.nav.channel')" sortable width="110" header-align="center">
            <template slot-scope="params">
              {{params.row.content.ch3 == 0 ? '-' : `K${params.row.content.ch3}`}}
            </template>
          </u-table-column>
          <u-table-column prop="cmd3" :label="showLang('com.op.if.linkage')" sortable width="110" header-align="center">
            <template slot-scope="params">
              {{params.row.content.cmd3 ? showLang('com.state.enable'):showLang('com.state.disable')}}
            </template>
          </u-table-column>
          <u-table-column prop="open3" :label="showLang('com.op.linkage.switch')" sortable width="110" header-align="center">
            <template slot-scope="params">
              {{params.row.content.open3 ? showLang('com.op.linkage.on') : showLang('com.op.linkage.close')}}
            </template>
          </u-table-column>
          <u-table-column prop="expire3" :label="showLang('com.time.delay')" sortable width="110" header-align="center">
            <template slot-scope="params">
              {{params.row.content.expire3}} min
            </template>
          </u-table-column>
        </u-table-column>
        <u-table-column width="120" fixed="right">
          <template slot="header">
            <AuthButton opCode='pa9' style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</AuthButton>
            <AuthButton opCode='pv9' style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}</AuthButton>
          </template>
          <template slot-scope="scope">
            <AuthButton opCode='pe9' style="margin-right: 5px" @click="handleEdit(scope)">{{showLang('com.op.edit')}}</AuthButton>
            <AuthButton opCode='pd9' type="error" @click="handleDelete(scope)">{{showLang('com.op.del')}}</AuthButton>
          </template>
        </u-table-column>
      </u-table>
      <ModalWaterEdit v-model="showEditModal" :item="editItem" :stations="selectedStations" @saved="itemSaved" />
      <!-- <ModalArgEdit v-model="showArgModal" /> -->
    </div>
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import ModalWaterEdit from './ModalWaterEdit'
// import ModalArgEdit from './ModalArgEdit'
export default {
  name: 'ConfigBranchIndex',
  components: {
    ModalWaterEdit,
    // ModalArgEdit,
  },
  props: {
  },
  data() {
    return {
      curTab: 'hour24',
      list: [],
      tabHeight: 600,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showArgModal: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.getList();
    }
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    this.getList();
  },
  destroyed: function () {
  },
  methods: {
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 10;
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params.row };
      this.showEditModal = true;
    },
    itemSaved: function () {
      this.getList();
    },
    deleteParams: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.del.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, { list: list }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', params.row.name),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, { list: [params.row.id] }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    getList: function () {
      // console.log('windowOnResize', this.selectedStationIds.join(','))
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryWater`, { groupId, stationId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-rule-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area {
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  border: solid 1px rgba(143, 138, 138, 0.452);
  margin-bottom: 5px;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  padding-left: 10px;
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>