<template>
  <div class="box-header2">
    <div class="header-info">
      <div class="header-info-item"><img :src="img.light.road.single.header.unit" />{{showLang('com.site.info.manage')}}: {{organOwner.name}}</div>
      <div class="header-info-item"><img :src="img.light.road.single.header.personal" />{{showLang('com.site.info.liable')}}: {{organOwner.linkman}}</div>
      <div class="header-info-item"><img :src="img.light.road.single.header.phone" />{{showLang('com.site.phone')}}: {{organOwner.mobile}}</div>
      <div class="header-info-item"><img :src="img.light.road.single.header.unit" />{{showLang('com.site.info.servic')}}: {{organMaintenance.name}}</div>
      <div class="header-info-item"><img :src="img.light.road.single.header.personal" />{{showLang('com.site.info.liable')}}: {{organMaintenance.linkman}}</div>
      <div class="header-info-item"><img :src="img.light.road.single.header.phone" />{{showLang('com.site.phone')}}: {{organMaintenance.mobile}}</div>
    </div>
    <div class="header-simcard">
      <template v-for="(t, idx) in tops">
        <div class="simcard-item" :key="t.id" v-if="idx==0"><img :src="img.light.road.single.header.card" />{{showLang('com.site.deviceID')}}: {{t.code}}, {{showLang('com.site.block')}}{{idx+1}}：ICCID: {{t.simCard || '-'}}, IMEI: {{t.imei || '-'}}, {{showLang('com.site.hw')}}：{{t.hardwareVersion || '-'}}, {{showLang('com.site.sw')}}：{{t.softwareVersion || '-'}}</div>
      </template>
      <template v-if="tops.length>1">
        <a class="header-info-more" @click="modal=true">{{showLang('com.op.more')}}</a>
        <!-- <Poptip v-model="visible">
          <div slot="content" class="header-info-moreBox">
            <div class="simcard-item" v-for="(t, idx) in tops" :key="t.id">
              {{showLang('com.site.deviceID')}}: {{t.code}}, 
              {{showLang('com.site.block')}}{{idx+1}}：
              ICCID: {{t.simCard || '-'}}, 
              IMEI: {{t.imei || '-'}}, 
              RSSI: {{t.rssi || '-'}},
               {{showLang('com.site.hw')}}：{{t.hardwareVersion || '-'}},
               {{showLang('com.site.sw')}}：{{t.softwareVersion || '-'}}
            </div>
          </div>
        </Poptip> -->
        <Modal v-model="modal" :title="showLang('com.site.deviceID')" width="900">
          <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source ref="table" align="center" :key="dataRefresh" :data="tops" height="500" :row-config="{isHover: true}">
            <vxe-column type="seq" field="seq" width="60"></vxe-column>
            <vxe-column field="code" :title="showLang('com.site.deviceID')" header-align="center"></vxe-column>
            <!-- <vxe-column  field="code" :title="showLang('com.site.block')+index"  header-align="center"></vxe-column> -->
            <vxe-column field="simCard" title="ICCID" header-align="center"></vxe-column>
            <vxe-column field="imei" title="IMEI" header-align="center"></vxe-column>
            <!-- <vxe-column field="rssi" title="RSSI" header-align="center"></vxe-column> -->
            <vxe-column field="hardwareVersion" :title="showLang('com.site.hw')" header-align="center"></vxe-column>
            <vxe-column field="softwareVersion" :title="showLang('com.site.sw')" header-align="center"></vxe-column>
          </vxe-table>
          <!-- <Page :total="count" @on-change="pageIndexChange" style="text-align: right;" :page-size='20' /> -->
          <p>
            <vxe-pager :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']" :current-page.sync="form.index" :page-size.sync="form.size" :total="count" :page-sizes='pageSizes' @page-change="handlePageChange">
            </vxe-pager>
          </p>
        </Modal>
      </template>

    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'StationBaseIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      organMaintenance: {},
      organOwner: {},
      visible: false,
      modal: false,
      tops: [],
      count: 0,
      dataRefresh: 0,
      pageSizes:[20,100,500,1000,5000],
      form: {
        "index": 1,
        "size": 20,
      }
    }
  },
  computed: {
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState('common', ['weeks', 'productCodes', 'deviceTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    currentStation: function () {
      if (this.selectedNode.type == this.productCodes.station) {
        return this.selectedNode;
      }
      return null;
    },
  },
  watch: {
    selectedNode() {
      this.initData();
      this.queryWorkData();
    },
    modal() {
      this.form.index = 1
    }
  },
  mounted: function () {
    this.initData();
    this.queryWorkData();
  },
  destroyed: function () {
  },
  methods: {
    queryWorkData: function () {
      if (!this.currentStation) {
        this.$set(this, 'organMaintenance', {});
        this.$set(this, 'organOwner', {});
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/Maintance/QueryWorkInfo`, { stationId: this.currentStation.id }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'organMaintenance', res.data.maintenance);
          this.$set(this, 'organOwner', res.data.manage);
        }
      });
    },
    handlePageChange({ currentPage, pageSize }) {
      this.form.index = currentPage
      this.form.size = pageSize
      this.initData()
    },
    initData: function () {
      if (!this.currentStation) {
        this.$set(this, 'tops', []);
        return;
      }
      this.form.stationId = this.currentStation.id
      this.$axios.post(`//${this.domains.trans}/station/config/QueryTops`, this.form).then(res => {
        if (res.code == 0) {
          this.$set(this, 'tops', res.data.list);
          this.count = res.data.count;
          this.dataRefresh++;
        }
      });
    },
  }
}
</script>
<style scoped>
/* 站点信息 */
.box-header2 {
  flex: none;
  /* height: 172px; */
  background: #fff;
  margin-top: 16px;
  position: relative;
  padding: 0 30px;
}
.box-header2-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
  border-bottom: 1px solid #dee5ed;
  padding-bottom: 15px;
}
.header-info-region {
  float: right;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  margin-right: 35px;
}
.header-info {
  color: #324252;
  display: flex;
  justify-content: space-evenly;
  padding: 0 0 5px;
}
.header-info-item {
  flex: auto;
  text-align: left;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.header-info-item img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
  margin-top: -3px;
}
.header-info-region img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
  margin-top: -3px;
}
.header-simcard {
  height: 20px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff99;
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
}
.header-info-moreBox .simcard-item {
  margin-top: 10px;
}
.simcard-item {
  border-radius: 6px;
  padding: 2px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.simcard-item img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-left: -2px;
  margin-top: -3px;
}
</style>