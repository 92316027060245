<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        {{showLang('dev.meter.look.ammeter')}}：
        <Select :placeholder="showLang('save.select')" v-model="form.energyId" style="width: 150px; margin-right: 10px;">
          <!-- <Option :value="0">所有配电柜</Option> -->
          <template v-for="(item, idx) in energys">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
        {{showLang('com.date.start')}}：
        <DatePicker v-model="form.start" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
        {{showLang('com.date.end')}}：
        <DatePicker v-model="form.end" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
        <Button v-if="funCodes('spsv')" type="info" :loading="loading" @click="getList">{{showLang('com.op.query')}}</Button>
      </div>
      <div class="search-data-view">
        {{showLang('com.data.show')}}：
        <Select :placeholder="showLang('save.select')" v-model="viewCode" style="width: 180px; margin:3px 10px; flex: none;">
          <!-- <Option :value="0">所有配电柜</Option> -->
          <template v-for="(item, idx) in views">
            <Option :value="item.code" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
        <!-- <i-switch v-model="showTable" size="large" style="width: 56px; margin:3px 10px; flex: none;" true-color="green" false-color="blue">
        <span slot="open">表格</span>
        <span slot="close">图表</span>
      </i-switch> -->
        <Button v-if="funCodes('spse')" type="success" @click="exportData">{{showLang('com.op.export')}}</Button>
      </div>
    </div>
    <div class="switchType">
      <div :class="['switchType-tab fillet-left',  showTable? 'tab-active' : '']" @click="showTable=!showTable">{{showLang('com.data')}}</div>
      <div :class="['switchType-tab fillet-right',  showTable? '' : 'tab-active']" @click="showTable=!showTable">{{showLang('com.data.chart')}}</div>
    </div>
    <div class="search-data-content" ref="table1" v-if="showTable">
      <uTable :option="tabData" :tabHead="tabHead" :tabHeight="tabHeight"></uTable>
      <Spin size="large" fix v-if="loading"></Spin>
    </div>
    <div class="search-data-chart" v-if="!showTable">
      <!-- <div class="colorDeputy">
        <div class="deputy-list" v-for="(el,index) in chartsLineData.series" :key="index"><i style="background:#f00"></i>{{el.name}}</div>
      </div> -->
      <lineChart :option="chartsLineData" id="chartsLineData" :key="timer" v-if="isShow"></lineChart>
    </div>
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import uTable from '../table/Index'
import exportXlsx from '@/components/xlsx/exportXlsx'
import lineChart from '@/components/charts/line'
export default {
  name: 'SearchPowerboxIndex',
  components: {
    uTable,
    lineChart,
  },
  props: {
  },
  data() {
    return {
      loading: false,
      curTab: 'group',
      tabHeight: 1,
      tabData: [//列表数据
      ],
      tabHead: { //头部标题
        name: "名字",
        sex: "性别",
        age: "年龄",
      },
      form: {
        stationId: 0,
        start: "",
        end: "",
      },
      contactTexts: {
        0: '停止',
        1: '手动',
        2: '遥控',
        3: '时控',
      },
      timetable: {
        "0": "关灯时段",
        "1": "亮灯时段",
        "2": "未启用",
        "-1": "未设置",
        "-2": "已删除",
      },
      viewCode: '',
      showTable: true,
      views: [
        // {code: 'all', name: '全部显示', cols: []},
        // { code: 's1c', name: '支路一电流', cols: [] },
        // { code: 's1u', name: '支路一电压', cols: [] },
        // { code: 's1p', name: '支路一功率', cols: [] },
      ],
      history: [],
      energyId: 0,
      energys: [],
      chartsLineData: {
        color: ["#4166F5", "#0DEEBC", "#C96CFF"],
        title: "",
        x: ["Mon"],
        unit: "",
        legend: {
          data: ['Email'],
          left: "right",
        },
        series: [
          {
            name: "亮灯率",
            data: [150],
          },
        ],
        grid: {
          top: "5%", // 等价于 y: '16%'
          left: "40",
          right: "40",
          bottom: "3%",
          containLabel: true,
        },
        setSeries: {
          smooth: true, //平滑折线图
        },
      },
      timer: "",
      isShow: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    currentPower: function () {
      if (this.selectedNode.type == this.productCodes.station) {
        return this.selectedNode;
      }
      return null;
    },
    doors: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 6);
    },
    locks: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 7);
    },
    smokes: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 8);
    },
    waters: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 9);
    },
    switchs: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 2);
    },
    output: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 3);
    },
    contactor: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 4);
    },
    leakage: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 11);
    },
  },
  watch: {
    viewCode() {
      this.createColAndData();
    },
    selectedNode() {
      this.initEnergys();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 1);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.initEnergys();
    this.chartsLineData.series[0].name=this.showLang('com.stat.light.rate')
    // this.getList();
  },
  destroyed: function () {
  },
  methods: {
    initEnergys: function () {
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == 'root' || this.selectedNode.type == 'group'){
        groupId = this.selectedNode.id;
      }else{
        stationId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryEnergy`, { groupId, stationId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'energys', res.data);
          // console.log('get light data', res.data)
          if (res.data.length > 0) {
            this.form.energyId = res.data[0].id;
          } else {
            this.form.energyId = 0;
          }
        }
      });
    },
    setTableHeight: function () {
      if(!this.showTable)return;
      this.tabHeight = this.$refs.table1.clientHeight - 5;
      //this.tabHeight = 500 - 5;
    },
    exportData() {
      let dataArr = JSON.parse(JSON.stringify(this.tabData));
      let name = this.showLang('com.data.export');
      let vs = this.views.filter(p => p.code == this.viewCode);
      if (vs.length > 0) {
        name = vs[0].name+this.showLang('com.data.export');
      }
      exportXlsx(dataArr, this.tabHead, name);
    },
    getList() {
      if(!this.form.energyId){
        this.$Message.warning(this.showLang('energy.please.query.meter'));
        return;
      }
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let params = { paramId: this.form.energyId, start: this.form.start, end: this.form.end };
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/param/history`, params).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$set(this, 'history', res.data);
          this.calColViews();
        }
      });
    },
    calColViews: function () {
      let views = [];
      views.push({code: `c1`, name: `电流`, cols: { ts: '时间', ca: 'A相(A)', cb: 'B相(A)', cc: 'C相(A)' } });
      views.push({code: `c2`, name: `电压`, cols: { ts: '时间', ua: 'A相(V)', ub: 'B相(V)', uc: 'C相(V)' } });
      views.push({code: `c3`, name: `有功功率`, cols: { ts: '时间', apa: 'A相(W)', apb: 'B相(W)', apc: 'C相(W)' } });
      views.push({code: `c4`, name: `无功功率`, cols: { ts: '时间', rpa: 'A相(Var)', rpb: 'B相(Var)', rpc: 'C相(Var)' } });
      views.push({code: `c5`, name: `功率因数`, cols: { ts: '时间', fa: 'A相', fb: 'B相', fc: 'C相' } });
      views.push({code: `c6`, name: `能耗`, cols: { ts: '时间', ae: '有功电能(KWh)', re: '无功电能(Kvarh)' } });
      this.$set(this, 'views', views);
      if (this.views.length > 0) {
        this.viewCode = this.views[0].code;
      } else {
        this.viewCode = '';
      }
      this.createColAndData();
    },
    createColAndData: function () {
      if (this.viewCode == '') {
        this.tabData = [];
        this.tabHead = {};
        return;
      }
      let vs = this.views.filter(p => p.code == this.viewCode);
      if (vs.length == 0) {
        this.tabData = [];
        this.tabHead = {};
        return;
      }
      let v = vs[0];
      this.$set(this, 'tabHead', v.cols);
      this.history.sort((a, b) => {
        if(a.fireTime == b.fireTime)return 0;
        return a.fireTime > b.fireTime ? 1 : -1;
      });
      let list = []
      for (let item of this.history) {
        let di = {};
        for (let key in v.cols) {
          di['ts'] = new Date(item.fireTime).format('yyyy-MM-dd HH:mm:ss');
          let v = item.content[key];
          if (v == -1) {
            v = this.showLang('com.state.not.measured');
          } else if (v == -2) {
            v = this.showLang('com.not.set');
          }
          di[key] = v;
        }
        list.push(di);
      }
      this.$set(this, 'tabData', list);
      this.chartData();
    },
    chartData() {
      this.chartsLineData.x = [];
      this.chartsLineData.series = [];
      Object.keys(this.tabHead).map(key => {
        if (key != "ts") {
          this.chartsLineData.series.push({
            name: key,
            data: [],
          })
        }
      })
      this.tabData.forEach(el => {
        this.chartsLineData.x.push(el.ts);
        this.chartsLineData.series.forEach((item, index) => {
          if (el[item.name] == this.showLang('com.state.not.measured') || el[item.name] == this.showLang('com.not.set')) {
            el[item.name] = 0;
          }
          this.chartsLineData.series[index].data.push(el[item.name])
        });
      });
      let vs = this.views.filter(p => p.code == this.viewCode);
      let cols = vs[0].cols;
      this.chartsLineData.legend.data = [];
      this.chartsLineData.series.forEach((item, index) => {
        this.chartsLineData.series[index].name = cols[item.name];
        this.chartsLineData.legend.data.push(item.name)
      });

      this.isShow = false;
      this.timer = new Date().getTime();
      this.isShow = true;
    },
    leakageStatus: function (params) {
      if (params.cl > (this.leakage[0].content.lh)) return this.showLang('com.lighting.severe.leakage');
      if (params.cl > (this.leakage[0].content.ll)) return  this.showLang('com.lighting.minor.leakage');
      return this.showLang('com.lighting.cl.no');
    },
    switchStatus: function (params) {
      if (params.hv == 1) return this.showLang('com.op.hand');
      if (params.rv == 1) return this.showLang('com.op.remote');
      if (params.tv == 1) return this.showLang('com.op.time.control');
      return this.showLang('com.op.cease');
    },
  }
}
</script>
<style scoped>
.switchType {
  height: 30px;
  display: flex;
  flex-direction: initial;
  margin: 10px 0 0 10px;
}
.switchType-tab {
  width: 68px;
  height: 30px;
  background: #eef4f9;
  color: #324252;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.fillet-left {
  border-top-left-radius: 6px;
}
.fillet-right {
  border-top-right-radius: 6px;
}
.switchType-tab.tab-active {
  background: #3880fc;
  color: #ffffff;
}
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  margin-top: 10px;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: none;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
.search-data-chart {
  width: calc(100% - 20px);
  margin: 0 auto;
  height: 500px;
  position: relative;
}
.colorDeputy {
  position: absolute;
  right: 40px;
  display: flex;
}
.deputy-list {
  margin-right: 10px;
}
.deputy-list i {
  display: inline-block;
  width: 5px;
  height: 5px;
}
</style>