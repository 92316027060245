<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">{{title}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="120" :key="form.parentId">
      <!-- <FormItem prop="isTop" label="是否顶级设备">
      <i-switch ref="isTop" v-model="form.isTop" />
    </FormItem> -->
      <FormItem prop="stationId" :label="showLang('com.site.affiliated')">
        <Select :placeholder="showLang('save.select')" ref="stationId" v-model="form.stationId" number :disabled="!isAdd">
          <template v-for="(item, idx) in stations">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="parentId" :label="showLang('com.superior.dev')">
        <Select :placeholder="showLang('save.select')"  ref="parentId" v-model="form.parentId" number>
          <Option :value="0">{{showLang('com.state.not')}}</Option>
          <template v-for="(item, idx) in tops">
            <Option v-if="form.id <= 0 || form.id != item.id" :value="item.id" :key="idx">{{item.typeName}}：{{item.name}}[ID:{{item.code}}]</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="bigType" :label="showLang('com.export.cn.category.big')">
        <Select :placeholder="showLang('save.select')"  ref="bigType" v-model="form.bigType" number>
          <template v-for="(item, idx) in bigTypes">
            <Option :value="item.code" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="smallType" :label="showLang('com.export.cn.category.small')">
        <Select :placeholder="showLang('save.select')"  ref="smallType" v-model="form.smallType" number @on-change="smallTypeChanged">
          <template v-for="(item, idx) in smallTypes">
            <Option v-if="item.type == form.bigType" :value="item.code" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
        <!-- {{form.smallType}} -->
      </FormItem>
      <FormItem prop="type" :label="showLang('com.protocol.type')">
        <Select :placeholder="showLang('save.select')"  ref="type" v-model="form.type" disabled @on-change="protocalTypeChanged">
          <OptionGroup v-for="(group, gkey) in types.groups" :key="gkey" :label="group.name">
            <Option v-for="(item, key) in group.list" :value="item" :key="key">{{ types[item] }}</Option>
          </OptionGroup>
        </Select>
        {{form.type}}
      </FormItem>
      <FormItem prop="name" :label="showLang('com.export.cn.devname')">
        <Input ref="name" type="text" v-model="form.name"></Input>
      </FormItem>
      <FormItem v-if="form.type != 'zxklm20'" prop="code" :label="showLang('com.ins.last.id')">
        <Input ref="code" type="text" maxlength="20" v-model="form.code" :placeholder="showLang('save.light.code.need')"></Input>
      </FormItem>
      <FormItem v-if="form.type != 'zxklm20'" prop="channel" :label="showLang('com.ins.ch.parameter')">
        <div v-if="form.type == 'dms8088iv'">
          <table border="0">
            <tr>
              <td style="width: 100px; text-align:center;">{{showLang('com.tab.slogan')}}</td>
              <td style="width: 220px; text-align:center;">{{showLang('com.export.cn.type')}}</td>
              <td style="width: 60px">{{showLang('com.tab.access.num')}}</td>
              <td style="width: 60px"><Button type="success" @click="addSub">{{showLang('com.op.add')}}</Button></td>
            </tr>
            <tr v-for="(item, idx) in channelDevices" :key="idx">
              <td>
                <Select :placeholder="showLang('save.select')" v-model="item.com">
                  <template v-for="(v, k) in coms8088names">
                    <Option v-if="k != '3'" :key="k" :value="parseInt(k)">{{v}}</Option>
                  </template>
                </Select>
              </td>
              <td>
                <Select :placeholder="showLang('save.select')" v-model="item.type">
                  <template v-for="(group, gkey) in types.groups">
                    <OptionGroup v-if="group.name == showLang('com.device.measuring')" :key="gkey" :label="group.name">
                      <Option v-for="(item, key) in group.list" :value="item" :key="key">{{ types[item] }}</Option>
                    </OptionGroup>
                  </template>
                </Select>
              </td>
              <td><Input type="number" maxlength="20" v-model="item.count"></Input></td>
              <td><Button type="error" @click="removeSub(idx)">{{showLang('com.op.del')}}</Button></td>
            </tr>
          </table>
        </div>
        <Input v-else ref="channel" type="text" maxlength="20" v-model="form.channel" :placeholder="showLang('com.ins.ch.dev.parameters')"></Input>
      </FormItem>
      <FormItem v-if="form.type == 'zxklm20'" prop="code" :label="showLang('com.light.account.need')">
        <Select :placeholder="showLang('save.select')" ref="code" v-model="form.code">
          <Option v-for="(item, key) in accounts" :value="`ZXKL:${item.id}`" :key="key">{{ item.name }}:{{item.appKey}}</Option>
        </Select>
      </FormItem>
      <FormItem prop="simCard" :label="showLang('com.but.sim')">
        <Input ref="simCard" type="text" v-model="form.simCard"></Input>
      </FormItem>
      <!-- <FormItem prop="interval" label="数据巡检时间">
        <Select :placeholder="showLang('save.select')" v-model="form.interval">
          <Option :value="0">不巡检</Option>
          <Option :value="1">每隔1分钟正点</Option>
          <Option :value="2">每隔2分钟正点</Option>
          <Option :value="3">每隔3分钟正点</Option>
          <Option :value="5">每隔5分钟正点</Option>
          <Option :value="10">每隔10分钟正点</Option>
          <Option :value="15">每隔15分钟正点</Option>
          <Option :value="20">每隔20分钟正点</Option>
          <Option :value="30">每隔30分钟正点</Option>
          <Option :value="60">每隔60分钟正点</Option>
        </Select>
      </FormItem> -->
      <FormItem prop="timeout" :label="showLang('com.date.offline.timeout')">
        <Input ref="timeout" type="number" v-model="form.timeout"><span slot="append">min</span></Input>
      </FormItem>
      <FormItem prop="calOnline" label="">
        <Checkbox v-model="form.calOnline">{{showLang('com.cal.station.online')}}</Checkbox>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalEditDevice',
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    types: {
      type: Object,
      default() { return {}; }
    },
    devices: {
      type: Array,
      default() { return []; }
    },
    stations: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      title: '编辑设备信息',
      loading: false,
      showModal: this.value,
      isAdd: false,
      form: {
        id: '',
        stationId: 0,
        name: '',
        code: '',
        type: '',
        bigType: 0,
        smallType: 0,
        parentId: 0,
        channel: '2',
        interval: 15,
        simCard: '',
        timeout: 5,
        calOnline: true, //是否计入站点在线逻辑
      },
      channelDevices: [],
      accounts: [],
      rules: {
        name: { required: true, type: 'string', max: 30, message: '设备名称不能为空且长度不能超过30个字符', trigger: 'blur' },
        type: { required: true, type: 'string', message: '必须选择一个设备类型', trigger: 'on-change' },
        stationId: { required: true, type: 'number', message: '必须选择一个所属站点', trigger: 'on-change' },
      },
      tops: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('auth', ['roles', 'customers', 'appScenes']),
    ...mapState('common', ['productCodes', 'deviceTypes', 'bigTypes', 'smallTypes', 'coms8088names']),
    ...mapGetters('common', ['getBigTypeName', 'getSmallTypeName', 'getSmallTypes']),
  },
  watch: {
    form:{
       handler(val){
         if(val.parentId==0){
            this.rules.code={ required: true, type: 'string', message: '添加通信ID', trigger: 'on-change'}
         }else{
           delete this.rules.code;
         }
       },
      deep:true
    },
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        // console.log('edit device', this.item)
        this.isAdd = this.item.isAdd;
        this.channelDevices = [];
        if (this.isAdd) {
          this.form.id = "";
          this.form.stationId = this.item.data.stationId;
          this.form.code = "";
          this.form.type = "";
          this.form.name = '';
          this.form.channel = '2';
          this.form.parentId = this.item.data.parentId;
          this.form.interval = 15;
          this.form.timeout = 5;
          this.form.simCard = '';
          this.form.bigType = 0;
          this.form.smallType = 0;
          this.form.calOnline = true;
        } else {
          this.form.id = this.item.data.id;
          this.form.stationId = this.item.data.stationId;
          this.form.code = this.item.data.code;
          this.form.type = this.item.data.type;
          this.form.name = this.item.data.name;
          this.form.channel = this.item.data.channel;
          this.form.parentId = this.item.data.parentId;
          this.form.interval = this.item.data.interval;
          this.form.timeout = this.item.data.timeout;
          this.form.simCard = this.item.data.simCard;
          this.form.bigType = this.item.data.bigType;
          this.form.smallType = this.item.data.smallType;
          this.form.calOnline = this.item.data.calOnline;
          if (this.form.type == 'dms8088iv') {
            if (!this.form.channel || this.form.channel[0] != '[') this.form.channel = '[]';
            this.channelDevices = JSON.parse(this.form.channel)
          }
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    "form.stationId"(){
      this.getTops();
    }
  },
  mounted: function () {
      this.title=this.showLang('com.but.edit.deviceInfo');
      this.rules.name.message=this.showLang('com.save.err.len.name',30);
      this.rules.type.message=this.showLang('com.save.err.dev.type');
      this.rules.stationId.message=this.showLang('com.save.err.station');
  },
  methods: {
    getTops: function(){
      if(!this.form.stationId){
        this.$set(this, "tops", []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryParentDevices`, {stationId: this.form.stationId}).then(res => {
        if(res.code != 0)return;
        this.$set(this, "tops", res.data);
      });
    },
    addSub: function () {
      this.channelDevices.push({ com: 0, type: '', count: 1 });
    },
    removeSub: function (idx) {
      this.channelDevices.splice(idx, 1);
    },
    protocalTypeChanged: function () {
      if (this.form.type == 'dms8088iv') {
        if (!this.form.channel) this.form.channel = '[]';
        this.channelDevices = JSON.parse(this.form.channel)
      } else {
        this.channelDevices = [];
      }
      // console.log('protocal type changed', this.form.type)
    },
    smallTypeChanged: function () {
      if (this.form.smallType <= 0) {
        this.form.type = '';
      } else {
        let ts = this.smallTypes.filter(p => p.code == this.form.smallType);
        if (ts.length == 0) {
          this.form.type = '';
        } else {
          this.form.type = ts[0].protocol;
        }
      }
      // console.log('small type changed', this.form.smallType, this.form.type)
    },
    ok: async function () {
      let result = await this.$refs.form.validate();
      if (!result) {
        return;
      }
      this.form.parentId = parseInt(this.form.parentId);
      if (this.form.parentId == 0 && this.form.code == '') {
        this.$Message.warning(this.showLang('com.dev.save.err.id'))
        return;
      }
      if (this.form.type == 'dms8088iv') {
        this.form.channel = JSON.stringify(this.channelDevices);
      }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/config/SaveDevice`, this.form).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$Message.info(this.showLang('com.tips.save'));
          setTimeout(() => {
            this.showModal = false;
            this.$emit('saved');
          }, 800);
        } else {
          // this.$Message.error(res.data);
        }
      });
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>