<template>
<div class="union-container">
  <div class="union-header">
    <Input v-model="filter.name" style="width: 300px" search clearable placeholder="查询关键字" />
    <Button type="info" :loading="loading" @click="getList" style="margin-left: 10px;">查询</Button>
  </div>
  <div class="union-content" ref="table">
    <u-table :data="list" use-virtual style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all>
      <u-table-column type="index" label="序号" width="50"></u-table-column>
      <u-table-column prop="enable" label="是否启用" width="80">
        <template slot-scope="params">
          {{params.row.config.enable ? '启用' : '停用'}}
        </template>
      </u-table-column>
      <u-table-column prop="name" label="规则名称" sortable width="200">
        <template slot-scope="params">
          {{params.row.config.name}}
        </template>
      </u-table-column>
      <u-table-column prop="weeks" label="周计划" width="400">
        <template slot-scope="params">
          {{params.row.config.weeks.map(p => weeks[p]).join(', ')}}
        </template>
      </u-table-column>
      <u-table-column prop="day" label="日计划" width="400">
        <template slot-scope="params">
          {{getDayPlan(params.row)}}
        </template>
      </u-table-column>
      <u-table-column prop="day" label="触发设备" width="400">
        <template slot-scope="params">
          {{params.row.condDevices}}
        </template>
      </u-table-column>
      <u-table-column prop="day" label="联动设备" width="400">
        <template slot-scope="params">
          {{params.row.actionDevices}}
        </template>
      </u-table-column>
      <u-table-column prop="type" label="规则说明" sortable width="400">
        <template slot-scope="params">
          {{params.row.config.description}}
        </template>
      </u-table-column>
      <u-table-column width="175" fixed="right">
        <template slot="header" slot-scope="scope">
          <AuthButton opCode='sa' style="margin-right: 5px" @click="handleAdd(scope)">新增</AuthButton>
        </template>
        <template slot-scope="scope">
          <AuthButton opCode='se' :type="scope.row.config.enable ?  'warning' : 'success'" style="margin-right: 5px" @click="handleEnable(scope)">{{scope.row.config.enable ? '禁用' : '启用'}}</AuthButton>
          <AuthButton opCode='se' style="margin-right: 5px" @click="handleEdit(scope)">编辑</AuthButton>
          <AuthButton opCode='sd' type="error" @click="handleDelete(scope)">删除</AuthButton>
        </template>
      </u-table-column>
    </u-table>
  </div>
  <ModalUnionEdit v-model="showEditModal" :item="editItem" :codes="codes" :actions="actions" :medias="medias" :stations="stations" :devices="devices" @saved="dataSaved" />
</div>
</template>
<script>
import { mapState } from 'vuex'
import ModalUnionEdit from './ModalUnionEdit'
export default {
  name: 'PoleUnionIndex',
  components: {
    ModalUnionEdit
  },
  data() {
    return {
      loading: false,
      showEditModal: false,
      tabHeight: 300,
      editItem: {isAdd: true, index: -1, data: {}},
      filter: {
        name: '',
      },
      list: [],
      codes: [],
      actions: [],
      medias: [],
      stations: [],
      devices: [],
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('group', ['selectedNode', 'groupLoaded', 'selectedDevices']),
    ...mapState('common', ['alarmTypes', 'productCodes', 'weeks']),
    selectedStationIds: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
    },
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    selectedNode(){
      this.getList();
    },
  },
  destroyed: function () {
  },
  mounted: function () {
    this.getActions();
    this.getCodes();
    setTimeout(() => {
      this.setTableHeight();
    }, 200);
    this.getList();
    this.getMedias();
    this.getAllStations();
    this.getAllDevices();
  },
  methods: {
    getAllStations: function(){
      this.$axios.post(`//${this.domains.trans}/station/union/QueryStation`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'stations', res.data);
      });
    },
    getAllDevices: function(){
      this.$axios.post(`//${this.domains.trans}/station/union/QueryDevice`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'devices', res.data);
      });
    },
    getMedias: function(){
      this.$axios.post(`//${this.domains.trans}/media/operation/GetAll`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'medias', res.data);
      });
    },
    getDayPlan: function(row){
      return `${new Date(row.config.start1).format('HH:mm')} - ${new Date(row.config.end1).format('HH:mm')}, 
      ${new Date(row.config.start2).format('HH:mm')} - ${new Date(row.config.end2).format('HH:mm')}, 
      ${new Date(row.config.start3).format('HH:mm')} - ${new Date(row.config.end3).format('HH:mm')}, 
      ${new Date(row.config.start4).format('HH:mm')} - ${new Date(row.config.end4).format('HH:mm')}`;
      // console.log('get day plan', new Date(row.config.start1).format('HH:mm'))
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 3;
    },
    getCodes: function(){
      this.$axios.get(`//${this.domains.trans}/union/config/codes`).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'codes', res.data);
      });
    },
    getActions: function(){
      this.$axios.get(`//${this.domains.trans}/union/config/action`).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'actions', res.data);
      });
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/union/config/station`, {ids: this.selectedStationIds, name: this.filter.name}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'list', res.data);
      });
    },
    handleAdd: function(){
      let stationId = 0;
      if(this.selectedNode.type == 'station'){
        stationId = this.selectedNode.id;
      }
      this.editItem = {isAdd: true, data: {stationId: stationId}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除联动配置[${params.row.config.name}]吗?`,
        onOk: async () => {
          this.$axios.delete(`//${this.domains.trans}/union/config/list/${params.row.config.id}`).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
              return;
            }
            this.$Message.info('操作成功');
            this.getList();
          });
        }
      });
    },
    handleEnable: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要${params.row.config.enable?'禁用':'启用'}联动配置[${params.row.config.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/union/config/enable`, {id: params.row.config.id, enable: !params.row.config.enable}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
              return;
            }
            this.$Message.info('操作成功');
            this.getList();
          });
        }
      });
    },
    dataSaved: function(){
      this.getList();
    },
  }
}
</script>
<style scoped>
.union-container {
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: stretch;
  /* border: solid 1px red; */
  position: relative;
}
.union-header{
  /* border: solid 1px red; */
  height: 42px;
  flex: none;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.union-content{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>